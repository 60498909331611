import React, { useEffect } from 'react';
// import { ManCheckingStatsAnimation, Title } from '@keymono/design-system';
import { useAuthStore } from '@keymono/services';
import {
  PeriodDTO,
  useMetrics,
  SeriesMetric,
  MetricFactory,
  SeriesMetricColorMap,
} from '@keymono/apis';
import { CalendarDropdown, DropdownMenu, Title } from '@keymono/design-system';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import {
  DefaultScoreCard,
  DefaultScoreCardLoader,
} from './components/ScoreCard';
import {
  MetricCompareChart,
  MetricCompareChartLoader,
} from './components/MetricCompareChart';

function SalesScores({
  periods,
  loading,
}: {
  periods?: [MetricFactory, MetricFactory];
  loading: boolean;
}) {
  if (!periods || loading) {
    return <DefaultScoreCardLoader />;
  }

  const metric: SeriesMetric['tag'] = 'series:sales';
  const [currentPeriod, previousPeriod] = periods;
  const prev = previousPeriod.getSeriesMetric(metric);
  const curr = currentPeriod.getSeriesMetric(metric);

  return (
    <DefaultScoreCard
      title={curr.name}
      titleScore={MetricFactory.formatMetric(curr.val, curr.uom, 'en-us')}
      titleScoreChange={MetricFactory.percChange(prev.val, curr.val)}
      subScores={[
        { title: 'Top selling SKU', score: currentPeriod.sku.bestSeller },
        {
          title: 'Top selling SKU sales %',
          score: MetricFactory.formatMetric(
            currentPeriod.sku.totalSalesPrice / curr.val,
            'percentage',
            'en-us'
          ),
        },
      ]}
      barData={{
        color: SeriesMetricColorMap[metric],
        label: 'Sales',
        unit: '$',
        data: [prev.val, curr.val],
      }}
    />
  );
}

function AverageSalesScores({
  periods,
  loading,
}: {
  periods?: [MetricFactory, MetricFactory];
  loading: boolean;
}) {
  if (!periods || loading) {
    return <DefaultScoreCardLoader />;
  }

  const metric: SeriesMetric['tag'] = 'series:sales:price';
  const [currentPeriod, previousPeriod] = periods;
  const prev = previousPeriod.getSeriesMetric(metric);
  const curr = currentPeriod.getSeriesMetric(metric);

  const salesVelocity = currentPeriod.getFiniteMetric('finite:sales:velocity');

  return (
    <DefaultScoreCard
      title="Avg. sales price"
      titleScore={MetricFactory.formatMetric(curr.val, curr.uom, 'en-us')}
      titleScoreChange={MetricFactory.percChange(prev.val, curr.val)}
      subScores={[
        {
          title: 'Sales velocity',
          score: MetricFactory.formatMetric(
            salesVelocity.val,
            salesVelocity.uom,
            'en-us'
          ),
        },
      ]}
      barData={{
        color: SeriesMetricColorMap[metric],
        label: 'Avg. sales price',
        unit: '',
        data: [prev.val, curr.val],
      }}
    />
  );
}

function UnitsScores({
  periods,
  loading,
}: {
  periods?: [MetricFactory, MetricFactory];
  loading: boolean;
}) {
  if (!periods || loading) {
    return <DefaultScoreCardLoader />;
  }

  const metric: SeriesMetric['tag'] = 'series:units';
  const [currentPeriod, previousPeriod] = periods;
  const prev = previousPeriod.getSeriesMetric(metric);
  const curr = currentPeriod.getSeriesMetric(metric);

  const fullPrice = currentPeriod.getSeriesMetric('series:units:price:full');
  const discounted = currentPeriod.getSeriesMetric(
    'series:units:price:discounted'
  );

  return (
    <DefaultScoreCard
      title="Units sold"
      titleScore={MetricFactory.formatMetric(curr.val, curr.uom, 'en-us')}
      titleScoreChange={MetricFactory.percChange(prev.val, curr.val)}
      subScores={[
        {
          title: 'Full price',
          score: MetricFactory.formatMetric(
            fullPrice.val,
            fullPrice.uom,
            'en-us'
          ),
        },
        {
          title: 'Discounted',
          score: MetricFactory.formatMetric(
            discounted.val,
            discounted.uom,
            'en-us'
          ),
        },
      ]}
      barData={{
        color: SeriesMetricColorMap[metric],
        label: 'Units sold',
        unit: '',
        data: [prev.val, curr.val],
      }}
    />
  );
}

function OrdersScores({
  periods,
  loading,
}: {
  periods?: [MetricFactory, MetricFactory];
  loading: boolean;
}) {
  if (!periods || loading) {
    return <DefaultScoreCardLoader />;
  }

  const metric: SeriesMetric['tag'] = 'series:orders:value';
  const [currentPeriod, previousPeriod] = periods;
  const prev = previousPeriod.getSeriesMetric(metric);
  const curr = currentPeriod.getSeriesMetric(metric);

  const totalOrders = currentPeriod.getSeriesMetric('series:orders');
  const unitsPerOrder = currentPeriod.getSeriesMetric('series:units-per-order');

  return (
    <DefaultScoreCard
      title="Avg. order value"
      titleScore={MetricFactory.formatMetric(curr.val, curr.uom, 'en-us')}
      titleScoreChange={MetricFactory.percChange(prev.val, curr.val)}
      subScores={[
        {
          title: 'Orders',
          score: MetricFactory.formatMetric(
            totalOrders.val,
            totalOrders.uom,
            'en-us'
          ),
        },
        {
          title: 'Avg. units / order',
          score: MetricFactory.formatMetric(
            unitsPerOrder.val,
            unitsPerOrder.uom,
            'en-us'
          ),
        },
      ]}
      barData={{
        color: SeriesMetricColorMap[metric],
        label: 'Orders',
        unit: '$',
        data: [prev.val, curr.val],
      }}
    />
  );
}

function makePeriods(fromDate: Date, toDate: Date): [PeriodDTO, PeriodDTO] {
  const fromDate0 = dayjs(fromDate);
  const toDate0 = dayjs(toDate);

  const daysDiff = fromDate0.diff(toDate0, 'days');

  const toDate1 = fromDate0.subtract(1, 'days');
  const fromDate1 = toDate1.subtract(-daysDiff, 'days');

  const result = [
    { dateFrom: fromDate0.toDate(), dateTo: toDate0.toDate() },
    { dateFrom: fromDate1.toDate(), dateTo: toDate1.toDate() },
  ];

  return result as [PeriodDTO, PeriodDTO];
}

function usePeriodMetrics({
  orgId,
  authToken,
}: {
  authToken: string;
  orgId: string;
}) {
  const [selectedPeriod, setSelectedPeriod] = React.useState<PeriodDTO>({
    dateFrom: dayjs().toDate(),
    dateTo: dayjs().toDate(),
  });
  const [comparePeriod, setComparePeriod] = React.useState<PeriodDTO>({
    dateFrom: dayjs().subtract(1, 'day').toDate(),
    dateTo: dayjs().subtract(1, 'day').toDate(),
  });

  const setPeriods = React.useCallback((range: PeriodDTO) => {
    const [a, b] = makePeriods(range.dateFrom, range.dateTo);
    setSelectedPeriod(a);
    setComparePeriod(b);
  }, []);

  const metricsQueryReuslt = useMetrics(
    { authToken, orgId, periods: [selectedPeriod, comparePeriod] },
    { retry: false, refetchOnWindowFocus: false }
  );

  return {
    selectedPeriod,
    comparePeriod,
    setPeriods,
    metricsQueryReuslt,
  };
}

function Dashboard({ orgId, authToken }: { authToken: string; orgId: string }) {
  const {
    selectedPeriod,
    comparePeriod,
    setPeriods,
    metricsQueryReuslt: { data, isLoading, error },
  } = usePeriodMetrics({ orgId, authToken });

  function comparisonText() {
    return `${dayjs(comparePeriod.dateFrom).format('MM/DD/YYYY')} - ${dayjs(
      comparePeriod.dateTo
    ).format('MM/DD/YYYY')}`;
  }

  if (error) {
    switch (error.errorType) {
      case 'client':
        return <div>Client error</div>;
      case 'server':
        return <div>Server error</div>;
      default:
        return <div>Unexpected error</div>;
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <Title tag="h3">Dashboard</Title>
        <div className="flex items-end gap-4">
          <div>
            <span className="text-xs text-gray-600">
              {'compared with '}
              {comparisonText()}
            </span>
            <CalendarDropdown
              inputWidth="min-w-[230px]"
              label="Period"
              startDate={selectedPeriod.dateFrom}
              endDate={selectedPeriod.dateTo}
              minDate={dayjs().subtract(3, 'month').toDate()}
              maxDate={dayjs().toDate()}
              onChange={(range) => {
                setPeriods({
                  dateFrom: range!.startDate as unknown as Date,
                  dateTo: range!.endDate as unknown as Date,
                });
              }}
            />
          </div>
          <div>
            <DropdownMenu
              label="Channel"
              options={[{ key: 'amazon', label: 'Amazon' }]}
              defaultOption={{ key: 'amazon', label: 'Amazon' }}
            />
          </div>
        </div>
      </div>
      <main className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <SalesScores periods={data?.periods} loading={!data || isLoading} />
        <AverageSalesScores
          periods={data?.periods}
          loading={!data || isLoading}
        />
        <UnitsScores periods={data?.periods} loading={!data || isLoading} />
        <OrdersScores periods={data?.periods} loading={!data || isLoading} />

        <div className="col-span-4">
          {data && <MetricCompareChart metrics={data.periods[0]} />}
          {isLoading && <MetricCompareChartLoader />}
        </div>
      </main>
    </div>
  );
}

function useActiveSession() {
  const query = useRouter().query as { organizationId: string };
  const store = useAuthStore();
  const [state, setState] = React.useState<{
    token: string;
    activeOrgId: string;
  }>({
    token: store.activeSession?.token || '',
    activeOrgId: query.organizationId,
  });
  useEffect(() => {
    if (!store) return;
    if (!store.activeSession) return;
    // eslint-disable-next-line prefer-const
    let { token, activeOrgId } = store.activeSession;
    if (!activeOrgId) {
      activeOrgId = query.organizationId;
    }
    setState({ token, activeOrgId });
  }, [store, setState, query]);

  return state;
}

export function OrgDashboardOverview() {
  const { token, activeOrgId } = useActiveSession();
  return <Dashboard authToken={token} orgId={activeOrgId} />;
}
