import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

import { IApiError } from '@keymono/apis';
import { useAuthStore } from '@keymono/services';
import { ButtonLoadingIndicator, IUIError } from '@keymono/design-system';
import { IActiveSession } from '@keymono/shared-types';

import { useLogOutHook } from '../hooks';

interface ISignOutErrorFallbackProps {
  error: IUIError | null;
  index?: number;
}

/**
 *------------------------------------------------------------------------------
 *Error showed incase of failure to sign out of a single account or multiple
 *accounts
 */
function SignOutErrorFallback({ error, index }: ISignOutErrorFallbackProps) {
  return (
    <div className="py-1 text-center text-xs text-red-500">
      {error?.message}
      <span>{index}</span>
    </div>
  );
}
interface ISignOutOfAllAccountsBtnProps {
  label: string;
  targetSession?: IActiveSession | null;
  isMultipleSignOut?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a sign out button that will trigger a another modal requesting the
 * user of whether they really wish to sign out of all or just a single account.
 *
 * @returns JSX.Element
 */
export function SignOutBtn({
  label,
  targetSession,
  isMultipleSignOut,
}: ISignOutOfAllAccountsBtnProps) {
  const { replace } = useRouter();
  const {
    allSessionsByIndex,
    removeSession,
    removeAllSessions,
    sessionsUnderSignOut,
    updateSessionsUnderSignOut,
    allSessionsIndices,
  } = useAuthStore();

  const [hasError, setHasError] = useState<{
    error: IApiError;
    index: number;
  } | null>(null);

  const handleSignOutSuccess = () => {
    setHasError(null);
    const sessionsCount = allSessionsIndices.length;

    if (targetSession) {
      removeSession(targetSession);
    } else {
      removeAllSessions();
    }

    if (sessionsCount > 1 && targetSession) {
      replace('/login?showActiveSessions=true');
    } else {
      replace('/auth/login');
    }
  };

  const handleSignOutError = (error: IApiError, index: number) => {
    // TODO: This needs to be revised with better error handling
    // eslint-disable-next-line no-console

    setHasError({ error, index });
  };

  const handleClearSignOutError = () => {
    setHasError(null);
  };

  /* eslint-disable indent */
  const signOutPayload = hasError
    ? []
    : Object.values(allSessionsByIndex!)
        .filter(
          (otherActiveSessions) =>
            otherActiveSessions.token !== targetSession?.token
        )
        .map((session) => ({
          token: session.token,
          userId: session.userId,
          index: session.index,
        }));
  /* eslint-disable indent */

  const tokens = targetSession?.token ?? signOutPayload;

  const { isLoading, handleSignOut, error } = useLogOutHook({
    onClearSignOutError: handleClearSignOutError,
    onSignOutError: handleSignOutError,
    onSignOutSuccess: handleSignOutSuccess,
    token: tokens,
  });

  const [isMultipleSigOutButton, setIsMultipleSigOutButton] = useState(false);

  const handleMultipleSignOut = () => {
    updateSessionsUnderSignOut(signOutPayload);
    setIsMultipleSigOutButton(true);
    handleSignOut();
  };

  useEffect(() => {
    if (error) updateSessionsUnderSignOut([]);
  }, [error, updateSessionsUnderSignOut]);

  const sessionToSignOut = sessionsUnderSignOut?.find(
    (token) => token.token === targetSession?.token
  )?.token;

  return (
    <>
      <div className="relative py-1.5">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-100" />
        </div>
        <div className="relative flex justify-center">
          {isMultipleSignOut ? (
            <button
              type="button"
              className="form-btn-variant-secondary group"
              onClick={handleMultipleSignOut}
              disabled={isMultipleSigOutButton}
            >
              <ArrowRightOnRectangleIcon
                className="group-hover:text-red-800"
                aria-hidden="true"
              />
              <span>{label}</span>
            </button>
          ) : (
            <button
              type="button"
              className="form-btn-variant-secondary group"
              onClick={handleSignOut}
            >
              {isLoading || sessionToSignOut ? (
                <ButtonLoadingIndicator
                  className="shrink-0 ltr:mr-5 rtl:ml-5"
                  isEnabled
                />
              ) : (
                <ArrowRightOnRectangleIcon
                  className="group-hover:text-red-800"
                  aria-hidden="true"
                />
              )}
              <span>{label}</span>
            </button>
          )}
        </div>
      </div>
      {hasError ? <SignOutErrorFallback error={hasError.error} /> : null}
    </>
  );
}
