import { IBaseUIFallbackCommonOptions } from '@keymono/design-system';

import { InvitationsListLoader } from './InvitationsListLoader';
import { InvitationsListError } from './InvitationsListError';

interface IInvitationsListFallbackProps extends IBaseUIFallbackCommonOptions {
  invitationsCount: number;
}

/**
 * -----------------------------------------------------------------------------
 * Renders the loading state or error state for `InvitationsList`.
 */
export function InvitationsListFallback({
  isLoading,
  invitationsCount,
  error,
  onRetryAction,
}: IInvitationsListFallbackProps) {
  if (error) {
    return (
      <div className="mx-auto mb-40 flex flex-col items-center justify-center px-4 pb-10">
        <InvitationsListError error={error} onRetryAction={onRetryAction} />
      </div>
    );
  }
  return isLoading ? (
    <InvitationsListLoader invitationsCount={invitationsCount} />
  ) : null;
}
