import { useState } from 'react';
import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';
import { useForgotPasswordMutation, IApiError } from '@keymono/apis';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';
import {
  EmailAddressInputField,
  Button,
  FormFeedbackSection,
  LogoKeymono,
  useForm,
} from '@keymono/design-system';

import { FormContainer, StyledForm } from '../styles';

import { LoginInsteadCTA } from './LoginInsteadCTA';
import { LoginCTA } from './LoginCTA';
import { RetryForgotPasswordCTA } from './RetryForgotPasswordCTA';

interface IForgotPasswordFormInputFields {
  email: string;
}

export interface IForgotPasswordFormProps {
  isFormSubmitted: boolean;
  onSubmitSuccess: () => void;
  onRetryForgotPassword: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the forgot password form that captures the email address and
 * sends an auth link to the user, to be used in password reset.
 */
export default function ForgotPasswordForm({
  onSubmitSuccess,
  onRetryForgotPassword,
  isFormSubmitted,
}: IForgotPasswordFormProps) {
  const t = useTranslations('ForgotPassword');
  const tCommon = useTranslations('Common');

  const [formFeedback, setFormFeedback] = useState({
    message: t('formIntro.message'),
    description: t('formIntro.description'),
  });

  const handleOnForgotPasswordError = (error: IApiError) => {
    // TODO: Move this to the mutation/query
    errorLogToRemoteUtil({
      error,
      errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
      errorTitle: 'Error in Forgot Password Attempt',
      message: error.message,
    });
  };

  const handleOnForgotPasswordSuccess = () => {
    setFormFeedback({
      message: t('formSuccess.message'),
      description: t('formSuccess.description'),
    });
    onSubmitSuccess();
  };

  const {
    error,
    isLoading: isFormSubmitting,
    submitForgotPasswordEmail,
  } = useForgotPasswordMutation({
    onSuccessCallback: handleOnForgotPasswordSuccess,
    // TODO: Temp, remove it later.
    onErrorCallback: handleOnForgotPasswordError,
  });

  const errorFields = error?.errorFields;

  const handlePostEmail = ({ email }: IForgotPasswordFormInputFields) => {
    submitForgotPasswordEmail({
      email,
    });
  };

  /**
   * The fields to be tracked within the form.
   */
  const forgotPasswordInputFieldsValues: IForgotPasswordFormInputFields = {
    email: '',
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: forgotPasswordInputFieldsValues,
    onSubmitCb: handlePostEmail,
  });

  return (
    <FormContainer>
      <div className="form-responsive-wrapper">
        <div>
          <Link
            href="/"
            className="my-8 hidden w-auto sm:inline-flex"
            aria-label={tCommon('logoAriaLabel')}
            title={tCommon('logoAriaLabel')}
          >
            <LogoKeymono />
          </Link>
          <div className="form-header flex">
            <h1 className="grow">{t('title')}</h1>
          </div>

          {error?.message ? (
            <FormFeedbackSection
              isFormSubmitting={isFormSubmitting}
              type="error"
              message={error.message}
              description={error.description}
            />
          ) : (
            <FormFeedbackSection
              isFormSubmitting={isFormSubmitting}
              type="info"
              message={formFeedback.message}
              // messageLoadingText={t('formSubmitting.message')}
              messageLoadingText=""
              description={formFeedback.description}
              // descriptionLoadingText={t('formSubmitting.description')}
              descriptionLoadingText=""
            />
          )}
        </div>

        {isFormSubmitted ? (
          <div>
            <RetryForgotPasswordCTA onRetry={onRetryForgotPassword} />
            <LoginCTA />
          </div>
        ) : (
          <StyledForm onSubmit={handleOnSubmitForm}>
            <EmailAddressInputField
              error={errorFields?.email || errors.email}
              label={t('formFields.email.label')}
              name="email"
              onBlurCallback={handleOnBlur}
              onChangeCallback={handleOnInputChange}
              onError={handleOnError}
              placeholder={t('formFields.email.placeholder')}
              value={values.email}
            />

            <Button
              type="submit"
              isEnabled
              isLoading={isFormSubmitting}
              label={t('formFields.submit.label')}
              loadingLabel={t('formFields.submit.loadingLabel')}
            />

            <LoginInsteadCTA />
          </StyledForm>
        )}
      </div>
    </FormContainer>
  );
}
