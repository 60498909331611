import { ReactNode } from 'react';

import {
  OrdersListTableContainer,
  OrdersListTableHeaderWrapper,
  OrdersListTableHeader,
  OrdersListTableHeaderColumnLabelStyledSpan as ColumnLabel,
} from './styles';

interface IOrdersListBaseProps {
  children: ReactNode;
  className?: string;
  isMobileViewDisabled?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This is the base component used by `OrdersListFallback` and `OrdersListTable`
 * Renders the table UI with the header and passes the children prop to the table body
 */
export function OrdersListBase({
  children,
  className = '',
  isMobileViewDisabled = false,
}: IOrdersListBaseProps) {
  return (
    <OrdersListTableContainer
      isMobileViewDisabled={isMobileViewDisabled}
      className={className}
    >
      <OrdersListTableHeaderWrapper isMobileViewDisabled={isMobileViewDisabled}>
        <OrdersListTableHeader>
          <div className="col-span-1 max-lg:hidden">
            <input
              id="select-all-orders"
              aria-describedby="select-all-orders"
              name="select-all-orders"
              type="checkbox"
              className="text-colorPri-600 focus:ring-colorPri-600 scale-75 rounded border-gray-300"
            />
          </div>
          <ColumnLabel className="col-span-6">Channel Order</ColumnLabel>
          <ColumnLabel className="col-span-5">Channel Date</ColumnLabel>
          <ColumnLabel className="col-span-3">Channel</ColumnLabel>
          <ColumnLabel className="col-span-2">Items</ColumnLabel>
          <ColumnLabel className="col-span-4">Total</ColumnLabel>
          <ColumnLabel className="col-span-5">Fulfillment Channel</ColumnLabel>
          <ColumnLabel className="col-span-5">Fulfillment Status</ColumnLabel>
          <ColumnLabel className="col-span-5">Order Status</ColumnLabel>
        </OrdersListTableHeader>
      </OrdersListTableHeaderWrapper>
      {children}
    </OrdersListTableContainer>
  );
}
