import { IconButton } from '@keymono/design-system';

interface IColItemActionButtonProps {
  label: string;
}

/**
 * -----------------------------------------------------------------------------
 * Col Item component that renders an Action Button.
 */
export function ColItemActionButton({ label }: IColItemActionButtonProps) {
  /**
   * TODO: Implement the action handler.
   */
  const handleOnClick = () => {};

  return (
    <IconButton
      className="w-max rounded-lg border border-gray-100 bg-gray-100 text-gray-500 hover:bg-gray-200 max-lg:hidden"
      type="button"
      onClick={handleOnClick}
    >
      <IconButton.Label>{label}</IconButton.Label>
    </IconButton>
  );
}
