import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { IIntegration } from '@keymono/apis';

import { IntegrationsSidePanelContent } from './IntegrationsSidePanelContent';

interface IAddIntegrationModal2Props {
  integration: IIntegration;
  isModalVisible: boolean;
  orgId: string;
  onClosePanel: () => void;
}

/**
 * ----------------------------------------------------------------------------
 * This side panel provides the options for adding a new integration by providing
 * customizable inputs to capture the integration requirements.
 */
export function AddIntegrationSidePanel({
  integration,
  isModalVisible,
  orgId,
  onClosePanel,
}: IAddIntegrationModal2Props) {
  return (
    <Transition.Root show={isModalVisible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClosePanel}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className="
                pointer-events-none fixed inset-y-0 flex max-w-full ps-10
                ltr:right-0 rtl:left-0 sm:ps-16
              "
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="ltr:translate-x-full rtl:-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="ltr:translate-x-full rtl:-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <IntegrationsSidePanelContent
                    integration={integration}
                    onClosePanel={onClosePanel}
                    orgId={orgId}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
