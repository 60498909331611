import { PhoneIcon, PencilIcon } from '@heroicons/react/24/outline';

import { CardWrapper } from '../styles';

interface INamesCardProps {
  phonePrimary?: string;
  onEdit: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the user's contact mobile numbers summary
 */
export function ContactNumbersCard({ phonePrimary, onEdit }: INamesCardProps) {
  const handlePressEdit = () => {
    onEdit();
  };

  return (
    <CardWrapper className="group">
      <div>
        <span className="inline-flex rounded-lg bg-violet-50 p-3 text-violet-700 ring-4 ring-white">
          <PhoneIcon className="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium">
          <p className="focus:outline-none">
            {phonePrimary ? (
              <span className="mx-1">{phonePrimary}</span>
            ) : (
              'No number added'
            )}
          </p>
        </h3>
        <p className="mt-2 text-sm text-gray-500">
          {phonePrimary
            ? 'The primary contact number is private and will not shared by anyone.'
            : 'Please add a phone number to allow for alternative logins and add additional security verification to your account.'}
        </p>
      </div>

      <button
        type="button"
        title="Edit your contact numbers"
        className="
          form-link-action
          absolute
          right-6 top-6 flex cursor-pointer rounded-lg
          p-2
          text-gray-300
          opacity-80
          hover:bg-red-100
          hover:text-red-700
          group-hover:text-red-700 group-hover:opacity-100
        "
        aria-hidden="true"
        onClick={handlePressEdit}
      >
        <PencilIcon className="h-6 w-6" />
      </button>
    </CardWrapper>
  );
}
