// import { useTranslations } from '@keymono/i18n';
import { IUserInvite } from '@keymono/shared-types';

import { OrgInvitationsListItem } from './OrgInvitationsListItem';

interface IOrganizationsListViewProps {
  invitations: IUserInvite[];
}

/**
 * -----------------------------------------------------------------------------
 * This renders the list of alongside the invitations to join an org the user has
 * recently but not accepted
 */
export function OrgInvitationsList({
  invitations,
}: IOrganizationsListViewProps) {
  // const t = useTranslations('Login');

  return (
    <div className="mt-10">
      <h2 className="mt-2 text-center text-xl font-medium text-gray-900">
        Pending Invitations
      </h2>
      <p className="mb-8 mt-1 text-center text-sm text-teal-800">
        You have been invited to the following organizations.
      </p>

      <ul
        className="
          flex flex-col flex-wrap items-center justify-center gap-2 sm:flex-row
        "
      >
        {invitations.map((invitation) => (
          <OrgInvitationsListItem
            key={invitation.orgName}
            invitation={invitation}
          />
        ))}
      </ul>
    </div>
  );
}
