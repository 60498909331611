import { useQuery } from '@tanstack/react-query';

import { IApiError, IPublicChoicesUrlOptions } from '../../../../types';

import { COMMON_API } from '../commonAPIClient';

export const QUERY_FETCH_PUBLIC_CHOICE_KEY = 'fetch-public-choices-key';

/**
 * Defining the interface for the useChoicesQuery hook.
 * Also exporting it so we can use it when we call the hook in a component.
 * Currently used in `apps/console-web/src/components/CountrySelectorInput/CountrySelectorInput.tsx`
 */
export interface IUseChoicesQueryProps extends IPublicChoicesUrlOptions {}

/**
 * This fetches a list of all invitations for the user to join an organization.
 */
export const useChoicesQuery = ({ ...options }: IUseChoicesQueryProps) => {
  const { type, page, query } = options;

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [QUERY_FETCH_PUBLIC_CHOICE_KEY, type, page, query],
    networkMode: 'always',
    enabled: !!type,
    queryFn: () => COMMON_API.fetchChoices(options),
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isLoading,
    refetch,
  };
};
