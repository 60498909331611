import { PhoneIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { useTranslations } from '@keymono/i18n';
import { useAuthStore } from '@keymono/services';
import { useUpdateUserDetailsMutation } from '@keymono/apis';
import {
  Button,
  FormFeedbackSection,
  TextInputField,
  useForm,
} from '@keymono/design-system';

import { CardWrapper } from '../styles';

interface IUpdateUserDetailsFormInputFields {
  phonePrimary: string;
}

interface INamesCardEditProps {
  onExitEditMode: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This card allows for the user to update their phone numbers.
 * TODO: Refactor more to merge common items with NamesCard
 */
export function ContactNumbersCardEdit({
  onExitEditMode,
}: INamesCardEditProps) {
  const t = useTranslations('UserProfile.contactPhonesSection');
  const user = useAuthStore().activeSession?.user;

  const handleCancelEdit = () => {
    onExitEditMode();
  };

  const handleOnUpdateNamesSuccess = () => {
    onExitEditMode();
  };

  const {
    error,
    isLoading: isFormSubmitting,
    updateUserDetails,
  } = useUpdateUserDetailsMutation({
    onSuccessCallback: handleOnUpdateNamesSuccess,
  });
  const errorFields = error?.errorFields;

  const handlePostDetails = (values: IUpdateUserDetailsFormInputFields) => {
    updateUserDetails({
      userId: user?.id || '',
      phone_primary: values.phonePrimary || '',
    });
  };

  /**
   * The fields to be tracked within the form.
   */
  const contactInputFieldsValues: IUpdateUserDetailsFormInputFields = {
    phonePrimary: user?.phonePrimary || '',
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: contactInputFieldsValues,
    onSubmitCb: handlePostDetails,
  });

  return (
    <CardWrapper className="group">
      <div>
        <span className="inline-flex rounded-lg bg-violet-50 p-3 text-violet-700 ring-4 ring-white">
          <PhoneIcon className="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <form className="mt-5 sm:mt-6" onSubmit={handleOnSubmitForm}>
        {error?.message ? (
          <FormFeedbackSection
            isFormSubmitting={isFormSubmitting}
            type="error"
            message={error.message}
            description={error.description}
          />
        ) : (
          <FormFeedbackSection
            isFormSubmitting={isFormSubmitting}
            type="info"
            message={t('editFormIntro.message')}
            description={t('editFormIntro.description')}
          />
        )}

        <TextInputField
          type="tel"
          error={errorFields?.phone_primary || errors.phonePrimary}
          label={t('formFields.phonePrimary.label')}
          name="phonePrimary"
          onBlurCallback={handleOnBlur}
          onChangeCallback={handleOnInputChange}
          onError={handleOnError}
          placeholder={t('formFields.phonePrimary.placeholder')}
          value={values.phonePrimary}
        />

        <div className="mt-2 flex w-full justify-end gap-2">
          <button
            type="button"
            onClick={handleCancelEdit}
            className="form-btn-secondary w-auto py-1.5 text-xs"
            disabled={isFormSubmitting}
          >
            {t('formFields.cancelCTA.label')}
          </button>
          <Button
            type="submit"
            className="form-btn w-auto px-8 py-1 text-xs"
            isEnabled={!isFormSubmitting}
            isLoading={isFormSubmitting}
            label={t('formFields.submitCTA.label')}
            loadingLabel={t('formFields.submitCTA.loadingLabel')}
          />
        </div>
      </form>
      <button
        type="button"
        title={t('closeCTATitle')}
        className="
          form-link-action
          absolute
          right-6 top-6 flex cursor-pointer rounded-lg
          p-2
          text-gray-300
          opacity-40
          hover:bg-red-100
          hover:text-red-700
          group-hover:text-red-700 group-hover:opacity-100
        "
        aria-hidden="true"
        onClick={handleCancelEdit}
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
    </CardWrapper>
  );
}
