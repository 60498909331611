import { UserCircleIcon, PencilIcon } from '@heroicons/react/24/outline';

import { CardWrapper } from '../styles';

interface INamesCardProps {
  firstName: string;
  lastName: string;
  otherName: string;
  onEdit: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the user's names summary
 * TODO: Refactor more to merge common items with NamesCardEdit
 */
export function NamesCard({
  firstName,
  lastName,
  otherName,
  onEdit,
}: INamesCardProps) {
  const handlePressEdit = () => {
    onEdit();
  };

  return (
    <CardWrapper className="group">
      <div>
        <span className="inline-flex rounded-lg bg-red-50 p-3 text-red-700 ring-4 ring-white">
          <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium">
          <p className="focus:outline-none">
            <span className="mx-1">{firstName}</span>
            {lastName ? <span className="mx-1">{lastName}</span> : null}
            {otherName ? <span className="mx-1">{otherName}</span> : null}
          </p>
        </h3>
        <p className="mt-2 text-sm text-gray-500">
          These names will appear on your emails, invoices, transactions,
          documents and in organizations you are part of.
        </p>
      </div>

      <button
        type="button"
        title="Edit your profile names"
        className="
          form-link-action
          absolute
          right-6 top-6 flex cursor-pointer rounded-lg
          p-2
          text-gray-300
          opacity-80
          hover:bg-red-100
          hover:text-red-700
          group-hover:text-red-700 group-hover:opacity-100
        "
        aria-hidden="true"
        onClick={handlePressEdit}
      >
        <PencilIcon className="h-6 w-6" />
      </button>
    </CardWrapper>
  );
}
