import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { useAuthStore } from '@keymono/services';
import { IAuthState } from '@keymono/shared-types';

interface IUseSessionInstanceReturn {
  activeSession: IAuthState['activeSession'];
  allSessionsByIndex: IAuthState['allSessionsByIndex'];
  otherSessionsIndices: number[];
  sessionsCount: number;
  updateActiveSession: IAuthState['updateActiveSession'];
}

/**
 * -----------------------------------------------------------------------------
 * This hook taps into the active sessions data, filters out the selected one
 * based on the current accountId query param and filters it from the rest of
 * the other session instances.
 */
export function useSessionInstance(): IUseSessionInstanceReturn {
  const router = useRouter();
  const {
    activeSession,
    allSessionsByIndex,
    allSessionsIndices,
    updateActiveSession,
  } = useAuthStore();

  /**
   * This gets the session instance array index from the query params.
   * Fallback to 0 to target the first one if nothing else if found.
   */
  const { accountId = '0' } = router.query;
  const sessionIndex = useRef(0);

  const otherSessionsIndices = allSessionsIndices.filter(
    (instanceIndex) => instanceIndex !== sessionIndex.current
  );

  useEffect(() => {
    if (typeof accountId === 'string') {
      sessionIndex.current = parseInt(accountId, 10) || 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return {
    activeSession,
    otherSessionsIndices,
    allSessionsByIndex,
    sessionsCount: allSessionsIndices?.length,
    updateActiveSession,
  };
}
