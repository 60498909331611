type AsyncResult = any;
type AsyncError = any;
type AsyncReturnFail<TError> = [null, TError];
type AsyncReturnSuccess<TResult> = [TResult, null];
export type AsyncReturn<TResult = any, TError = any> =
  | AsyncReturnSuccess<TResult>
  | AsyncReturnFail<TError>;
type AsyncFn = Promise<AsyncResult>;

/**
 * This function will never throw an error. All errors are caught and returned as a tuple
 */
export async function executeAsyncFn<
  TResult = AsyncResult,
  TError = AsyncError
>(fn: AsyncFn): Promise<AsyncReturn<TResult, TError>> {
  try {
    const data: TResult = await fn;
    return [data, null] as [TResult, null];
  } catch (error) {
    return [null, error as TError] as [null, TError];
  }
}

export function asyncResultIsError<TResult, TError>(
  v: AsyncReturn<TResult, TError>
): v is AsyncReturnFail<TError> {
  return v[1] !== null;
}
