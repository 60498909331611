import {
  IApiError,
  TMultiLogoutResponseData,
  useLogoutMutation,
} from '@keymono/apis';

interface IUseLogOutHookOptions {
  token: string | { token: string }[];
  onSignOutSuccess: (message: string, index: number) => void;
  onSignOutError: (err: IApiError, index: number) => void;
  onClearSignOutError: () => void;
}

export function useLogOutHook({
  token,
  onSignOutSuccess,
  onSignOutError,
  onClearSignOutError,
}: IUseLogOutHookOptions) {
  const handleLogoutSuccess = (
    logoutData: TMultiLogoutResponseData,
    err?: IApiError
  ) => {
    // TODO: Revise this logic
    logoutData.forEach((response, index) => {
      if (response.success) {
        onSignOutSuccess('SIGNOUT_SUCCESS', index);
      } else if (
        !response.success &&
        response.description === 'Invalid token.'
      ) {
        onSignOutSuccess('SIGNOUT_SUCCESS', index);
      } else {
        onSignOutError(err!, index);
      }
    });
  };

  const handleLogoutError = (err: IApiError) => {
    onSignOutError(err, 0);
  };

  const { submitLogoutDetails, isLoading, error } = useLogoutMutation({
    onSuccessCallback: handleLogoutSuccess,
    onErrorCallback: handleLogoutError,
  });

  const handleSignOut = () => {
    onClearSignOutError();

    const signOutPayload = typeof token === 'string' ? [{ token }] : token;
    submitLogoutDetails(signOutPayload);
  };

  return {
    handleSignOut,
    isLoading,
    error,
  };
}
