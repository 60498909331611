import {
  AsyncComponentFallbackTemplate,
  IBaseUIFallbackCommonOptions,
} from '@keymono/design-system';

function OrgIntegrationsListItemsLoader() {
  return (
    <div>
      <h1 className="flex w-full items-center justify-center py-10 text-center text-5xl font-light">
        Loading your integrated tools
      </h1>
      <p>Please wait</p>
    </div>
  );
}

interface IOrgIntegrationsListItemsFallbackProps
  extends IBaseUIFallbackCommonOptions {}

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback loading, error empty states for the
 * `OrgIntegrationsListItems` component.
 */
export function OrgIntegrationsListItemsFallback({
  error,
  onRetryAction,
  isLoading,
}: IOrgIntegrationsListItemsFallbackProps) {
  return (
    <AsyncComponentFallbackTemplate
      isLoading={isLoading}
      error={error}
      onRetryAction={onRetryAction}
      LoadingComponent={OrgIntegrationsListItemsLoader}
    />
  );
}
