import { IOrgNameIdentifiers } from '@keymono/shared-types';

interface IOrgNamesCardProps {
  names: IOrgNameIdentifiers;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the orgs names summary
 */
export function OrgNamesCard({ names }: IOrgNamesCardProps) {
  const { name, legalName, uniqueName } = names;

  return (
    <>
      <div className="mb-2 flex flex-wrap items-center">
        <p className="w-28 shrink-0 text-xs uppercase text-gray-400">Name</p>
        <h3 className="text-lg font-medium">{name}</h3>
      </div>
      <div className="mb-2 flex flex-wrap items-center">
        <p className="w-28 shrink-0 text-xs uppercase text-gray-400">
          Legal Name
        </p>
        <h3 className="text-sm">{legalName}</h3>
      </div>
      <div className="mb-2 flex flex-wrap items-center">
        <p className="w-28 shrink-0 text-xs uppercase text-gray-400">
          Unique Name
        </p>
        <h3 className="text-sm text-gray-600">{uniqueName}</h3>
      </div>
    </>
  );
}
