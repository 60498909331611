import { Suspense } from 'react';

import RegistrationForm, { IRegistrationFormProps } from './RegistrationForm';

export function DynamicRegistrationForm(props: IRegistrationFormProps) {
  return (
    <Suspense fallback={<h1 className="text-2xl">...</h1>}>
      <RegistrationForm {...props} />
    </Suspense>
  );
}
