import { IOrderListItem } from '@keymono/apis';

import { formatDate, statusColors } from '../../../utilities';
import { OrdersListBase } from '../OrdersListBase';
import { OrdersListTableBodyWrapper, OrdersListTableRow } from '../styles';
import {
  ColItemImgTitleDesc,
  ColItemStatusLabel,
  ColItemText,
} from './ColItems';

interface IOrdersListTableProps {
  navigateToOrderDetails: (orderId: string) => void;
  orders: IOrderListItem[];
  isMobileViewDisabled: boolean;
}

/**
 * -------------------------------------------------------
 * Renders the list of all orders in table view.
 * This is a dummy component to simulate the UI and the file structure.
 */
export function OrdersListTable({
  navigateToOrderDetails,
  orders,
  isMobileViewDisabled,
}: IOrdersListTableProps) {
  /**
   * Navigate to the order details page when the user clicks on an Order row
   */
  const handleOnClickOrderRow = (orderId: string) => {
    navigateToOrderDetails(orderId);
  };

  return (
    <OrdersListBase isMobileViewDisabled={isMobileViewDisabled}>
      <OrdersListTableBodyWrapper>
        {orders.map((order, index) => {
          const {
            id,
            channelTimestamp,
            currency,
            extId,
            fulfillmentChannelLabel,
            fulfillmentStatus,
            fulfillmentStatusLabel,
            noOfItems,
            orderStatus,
            orderStatusLabel,
            totalAmount,
          } = order;

          return (
            <OrdersListTableRow
              isMobileViewDisabled={isMobileViewDisabled}
              className={`${index % 2 === 0 ? 'bg-white' : 'bg-white/50'}`}
              key={id}
              onClick={() => handleOnClickOrderRow(id)}
            >
              <div
                className={`max-lg:hidden ${
                  isMobileViewDisabled ? 'col-span-1' : 'lg:col-span-1'
                }`}
              >
                <input
                  id="select-all-orders"
                  aria-describedby="select-all-orders"
                  name="select-all-orders"
                  type="checkbox"
                  className="text-colorPri-600 focus:ring-colorPri-600 scale-75 rounded border-gray-300"
                />
              </div>
              <ColItemImgTitleDesc
                title={extId}
                imageUrl={false}
                className={`${
                  isMobileViewDisabled
                    ? 'col-span-6'
                    : 'col-span-2 lg:col-span-6'
                }`}
              />
              <ColItemText
                text={formatDate(channelTimestamp)}
                className={`text-sm font-medium max-lg:justify-self-start ${
                  isMobileViewDisabled ? 'col-span-5' : 'lg:col-span-5'
                }`}
              />
              <ColItemText
                text="Amazon"
                className={`text-sm font-medium max-lg:justify-self-start ${
                  isMobileViewDisabled ? 'col-span-3' : 'lg:col-span-3'
                }`}
              />
              <div
                className={`flex items-center gap-x-2 text-center text-sm font-medium max-lg:justify-self-start ${
                  isMobileViewDisabled ? 'col-span-2' : 'lg:col-span-2'
                }`}
              >
                <ColItemText className="lg:hidden" text="Items:" />
                <ColItemText text={noOfItems.toString()} />
              </div>
              <ColItemText
                className={`${
                  isMobileViewDisabled ? 'col-span-4' : 'lg:col-span-4'
                }`}
                text={totalAmount ? `${totalAmount} ${currency}` : '-'}
              />
              <ColItemText
                text={fulfillmentChannelLabel}
                className={`text-sm font-medium max-lg:justify-self-start ${
                  isMobileViewDisabled ? 'col-span-5' : 'lg:col-span-5'
                }`}
              />
              <ColItemStatusLabel
                className={`w-max ${
                  isMobileViewDisabled ? 'col-span-5' : 'lg:col-span-5'
                }`}
                status={fulfillmentStatusLabel}
                color={statusColors[fulfillmentStatus]}
              />
              <ColItemStatusLabel
                className={`w-max ${
                  isMobileViewDisabled ? 'col-span-5' : 'lg:col-span-5'
                }`}
                status={orderStatusLabel}
                color={statusColors[orderStatus]}
              />
            </OrdersListTableRow>
          );
        })}
      </OrdersListTableBodyWrapper>
    </OrdersListBase>
  );
}
