import { XCircleIcon } from '@heroicons/react/24/outline';

import { useAuthStore } from '@keymono/services';
import { useOrganizationDetailsQuery } from '@keymono/apis';
import { SimpleButton } from '@keymono/design-system';

import { OrgOverviewHeader } from '../OrgOverviewHeader';

import {
  CardsGridContainer,
  CentralColumnPanelContainer,
  RightColumnPanelContainer,
} from '../styles';

import {
  OrgAddressInformationCard,
  OrgNameIdentifiersCard,
  OrgAgreementsContractsCard,
} from '../components';

import { AddChannels } from './AddChannels';
import { ChannelsSummary } from './ChannelsSummary';

interface IFallbackPageProps {
  isLoading: boolean;
  error?: {
    message: string;
    description?: string;
  } | null;
  onRetry: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * TODO: Extract this FallbackPage into a fully customizable component with better
 * error logging and handling.
 */
function FallbackPage({ isLoading, error, onRetry }: IFallbackPageProps) {
  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Something went wrong while retrieving your organization details
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                <li>
                  {error?.message}
                  Some error is here
                </li>
                <li>
                  {error?.description}
                  Another error is here
                </li>
              </ul>
            </div>
            <SimpleButton onClick={onRetry} label="Retry" />
          </div>
        </div>
      </div>
    );
  }

  return isLoading ? (
    <div>
      <h2>Loading your profile overview</h2>
      <p> Please wait.</p>
    </div>
  ) : (
    <div>No content loaded yet</div>
  );
}

export function OrgOverviewContent() {
  const orgId = useAuthStore().activeSession?.activeOrgId || '';

  const { data, isLoading, error, refetch } = useOrganizationDetailsQuery({
    orgId,
  });

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !data) {
    return (
      <FallbackPage
        isLoading={isLoading}
        error={error}
        onRetry={handleRetryOnFail}
      />
    );
  }

  const {
    name,
    legalName,
    uniqueName,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressCountryLabel,
    addressZip,
  } = data;

  const names = { name, legalName, uniqueName };
  const address = {
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressCountryLabel,
    addressZip,
  };

  return (
    <div className="flex flex-col gap-y-2">
      <OrgOverviewHeader orgId={orgId} />

      <CardsGridContainer>
        <CentralColumnPanelContainer>
          <ChannelsSummary />
          <AddChannels />
        </CentralColumnPanelContainer>

        <RightColumnPanelContainer>
          <OrgNameIdentifiersCard names={names} orgId={orgId} />
          <OrgAddressInformationCard address={address} orgId={orgId} />
          <OrgAgreementsContractsCard orgId={orgId} />
        </RightColumnPanelContainer>
      </CardsGridContainer>
    </div>
  );
}
