import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { useTranslations } from '@keymono/i18n';

import {
  AsyncComponentFallbackTemplate,
  CardSecButton,
  CircularLoader,
  IBaseUIFallbackCommonOptions,
  IBaseUIErrorOptions,
} from '@keymono/design-system';

function AllIntegrationsListItemsLoader() {
  const t = useTranslations('Integrations');

  return (
    <div className="flex w-full flex-col items-center justify-center py-20 text-center">
      <CircularLoader className="my-10 h-10 w-10" />
      <h1 className="flex items-center py-4 text-2xl">{t('loading')}</h1>
      <h1 className="flex items-center justify-center py-2 text-center text-xl font-light">
        {t('allIntegrationsTitle')}
      </h1>
      {/* <p>TODO: Replace with skeleton loader</p> */}
      <p className="py-10">{t('pleaseWait')}</p>
    </div>
  );
}

/**
 * -----------------------------------------------------------------------------
 * Fallback shown when the app fails to retrieve the list of all supported
 * integrations.
 *
 * TODO: All a helpful illustration, with proper recovery message.
 * TODO: Translate the text from the parent component.
 */
function AllIntegrationsListItemsError({
  onRetryAction,
  error,
}: IBaseUIErrorOptions) {
  const t = useTranslations('Integrations');

  return (
    <div className="flex w-full flex-col items-center justify-center py-20 text-center text-red-900">
      {/* // TODO: Replace this with an illustration */}
      <ExclamationCircleIcon className="my-5 h-16 w-16 text-red-700" />

      <h1 className="flex items-center py-4 text-2xl">{t('sorry')}</h1>

      {error?.message ? (
        <>
          <h1 className="flex items-center justify-center py-2 text-center text-xl font-light">
            {error.message}
          </h1>
          <p>{error.description}</p>
        </>
      ) : null}

      <CardSecButton className="my-8" onClick={onRetryAction.onClick}>
        {t('retry.retry')}
      </CardSecButton>
    </div>
  );
}

interface IAllIntegrationsListItemsFallbackProps
  extends IBaseUIFallbackCommonOptions {}

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback loading, error empty states for the
 * `AllIntegrationsListItems` component.
 */
export function AllIntegrationsListItemsFallback({
  error,
  onRetryAction,
  isLoading,
}: IAllIntegrationsListItemsFallbackProps) {
  return (
    <AsyncComponentFallbackTemplate
      isLoading={isLoading}
      error={error}
      onRetryAction={onRetryAction}
      ErrorComponent={AllIntegrationsListItemsError}
      LoadingComponent={AllIntegrationsListItemsLoader}
    />
  );
}
