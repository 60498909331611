import { IllustrationProductsInternationally } from '@keymono/design-system';

/**
 * -----------------------------------------------------------------------------
 * This renders the illustration and message when there are no products.
 * TODO: Add translations
 */
export function NoProducts() {
  return (
    <div className="flex w-full flex-col items-center justify-center">
      <IllustrationProductsInternationally className="max-w-full" />
      <h2 className="max-w-xl py-4 text-2xl text-gray-600">
        Sorry, Nothing to Report. Yet!
      </h2>
      <p className="max-w-sm text-center text-sm text-teal-700 ">
        You have not added any product to your organization. Please start by
        visiting the categories page and adding a new Category to later add a
        few products.
      </p>
    </div>
  );
}
