import { useState } from 'react';

import { useAuthStore } from '@keymono/services';

import { EmailsCard } from './EmailsCard';
import { EmailsCardEdit } from './EmailsCardEdit';

/**
 * -----------------------------------------------------------------------------
 * Displays the user's email address information and allows for editing.
 */
export function ProfileEmailsCard() {
  const user = useAuthStore().activeSession?.user;

  const [isEditing, setIsEditing] = useState(false);

  if (!user) return null;

  const { email } = user;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  return !isEditing ? (
    <EmailsCard email={email} onEdit={handleEdit} />
  ) : (
    <EmailsCardEdit onExitEditMode={handleCancelEdit} />
  );
}
