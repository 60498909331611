import { SVGContentLoader } from '@keymono/design-system';

function LoaderItem() {
  return (
    <SVGContentLoader
      speed={2}
      height={20}
      viewBox="0 0 100 20"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="5" rx="3" ry="3" width="100" height="10" />
    </SVGContentLoader>
  );
}

/**
 * -----------------------------------------------------------------------------
 * The Header component of OrdersListLoader.
 * Renders the skeleton screen for the the `OrdersListHeader` component.
 */
export function OrdersListLoaderHeader() {
  return (
    <div className="flex items-center justify-between gap-5 p-3 max-md:flex-col max-md:items-start">
      <div className="flex w-full items-center justify-between gap-x-6 xl:justify-start">
        <LoaderItem />
      </div>
      <div className="flex items-center justify-end gap-x-6 max-xl:hidden">
        <LoaderItem />
        <LoaderItem />
        <LoaderItem />
        <LoaderItem />
        <LoaderItem />
      </div>
    </div>
  );
}
