import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';
import { ICancelOrgInvitationsData } from '../types';
import { ORGANIZATION_API } from '../organizationAPIClient';
import { QUERY_FETCH_INVITATIONS_LIST_KEY } from './useInvitationsListQuery';

const MUT_CANCEL_INVITATION = 'cancel-invitation-mutation-key';

interface IUseCancelInvitationOptions {
  id: string;
  orgId: string;
  onSuccessCallback?: (sendInviteData: ICancelOrgInvitationsData) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This mutation cancels a pending invitation for joining an org.
 */
export const useCancelInvitationMutation = ({
  id,
  orgId,
  onSuccessCallback,
  onErrorCallback,
}: IUseCancelInvitationOptions) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data, error } = useMutation({
    mutationKey: [MUT_CANCEL_INVITATION, id, orgId],
    retry: false,
    mutationFn: ORGANIZATION_API.cancelOrganizationInvitation,
    onSuccess: (responseData) => {
      queryClient.invalidateQueries([QUERY_FETCH_INVITATIONS_LIST_KEY, orgId]);
      onSuccessCallback?.(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Cancelling the Organization Invitation Attempt',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    data,
    cancelOrganizationInvitation: mutate,
  };
};
