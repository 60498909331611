import { SVGContentLoader } from '@keymono/design-system';
import { useI18n } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state of `OrgOverviewHeader`.
 * Displays Skeleton UI with the help of SVG content loader.
 */
export function OrgOverviewHeaderLoader() {
  const { language } = useI18n();
  const isRTL = language.isRTL ?? false;

  return (
    <div className="sm:flex sm:items-center sm:justify-between">
      <div className="sm:flex sm:gap-5">
        <div className="flex-shrink-0">
          <SVGContentLoader
            speed={2}
            height={80}
            viewBox="0 0 80 80"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            rtl={isRTL}
          >
            <circle cx="40" cy="40" r="40" />
          </SVGContentLoader>
        </div>
        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
          <SVGContentLoader
            speed={2}
            height={80}
            viewBox="0 0 320 80"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            rtl={isRTL}
          >
            <rect x="0" y="5" rx="3" ry="3" width="210" height="20" />
            <rect x="0" y="40" rx="3" ry="3" width="70" height="10" />
            <rect x="0" y="55" rx="3" ry="3" width="140" height="10" />
          </SVGContentLoader>
        </div>
      </div>
    </div>
  );
}
