import { useState } from 'react';
import { useRouter } from 'next/router';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { IChoice } from '@keymono/design-system';
import { IUseOrgOrdersQueryOptions, useOrgOrdersQuery } from '@keymono/apis';

import { OrdersListHeader } from './OrdersList/OrdersListHeader';
import { OrdersList } from './OrdersList';

/**
 * -----------------------------------------------------------------------------
 * This renders the summary of all orders for an organization.
 */
export function OrdersOverview() {
  const { asPath: currentUrl, push, query } = useRouter();
  const orgId = (query.organizationId || '') as string;

  /**
   * Manage the query params for pagination, sorting, filtering, etc.
   * Maintain the state here and pass it to the child components.
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [channelCreatedTsGte, setChannelCreatedTsGte] = useState<
    IUseOrgOrdersQueryOptions['channelCreatedTsGte']
  >(new Date());
  const [channelCreatedTsLte, setChannelCreatedTsLte] = useState<
    IUseOrgOrdersQueryOptions['channelCreatedTsLte']
  >(new Date());
  const [ordering, setOrdering] =
    useState<IUseOrgOrdersQueryOptions['ordering']>('');
  const [isMobileViewDisabled, setIsMobileViewDisabled] =
    useState<boolean>(false);
  /**
   * TODO: Will enable this once API is ready.
   */
  // const [activeSearchKey, setActiveSearchKey] = useState('');

  const { data, isLoading, error, refetch } = useOrgOrdersQuery({
    orgId,
    page: currentPage,
    channelCreatedTsGte,
    channelCreatedTsLte,
    ordering,
    // query: activeSearchKey, // Will enable this once API is ready.
  });

  const orders = data?.orders;

  const onUpdateDateRange = (dateRange: DateValueType) => {
    if (!dateRange) return;
    const startDate = dateRange.startDate as Date;
    const endDate = dateRange.endDate as Date;
    setChannelCreatedTsGte(startDate);
    setChannelCreatedTsLte(endDate);
  };

  const onUpdateOrdering = (newOrdering: IChoice) => {
    const orderingKey =
      newOrdering.key as IUseOrgOrdersQueryOptions['ordering'];
    setOrdering(orderingKey);
  };

  /**
   * Navigate to the order details page of the selected order
   */
  const navigateToOrderDetails = (orderId: string) => {
    push(`${currentUrl}/${orderId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  /**
   * Update the active search key in the parent component
   * TODO: Will enable this once API is ready.
   */
  /*
  const onUpdateSearchKey = (searchKey: string) => {
    setActiveSearchKey(searchKey);
  };
  */

  const searchOptions: IChoice[] | undefined = orders?.map((order) => ({
    key: order.id,
    label: order.extId,
  }));

  const onUpdateMobileView = (newValue: boolean) => {
    setIsMobileViewDisabled(newValue);
  };

  return (
    <div className="flex flex-col gap-y-8">
      <OrdersListHeader
        isMobileViewDisabled={isMobileViewDisabled}
        onUpdateMobileView={onUpdateMobileView}
        navigateToOrderDetails={navigateToOrderDetails}
        onUpdateDateRange={onUpdateDateRange}
        onUpdateOrdering={onUpdateOrdering}
        ordering={ordering}
        // TODO: Will enable this once API is ready.
        // onUpdateSearchKey={onUpdateSearchKey}
        searchOptions={searchOptions}
        startDate={channelCreatedTsGte}
        endDate={channelCreatedTsLte}
      />
      <OrdersList
        data={data}
        error={error}
        handlePageChange={handlePageChange}
        isLoading={isLoading}
        navigateToOrderDetails={navigateToOrderDetails}
        refetch={refetch}
        isMobileViewDisabled={isMobileViewDisabled}
      />
    </div>
  );
}
