import Image from 'next/image';
import { UserIcon } from '@heroicons/react/24/outline';

interface IProfilePopoverTriggerBtnProps {
  /**
   * The name of the active account to be passed down to the alt text &
   * screen readers.
   */
  name: string;
  /**
   * The image src of to render as the profile picture.
   * A fallback user icon is shown if the image is not present.
   */
  imageUrl?: string;
}

/**
 * -----------------------------------------------------------------------------
 * The button rendered as a small profile picture that triggers this pop-over
 * menu when clicked. It can be for example shown in the top right app header.
 */
export function ProfilePopoverTriggerBtn({
  name,
  imageUrl,
}: IProfilePopoverTriggerBtnProps) {
  return (
    <>
      {/* TODO: Get this from translations */}
      <span className="sr-only">Open profile menu</span>

      {imageUrl ? (
        <Image
          alt={`Profile picture for logged in user - ${name}`}
          className="
            ui-open:border-gray-400 inline-block h-10 w-10 shrink-0
            rounded-full border-2 border-gray-200/80
          "
          height={40}
          // placeholder="blur"
          src={imageUrl}
          width={40}
        />
      ) : (
        <div
          className="
            bg-gray/50 mx-auto flex h-10 w-10 shrink-0 items-center
            justify-center rounded-full border-4 border-gray-200/80
          "
        >
          <UserIcon className="flex h-6 w-6" aria-hidden="true" />
        </div>
      )}
    </>
  );
}
