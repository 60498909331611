import { requestBuilder } from '../utils/rb';
import { request as httpRequest } from '../../api-client-v2';
import { UserMeDTI } from '../users';

export type LoginDTO = {
  email: string;
  password: string;
};

export type LoginDTI = {
  expiry: string;
  token: string;
  user: UserMeDTI;
};

export async function login(request: LoginDTO): Promise<LoginDTI> {
  const [url, init] = requestBuilder()
    .method('POST')
    .path('/users/login', null)
    .requestBody('application/json', request)
    .build();

  const response = await httpRequest<LoginDTI>(url, init);
  if (!response.success) throw response.error;
  return response.json;
}

export type LogoutDTO = {
  authToken: string;
};

export type LogoutDTI = void;

export async function logout(request: LogoutDTO): Promise<LogoutDTI> {
  const [url, init] = requestBuilder()
    .setHeader('Content-Type', 'application/json')
    .setHeader('Authorization', `Token ${request.authToken}`)
    .method('POST')
    .path('/users/logout', null)
    .build();

  const response = await httpRequest<LogoutDTI>(url, init);
  if (!response.success) throw response.error;
}
