import { ReactNode } from 'react';

import { useTranslations } from '@keymono/i18n';

interface IInvitationsListBaseProps {
  children: ReactNode;
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This is the base component used by `InvitationsListFallback` and `InvitationsListTable`
 * Renders the table UI with the header and passes the children prop to the table body
 */
export function InvitationsListBase({
  children,
  className = '',
}: IInvitationsListBaseProps) {
  const t = useTranslations('Invitations.invitationsListTable.headerTitles');
  const headerTitles = [t('type'), t('status'), t('actions')];

  return (
    <div className={`mt-8 flow-root ${className}`}>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-100">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pe-3 ps-4 text-start text-sm font-semibold text-gray-900 sm:ps-0"
                >
                  {t('name')}
                </th>
                {headerTitles.map((headerTitle) => (
                  <th
                    key={headerTitle}
                    scope="col"
                    className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
                  >
                    {headerTitle}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100/80">{children}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
