import { TFulfillmentStatusLabel, TOrderStatusLabel } from '@keymono/apis';

import { TStatusColor } from '../../../../types';

interface IColItemStatusLabelProps {
  status: TFulfillmentStatusLabel | TOrderStatusLabel;
  color?: TStatusColor;
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Col Item component that renders a status label.
 * Not to be confused with `ColItemTags` which is a generic label
 * This renders a status label with a background color and text color based on status
 */
export function ColItemStatusLabel({
  status,
  className = '',
  color = 'gray',
}: IColItemStatusLabelProps) {
  let bgClass;
  let textClass;

  switch (color) {
    case 'green':
      bgClass = 'bg-green-100';
      textClass = 'text-green-800';
      break;
    case 'red':
      bgClass = 'bg-red-100';
      textClass = 'text-red-800';
      break;
    case 'yellow':
      bgClass = 'bg-yellow-100';
      textClass = 'text-yellow-800';
      break;
    case 'blue':
      bgClass = 'bg-blue-100';
      textClass = 'text-blue-800';
      break;
    default:
      bgClass = 'bg-gray-100';
      textClass = 'text-gray-800';
      break;
  }

  return (
    <div
      className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${bgClass} ${textClass} ${className}`}
    >
      {status}
    </div>
  );
}
