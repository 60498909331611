import dayjs from 'dayjs';
import { request as httpRequest } from '../../api-client-v2';
import { requestBuilder } from '../utils/rb';

export type SalesByDateDTO = {
  orgId: string;
  authToken: string;
  dateFrom: Date;
  dateTo: Date;
};

export type SalesByDateResult = {
  date: string;
  no_of_discounted_units: number | null;
  no_of_orders: number | null;
  no_of_units: number | null;
  total_sale_price: string | number | null;
  total_full_price: string | number | null;
};

export type SalesByDateResultDated = Omit<SalesByDateResult, 'date'> & {
  date: dayjs.Dayjs;
};

export type SalesByDateDTI = {
  meta: {
    report: string;
  };
  results: Array<SalesByDateResult>;
};

export async function getSalesByDate(request: SalesByDateDTO) {
  const [url, init] = requestBuilder()
    .setHeader('Content-Type', 'application/json')
    .setHeader('Authorization', `Token ${request.authToken}`)
    .method('GET')
    .path('/orgs/{orgId}/reports/sales-by-date', { orgId: request.orgId })
    .queryParams({
      start_date: dayjs(request.dateFrom).format('YYYY-MM-DD'),
      end_date: dayjs(request.dateTo).format('YYYY-MM-DD'),
    })
    .build();

  const response = await httpRequest<SalesByDateDTI>(url, init);
  return response;
}

export type TopSkuByDateDTO = {
  orgId: string;
  authToken: string;
  dateFrom: Date;
  dateTo: Date;
};

export type TopSkuByDateResult = {
  sku: string;
  total_sale_price: number;
};

export type TopSkyByDateDTI = {
  meta: {
    report: string;
  };
  results: Array<TopSkuByDateResult>;
};

export async function getTopSkuByDate(request: TopSkuByDateDTO) {
  const [url, init] = requestBuilder()
    .setHeader('Content-Type', 'application/json')
    .setHeader('Authorization', `Token ${request.authToken}`)
    .method('GET')
    .path('/orgs/{orgId}/reports/top-sku-by-date-range', {
      orgId: request.orgId,
    })
    .queryParams({
      start_date: dayjs(request.dateFrom).format('YYYY-MM-DD'),
      end_date: dayjs(request.dateTo).format('YYYY-MM-DD'),
    })
    .build();

  const response = await httpRequest<TopSkyByDateDTI>(url, init);
  return response;
}
