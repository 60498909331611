import { IIntegration } from '@keymono/apis';

interface INewIntegrationCardHeaderProps {
  integration: IIntegration;
}

/**
 * -----------------------------------------------------------------------------
 * The header component of the New Integrations card.
 * Shows the name and the category of the integration.
 */
export function NewIntegrationCardHeader({
  integration,
}: INewIntegrationCardHeaderProps) {
  const { name, categoryLabel } = integration;

  return (
    <div className="flex flex-1 flex-col justify-center pt-2 text-center">
      <h3 className="text-base font-semibold text-gray-800">{name}</h3>
      <div className="gap-2">
        <span
          className="
            -mx-1 inline-flex items-center rounded-full bg-red-50
            px-2.5 py-0.5 text-xs font-medium text-red-900 opacity-80
          "
        >
          {categoryLabel}
        </span>
      </div>
    </div>
  );
}
