import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ApiClientError } from '../../api-client-v2';
import { getSalesByDate, getTopSkuByDate } from '../reports';
import { MetricFactory } from './metric-definitions';

export type PeriodDTO = {
  dateFrom: Date;
  dateTo: Date;
};

type MetricsDTO = {
  authToken: string;
  orgId: string;
  periods: [PeriodDTO, PeriodDTO];
};

type MetricsDTI = {
  periods: [MetricFactory, MetricFactory];
};

function USE_METRICS_QUERY_KEY(request: MetricsDTO) {
  return [
    'metrics.all',
    request.orgId,
    request.periods[0].dateFrom.toISOString(),
    request.periods[0].dateTo.toISOString(),
    request.periods[1].dateFrom.toISOString(),
    request.periods[1].dateTo.toISOString(),
  ] as const;
}

type UseMetricsOptions = Omit<
  UseQueryOptions<
    MetricsDTI,
    ApiClientError<'client'> | ApiClientError<'server'>,
    MetricsDTI,
    ReturnType<typeof USE_METRICS_QUERY_KEY>
  >,
  'queryFn' | 'queryKey'
>;

export function useMetrics(request: MetricsDTO, options?: UseMetricsOptions) {
  const { authToken, orgId } = request;
  return useQuery<
    MetricsDTI,
    ApiClientError<'client'> | ApiClientError<'server'>,
    MetricsDTI,
    ReturnType<typeof USE_METRICS_QUERY_KEY>
  >({
    queryKey: USE_METRICS_QUERY_KEY(request),
    queryFn: async () => {
      const p1TopSkuResponseP = getTopSkuByDate({
        authToken,
        orgId,
        dateFrom: request.periods[1].dateFrom,
        dateTo: request.periods[1].dateTo,
      });

      const p0TopSkuResponseP = getTopSkuByDate({
        authToken,
        orgId,
        dateFrom: request.periods[0].dateFrom,
        dateTo: request.periods[0].dateTo,
      });

      const p1SalesResponseP = getSalesByDate({
        authToken,
        orgId,
        dateFrom: request.periods[1].dateFrom,
        dateTo: request.periods[1].dateTo,
      });

      const p0SalesResponseP = getSalesByDate({
        authToken,
        orgId,
        dateFrom: request.periods[0].dateFrom,
        dateTo: request.periods[0].dateTo,
      });

      const [
        p0SalesResponse,
        p1SalesResponse,
        p0TopSkuResponse,
        p1TopSkuResponse,
      ] = await Promise.all([
        p0SalesResponseP,
        p1SalesResponseP,
        p0TopSkuResponseP,
        p1TopSkuResponseP,
      ]);

      if (!p0SalesResponse.success) throw p0SalesResponse.error;
      if (!p1SalesResponse.success) throw p1SalesResponse.error;
      if (!p0TopSkuResponse.success) throw p0TopSkuResponse.error;
      if (!p1TopSkuResponse.success) throw p1TopSkuResponse.error;

      const p0Metrics = new MetricFactory(
        p0SalesResponse.json.results,
        p0TopSkuResponse.json.results[0]
      );
      const p1Metrics = new MetricFactory(
        p1SalesResponse.json.results,
        p1TopSkuResponse.json.results[0]
      );

      return {
        periods: [p0Metrics, p1Metrics],
      };
    },
    ...options,
  });
}
