import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';
import { FormFeedbackSection, LogoKeymono } from '@keymono/design-system';

import { FormLinkCTA } from '../../components/FormLinkCTA';

import { FormContainer } from '../styles';
/**
 * -----------------------------------------------------------------------------
 * This renders a simple placeholder UI on the login screen when no token is found.
 */
export function EmptyLoginForm() {
  const t = useTranslations('Login');
  const tCommon = useTranslations('Common');

  return (
    <FormContainer>
      <div className="form-responsive-wrapper">
        <div>
          <Link
            href="/"
            className="my-8 hidden w-auto sm:inline-flex"
            aria-label={tCommon('logoAriaLabel')}
            title={tCommon('logoAriaLabel')}
          >
            <LogoKeymono />
          </Link>
          <div className="form-header flex">
            <h1 className="grow">{t('title')}</h1>
          </div>
          <FormFeedbackSection
            isFormSubmitting={false}
            type="info"
            message={t('formIntro.message')}
            description={t('formIntro.description')}
          />
        </div>
        <div>
          <FormLinkCTA
            href="/sign-up"
            title={t('signUpInsteadCTA.title')}
            subTitle={t('signUpInsteadCTA.subTitle')}
          />
        </div>
      </div>
    </FormContainer>
  );
}
