import Image from 'next/image';

interface IColItemImgTitleDescProps {
  imageUrl?: string | false;
  title: string;
  description?: string;
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Col Item component that renders an Image, Title and Description.
 */
export function ColItemImgTitleDesc({
  title,
  description,
  imageUrl,
  className = '',
}: IColItemImgTitleDescProps) {
  return (
    <div className={`flex gap-x-4 ${className}`}>
      {imageUrl && (
        <div className="flex-none overflow-hidden rounded-lg">
          <Image
            className="h-16 w-16"
            src={imageUrl}
            alt={title}
            width={64}
            height={64}
          />
        </div>
      )}
      <div className="flex min-w-0 flex-col justify-center">
        <p className="mb-1 truncate font-medium text-gray-900">{title}</p>
        {description && (
          <p className="mb-1 truncate text-xs font-medium text-gray-700">
            {description}
          </p>
        )}
      </div>
    </div>
  );
}
