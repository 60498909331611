import { IPublicChoicesUrlOptions } from '../types';

/**
 * -----------------------------------------------------------------------------
 * These endpoints provide support for app-wider user authentication & management
 * -----------------------------------------------------------------------------
 */

/**
 * Endpoint for fetching multiple choice fields from the backend.
 */
export const publicChoicesUrl = ({
  type,
  page = 1,
  query = '',
}: IPublicChoicesUrlOptions) => `choices/${type}?page=${page}&q=${query}`;
