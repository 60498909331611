// TODO: Refactor this file to delete most of the redundant exports.

import Image from 'next/image';
import { useRouter } from 'next/router';
import { XCircleIcon } from '@heroicons/react/24/outline';

import { IMember } from '@keymono/shared-types';
import { useMembersListQuery } from '@keymono/apis';
import { CardSecButton } from '@keymono/design-system';

import { OrgOverviewHeader } from '../org-details';

import { MembersTable } from './MembersTable';
import { NoNewMembers } from './NoNewMembers';

interface IMemberListItemProps {
  member: IMember;
}

/**
 * -----------------------------------------------------------------------------
 * Displays a single member in the list of members in an org.
 */
export function MemberListItem({ member }: IMemberListItemProps) {
  const { profileImageUrl, fullName, email, type } = member;

  return (
    <li className="py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Image
            className="h-8 w-8 rounded-full"
            src={profileImageUrl}
            alt=""
            width={32}
            height={32}
          />
        </div>
        <div className="min-w-0 flex-1">
          <p className="truncate text-sm font-medium text-gray-900">
            {fullName}
          </p>
          <p className="truncate text-sm font-medium text-gray-700">{email}</p>
          <p className="truncate text-sm capitalize text-gray-500">{type}</p>
        </div>
        <div>
          <button
            type="button"
            title="Revoke Access" // TODO: Translate this component.
            className="
              inline-flex items-center rounded-full border border-gray-300
              bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700
              shadow-sm hover:bg-gray-50
            "
          >
            Revoke
          </button>
        </div>
      </div>
    </li>
  );
}

/**
 * -----------------------------------------------------------------------------
 * Renders the fallback UI when there is only one member in the org, prompting the
 * user to invite other members.
 */
export function MemberListEmptyWithInvitationCTA() {
  return (
    <div>
      <h3>You are alone here</h3>
      <p>No other member has joined yet</p>
      <p>TODO: Add a better illustrated UI. With CTA to invite members</p>
      <button type="button">Invite Members</button>
    </div>
  );
}

/**
 * -----------------------------------------------------------------------------
 * Renders the fallback noop UI when the data fetch is successful but there is no
 * member in the system.
 *
 * NOTE: This UI might never be reached since there is always am owner  in an org.
 * TODO: Update/Delete this component, to use the refactored component component
 */
export function MemberListEmpty() {
  return <div />;
}

interface IMemberListsProps {
  accountId: string;
  orgId: string;
  members: IMember[];
}

/**
 * -----------------------------------------------------------------------------
 * Displays a single member in the list of members in an org.
 */
export function MemberLists({ accountId, members, orgId }: IMemberListsProps) {
  const membersCount = members.length;

  if (membersCount === 1) {
    return <NoNewMembers accountId={accountId} orgId={orgId} />;
  }

  return <MembersTable members={members} />;
}

/**
 * -----------------------------------------------------------------------------
 * This displays the loading UI wile fetching the list of members.
 *
 * TODO: Create a loading scaffold component that looks closer to the final UI
 *  as much as possible. (React Content Loaders)
 */
export function MembersListLoading() {
  return (
    <div>
      <h3>Loading members list</h3>
      <p>Please wait</p>
    </div>
  );
}

interface IMembersListErrorProps {
  error: {
    message: string;
    description?: string;
  };
}

/**
 * -----------------------------------------------------------------------------
 * This displays the error captured while loading the members list items.
 *
 * TODO: 1.Add an error boundary component to this error.
 * TODO: 2. Add an alternative inline error over-layed on top of data states.
 *  such as errors in background re-fetches.
 */
export function MembersListError({ error }: IMembersListErrorProps) {
  const { message, description } = error;
  return (
    <div>
      <h3>Error while fetching the Members in this Organization</h3>
      <p>{message}</p>
      {description ? <p>{description}</p> : null}
    </div>
  );
}

interface IFallbackPageProps {
  isLoading: boolean;
  error?: {
    message: string;
    description?: string;
  } | null;
  onRetry: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * TODO: Extract this FallbackPage into a fully customizable component with better
 * error logging and handling.
 */
function FallbackPage({ isLoading, error, onRetry }: IFallbackPageProps) {
  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Something went wrong while retrieving your the members in this
              org.
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                <li>{error?.message}</li>
                <li>{error?.description}</li>
              </ul>
            </div>
            <CardSecButton onClick={onRetry}>Retry</CardSecButton>
          </div>
        </div>
      </div>
    );
  }

  return isLoading ? (
    <div>
      <h2>Loading your profile overview</h2>
      <p> Please wait.</p>
    </div>
  ) : (
    <div>No content loaded yet</div>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This displays a list of members in an organization.
 *
 * TODO: 1. Surround this in an error boundary
 * TODO: 2. Add a background error refetch
 */
export function Members() {
  const { query } = useRouter();

  const accountId = (query.accountId || '0') as string;
  const orgId = (query.organizationId || '') as string;
  const { data, isLoading, error, refetch } = useMembersListQuery({ orgId });

  const handleRetryOnFail = () => {
    refetch();
  };

  const members = data?.members || [];

  return (
    <div className="flex flex-col gap-y-2">
      <OrgOverviewHeader orgId={orgId} />

      {isLoading || error || !data ? (
        <FallbackPage
          isLoading={isLoading}
          error={error}
          onRetry={handleRetryOnFail}
        />
      ) : (
        <section className="flex w-full flex-col overflow-hidden rounded-lg bg-white py-8 shadow-gray-100">
          <MemberLists accountId={accountId} orgId={orgId} members={members} />
        </section>
      )}
    </div>
  );
}
