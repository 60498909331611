import dynamic from 'next/dynamic';

/**
 * This dynamically imports `LoginForm` content at the lazily when required.
 * This helps prevent nextjs hydration errors.
 */
const DynamicCreateOrganizationForm = dynamic(
  () => import('./CreateOrganizationForm'),
  {
    ssr: false,
  }
);

export default DynamicCreateOrganizationForm;
