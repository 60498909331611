import { OrdersListLoaderFooter } from './OrdersListLoaderFooter';
import { OrdersListLoaderHeader } from './OrdersListLoaderHeader';
import { OrdersListLoaderTable } from './OrdersListLoaderTable';

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state (skeleton loader) of `OrdersList`.
 */
export function OrdersListLoader() {
  return (
    <div className="flex flex-col gap-y-8">
      <OrdersListLoaderHeader />
      <OrdersListLoaderTable />
      <OrdersListLoaderFooter />
    </div>
  );
}
