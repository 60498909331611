import { IllustrationProductsInternationally } from '@keymono/design-system';
import { useTranslations } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * This renders the illustration and message when there are no organizations.
 */
export function NoOrganizations() {
  const t = useTranslations('Organizations.noOrganizations');

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <IllustrationProductsInternationally className="max-w-full" />
      <h2 className="max-w-xl py-4 text-2xl text-gray-600">{t('message')}</h2>
      <p className="max-w-sm text-center text-sm text-teal-700 ">
        {t('description')}
      </p>
    </div>
  );
}
