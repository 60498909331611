import tw, { styled } from 'twin.macro';

export const CountrySelectorDropDownFallBack = styled.div`
  ${tw`
     mt-1
     flex
     h-full
     w-full
     items-center
     justify-start
     gap-2
     rounded-md
     bg-transparent
     text-sm
     text-red-800
     ltr:pl-2
     rtl:pr-2
  `}
`;

export const CountrySelectorContainer = styled.div`
  ${tw`
     relative
     inset-y-0
     right-0
     flex
     w-full
     items-center
  `}
`;

export const CountrySelectorDropDownWrapper = styled.div`
  ${tw`
     w-full
     pb-2
     sm:pb-0
 `}

  &.has-error {
    ${tw`
       mt-0
       mb-0
       w-full
       sm:mb-0
       sm:pb-0
    `}
  }

  label {
    ${tw`
       mb-0
       text-gray-700
    `}
  }

  &.has-error {
    label {
      ${tw`
         mb-0
         text-red-800/80
         transition-all
         duration-300
         ease-in-out
      `}
    }
  }

  .country-input {
    ${tw`
       border-gray-200
       text-sm
    `}
  }

  &.has-error {
    .country-input {
      ${tw`
         border-red-300
         text-red-500/80
         text-red-900
      `}
    }
  }

  .error-message {
    ${tw`
       relative
       mt-2
       overflow-hidden
       text-xs
       text-red-600/80
       transition-all duration-300 ease-in-out
    `}
  }
`;

export const RefetchChoicesButton = styled.button`
  ${tw`
     mr-0.5
     flex
     items-center
     justify-start
     gap-2
     rounded-r-lg
     border-transparent
     bg-transparent
     bg-red-50
     w-full
     py-2.5
     pl-2
     pr-2
     cursor-pointer
     text-red-800
     focus:border-red-300
 `}
`;
