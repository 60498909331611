import { IIntegration } from '@keymono/apis';
import {
  CardSecButton,
  CircularLoader,
  IUIError,
} from '@keymono/design-system';
import { useTranslations } from '@keymono/i18n';

import { NewIntegrationCardHeader } from './NewIntegrationCardHeader';

interface INewIntegrationCardItemFallbackProps {
  error: IUIError | null;
  isLoading: boolean;
  integration: IIntegration;
  onCancel: () => void;
  onRetry: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * The fallback component shown when the integration id loading or when an error
 * occurs during the process.
 * TODO: Improve upon this later.
 */
export function NewIntegrationCardItemFallback({
  isLoading,
  error,
  integration,
  onRetry,
  onCancel,
}: INewIntegrationCardItemFallbackProps) {
  const t = useTranslations('Integrations');

  return (
    <div
      className="
        group relative flex flex-col overflow-hidden rounded-xl border
        border-gray-100 bg-white text-start shadow-lg shadow-gray-100/50
        transition-all duration-1000 ease-in
        hover:border-red-100 hover:bg-red-50/50 hover:shadow-red-50/60
      "
    >
      <NewIntegrationCardHeader integration={integration} />

      {error ? (
        <div
          className="
            flex flex-1 flex-col border-t border-gray-50 px-4 pb-4 pt-2 text-xs
            text-red-700 transition-all duration-200 ease-linear
          "
        >
          <p className="pb-2 pt-2">{error.message}</p>

          <div className="flex justify-end gap-2">
            <CardSecButton className="form-btn" onClick={onCancel}>
              {t('cancel')}
            </CardSecButton>
            <CardSecButton onClick={onRetry}>{t('retry.retry')}</CardSecButton>
          </div>
        </div>
      ) : (
        <div>
          {isLoading ? (
            <div
              className="
                flex flex-1 flex-row border-t border-gray-50 px-4 pb-4 pt-2
                text-xs text-teal-700 transition-all duration-500 ease-linear
              "
            >
              <CircularLoader className="mx-5 my-8 h-8 w-8 !border-t-teal-800" />
              <div className="mx-6 flex flex-1 flex-col justify-center">
                <p className="font-medium">{t('addingIntegrationWait')}</p>
                <p className="pt-1">{t('pleaseWait')}</p>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}
