import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ApiClientError, ApiResponse } from '../../api-client-v2';
import { getSalesByDate, getTopSkuByDate } from './reports.api';
import type {
  SalesByDateDTI,
  SalesByDateDTO,
  TopSkuByDateDTO,
  TopSkyByDateDTI,
} from './reports.api';

export function USE_SALES_BY_DATE_QUERY_KEY(request: SalesByDateDTO) {
  return [
    'reports.sales-by-date',
    request.orgId,
    request.dateFrom,
    request.dateTo,
  ] as const;
}

type UseSalesByDateOptions = Omit<
  UseQueryOptions<
    SalesByDateDTI,
    ApiClientError<'client'> | ApiClientError<'server'>,
    SalesByDateDTI,
    ReturnType<typeof USE_SALES_BY_DATE_QUERY_KEY>
  >,
  'queryFn' | 'queryKey'
>;
export function useSalesByDate(
  request: SalesByDateDTO,
  options?: UseSalesByDateOptions
) {
  return useQuery({
    queryKey: USE_SALES_BY_DATE_QUERY_KEY(request),
    queryFn: async () => {
      const response = await getSalesByDate(request);
      if (!response.success) throw response.error;
      return response.json;
    },
    ...options,
  });
}

export function USE_TOP_SKU_BY_DATE_QUERY_KEY(request: TopSkuByDateDTO) {
  return [
    'reports.top-sku-by-date',
    request.orgId,
    request.dateFrom,
    request.dateTo,
  ] as const;
}

type UseTopSkuByDateOptions = Omit<
  UseQueryOptions<
    ApiResponse<TopSkyByDateDTI>,
    unknown,
    ApiResponse<TopSkyByDateDTI>,
    ReturnType<typeof USE_TOP_SKU_BY_DATE_QUERY_KEY>
  >,
  'queryFn' | 'queryKey'
>;
export function useTopSkuByDate(
  request: TopSkuByDateDTO,
  options?: UseTopSkuByDateOptions
) {
  return useQuery({
    queryKey: USE_TOP_SKU_BY_DATE_QUERY_KEY(request),
    queryFn: () => getTopSkuByDate(request),
    ...options,
  });
}
