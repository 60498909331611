import {
  AsyncComponentFallbackTemplate,
  IBaseUIFallbackCommonOptions,
} from '@keymono/design-system';

import { OrdersListError } from './OrdersListError';
import { OrdersListLoader } from './OrdersListLoader';

interface IOrdersListFallbackProps extends IBaseUIFallbackCommonOptions {}

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback loading, and error states
 * Used in the `OrdersList` component.
 */
export function OrdersListFallback({
  error,
  onRetryAction,
  isLoading,
}: IOrdersListFallbackProps) {
  return (
    <AsyncComponentFallbackTemplate
      isLoading={isLoading}
      error={error}
      onRetryAction={onRetryAction}
      ErrorComponent={OrdersListError}
      LoadingComponent={OrdersListLoader}
    />
  );
}
