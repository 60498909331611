import { useRouter } from 'next/router';
import { AllIntegrationsListItems } from './AllIntegrationsListItems';
import { OrgIntegrationsListItems } from './OrgIntegrationsListItems';

/**
 * -----------------------------------------------------------------------------
 * This displays the existing already added integrations and lists all the
 * available integration an organization can setup.
 */
export function IntegrationsOverview() {
  const {
    query: { organizationId },
  } = useRouter();

  const orgId = organizationId as string | undefined;

  if (!orgId) return null;

  return (
    <div>
      <OrgIntegrationsListItems orgId={orgId} />
      <AllIntegrationsListItems orgId={orgId} />
    </div>
  );
}
