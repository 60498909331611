import { CheckCircleIcon, ClockIcon } from '@heroicons/react/24/outline';
import { IFetchOrderDetails, TFulfillmentStatus } from '@keymono/apis';

import { OrderProductCard } from './OrderProductCard';
import { OrderTotalCard } from './OrderTotalCard';

const statusIcon = (status: TFulfillmentStatus) => {
  switch (status) {
    case 'fulfilled':
      return <CheckCircleIcon width={24} className="text-green-500" />;
    default:
      return <ClockIcon width={24} className="text-yellow-500" />;
  }
};

interface IOrderProductsSummaryProps {
  className?: string;
  fulfillmentStatus: IFetchOrderDetails['fulfillmentStatus'];
  fulfillmentStatusLabel: IFetchOrderDetails['fulfillmentStatusLabel'];
  items: IFetchOrderDetails['items'];
  totalAmount: IFetchOrderDetails['totalAmount'];
}

/**
 * -----------------------------------------------------------------------------
 * Renders the details related to the products in an order.
 */
export function OrderProductsSummary({
  className = '',
  fulfillmentStatus,
  fulfillmentStatusLabel,
  items,
  totalAmount,
}: IOrderProductsSummaryProps) {
  return (
    <div
      className={`flex flex-col gap-y-8 rounded-lg bg-white p-8 ${className}`}
    >
      <div className="flex items-center gap-x-4">
        {statusIcon(fulfillmentStatus)}
        <h2 className="text-lg font-medium text-gray-900">
          {fulfillmentStatusLabel}
        </h2>
      </div>
      <div className="flex flex-col gap-y-4">
        {items.map((item) => (
          <OrderProductCard key={item.id} item={item} />
        ))}
        <OrderTotalCard totalAmount={totalAmount} />
      </div>
    </div>
  );
}
