import { IOrderDetailsProductItem } from '@keymono/apis';

/**
 * Renders the product card for the order products summary section.
 */
export function OrderProductCard({ item }: { item: IOrderDetailsProductItem }) {
  const itemSubtotalDesc = `${item.pricePerItem} x ${item.quantity}`;
  const itemTaxDesc = `${item.taxPerItem} x ${item.quantity}`;

  return (
    <div className="my-4 flex items-start gap-x-8 gap-y-4 max-sm:flex-col">
      <div className="flex items-start gap-x-8">
        <div className="flex flex-col gap-y-4">
          <p className="text-lg font-medium text-gray-900">{item.name}</p>
          <div className="flex flex-col items-start gap-y-2">
            <p className="text-sm text-gray-700">
              {`Line Item: ${item.extId}`}
            </p>
            <p className="text-sm text-gray-700">{`SKU: ${item.sku}`}</p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-8">
        <div className="flex flex-col gap-y-4">
          <p className="text-sm text-gray-700">Subtotal:</p>
          <p className="whitespace-nowrap text-lg font-medium text-gray-900">
            {itemSubtotalDesc}
          </p>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-sm text-gray-700">Tax:</p>
          <p className="whitespace-nowrap text-lg font-medium text-gray-900">
            {itemTaxDesc}
          </p>
        </div>
        <div className="flex flex-col justify-end gap-y-4">
          <p className="text-lg font-medium text-gray-900">{item.amount}</p>
        </div>
      </div>
    </div>
  );
}
