import { IFetchOrgProductsDataRaw, IFetchOrgProductsData } from './types';

/**
 * This transforms the data receieved from `orgs/{{__ORG_ID}}/products` endpoint.
 * Currently removing the `category` and `brand` fields, since not using them in the UI as of now.
 * TODO: Add the `category` and `brand` fields to the response.
 */
export function formatOrgProductsResponseData(
  data: IFetchOrgProductsDataRaw
): IFetchOrgProductsData {
  const { results, meta } = data;

  const products = results.map(({ id, name, description }) => ({
    id,
    name,
    description,
  }));

  return { meta, products };
}
