import { useState } from 'react';
import Image from 'next/image';
import { UserIcon } from '@heroicons/react/24/outline';

import { IApiError } from '@keymono/apis';
import { IUIError } from '@keymono/design-system';
import { useAuthStore } from '@keymono/services';
import { IActiveSession } from '@keymono/shared-types';

import { useMembershipRedirection } from '../../../../organizations';

import { SwitchAccountOptionItemWrapper } from '../styles';
import { SignOutTriggerButton } from '../SignOutTriggerButton';

interface ISignOutTriggerButtonErrorFallbackProps {
  error: IUIError | null;
  index?: number;
}

/**
 *------------------------------------------------------------------------------
 *Error showed incase of failure to sign out of a single account or multiple
 *accounts
 */
function SignOutTriggerButtonErrorFallback({
  error,
  index,
}: ISignOutTriggerButtonErrorFallbackProps) {
  return (
    <div>
      <div className="py-1 text-center text-xs text-red-500">
        {error?.message}
        <span>{index}</span>
      </div>
    </div>
  );
}
interface IItemProfileImageProps {
  fullName: string;
  imageUrl?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the profile picture on the session item in the list or a fallback
 * user icon if the image url is not present.
 */
function ItemProfileImage({ fullName, imageUrl }: IItemProfileImageProps) {
  return (
    <div className="shrink-0">
      {imageUrl ? (
        <Image
          alt=""
          title={`${fullName}'s photo. The other account you are logged in too`}
          className="profile-image"
          height={24}
          src={imageUrl}
          width={24}
        />
      ) : (
        <div className="no-profile-image-icon">
          <UserIcon aria-hidden="true" />
        </div>
      )}
    </div>
  );
}

interface IItemNameAndEmailProps {
  fullName: string;
  email: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the full name and email address on the session item.
 */
function ItemNameAndEmail({ fullName, email }: IItemNameAndEmailProps) {
  return (
    <div className="center mx-3 grow text-start">
      <p className="text-sm font-medium capitalize text-gray-500 group-hover:text-gray-900">
        {fullName}
      </p>
      <p className="text-xs text-gray-400 group-hover:text-gray-700">{email}</p>
    </div>
  );
}

export type TOperationType = 'select' | 'remove';

interface ISwitchAccountOptionItemProps {
  /**
   * The handler to be called on pressing the item. Such as close the popover later.
   */
  onSwitchAccount?: () => void;
  /**
   * The profile information of the session instance.
   */
  session: IActiveSession;
  /**
   * Determines whether to show a delete option to remove the item or active it.
   */
  type: TOperationType;
}

/**
 * -----------------------------------------------------------------------------
 * This renders an individual link action from that shows a snippet of profile
 * information, and navigates the user to that account when clicked.
 *
 * @returns JSX.Element
 */
export function SwitchAccountOptionItem({
  session,
  onSwitchAccount,
  type = 'remove',
}: ISwitchAccountOptionItemProps) {
  const { removeSession, updateActiveSession } = useAuthStore();
  const { requestRedirectOnLogin } = useMembershipRedirection();
  const { user, index: sessionIndex } = session;
  const { email, firstName, lastName, profileImageUrl } = user;
  const fullName = `${firstName} ${lastName}`;
  const displayPicture = profileImageUrl;

  const [hasError, setHasError] = useState<{
    error: IApiError;
    index: number;
  } | null>(null);

  const handleSignOutSuccess = () => {
    setHasError(null);
    removeSession(session);
  };

  const handleSignOutError = (error: IApiError, index: number) => {
    setHasError({ error, index });
  };

  const handleClearSignOutError = () => {
    setHasError(null);
  };

  const handleSwitchAccount = () => {
    updateActiveSession(session);
    requestRedirectOnLogin({ sessionIndex, user });

    onSwitchAccount?.();
  };

  return (
    <SwitchAccountOptionItemWrapper
      className={`group ${type}-operation flex flex-col`}
    >
      <div className="session-list-item-wrapper">
        <button
          type="button"
          className="flex flex-1 flex-row p-4"
          onClick={handleSwitchAccount}
        >
          <ItemProfileImage fullName={fullName} imageUrl={displayPicture} />
          <ItemNameAndEmail fullName={fullName} email={email} />
        </button>

        <SignOutTriggerButton
          token={session.token}
          onSignOutError={handleSignOutError}
          onSignOutSuccess={handleSignOutSuccess}
          onClearSignOutError={handleClearSignOutError}
        />
      </div>
      {hasError ? (
        <div
          className="
            mb-2 flex border-b border-red-100 px-4 py-0.5 text-xs
            text-red-500
          "
        >
          <SignOutTriggerButtonErrorFallback error={hasError.error} />
        </div>
      ) : null}
    </SwitchAccountOptionItemWrapper>
  );
}

export default SwitchAccountOptionItem;
