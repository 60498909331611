import { useTranslations } from '@keymono/i18n';
import Link from 'next/link';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { IMembership, IUserInvite } from '@keymono/shared-types';

import { OrgInvitationsList } from './OrgInvitationsList';
import { OrgMembershipListView } from './OrgMembershipListView';
import { OrgMembersAndInvitationsEmpty } from './OrgMembersAndInvitationsEmpty';

interface IOrganizationsListViewProps {
  accountId: number;
  invitation: IUserInvite[];
  membership: IMembership[];
}

/**
 * -----------------------------------------------------------------------------
 * This renders the list of organizations from which a user can select from,
 * alongside the invitations they have received.
 */
export function OrgMembersAndInvitationsView({
  accountId,
  invitation,
  membership,
}: IOrganizationsListViewProps) {
  const t = useTranslations('Organizations');

  const hasMembers = membership.length > 0;
  const hasInvitations = invitation.length > 0;

  return hasMembers || hasInvitations ? (
    <div className="mx-auto mb-40 flex max-w-lg flex-col items-center px-4 pb-10">
      {hasMembers ? <OrgMembershipListView memberships={membership} /> : null}
      {hasInvitations ? <OrgInvitationsList invitations={invitation} /> : null}

      <div className="mt-8 flex justify-center border-t border-gray-200">
        <Link
          href={`/${accountId}/org/create`}
          className="
            group mt-8 inline-flex items-center rounded-full border
            border-red-200 bg-red-50 px-2.5 py-1.5 text-sm
            leading-5 text-red-500 shadow-sm transition-all duration-500
            ease-linear hover:bg-red-600 hover:text-white
            focus:outline-none focus:ring-2 focus:ring-red-100
            focus:ring-offset-1
          "
        >
          <BuildingOffice2Icon
            className="h-5 w-5 group-hover:text-white"
            aria-hidden="true"
          />
          <span className="mx-5 text-xs">
            {t(
              'Onboarding.orgMembersAndInvitationsView.addAnotherOrganizationCTA'
            )}
          </span>
        </Link>
      </div>
    </div>
  ) : (
    <OrgMembersAndInvitationsEmpty accountId={accountId} />
  );
}
