import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';

import { IAddOrgIntegrationData } from '../types';
import { ORGANIZATION_API } from '../organizationAPIClient';

const MUT_ADD_INTEGRATION = 'add-integration-mutation-key';

interface IUseAddIntegrationOptions {
  orgId: string;
  integrationId: string;
  onSuccessCallback?: (addIntegrationData: IAddOrgIntegrationData) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This mutation posts a new request to enable an integration on an organization.
 */
export const useAddIntegrationMutation = ({
  orgId,
  integrationId,
  onSuccessCallback,
  onErrorCallback,
}: IUseAddIntegrationOptions) => {
  const reactQueryClient = useQueryClient();
  const { mutate, isLoading, data, error } = useMutation({
    mutationKey: [MUT_ADD_INTEGRATION, orgId, integrationId],
    retry: false,
    mutationFn: ORGANIZATION_API.addOrgIntegration,
    onSuccess: (responseData) => {
      reactQueryClient.invalidateQueries([MUT_ADD_INTEGRATION, orgId]);
      onSuccessCallback?.(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Adding a new integration to the Organization',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    data,
    addOrganizationIntegration: mutate,
  };
};
