import { XCircleIcon } from '@heroicons/react/24/outline';

import { useUserDetailsQuery } from '@keymono/apis';
import { SimpleButton } from '@keymono/design-system';

import { ProfileHeader } from '../components/ProfileHeader';
import {
  ProfileContactNumbersCard,
  ProfileEmailsCard,
  ProfileNamesCard,
} from '../components';

interface IFallbackPageProps {
  isLoading: boolean;
  error?: {
    message: string;
    description?: string;
  } | null;
  onRetry: () => void;
}

function FallbackPage({ isLoading, error, onRetry }: IFallbackPageProps) {
  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Something went wrong while retrieving your profile details
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                <li>
                  {error?.message}
                  Some error is here
                </li>
                <li>
                  {error?.description}
                  Another error is here
                </li>
              </ul>
            </div>
            <SimpleButton onClick={onRetry} label="Retry" />
          </div>
        </div>
      </div>
    );
  }

  return isLoading ? (
    <div>
      <h2>Loading your profile overview</h2>
      <p> Please wait.</p>
    </div>
  ) : (
    <div>No content loaded yet</div>
  );
}

export function ProfileOverview() {
  const { data, isLoading, error, refetch } = useUserDetailsQuery({});

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !data) {
    return (
      <FallbackPage
        isLoading={isLoading}
        error={error}
        onRetry={handleRetryOnFail}
      />
    );
  }

  return (
    <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
      <div className="col-span-1 md:col-span-3">
        <ProfileHeader />
      </div>

      <div className="col-span-1 flex flex-col gap-2 md:col-span-3">
        <ProfileNamesCard />
        <ProfileEmailsCard />
        <ProfileContactNumbersCard />
      </div>

      {/* None of the below is very useful right now */}
      {/* <section className="col-span-1 flex flex-col gap-2" aria-labelledby="quick-links-title">
        <h2 className="sr-only" id="quick-links-title">
          Quick links
        </h2>

        {ProfileQuickLinks.map((data) => (
          <ProfileQuickLink key={data.name} {...data} />
        ))}
      </section> */}
    </div>
  );
}
