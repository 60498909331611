import { useState } from 'react';
import { useLocale } from 'next-intl';

import { TLocale, useTranslations } from '@keymono/i18n';
import { IChoice, useSendInvitationMutation } from '@keymono/apis';
import {
  EmailAddressInputField,
  Button,
  useForm,
} from '@keymono/design-system';

import { MembershipRolesSelect } from './MembershipRolesSelect';

interface ISendInviteOrgInputFields {
  email: string;
}

interface ISendNewInvitationProps {
  className?: string;
  orgId: string;
  showUserGroupIcon?: boolean; // TODO: Temp, remove this later.
}

/**
 * -----------------------------------------------------------------------------
 * Provides an email capture input and a select option to allow the user send an
 * invite via email, with the respective role.
 * TODO: 1 - Loading states for stateful APIs.
 * TODO: 2 - Success state for sent invitation and updating the invitations query keys.
 * TODO: 3 - Decline is not working on invitations (404), not found
 * TODO: 4 - Update style for invitation resent success…..
 * TODO: 5 - Remove previous decline error or resend error on any success (decline/resend)
 */
export function SendNewInvitation({
  className = '',
  orgId,
  showUserGroupIcon = true,
}: ISendNewInvitationProps) {
  const locale = useLocale() as TLocale;
  const t = useTranslations('Invitations.sendNewInvitation');

  const [role, setRole] = useState<IChoice | null>(null);

  const handleOnInvitationSuccess = () => {
    // TODO: Handle success screen update
    /* eslint-disable @typescript-eslint/no-use-before-define */
    handleOnResetForm?.();
    /* eslint-enable @typescript-eslint/no-use-before-define */
  };

  const handleOnInvitationError = () => {
    // TODO: Handle Error in sending invitation
  };

  const handleOnRoleChange = (newRole: IChoice) => {
    setRole(newRole);
  };

  const { isLoading, error, submitOrganizationInvitation } =
    useSendInvitationMutation({
      onSuccessCallback: handleOnInvitationSuccess,
      onErrorCallback: handleOnInvitationError,
      orgId,
    });

  const handlePostDetails = (values: ISendInviteOrgInputFields) => {
    submitOrganizationInvitation({
      locale,
      orgId,
      email: values.email,
      type: role?.key || '',
    });
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnResetForm,
    handleOnSubmitForm,
  } = useForm({
    initialValues: { email: '' },
    onSubmitCb: handlePostDetails,
  });

  return (
    <div className={` ${className}`}>
      <div className="text-center">
        {showUserGroupIcon ? (
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        ) : null}

        <h2 className="mt-2 text-lg font-medium text-gray-900">
          {t('invitationIntro.message')}
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          {t('invitationIntro.description')}
        </p>
      </div>
      <form
        className="mt-6 sm:flex sm:items-center"
        action="#"
        onSubmit={handleOnSubmitForm}
      >
        <label htmlFor="email" className="sr-only">
          {t('formFields.email.label')}
        </label>
        <div className="relative h-12 rounded-md shadow-sm sm:min-w-0 sm:flex-1">
          <EmailAddressInputField
            error={error?.errorFields.email || errors.email}
            classNameIconEnd="mr-40 rtl:ml-40"
            className="
              absolute
              left-0
              top-0
              w-full
            "
            label=""
            name="email"
            onBlurCallback={handleOnBlur}
            onChangeCallback={handleOnInputChange}
            onError={handleOnError}
            value={values.email}
            placeholder={t('formFields.email.placeholder')}
          />
          <MembershipRolesSelect
            selectedRole={role}
            onChange={handleOnRoleChange}
          />
        </div>
        <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0 rtl:sm:mr-4">
          <Button
            type="submit"
            isEnabled={!isLoading && !!role}
            isLoading={isLoading}
            className="
            form-btn
              block w-full rounded-md border border-transparent bg-red-600 px-4
              py-2 text-center text-sm font-medium text-white shadow-sm
              hover:bg-red-700 focus:outline-none focus:ring-2
              focus:ring-red-500 focus:ring-offset-2
            "
            label={t('formFields.submitCTA.label')}
            loadingLabel={t('formFields.submitCTA.loadingLabel')}
          />
        </div>
      </form>
    </div>
  );
}
