import { IOrganizationDetailsRaw } from '@keymono/shared-types';

import { ApiClient } from '../../../api-client';
import {
  addOrgIntegrationUrl,
  acceptOrganizationInvitationUrl,
  cancelOrganizationInvitationUrl,
  createOrganizationUrl,
  declineOrganizationInvitationUrl,
  fetchAllIntegrationsUrl,
  fetchAllOrganizationsUrl,
  fetchOrgContractsUrl,
  fetchOrgDetailsUrl,
  fetchOrgIntegrationsUrl,
  fetchInvitationsListUrl,
  fetchMembersListUrl,
  reauthorizeOrgIntegrationUrl,
  removeOrgIntegrationUrl,
  resendOrganizationInvitationUrl,
  sendOrganizationInvitationUrl,
  updateOrganizationUrl,
} from '../../../urls';

import {
  IAddOrgIntegrationDataRaw,
  IAddOrgIntegrationOptions,
  IAddOrgIntegrationPayload,
  IAcceptOrgInvitationsData,
  IAcceptOrgInvitationsOptions,
  ICancelOrgInvitationsOptions,
  ICancelOrgInvitationsData,
  ICreateOrganizationOptions,
  ICreateOrganizationPayload,
  ICreatedOrganizationData,
  IDeclineOrgInvitationsData,
  IDeclineOrgInvitationsOptions,
  IFetchAllIntegrationsDataRaw,
  IFetchALLOrganizationsDataRaw,
  IFetchOrgContractsDataRaw,
  IFetchOrgDetailsDataRaw,
  IFetchOrgInvitationsListDataRaw,
  IFetchOrgIntegrationsDataRaw,
  IFetchOrgMembersListDataRaw,
  IReauthorizeOrgIntegrationDataRaw,
  IReauthorizeOrgIntegrationOptions,
  IReauthorizeOrgIntegrationPayload,
  IRemoveOrgIntegrationData,
  IRemoveOrgIntegrationOptions,
  IRemoveOrgIntegrationPayload,
  IResendOrgInvitationsData,
  IResendOrgInvitationsOptions,
  ISendOrgInvitationsOptions,
  ISendOrgInvitationsPayload,
  ISendOrgInvitationsResponse,
  IUpdatableOrgDetailsOptions,
  TUpdatableOrgDetailsPayloadRaw,
} from './types';

import {
  formatAddIntegrationResponseData,
  formatAllIntegrationsResponseData,
  formatMembersResponseData,
  formatOrganizationContractsResponseData,
  formatOrganizationDetailsResponseData,
  formatOrganizationsResponseData,
  formatOrgIntegrationsResponseData,
  formatReauthorizeIntegrationResponseData,
  formatOrgInvitationsResponseData,
} from './formatOrganizationResponse';

/**
 *
 */
export class OrganizationAPIClient extends ApiClient {
  private static classInstance?: OrganizationAPIClient;

  private constructor() {
    super({
      baseURL: process?.env?.NEXT_PUBLIC_BASE_API_URL || '',
      apiVersion: process?.env?.NEXT_PUBLIC_BASE_API_VERSION || '',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new OrganizationAPIClient();
    }

    return this.classInstance;
  };

  /**
   * Method to fetch the list of all organizations that the user is a member of.
   */
  public fetchAllOrganizations = async () => {
    const response = await this.get<IFetchALLOrganizationsDataRaw>(
      fetchAllOrganizationsUrl()
    );

    if (!response.success) throw response;

    return formatOrganizationsResponseData(response.data);
  };

  /**
   * This method is used to create a new organization.
   */
  public createOrganization = async (options: ICreateOrganizationOptions) => {
    const {
      locale,
      name,
      logoUrl,
      legalName,
      uniqueName,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressCountry,
      addressZip,
    } = options;

    const response = await this.post<
      ICreatedOrganizationData,
      ICreateOrganizationPayload
    >(
      createOrganizationUrl(),
      {
        name,
        logo_url: logoUrl,
        legal_name: legalName,
        unique_name: uniqueName,
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_city: addressCity,
        address_state: addressState,
        address_country: addressCountry,
        address_zip: addressZip,
      },
      {
        requiresAuth: true,
        headers: {
          'Accept-Language': locale,
        },
      }
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * This calls a mutation that patches and updates an organization's details.
   */
  public updateOrganizationDetails = async (
    options: IUpdatableOrgDetailsOptions
  ) => {
    const { orgId, ...payload } = options;

    const response = await this.patch<
      IOrganizationDetailsRaw,
      TUpdatableOrgDetailsPayloadRaw
    >(updateOrganizationUrl(orgId), payload);

    if (!response.success) throw response;

    return formatOrganizationDetailsResponseData(response.data);
  };

  /**
   * Api call to fetch the details of a specific org by id.
   */
  public fetchOrgDetails = async (orgId: string) => {
    const response = await this.get<IFetchOrgDetailsDataRaw>(
      fetchOrgDetailsUrl(orgId)
    );

    if (!response.success) throw response;

    return formatOrganizationDetailsResponseData(response.data);
  };

  /**
   * Api call to fetch the contracts and agreements of a specific org by id.
   */
  public fetchOrgContracts = async (orgId: string) => {
    const response = await this.get<IFetchOrgContractsDataRaw>(
      fetchOrgContractsUrl(orgId)
    );

    if (!response.success) throw response;

    return formatOrganizationContractsResponseData(response.data);
  };

  /**
   *
   */
  public sendOrganizationInvitation = async (
    options: ISendOrgInvitationsOptions
  ) => {
    const { orgId, locale, ...payload } = options;

    const response = await this.post<
      ISendOrgInvitationsResponse,
      ISendOrgInvitationsPayload
    >(sendOrganizationInvitationUrl(orgId), payload, {
      requiresAuth: true,
      headers: {
        'Accept-Language': locale,
      },
    });

    if (!response.success) throw response;

    return response.data;
  };

  /**
   *
   */
  public resendOrganizationInvitation = async ({
    orgId,
    invitationId,
  }: IResendOrgInvitationsOptions) => {
    const response = await this.get<IResendOrgInvitationsData>(
      resendOrganizationInvitationUrl({ orgId, invitationId })
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api call to fetch the list of all invitations to a given org.
   */
  public fetchInvitationsList = async (orgId: string) => {
    const response = await this.get<IFetchOrgInvitationsListDataRaw>(
      fetchInvitationsListUrl(orgId)
    );

    if (!response.success) throw response;

    return formatOrgInvitationsResponseData(response.data);
  };

  /**
   *
   */
  public acceptOrganizationInvitation = async ({
    id,
  }: IAcceptOrgInvitationsOptions) => {
    const response = await this.get<IAcceptOrgInvitationsData>(
      acceptOrganizationInvitationUrl(id)
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api Call to decline an invitation to join an organization (on USER LEVEL)
   */
  public declineOrganizationInvitation = async ({
    id,
  }: IDeclineOrgInvitationsOptions) => {
    const response = await this.get<IDeclineOrgInvitationsData>(
      declineOrganizationInvitationUrl(id)
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api Call to cancel a pending invitation  (on ORG LEVEL)
   */
  public cancelOrganizationInvitation = async ({
    id,
    orgId,
  }: ICancelOrgInvitationsOptions) => {
    const response = await this.delete<ICancelOrgInvitationsData>(
      cancelOrganizationInvitationUrl({ invitationId: id, orgId })
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api call to fetch the list of all members in a given org.
   */
  public fetchMembersList = async (orgId: string) => {
    const response = await this.get<IFetchOrgMembersListDataRaw>(
      fetchMembersListUrl(orgId)
    );

    if (!response.success) throw response;

    return formatMembersResponseData(response.data);
  };

  /**
   * Api call to fetch the list of all available integrations a Org can setup.
   */
  public fetchAllIntegrations = async () => {
    const response = await this.get<IFetchAllIntegrationsDataRaw>(
      fetchAllIntegrationsUrl()
    );

    if (!response.success) throw response;

    return formatAllIntegrationsResponseData(response.data);
  };

  /**
   * Api call to fetch the list of installed integrations for the Org.
   */
  public fetchOrgIntegrations = async (orgId: string) => {
    const response = await this.get<IFetchOrgIntegrationsDataRaw>(
      fetchOrgIntegrationsUrl(orgId)
    );

    if (!response.success) throw response;

    return formatOrgIntegrationsResponseData(response.data);
  };

  /**
   * Enables an integration on an org.
   */
  public addOrgIntegration = async (options: IAddOrgIntegrationOptions) => {
    const { orgId, integrationId, authenticationFields } = options;

    const response = await this.post<
      IAddOrgIntegrationDataRaw,
      IAddOrgIntegrationPayload
    >(addOrgIntegrationUrl(orgId), {
      integration: integrationId,
      authentication_fields: authenticationFields,
    });

    if (!response.success) throw response;

    return formatAddIntegrationResponseData(response.data);
  };

  /**
   * Api Call to remove an integration  from and ORG
   */
  public removeOrganizationIntegration = async ({
    id,
    orgId,
  }: IRemoveOrgIntegrationOptions) => {
    const response = await this.delete<
      IRemoveOrgIntegrationData,
      IRemoveOrgIntegrationPayload
    >(removeOrgIntegrationUrl({ integrationId: id, orgId }), {
      data: { integration: id },
    });

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api Call to reauthorize an integration  from and ORG
   */
  public reauthorizeOrganizationIntegration = async ({
    id,
    orgId,
  }: IReauthorizeOrgIntegrationOptions) => {
    const response = await this.post<
      IReauthorizeOrgIntegrationDataRaw,
      IReauthorizeOrgIntegrationPayload
    >(reauthorizeOrgIntegrationUrl({ integrationId: id, orgId }), {
      integration: id,
    });

    if (!response.success) throw response;

    return formatReauthorizeIntegrationResponseData(response.data);
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for All User Accounts related requests.
 */
export const ORGANIZATION_API = OrganizationAPIClient.getClientInstance();
