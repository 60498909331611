import { ApiClient } from '../../../api-client';

import {
  IFetchOrderDetailsRaw,
  IFetchOrgOrdersDataRaw,
  IUseOrderDetailsQueryOptions,
  IUseOrgOrdersQueryOptions,
} from './types';

import { fetchOrderDetailsUrl, fetchOrgOrdersUrl } from '../../../urls';

import {
  formatOrderDetailsResponseData,
  formatOrgOrdersResponseData,
} from './formatOrdersResponse';

/**
 * Class that wraps all the API calls related to the Orders API.
 */
export class OrdersAPIClient extends ApiClient {
  private static classInstance?: OrdersAPIClient;

  private constructor() {
    super({
      baseURL: process?.env?.NEXT_PUBLIC_BASE_API_URL || '',
      apiVersion: process?.env?.NEXT_PUBLIC_BASE_API_VERSION || '',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new OrdersAPIClient();
    }

    return this.classInstance;
  };

  /**
   * Api call to fetch the list of all available Orders for an Org.
   */
  public fetchOrgOrders = async (options: IUseOrgOrdersQueryOptions) => {
    const response = await this.get<IFetchOrgOrdersDataRaw>(
      fetchOrgOrdersUrl(options)
    );

    if (!response.success) throw response;

    return formatOrgOrdersResponseData(response.data);
  };

  /**
   * Api call to fetch the details of a single Order for an Org.
   */
  public fetchOrderDetails = async (options: IUseOrderDetailsQueryOptions) => {
    const response = await this.get<IFetchOrderDetailsRaw>(
      fetchOrderDetailsUrl(options)
    );

    if (!response.success) throw response;

    return formatOrderDetailsResponseData(response.data);
  };
}

/**
 * This creates a new instance of the class.
 * it is the base Axios API client Class wrapper for Orders related requests.
 */
export const ORDERS_API = OrdersAPIClient.getClientInstance();
