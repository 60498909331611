import { PhotoIcon } from '@heroicons/react/24/outline';

import { SVGContentLoader } from '@keymono/design-system';

const test = true;

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state of an individual organization membership item.
 */
export function OrgMembershipListItemLoader() {
  if (test) {
    return (
      <li className="flex w-full  max-w-sm flex-grow">
        <SVGContentLoader
          title="Loading Organization Membership, please wait."
          id="OrgMembershipListItemLoader"
          speed={2}
          width={400}
          height={68}
          viewBox="0 0 400 68"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="
            relative flex w-full flex-col justify-between space-x-2 overflow-hidden
            rounded-lg border border-gray-100 bg-white text-left
          "
        >
          <rect
            className="opacity-20"
            x="90%"
            y="36"
            rx="4"
            ry="4"
            width="22"
            height="22"
          />
          <rect x="80" y="40" rx="4" ry="4" width="25%" height="14" />
          <rect x="80" y="12" rx="4" ry="4" width="60%" height="20" />
          <rect x="0" y="0" rx="0" ry="0" width="64" height="68" />
        </SVGContentLoader>
      </li>
    );
  }

  return (
    <li className="flex w-full  max-w-sm flex-grow">
      <div
        className="
          relative flex w-full flex-col justify-between space-x-2
          overflow-hidden rounded-md border border-gray-100 bg-white text-left
        "
      >
        <span className="flex min-w-0 flex-1 items-center">
          <span className="block flex-shrink-0">
            <PhotoIcon className="h-16 w-16" />
          </span>
          <span className="block min-w-0 flex-1 px-4">
            <span className="block text-sm">
              {/* {orgName} */}
              Title of Image loaded
            </span>
            <span className=" block text-xs">
              {/* {type} */}
              Role of the image
            </span>
          </span>
        </span>
        {/* <span
          className="
            absolute bottom-2 inline-flex h-6 w-6 flex-shrink-0 items-center
            justify-center ltr:right-2 rtl:left-2
          "
        >
          <ArrowRightIcon
            className="h-4 w-4 text-gray-300"
            aria-hidden="true"
          />
        </span> */}
      </div>
    </li>
  );
}
