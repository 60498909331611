import { ArrowPathIcon } from '@heroicons/react/24/outline';

import { IChoice, useChoicesQuery } from '@keymono/apis';
import {
  ButtonLoadingIndicator,
  ChoicesSelectDropdown,
} from '@keymono/design-system';
import { useTranslations } from '@keymono/i18n';

interface INoRolesLoadedYetProps {
  isLoading: boolean;
  loadingLabel: string;
  emptyLabel: string;
}

/**
 * -----------------------------------------------------------------------------
 * This isolates the negative case of unloaded choices to show either a loading
 * fallback or empty state. This makes sure that `SelectChoiceListItems` is
 * rendered with actual list items.
 */
function NoRolesLoadedYet({
  isLoading,
  loadingLabel,
  emptyLabel,
}: INoRolesLoadedYetProps) {
  return isLoading ? (
    <div
      className="
        flex h-full items-center justify-center gap-2 rounded-md
        border-transparent bg-transparent
        py-0 pl-4 pr-6 text-sm text-red-800
      "
    >
      <ButtonLoadingIndicator isEnabled className="h-5 w-5" />
      <p>{loadingLabel}</p>
    </div>
  ) : (
    <div>{emptyLabel}</div>
  );
}

interface IMembershipSelectProps {
  onChange: (selectedRole: IChoice) => void;
  selectedRole: IChoice | null;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a select item to provide a list of roles to choose from.
 */
export function MembershipRolesSelect({
  onChange,
  selectedRole,
}: IMembershipSelectProps) {
  const t = useTranslations('Invitations.sendNewInvitation.membershipRoles');
  const { data, error, isLoading, refetch } = useChoicesQuery({
    type: 'roles',
  });

  const listItems = data?.results;

  const handleRefetchChoices = () => {
    refetch();
  };

  if (error) {
    return (
      <div
        className="
          absolute inset-y-0 bottom-0 right-0 top-0 flex h-full items-center
          rounded-r-lg
        "
      >
        <button
          type="button"
          className="
            mr-0.5 mt-0.5 flex h-[calc(100%-8px)] items-center justify-center
            gap-2 rounded-r-lg border-transparent bg-red-100 bg-transparent
            py-0 pl-6 pr-8 text-sm text-red-800 focus:border-red-300
            focus:ring-1 focus:ring-red-200 active:bg-red-200
          "
          onClick={handleRefetchChoices}
        >
          <ArrowPathIcon className="h-4 w-4" />
          <div
            className="
              min-w-80 absolute -bottom-8 right-0 w-40 rounded-md border-2
              border-red-100 bg-red-50 px-2  py-1 text-red-900 shadow-sm
              ltr:text-xs rtl:text-sm
            "
          >
            <span>{t('errorMessage')}</span>
          </div>
          {t('roleRetry')}
        </button>
      </div>
    );
  }

  return (
    <div className="absolute inset-y-0 mr-1 flex  items-center ltr:right-0 rtl:left-0 rtl:ml-1">
      <span className="h-4 w-px bg-gray-200" aria-hidden="true" />
      <label htmlFor="role" className="sr-only">
        {t('label')}
      </label>

      {isLoading || !listItems ? (
        <NoRolesLoadedYet
          isLoading={isLoading}
          loadingLabel={t('loadingMessage')}
          emptyLabel={t('emptyLabel')}
        />
      ) : (
        <ChoicesSelectDropdown
          choices={listItems}
          onChange={onChange}
          placeholder={t('placeholder')}
          selectedChoice={selectedRole}
        />
      )}
    </div>
  );
}
