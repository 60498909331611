/**
 * -----------------------------------------------------------------------------
 * These endpoints provide support for app-wider organization management APIs
 * -----------------------------------------------------------------------------
 */

/**
 * Endpoint for fetching list of organizations for a user.
 * use with GET method.
 */
export const fetchAllOrganizationsUrl = () => '/orgs';

/**
 * Endpoint for handling creation of a new organization.
 */
export const createOrganizationUrl = () => '/orgs';

/**
 * Endpoint for handling patching/updating of an existing organization.
 */
export const updateOrganizationUrl = (orgId: string) => `/orgs/${orgId}`;

/**
 * Endpoint for retrieving details about an organization.
 */
export const fetchOrgDetailsUrl = (orgId: string) => `/orgs/${orgId}`;

/**
 * Endpoint for retrieving contracts and agreements for an organization.
 */
export const fetchOrgContractsUrl = (orgId: string) =>
  `/orgs/${orgId}/contracts`;

/**
 * Endpoint for retrieving list of invitations to an org.
 */
export const fetchInvitationsListUrl = (orgId: string) =>
  `/orgs/${orgId}/invitations`;

/**
 * Endpoint for sending a new invitation.
 */
export const sendOrganizationInvitationUrl = (orgId: string) =>
  `/orgs/${orgId}/invitations`;

interface IOrganizationInvitationOptions {
  orgId: string;
  invitationId: string;
}

/**
 * Endpoint for sending a new invitation.
 */
export const resendOrganizationInvitationUrl = ({
  orgId,
  invitationId,
}: IOrganizationInvitationOptions) =>
  `/orgs/${orgId}/invitations/${invitationId}/resend`;

/**
 * Endpoint for accepting an invitation.
 */
export const acceptOrganizationInvitationUrl = (invitationId: string) =>
  `/invitations/${invitationId}/accept`;

/**
 * Endpoint for declining an invitation.
 */
export const declineOrganizationInvitationUrl = (invitationId: string) =>
  `/invitations/${invitationId}/reject`;

/**
 * Endpoint for cancelling an invitation.
 */
export const cancelOrganizationInvitationUrl = ({
  orgId,
  invitationId,
}: IOrganizationInvitationOptions) =>
  `/orgs/${orgId}/invitations/${invitationId}`;

/**
 * Endpoint for retrieving list of members in an org.
 */
export const fetchMembersListUrl = (orgId: string) => `/orgs/${orgId}/members`;

/**
 * Endpoint for retrieving the list of all supported integrations in the app.
 */
export const fetchAllIntegrationsUrl = () => '/integrations';

/**
 * Endpoint for retrieving the list of installed integrations for the org.
 */
export const fetchOrgIntegrationsUrl = (orgId: string) =>
  `/orgs/${orgId}/integrations`;

/**
 * Endpoint for adding a new integration to an org.
 */
export const addOrgIntegrationUrl = (orgId: string) =>
  `/orgs/${orgId}/integrations`;

interface IOrgIntegrationOptions {
  integrationId: string;
  orgId: string;
}

/**
 * Endpoint for removing an integration from an org.
 */
export const removeOrgIntegrationUrl = ({
  integrationId,
  orgId,
}: IOrgIntegrationOptions) => `/orgs/${orgId}/integrations/${integrationId}`;

/**
 * Endpoint for reauthorizing an integration to an org.
 */
export const reauthorizeOrgIntegrationUrl = ({
  integrationId,
  orgId,
}: IOrgIntegrationOptions) =>
  `/orgs/${orgId}/integrations/${integrationId}/re-authorize`;
