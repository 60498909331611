import { useInvitationsListQuery } from '@keymono/apis';
import { useTranslations } from '@keymono/i18n';

import { InvitationsList } from './InvitationsList';
import { InvitationsListFallback } from './InvitationsList/InvitationsListFallback';
import { NoPendingInvitation } from './InvitationsList/NoPendingInvitation';

interface IIOrgInvitationsListProps {
  orgId: string;
}

/**
 * -------------------------------------------------------
 * Fetches and Displys the list of all pending invitations for an organization.
 * uses the `useInvitationsListQuery` hook to fetch the data.
 * uses the `InvitationsList` component to render the list.
 * uses the `InvitationsListFallback` component to render the loading and error states.
 * uses the `NoPendingInvitation` component to render the empty state.
 */
export function OrgInvitationsList({ orgId }: IIOrgInvitationsListProps) {
  const t = useTranslations('Invitations.noPendingInvitationsOrg');
  const { data, isLoading, error, refetch } = useInvitationsListQuery({
    orgId,
  });

  const handleRetryOnFail = () => {
    refetch();
  };

  const invitations = data?.invitations;
  const prevInvitationsCount = invitations?.length || 4;

  if (isLoading || error || !invitations) {
    return (
      <InvitationsListFallback
        isLoading={isLoading}
        invitationsCount={prevInvitationsCount}
        error={error}
        onRetryAction={{
          onClick: handleRetryOnFail,
        }}
      />
    );
  }

  const invitationsCount = invitations.length;

  if (invitationsCount <= 0) {
    return <NoPendingInvitation title={t('title')} message={t('message')} />;
  }

  return <InvitationsList invitations={invitations} orgId={orgId} />;
}
