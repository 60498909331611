// import Link from 'next/link';
import tw, { styled } from 'twin.macro';

/**
 * Wrapper around each individual list item displaying an organization profile.
 */
export const SwitchOrganizationItemWrapper = styled.button`
  ${tw`
    flex
    items-start
    rounded-lg
    p-3

    w-full
    flex-1
    shrink-0
    transition-all
    ease-in-out
    delay-100
    duration-500
    outline-none

    hover:bg-red-50
    focus:(bg-red-50 ring-1 ring-red-100 ring-offset-1)
  `};

  .org-logo {
    ${tw`
      mx-auto
      h-9
      w-9
      shrink-0
      rounded-full
      border-2
      border-gray-50
    `};
  }

  .no-org-logo-icon {
    ${tw`
      mx-auto
      flex
      h-10
      w-10
      shrink-0
      items-center
      justify-center
      rounded-full
      bg-gray-100
    `};

    svg {
      ${tw`
        flex
        h-6
        w-6
        text-red-600/50
      `};
    }
  }
`;
