import { ApiClient } from '../../../api-client';

import { IFetchOrgProductsDataRaw } from './types';

import { fetchOrgProductsUrl } from '../../../urls';

import { formatOrgProductsResponseData } from './formatProductsResponse';

/**
 * Class that wraps all the API calls related to the Products API.
 */
export class ProductsAPIClient extends ApiClient {
  private static classInstance?: ProductsAPIClient;

  private constructor() {
    super({
      baseURL: process?.env?.NEXT_PUBLIC_BASE_API_URL || '',
      apiVersion: process?.env?.NEXT_PUBLIC_BASE_API_VERSION || '',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new ProductsAPIClient();
    }

    return this.classInstance;
  };

  /**
   * Api call to fetch the list of all available Products for an Org.
   */
  public fetchOrgProducts = async (orgId: string) => {
    const response = await this.get<IFetchOrgProductsDataRaw>(
      fetchOrgProductsUrl(orgId)
    );

    if (!response.success) throw response;

    return formatOrgProductsResponseData(response.data);
  };
}

/**
 * This creates a new instance of the class.
 * it is the base Axios API client Class wrapper for Products related requests.
 */
export const PRODUCTS_API = ProductsAPIClient.getClientInstance();
