import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

import { IApiError } from '@keymono/apis';
import { ButtonLoadingIndicator } from '@keymono/design-system';

import { useAuthStore } from '@keymono/services';

import { useLogOutHook } from '../hooks';

interface ISignOutButtonProps {
  onClearSignOutError: () => void;
  onSignOutError: (error: IApiError, index: number) => void;
  onSignOutSuccess: (message: string, index: number) => void;
  token: string | { token: string }[];
}

export function SignOutTriggerButton({
  onSignOutError,
  onClearSignOutError,
  onSignOutSuccess,
  token,
}: ISignOutButtonProps) {
  const { isLoading, handleSignOut } = useLogOutHook({
    onClearSignOutError,
    onSignOutError,
    onSignOutSuccess,
    token,
  });

  const { sessionsUnderSignOut } = useAuthStore();

  const signOutToken = sessionsUnderSignOut?.find(
    (tokenToSignOut) => tokenToSignOut.token === token
  )?.token;

  return isLoading || signOutToken ? (
    <ButtonLoadingIndicator className="mx-5 shrink-0" isEnabled />
  ) : (
    <button
      type="button"
      className="
        group z-20 m-2 ml-2 shrink-0 rounded-full p-2 text-gray-400
        hover:bg-red-200 rtl:ml-0
      "
      onClick={handleSignOut}
    >
      <ArrowRightOnRectangleIcon
        className="h-6 w-6 shrink-0 grow-0 group-hover:text-red-600"
        height="24px"
        width="24px"
      />
    </button>
  );
}
