import { useTranslations } from 'next-intl';
import { CheckIcon } from '@heroicons/react/24/outline';

import { useAcceptInvitationMutation } from '@keymono/apis';

import { ActionContainer } from './ActionContainer';

interface IAcceptInvitationCTAProps {
  id: string;
}

/**
 * -----------------------------------------------------------------------------
 * Provides an action that allows a user to accept and invitation, thus be part
 * of that organization.
 */
export function AcceptInvitationCTA({ id }: IAcceptInvitationCTAProps) {
  const t = useTranslations('Invitations.accept');

  const { data, isLoading, error, acceptOrganizationInvitation } =
    useAcceptInvitationMutation({ id });

  const handleClick = () => {
    acceptOrganizationInvitation({ id });
  };

  return (
    <ActionContainer
      actionType="primary-action"
      errorMessage={error?.message || null}
      Icon={CheckIcon}
      isLoading={isLoading}
      isSuccess={!!data}
      loadingMessage={t('loading')}
      successMessage={t('success')}
      onActionPress={handleClick}
      title={t('title')}
      titleRetry={t('retry')}
    />
  );
}
