import { SVGContentLoader } from '@keymono/design-system';
import { useI18n } from '@keymono/i18n';

import { InvitationsListLoaderTableCell } from './styles';

/**
 * -----------------------------------------------------------------------------
 * This renders the skeleton loader of an individual Invitation item.
 */
export function InvitationsListLoaderItem() {
  const { language } = useI18n();
  const isRTL = language.isRTL ?? false;

  return (
    <tr>
      <InvitationsListLoaderTableCell>
        <SVGContentLoader
          speed={2}
          height={40}
          viewBox="0 0 250 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          rtl={isRTL}
        >
          <circle cx="20" cy="20" r="20" />
          <rect x="56" y="15" rx="3" ry="3" width="200" height="10" />
        </SVGContentLoader>
      </InvitationsListLoaderTableCell>
      <InvitationsListLoaderTableCell>
        <SVGContentLoader
          speed={2}
          height={40}
          viewBox="0 0 60 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          rtl={isRTL}
        >
          <rect x="0" y="5" rx="3" ry="3" width="60" height="10" />
        </SVGContentLoader>
      </InvitationsListLoaderTableCell>
      <InvitationsListLoaderTableCell>
        <SVGContentLoader
          speed={2}
          height={40}
          viewBox="0 0 60 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          rtl={isRTL}
        >
          <rect x="0" y="5" rx="6" ry="36" width="60" height="10" />
        </SVGContentLoader>
      </InvitationsListLoaderTableCell>
      <InvitationsListLoaderTableCell>
        <SVGContentLoader
          speed={2}
          height={40}
          viewBox="0 0 110 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          rtl={isRTL}
        >
          <rect x="0" y="5" rx="10" ry="10" width="50" height="15" />
          <rect x="60" y="5" rx="10" ry="10" width="50" height="15" />
        </SVGContentLoader>
      </InvitationsListLoaderTableCell>
    </tr>
  );
}
