import { useRouter } from 'next/router';

import { Button, Title } from '@keymono/design-system';
import { useOrgProductsQuery } from '@keymono/apis';

import { AllProductsListFallback } from './AllProductsListFallback';
import { AllProductsList } from './AllProductsList';
import { NoProducts } from './NoProducts';

interface IProductsOverviewProps {
  addProductLabel: string;
  title: string;
}

/**
 * Function to handle add product button click.
 * It will navigate to add product page.
 */
const handleOnAddProduct = () => {
  // TODO: Implement navigation to add product page.
};

/**
 * -----------------------------------------------------------------------------
 * This renders the summary of all products for an organization.
 */
export function ProductsOverview({
  addProductLabel,
  title,
}: IProductsOverviewProps) {
  const { query } = useRouter();
  const orgId = (query.organizationId || '') as string;

  const { data, isLoading, error, refetch } = useOrgProductsQuery(orgId);

  const products = data?.products;

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !products) {
    return (
      <AllProductsListFallback
        isLoading={isLoading}
        error={error}
        onRetryAction={{ onClick: handleRetryOnFail }}
      />
    );
  }

  return (
    <div className="flex grow flex-col items-start gap-6">
      <Title tag="h4">{title}</Title>
      <Button
        type="button"
        label={addProductLabel}
        isEnabled
        isLoading={false}
        loadingLabel=""
        className="w-auto"
        onClick={handleOnAddProduct}
      />
      {products.length > 0 ? (
        <AllProductsList products={products} />
      ) : (
        <NoProducts />
      )}
    </div>
  );
}
