import React from 'react';
import { SVGContentLoader, type DropdownOption } from '@keymono/design-system';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartOptions,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import {
  MetricFactory,
  MetricNamesSeriesTagMap,
  SeriesMetricColorMap,
  SeriesMetricNamesMap,
  SeriesMetricFormatMap,
  SeriesMetricTag,
} from '@keymono/apis';
import { cx } from 'class-variance-authority';
import dayjs from 'dayjs';
import { useLocalStorage } from '@keymono/utilities';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ScoreCard } from './ScoreCard';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function toBoldUnicode(val: string): string {
  const unicodeValueMap: { [key: string]: string } = {
    0: '\u{1D7EC}',
    1: '\u{1D7ED}',
    2: '\u{1D7EE}',
    3: '\u{1D7EF}',
    4: '\u{1D7F0}',
    5: '\u{1D7F1}',
    6: '\u{1D7F2}',
    7: '\u{1D7F3}',
    8: '\u{1D7F4}',
    9: '\u{1D7F5}',
  };

  function charToBold(c: string): string {
    return unicodeValueMap[c] || c;
  }

  const str = val.split('').map(charToBold).join('');
  return str;
}

const options = Object.entries(SeriesMetricNamesMap).map(([key, label]) => ({
  value: key,
  label,
})) as {
  value: keyof typeof SeriesMetricNamesMap;
  label: string;
}[];

const chartOptions: ChartOptions = {
  responsive: true,
  aspectRatio: 16 / 6,
  scales: {
    x: {
      ticks: {
        callback(value) {
          const label = this.getLabelForValue(value as number);
          const formatted = dayjs(label).format('DD. MMM');
          return formatted;
        },
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      position: 'nearest',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      titleColor: 'rgb(0,0,0)',
      bodyColor: 'rgb(0,0,0)',
      callbacks: {
        title: (items) => {
          const item = items[0].label;
          const date = dayjs(item).format('MMM DD, YYYY');
          return date;
        },
        label: (item) => {
          const series = MetricNamesSeriesTagMap.get(item.dataset.label || '');
          const value = Number(item.formattedValue);
          const format = SeriesMetricFormatMap[series as SeriesMetricTag];
          const formated = MetricFactory.formatMetric(
            value,
            format || 'number',
            'en-us'
          );
          return `${item.dataset.label}: ${toBoldUnicode(formated)}`;
        },
      },
    },
  },
};

function MetricPicker({
  metrics,
  metric,
  setMetric,
  filterMetrics,
}: {
  metrics: MetricFactory;
  metric: DropdownOption<SeriesMetricTag>;
  filterMetrics: DropdownOption<SeriesMetricTag>[];
  setMetric: (option: SeriesMetricTag) => void;
}) {
  const m = metrics.getSeriesMetric(metric.value);
  const filterValues = filterMetrics.map((v) => v.value);

  function listBoxClasses(): string {
    return cx('relative cursor-default select-none py-2 pl-10 pr-4');
  }

  function _setMetric(option: DropdownOption<SeriesMetricTag>) {
    setMetric(option.value);
  }

  return (
    <Listbox value={metric} onChange={_setMetric}>
      <div
        className="relative w-full flex-1 border-l-4"
        style={{ borderColor: SeriesMetricColorMap[metric.value] }}
      >
        <Listbox.Button className="flex w-full items-center justify-between gap-4 bg-white px-4 py-2 text-start">
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center justify-between">
              <ScoreCard.Title>{metric.label}</ScoreCard.Title>
              <ChevronDownIcon width={24} />
            </div>

            <ScoreCard.Score className="self-start">
              <span className="text-3xl">
                {MetricFactory.formatMetric(m.val, m.uom, 'en-us')}
              </span>
            </ScoreCard.Score>
          </div>
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute top-0 z-50 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {options.map((option) => {
              if (filterValues.includes(option.value)) return null;

              return (
                <Listbox.Option
                  key={option.value}
                  className={() => listBoxClasses()}
                  value={option}
                >
                  <p>{option.label}</p>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export function MetricCompareChart({ metrics }: { metrics: MetricFactory }) {
  const [metricA, setMetric1] = useLocalStorage<
    keyof typeof SeriesMetricNamesMap
  >('dashboard.metric-compare.a', 'series:units');
  const [metricB, setMetric2] = useLocalStorage<
    keyof typeof SeriesMetricNamesMap
  >('dashboard.metric-compare.b', 'series:units:price:full');
  const [metricC, setMetric3] = useLocalStorage<
    keyof typeof SeriesMetricNamesMap
  >('dashboard.metric-compare.c', 'series:units:price:discounted');

  function dropdownData(metricKey: keyof typeof SeriesMetricNamesMap) {
    return {
      label: SeriesMetricNamesMap[metricKey],
      value: metricKey,
    };
  }

  const metric1 = React.useMemo(() => dropdownData(metricA), [metricA]);

  const metric2 = React.useMemo(() => dropdownData(metricB), [metricB]);

  const metric3 = React.useMemo(() => dropdownData(metricC), [metricC]);

  const dataset = {
    labels: metrics.getSeriesLabels(),
    datasets: [
      {
        label: SeriesMetricNamesMap[metric1.value],
        data: metrics.getSeriesMetric(metric1.value).series,
        borderColor: SeriesMetricColorMap[metric1.value],
        backgroundColor: SeriesMetricColorMap[metric1.value],
      },
      {
        label: SeriesMetricNamesMap[metric2.value],
        data: metrics.getSeriesMetric(metric2.value).series,
        borderColor: SeriesMetricColorMap[metric2.value],
        backgroundColor: SeriesMetricColorMap[metric2.value],
      },
      {
        label: SeriesMetricNamesMap[metric3.value],
        data: metrics.getSeriesMetric(metric3.value).series,
        borderColor: SeriesMetricColorMap[metric3.value],
        backgroundColor: SeriesMetricColorMap[metric3.value],
      },
    ],
  };

  return (
    <div className="grid grid-cols-[1fr_1fr_1fr_1fr] gap-4 rounded bg-white p-4 shadow">
      <div className="flex flex-col justify-between">
        <ScoreCard.Title>Comparison Graph</ScoreCard.Title>
        <div className="my-2" />
        <MetricPicker
          metrics={metrics}
          metric={metric1}
          setMetric={setMetric1}
          filterMetrics={[metric2, metric3]}
        />
        <MetricPicker
          metrics={metrics}
          metric={metric2}
          setMetric={setMetric2}
          filterMetrics={[metric1, metric3]}
        />
        <MetricPicker
          metrics={metrics}
          metric={metric3}
          setMetric={setMetric3}
          filterMetrics={[metric1, metric2]}
        />
      </div>
      <div className="col-span-3">
        {metrics.getSeriesLabels().length > 1 ? (
          <Line options={chartOptions as unknown as {}} data={dataset} />
        ) : (
          <Bar options={chartOptions as unknown as {}} data={dataset} />
        )}
      </div>
    </div>
  );
}

export function MetricCompareChartLoader() {
  return (
    <div className="grid grid-cols-1 gap-4 rounded bg-white p-4 shadow md:grid-cols-[1fr_1fr_1fr_1fr]">
      <div className="flex flex-col justify-between">
        <div className="relative w-full flex-1 border-l-4">
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center justify-between">
              <SVGContentLoader height="24">
                <rect x="16" y="0" width="128" height="24" />
              </SVGContentLoader>
              <ChevronUpDownIcon width={24} />
            </div>
            <ScoreCard.Score className="self-start">
              <SVGContentLoader height="40">
                <rect x="16" y="0" width="48" height="40" />
              </SVGContentLoader>
            </ScoreCard.Score>
          </div>
        </div>
        <div className="relative w-full flex-1 border-l-4">
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center justify-between">
              <SVGContentLoader height="24">
                <rect x="16" y="0" width="128" height="24" />
              </SVGContentLoader>
              <ChevronUpDownIcon width={24} />
            </div>
            <ScoreCard.Score className="self-start">
              <SVGContentLoader height="40">
                <rect x="16" y="0" width="48" height="40" />
              </SVGContentLoader>
            </ScoreCard.Score>
          </div>
        </div>
        <div className="relative w-full flex-1 border-l-4">
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center justify-between">
              <SVGContentLoader height="24">
                <rect x="16" y="0" width="128" height="24" />
              </SVGContentLoader>
              <ChevronUpDownIcon width={24} />
            </div>
            <ScoreCard.Score className="self-start">
              <SVGContentLoader height="40">
                <rect x="16" y="0" width="48" height="40" />
              </SVGContentLoader>
            </ScoreCard.Score>
          </div>
        </div>
      </div>
      <div className="col-span-3">
        <SVGContentLoader height="379" width="">
          <rect x="0" y="0" height="379" width="1010" />
        </SVGContentLoader>
      </div>
    </div>
  );
}
