import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { useTranslations } from '@keymono/i18n';

import { useSessionInstance } from '../../hooks';

import {
  ActiveSessionPreview,
  AddAnotherLoginBtn,
  FooterSessionsListItems,
  SignOutBtn,
  SwitchAccountOptionItem,
  SessionsInfoContainer,
} from '../SessionManagement';

import { ProfilePopoverTriggerBtn } from './ProfilePopoverTriggerBtn';

interface IOtherSessionsListProps {
  closePopCallback: () => void;
}

function OtherSessionsList({ closePopCallback }: IOtherSessionsListProps) {
  const { allSessionsByIndex, otherSessionsIndices } = useSessionInstance();

  if (!allSessionsByIndex) {
    return null;
  }

  return (
    <>
      {otherSessionsIndices.map((sessionIndex) => (
        <SwitchAccountOptionItem
          key={sessionIndex}
          type="select"
          session={allSessionsByIndex[sessionIndex]}
          onSwitchAccount={closePopCallback}
        />
      ))}
    </>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders a dropdown that shows the profile options for the current account.
 * - It displays all the active session accounts and provides options for the user
 * to switch the active account.
 * - Provides CTA for the user to manage their accounts or add another account.
 * - Also adds helpful links to support or docs pages.
 *
 * TODO: Further reduce the font size for very small devices on this popup
 */
export function ProfileFlyoutMenu() {
  const t = useTranslations('Login');

  const { activeSession, sessionsCount } = useSessionInstance();

  if (!activeSession) {
    return null;
  }

  const { user } = activeSession;
  const { firstName, lastName, profileImageUrl } = user || {};

  const fullName = `${firstName} ${lastName}`;

  if (!activeSession) {
    return null;
  }

  const handleNavigateToSupport = () => {
    /**
     * Note: Using windows since `router.push(path)` can't support target='_blank'
     */
    const newWindow = window?.open('/support', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleNavigateToHelp = () => {
    const newWindow = window?.open('/help', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const footerActions = [
    { label: 'Support', onClick: handleNavigateToSupport },
    { label: 'Help', onClick: handleNavigateToHelp },
  ];

  const showFooterActions = false;

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button
            className="
              flex shrink-0 rounded-full bg-gray-100 text-sm text-white
              shadow-sm shadow-gray-100 hover:bg-red-200 hover:text-red-800
              focus:outline-none focus:ring-2  focus:ring-red-200
            "
          >
            <ProfilePopoverTriggerBtn
              name={fullName}
              imageUrl={profileImageUrl}
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className="
                absolute z-30 mt-3 w-screen max-w-xs transform rounded-md px-2
                ltr:right-0 rtl:left-0 rtl:right-auto sm:px-0
              "
            >
              <SessionsInfoContainer>
                <div className="session-item-wrapper">
                  <ActiveSessionPreview activeSession={activeSession} />

                  <SignOutBtn
                    label={t('activeAccounts.signOutLabel.signOut')}
                    targetSession={activeSession}
                  />

                  <div className="accounts-list-wrapper">
                    <OtherSessionsList closePopCallback={close} />
                    <AddAnotherLoginBtn />
                  </div>

                  {sessionsCount > 1 ? (
                    <SignOutBtn
                      label={t('activeAccounts.signOutLabel.signOutOfAll')}
                      isMultipleSignOut
                    />
                  ) : null}
                  {showFooterActions ? (
                    <FooterSessionsListItems actions={footerActions} />
                  ) : null}
                </div>
              </SessionsInfoContainer>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default ProfileFlyoutMenu;
