import { OrdersListBase } from '../../../OrdersListBase';
import { OrdersListTableBodyWrapper } from '../../../styles';
import { OrdersListLoaderTableRow } from './OrdersListLoaderTableRow';

/**
 * -----------------------------------------------------------------------------
 * This renders the skeleton loader of `OrdersListTable`.
 */
export function OrdersListLoaderTable() {
  const dummyOrdersCount = 7;
  const skeletonScreenItems = [...Array(dummyOrdersCount).keys()];

  return (
    <OrdersListBase className="opacity-70 max-sm:hidden">
      <OrdersListTableBodyWrapper>
        {skeletonScreenItems.map((item, index) => (
          <OrdersListLoaderTableRow
            className={`${index % 2 === 0 ? 'bg-white' : 'bg-white/50'}`}
            key={item}
          />
        ))}
      </OrdersListTableBodyWrapper>
    </OrdersListBase>
  );
}
