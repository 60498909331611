import { useMutation } from '@tanstack/react-query';

import { IApiError } from '../../../../types';
import { IPasswordResetData } from '../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the password reset up mutation keys in react query cache.
 */
const MUT_PASSWORD_RESET_USER = 'password-reset-mutation-key';

interface IUsePasswordResetPayload {
  onSuccessCallback: (passwordResetData: IPasswordResetData) => void;
  onErrorCallback: (err: IApiError) => void;
}

/**
 *  Mutation to gracefully handle user password reset requests
 */
export const usePasswordResetMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUsePasswordResetPayload) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_PASSWORD_RESET_USER],
    networkMode: 'always',
    retry: false,
    mutationFn: USER_ACCOUNTS_API.resetPassword,
    onSuccess: (response) => {
      onSuccessCallback(response?.data.data);
    },
    onError: (err: IApiError) => {
      onErrorCallback(err);
      return err;
    },
  });

  return {
    error,
    isLoading,
    submitPasswordResetDetails: mutate,
  };
};
