import { useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';

import { ICreatedOrganizationData } from '../types';
import { ORGANIZATION_API } from '../organizationAPIClient';

/**
 * This is to track the login mutation keys in react query cache.
 */
const MUT_CREATE_ORGANIZATION = 'create-organization-mutation-key';

interface IUseCreateOrganizationOptions {
  onSuccessCallback: (createdOrgData: ICreatedOrganizationData) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This mutation posts a new organization and on success return the org id.
 */
export const useCreateOrganizationMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseCreateOrganizationOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_CREATE_ORGANIZATION],
    networkMode: 'always',
    retry: false,
    mutationFn: ORGANIZATION_API.createOrganization,
    onSuccess: (responseData) => {
      onSuccessCallback(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Create Organization Attempt',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    submitCreateOrganization: mutate,
  };
};
