import Image from 'next/image';

import { IOrganization } from '@keymono/apis';

import { useMembershipRedirection } from '../../../../../organizations/hooks';
import {
  CardBodyWrapper,
  CardThumbnailWrapper,
  CardWrapper,
  CategoryBadgeWrapper,
  OrganizationBadgeWrapper,
} from '../styles';

interface IOrganizationCardHeaderProps {
  name: string;
  addressCountryLabel: string;
  logoUrl: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the header of the OrganizationListCard.
 * It includes the organization's logo and country badge.
 */
function OrganizationCardHeader({
  name,
  logoUrl,
  addressCountryLabel,
}: IOrganizationCardHeaderProps) {
  return (
    <CardThumbnailWrapper>
      <Image
        alt={name}
        className="thumbnail"
        src={logoUrl}
        height={600}
        width={600}
      />
      <OrganizationBadgeWrapper>
        <div className="organization-badge-container">
          <span className="organization-badge-label">
            {addressCountryLabel}
          </span>
        </div>
      </OrganizationBadgeWrapper>
    </CardThumbnailWrapper>
  );
}

interface IOrganizationListCardProps {
  organization: IOrganization;
}

/**
 * -----------------------------------------------------------------------------
 * This renders an already setup organization for the active user.
 */
export function OrganizationListCard({
  organization,
}: IOrganizationListCardProps) {
  const {
    id: orgId,
    name,
    logoUrl,
    uniqueName,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressCountryLabel,
    addressZip,
  } = organization;
  const { requestNavigationToOrg } = useMembershipRedirection();

  const address = [
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressCountryLabel,
    addressZip,
  ]
    .filter((item) => item)
    .join(', ');

  const handleOnClickItem = () => {
    requestNavigationToOrg({ orgId });
  };

  return (
    <CardWrapper
      aria-label="Organization Card"
      className="group"
      onClick={handleOnClickItem}
      role="button"
      tabIndex={0}
    >
      <OrganizationCardHeader
        name={name}
        logoUrl={logoUrl}
        addressCountryLabel={addressCountryLabel}
      />
      <CardBodyWrapper>
        <h3>{name}</h3>
        <div className="mb-2">
          <CategoryBadgeWrapper>{uniqueName}</CategoryBadgeWrapper>
        </div>

        <p className="description">{address}</p>
      </CardBodyWrapper>
    </CardWrapper>
  );
}
