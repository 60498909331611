import tw, { styled } from 'twin.macro';

/**
 * TODO: Make these styles responsive at smaller screens.
 */

export const CardWrapper = styled.div`
  ${tw`
    relative
    flex
    flex-col
    overflow-hidden
    rounded-xl
    border
    border-gray-100
    bg-white
    shadow-lg
    shadow-gray-100/10

    transition-all
    duration-1000
    ease-in

    hover:(border-red-100 shadow-red-50/60)
    // group-hover:(bg-red-50/50 opacity-75)
  `};
`;

export const CardThumbnailWrapper = styled.div`
  ${tw`
    aspect-auto

    transition-all
    duration-1000
    ease-in
  `};

  .thumbnail {
    ${tw`
      h-full
      w-full
      object-contain
      object-center
      px-28
      py-10

      sm:(h-full w-full)
    `};
  }
`;

export const StatusBadgeWrapper = styled.div`
  ${tw`
    flex
    gap-2
  `};

  .status-badge-container {
    ${tw`
      inline-flex
      items-center
      rounded-full
      px-2.5
      py-0.5
      text-xs
      font-medium
      bg-red-50
      text-red-900
    `}

    &.verified {
      ${tw`
        border
        bg-green-50
        border-green-800/20
        text-green-700
      `}
    }

    &.failed-verification {
      ${tw`
        border
        border-red-800/20
        text-red-700
      `}
    }

    .status-badge-icon {
      ${tw`
      w-5
      h-5

      ltr:(-ml-1 mr-1)
      rtl:(-mr-1 ml-1)
    `}
    }

    .status-badge-label {
      ${tw`
      text-xs
      font-medium
    `}
    }

    .failed-verification {
    }
  }
`;

export const CategoryBadgeWrapper = styled.div`
  ${tw`
    -mx-1
    px-2.5
    py-0.5
    inline-flex
    items-center
    rounded-full
    bg-red-50
    text-xs
    font-medium
    text-red-900
    opacity-80
  `};
`;

export const CardBodyWrapper = styled.div`
  ${tw`
    flex
    flex-1
    flex-col
    space-y-2
    py-6
    px-4

    transition-all
    duration-500
    ease-in

    // group-hover:bg-red-50/20
  `};

  h3 {
    ${tw`
      text-base
      font-semibold
      text-gray-800
    `}
  }

  .description {
    ${tw`
      leading-5
      py-2
      text-xs
      text-gray-700
    `}
  }

  .error-msg {
    ${tw`
      -mx-4
      p-4
      leading-5
      text-xs
      text-red-700
      border-l-2
      border-red-200
    `}
  }

  .actions {
    ${tw`
      flex
      w-full
      items-end
      justify-end
      gap-2
      pt-2
      text-sm

      transition-all
      duration-200
      ease-linear
    `}
  }
`;
