import Image from 'next/image';
import { ClockIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

import {
  CancelInvitationCTA,
  ResendInvitationCTA,
  AcceptInvitationCTA,
  DeclineInvitationCTA,
} from '../../actions';
import { IInvitationsListProps } from '../types';

import { InvitationsListBase } from './InvitationsListBase';

/**
 * -----------------------------------------------------------------------------
 * This renders the pending invitations of an org or user in a table layout.
 */
export function InvitationsListTable({
  invitations,
  orgId = '',
  className = '',
}: IInvitationsListProps) {
  return (
    <InvitationsListBase className={className}>
      {invitations.map((invitation, index) => {
        const { id, status, type, inviteType } = invitation;

        const inviteName =
          inviteType === 'org' ? invitation.email : invitation.orgName || '';
        const orgLogoUrl = inviteType === 'user' ? invitation.orgLogoUrl : '';

        return (
          <tr key={id} className={index % 2 === 0 ? undefined : 'bg-red-50/20'}>
            <td className="whitespace-nowrap py-4 pe-3 ps-4 text-sm sm:ps-0">
              <div className="flex items-center">
                <div className="flex h-10 w-10 flex-shrink-0 items-center">
                  {/**
                   * If the invitations are for an organization,
                   * render the Envelope Icon as Image.
                   * If the invitations are for a user,
                   * render the Image of the Org.
                   */}
                  {inviteType === 'org' ? (
                    <EnvelopeIcon
                      className="
                        h-10 w-10 rounded-full bg-gray-100 p-2 text-gray-500
                      "
                      aria-hidden="true"
                    />
                  ) : (
                    <Image
                      className="h-8 w-8 rounded-full"
                      src={orgLogoUrl}
                      width={32}
                      height={32}
                      alt=""
                    />
                  )}
                </div>
                <div className="ms-4">
                  <div className="font-medium text-gray-900">
                    <span>{inviteName}</span>
                  </div>
                </div>
              </div>
            </td>
            <td
              className="
                whitespace-nowrap px-3 py-4 text-sm capitalize text-gray-500
              "
            >
              {type}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <span
                className="
                  inline-flex items-center rounded-full border
                  border-yellow-800/20 bg-yellow-50 px-2.5 py-0.5 text-xs
                  font-semibold capitalize text-yellow-700
                "
              >
                <ClockIcon className="-ms-1 me-1 h-5 w-5" />
                {status}
              </span>
            </td>
            <td
              className="
                relative whitespace-nowrap py-4 pe-4 ps-3 text-end text-sm
                font-medium sm:pe-0
              "
            >
              <div className="flex gap-2">
                {/**
                 * If the invitations are for an organization,
                 * render the Cancel and Resend Invitation CTAs.
                 * If the invitations are for a user,
                 * render the Accept and Decline Invitation CTAs.
                 */}
                {inviteType === 'org' ? (
                  <>
                    <CancelInvitationCTA id={id} orgId={orgId} />
                    <ResendInvitationCTA id={id} orgId={orgId} />
                  </>
                ) : (
                  <>
                    <AcceptInvitationCTA id={id} />
                    <DeclineInvitationCTA id={id} />
                  </>
                )}
              </div>
            </td>
          </tr>
        );
      })}
    </InvitationsListBase>
  );
}
