import { useOrgIntegrationsQuery } from '@keymono/apis';

import { ExistingIntegrationCardItem } from './ExistingIntegrationCardItem';
import { OrgIntegrationsListItemsFallback } from './OrgIntegrationsListItemsFallback';

interface IOrgIntegrationsListItemsProps {
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the integrations  a particular organization.
 * NOTE: This is not to be confused with the `AllIntegrationsListItems`.
 */
export function OrgIntegrationsListItems({
  orgId,
}: IOrgIntegrationsListItemsProps) {
  const { data, isLoading, error, refetch } = useOrgIntegrationsQuery({
    orgId,
  });

  const integrations = data?.integrations;

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !integrations) {
    return (
      <OrgIntegrationsListItemsFallback
        isLoading={isLoading}
        error={error}
        onRetryAction={{
          onClick: handleRetryOnFail,
        }}
      />
    );
  }

  return (
    <>
      <div className="pb-8">
        <h1 className="text-2xl font-bold tracking-tight text-gray-700">
          Active Integrations
        </h1>
        <p className="mt-2 text-sm text-gray-500">
          The following integrations have been enabled for this organization
        </p>
      </div>

      <div
        className="
          grid grid-cols-1 gap-4 border-b border-gray-100 pb-8
          sm:grid-cols-2 xl:grid-cols-3
        "
      >
        {integrations.map((integration) => (
          <ExistingIntegrationCardItem
            integration={integration}
            key={integration.id}
            orgId={orgId}
          />
        ))}
      </div>
    </>
  );
}
