import { InvitationsListBase } from '../../InvitationsListBase';
import { InvitationsListLoaderItem } from './InvitationsListLoaderItem';
import { InvitationsListLoaderCard } from './InvitationsListLoaderCard';

interface IInvitationsListLoaderProps {
  invitationsCount: number;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state (skeleton loader) of `InvitationsList`.
 * TODO: Compute device width and render the appropriate view.
 */
export function InvitationsListLoader({
  invitationsCount,
}: IInvitationsListLoaderProps) {
  const items = [...Array(invitationsCount).keys()];

  return (
    <>
      <InvitationsListBase className="opacity-70 max-sm:hidden">
        {items.map((i) => (
          <InvitationsListLoaderItem key={i} />
        ))}
      </InvitationsListBase>
      <ul className="-my-5 divide-y divide-gray-200 pt-8 sm:hidden">
        {items.map((i) => (
          <InvitationsListLoaderCard key={i} />
        ))}
      </ul>
    </>
  );
}
