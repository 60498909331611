import { IApiError, IFetchOrgOrdersData } from '@keymono/apis';

import { OrdersListEmpty } from './OrdersListEmpty';
import { OrdersListFallback } from './OrdersListFallback';
import { OrdersListTable } from './OrdersListTable';
import { OrdersListFooter } from './OrdersListFooter';

interface IOrdersListProps {
  data?: IFetchOrgOrdersData;
  error?: IApiError | null;
  handlePageChange: (page: number) => void;
  isLoading: boolean;
  navigateToOrderDetails: (orderId: string) => void;
  refetch: () => void;
  isMobileViewDisabled: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the list of all Orders for the current organization.
 * Includes the header and the table view.
 * The header consists of the search bar, sorting, filtering, bulk actions, etc.
 */
export function OrdersList({
  data,
  error,
  handlePageChange,
  isLoading,
  navigateToOrderDetails,
  refetch,
  isMobileViewDisabled,
}: IOrdersListProps) {
  const handleRetryOnFail = () => {
    refetch();
  };

  const orders = data?.orders;

  if (isLoading || error || !orders) {
    return (
      <OrdersListFallback
        isLoading={isLoading}
        error={error}
        onRetryAction={{ onClick: handleRetryOnFail }}
      />
    );
  }

  const ordersCount = orders.length;

  if (ordersCount <= 0) {
    return <OrdersListEmpty />;
  }

  const {
    meta: { pagination },
  } = data;

  return (
    <>
      <OrdersListTable
        navigateToOrderDetails={navigateToOrderDetails}
        orders={orders}
        isMobileViewDisabled={isMobileViewDisabled}
      />
      <OrdersListFooter
        pagination={pagination}
        handlePageChange={handlePageChange}
      />
    </>
  );
}
