interface IColItemTagsProps {
  labels: string[];
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Col Item component that renders a list of labels.
 */
export function ColItemTags({ labels, className = '' }: IColItemTagsProps) {
  return (
    <div className={`flex gap-x-2 ${className}`}>
      {labels.map((label) => (
        <span
          className="inline-flex rounded-lg bg-slate-100 p-2 text-xs font-medium text-gray-700"
          key={label}
        >
          {label}
        </span>
      ))}
    </div>
  );
}
