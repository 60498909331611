import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';
import { IUseOrgOrdersQueryOptions } from '../types';
import { ORDERS_API } from '../ordersAPIClient';

export const QUERY_FETCH_ORG_ORDERS_KEY = 'fetch-org-orders-key';

/**
 * Custom hook to fetch the list of Orders for an Org
 * Fetches from the Order API Client.
 * Uses React Query under the hood.
 */
export function useOrgOrdersQuery({
  orgId,
  page,
  channelCreatedTsGte,
  channelCreatedTsLte,
  ordering,
}: IUseOrgOrdersQueryOptions) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      QUERY_FETCH_ORG_ORDERS_KEY,
      orgId,
      page,
      channelCreatedTsGte,
      channelCreatedTsLte,
      ordering,
    ],
    queryFn: () =>
      ORDERS_API.fetchOrgOrders({
        orgId,
        page,
        channelCreatedTsGte,
        channelCreatedTsLte,
        ordering,
      }),
    enabled: !!orgId, // Don't fetch if org id is not loaded.,
    onError: (err: IApiError) => err,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
}
