import { useRouter } from 'next/router';

import { useTranslations } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * Navigates the user to the login screen, from sign up
 */
export function LoginButtonCTA() {
  const t = useTranslations('Registration');
  const { push } = useRouter();

  const handleNavToLogin = () => {
    push('/login');
  };

  return (
    <div className="my-4 text-sm font-light text-gray-500 dark:text-gray-400">
      <button
        type="button"
        onClick={handleNavToLogin}
        className="form-link-action -mx-2"
        title={t('loginButtonCTA.subTitle')}
      >
        {t('loginButtonCTA.title')}
      </button>
    </div>
  );
}
