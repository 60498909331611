import type { IUser, IUserRaw } from '@keymono/shared-types';

import { ILoginUserData, ILoginUserDataRaw } from './types';

/**
 * This transforms the user details data to a more type safe variant with
 * additional fields.
 */
export function formatUserDetailsData(data: IUserRaw): IUser {
  const { invitation: invitationRaw, membership: membershipRaw } = data;

  const membership = membershipRaw.map((member) => {
    const {
      org_logo_url: orgLogoUrl,
      org_name: orgName,
      type_label: typeLabel,
      user: userId,
      ...rest
    } = member || {};

    return {
      ...rest,
      orgLogoUrl,
      orgName,
      typeLabel,
      userId,
    };
  });

  const invitation = invitationRaw.map((invite) => {
    const {
      org_logo_url: orgLogoUrl,
      org_name: orgName,
      org: orgId,
      ...rest
    } = invite || {};

    return {
      ...rest,
      orgId,
      orgLogoUrl,
      orgName,
      inviteType: 'user' as const, // Explicitly state that inviteType is 'user'
    };
  });

  const {
    first_name: firstName,
    last_name: lastName,
    profile_image_url: profileImageUrl,
    ...rest
  } = data;

  const userData = {
    ...rest,
    firstName,
    lastName,
    fullName: `${firstName} ${lastName || ''}`.trim(),
    membership,
    invitation,
    profileImageUrl,
  };

  return userData;
}

/**
 * This transforms the login data to more type safe variables within the app.
 * Also adds in custom fields not present on the backend here.
 */
export function formatLoginData(data: ILoginUserDataRaw): ILoginUserData {
  const { token, expiry, user } = data;

  return {
    token,
    expiry,
    user: formatUserDetailsData(user),
  };
}
