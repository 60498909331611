import { useState } from 'react';
import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';
import { IApiError, useRegistrationMutation } from '@keymono/apis';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';
import {
  EmailAddressInputField,
  FirstNameInputField,
  Button,
  FormFeedbackSection,
  LogoKeymono,
  PasswordInputField,
  useForm,
} from '@keymono/design-system';

import { FormContainer, StyledForm } from '../styles';

import { LoginInsteadCTA } from './LoginInsteadCTA';
import { LoginButtonCTA } from './LoginButtonCTA';
import { FormLinkCTA } from '../../components/FormLinkCTA/FormLinkCTA';

interface IRegistrationFormInputFields {
  email: string;
  firstName: string;
  password: string;
}

export interface IRegistrationFormProps {
  token: string;
  isFormSubmitted: boolean;
  onSubmitSuccess: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the registration form that captures the user details email address
 * and sends to complete the reg process.
 */
export default function RegistrationForm({
  token,
  onSubmitSuccess,
  isFormSubmitted,
}: IRegistrationFormProps) {
  const t = useTranslations('Registration');
  const tCommon = useTranslations('Common');

  const [formFeedback, setFormFeedback] = useState({
    message: t('formIntro.message'),
    description: t('formIntro.description'),
  });

  const handleOnRegistrationError = (error: IApiError) => {
    // TODO: Refactor this out to the mutation
    errorLogToRemoteUtil({
      error,
      errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
      errorTitle: 'Error in Registration Attempt',
      message: error.message,
    });
  };

  const handleOnRegistrationSuccess = () => {
    setFormFeedback({
      message: t('formSuccess.message'),
      description: t('formSuccess.description'),
    });
    onSubmitSuccess();
  };

  const {
    error,
    isLoading: isFormSubmitting,
    submitRegistrationDetails,
  } = useRegistrationMutation({
    onSuccessCallback: handleOnRegistrationSuccess,
    onErrorCallback: handleOnRegistrationError,
  });

  const errorFields = error?.errorFields;

  const handlePostRegistrationDetails = ({
    email,
    firstName,
    password,
  }: IRegistrationFormInputFields) => {
    submitRegistrationDetails({
      email,
      firstName,
      password,
      token,
    });
  };

  /**
   * The fields to be tracked within the form.
   */
  const registrationInputFieldsValues: IRegistrationFormInputFields = {
    email: '',
    firstName: '',
    password: '',
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: registrationInputFieldsValues,
    onSubmitCb: handlePostRegistrationDetails,
  });

  /**
   * TODO: Replace this temp work around with logic to get direct key from error object.
   */
  const isLinkExpired = error?.description?.includes(
    'is invalid or has expired.'
  );

  return (
    <FormContainer>
      <div className="form-responsive-wrapper">
        <div>
          <Link
            href="/"
            className="my-8 hidden w-auto sm:inline-flex"
            aria-label={tCommon('logoAriaLabel')}
            title={tCommon('logoAriaLabel')}
          >
            <LogoKeymono />
          </Link>
          <div className="form-header flex">
            <h1 className="grow">{t('title')}</h1>
          </div>

          {error?.message ? (
            <FormFeedbackSection
              isFormSubmitting={isFormSubmitting}
              type="error"
              message={error.message}
              description={error.description}
            />
          ) : (
            <FormFeedbackSection
              isFormSubmitting={isFormSubmitting}
              type="info"
              message={formFeedback.message}
              messageLoadingText={t('formSubmitting.message')}
              description={formFeedback.description}
              descriptionLoadingText={t('formSubmitting.description')}
            />
          )}
        </div>

        {isFormSubmitted ? (
          <div>
            <LoginButtonCTA />
          </div>
        ) : (
          <StyledForm onSubmit={handleOnSubmitForm}>
            <EmailAddressInputField
              error={errorFields?.email || errors.email}
              label={t('formFields.email.label')}
              name="email"
              onBlurCallback={handleOnBlur}
              onChangeCallback={handleOnInputChange}
              onError={handleOnError}
              placeholder={t('formFields.email.placeholder')}
              value={values.email}
            />

            <FirstNameInputField
              error={errorFields?.first_name || errors.firstName}
              label={t('formFields.firstName.label')}
              name="firstName"
              onBlurCallback={handleOnBlur}
              onChangeCallback={handleOnInputChange}
              onError={handleOnError}
              placeholder={t('formFields.firstName.placeholder')}
              value={values.firstName}
            />

            <PasswordInputField
              error={errorFields?.password || errors.password}
              label={t('formFields.password.label')}
              name="password"
              onBlurCallback={handleOnBlur}
              onChangeCallback={handleOnInputChange}
              onError={handleOnError}
              placeholder={t('formFields.password.placeholder')}
              value={values.password}
            />

            <Button
              type="submit"
              isEnabled={!isFormSubmitting}
              isLoading={isFormSubmitting}
              label={t('formFields.submit.label')}
              loadingLabel={t('formFields.submit.loadingLabel')}
            />

            {isLinkExpired ? (
              <FormLinkCTA
                href="/auth/sign-up"
                title={t('goSignUpCTA.title')}
                subTitle={t('goSignUpCTA.subTitle')}
              />
            ) : null}
            <LoginInsteadCTA />
          </StyledForm>
        )}
      </div>
    </FormContainer>
  );
}
