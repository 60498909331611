import { useTranslations } from '@keymono/i18n';
import { useUpdateOrgDetailsMutation } from '@keymono/apis';

import {
  CardSecButton,
  CardSubmitButton,
  FormFeedbackSection,
  TextInputField,
  useForm,
} from '@keymono/design-system';
import {
  IOrgNameIdentifiers,
  IOrganizationDetails,
} from '@keymono/shared-types';

type TUpdateOrgNamesInputFields = Pick<
  IOrganizationDetails,
  'name' | 'legalName' | 'uniqueName'
>;

interface IOrgNamesCardEditProps {
  names: IOrgNameIdentifiers;
  orgId: string;
  onExitEditMode: () => void;
  onUpdateSuccess: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This card allows for the user to update the org's names identifiers.
 */
export function OrgNamesCardEdit({
  orgId,
  names,
  onExitEditMode,
  onUpdateSuccess,
}: IOrgNamesCardEditProps) {
  const t = useTranslations('UserProfile.namesSection');

  const {
    name: initName,
    legalName: initLegalName,
    uniqueName: initUniqueName,
  } = names;

  const handleCancelEdit = () => {
    onExitEditMode();
  };

  const handleOnUpdateNamesSuccess = () => {
    onUpdateSuccess();
  };

  const {
    error,
    isLoading: isFormSubmitting,
    updateOrgDetails,
  } = useUpdateOrgDetailsMutation({
    orgId,
    onSuccessCallback: handleOnUpdateNamesSuccess,
  });

  const errorFields = error?.errorFields;

  const handlePostDetails = (values: TUpdateOrgNamesInputFields) => {
    const { name, legalName, uniqueName } = values;

    /**
     * Post form fields that have been changed and ignore  the none touched.
     */
    updateOrgDetails({
      orgId,
      ...(name !== initName ? { name } : {}),
      ...(legalName !== initLegalName ? { legal_name: legalName } : {}),
      ...(uniqueName !== initUniqueName ? { unique_name: uniqueName } : {}),
    });
  };

  /**
   * The fields to be tracked within the form.
   */
  const addressInputFieldsValues: TUpdateOrgNamesInputFields = {
    name: initName,
    legalName: initLegalName,
    uniqueName: initUniqueName,
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: addressInputFieldsValues,
    onSubmitCb: handlePostDetails,
  });

  return (
    <form className="mt-5 sm:mt-6" onSubmit={handleOnSubmitForm}>
      {error?.message ? (
        <FormFeedbackSection
          isFormSubmitting={isFormSubmitting}
          type="error"
          message={error.message}
          description={error.description}
        />
      ) : (
        <FormFeedbackSection
          isFormSubmitting={isFormSubmitting}
          type="info"
          message={t('editNamesFormIntro.message')}
          description={t('editNamesFormIntro.description')}
        />
      )}

      <TextInputField
        type="text"
        error={errorFields?.name || errors.name}
        label="Name"
        name="name"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="Enter the name of the Organization"
        onError={handleOnError}
        value={values.name}
      />

      <TextInputField
        type="text"
        error={errorFields?.legal_name || errors.legalName}
        label="Legal Name"
        name="legalName"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="Organization Legal/ Official Name"
        onError={handleOnError}
        value={values.legalName}
      />

      <TextInputField
        type="text"
        error={errorFields?.unique_name || errors.uniqueName}
        label="Unique Name"
        name="uniqueName"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="Short unique identifier for the org"
        onError={handleOnError}
        value={values.uniqueName}
        defaultValue={values.name}
        valuePrefix="console.keymono.com/"
      />

      <div className="mt-2 flex w-full justify-end gap-2">
        <CardSecButton onClick={handleCancelEdit} isEnabled={!isFormSubmitting}>
          {t('formFields.cancelCTA.label')}
        </CardSecButton>
        <CardSubmitButton
          isEnabled={!isFormSubmitting}
          isLoading={isFormSubmitting}
          label={t('formFields.submitCTA.label')}
          loadingLabel={t('formFields.submitCTA.loadingLabel')}
        />
      </div>
    </form>
  );
}
