import { useRouter } from 'next/router';

import {
  CardSecButton,
  IllustrationTeamworkPullingUp,
} from '@keymono/design-system';

interface INoNewMembersProps {
  accountId: string;
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This is displayed when only one member is present in the organization.
 */
export function NoNewMembers({ accountId, orgId }: INoNewMembersProps) {
  const { push } = useRouter();

  const handleNavToInvitations = () => {
    push(`/${accountId}/org/${orgId}/invitations`);
  };

  return (
    <div
      className="
        mt-1 flex min-h-full w-full flex-1
        flex-col items-center justify-center overflow-hidden
        rounded-lg bg-white px-4 py-8 text-center shadow-sm
        shadow-gray-100
      "
    >
      <IllustrationTeamworkPullingUp className="w-[50%] sm:w-[30%]" />
      <p className="w-[60%] max-w-xl text-sm text-gray-600">
        You are the only one here. Grow your operations by inviting more members
        to your organization.
      </p>
      <div className="my-8">
        <CardSecButton onClick={handleNavToInvitations}>
          Invite other members
        </CardSecButton>
      </div>
    </div>
  );
}
