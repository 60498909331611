import {
  KeymonoApiEnv,
  keymonoApiHost,
} from '../../api-client-v2/config/hosts';
import { RequestBuilder } from '../../api-client-v2/utils/requestBuilder';

const rb = new RequestBuilder()
  .scheme('https')
  .host(
    keymonoApiHost(
      (process?.env?.NEXT_PUBLIC_BASE_API_URL as KeymonoApiEnv) ||
        'https://services.keymono.com/api'
    )
  )
  .basePath('/api/0.0.1');

export function requestBuilder() {
  return new RequestBuilder(rb.getConfig());
}
