import { useMutation } from '@tanstack/react-query';

import { IApiError } from '../../../../types';
import { IForgotPasswordData } from '../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the forgot password mutation keys in react query cache.
 */
const MUT_FORGOT_PASSWORD = 'forgot-password-mutation-key';

interface IForgotPasswordOptions {
  onSuccessCallback: (forgotPasswordData: IForgotPasswordData) => void;
  onErrorCallback: (err: IApiError) => void;
}

/**
 *  Mutation to gracefully handle forgot password email sending  requests
 */
export const useForgotPasswordMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IForgotPasswordOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_FORGOT_PASSWORD],
    networkMode: 'always',
    retry: false,
    mutationFn: USER_ACCOUNTS_API.forgotPassword,
    onSuccess: (response) => {
      onSuccessCallback(response?.data.data);
    },
    onError: (err: IApiError) => {
      onErrorCallback(err);
      return err;
    },
  });

  return {
    error,
    isLoading,
    submitForgotPasswordEmail: mutate,
  };
};
