import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { CardSecButton, IBaseUIErrorOptions } from '@keymono/design-system';
import { Text } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * Fallback shown when the app fails to retrieve the list of all orders.
 * TODO: Add a helpful illustration, with proper recovery message.
 * TODO: Translate the text from the parent component.
 */
export function OrdersListError({ onRetryAction, error }: IBaseUIErrorOptions) {
  return (
    <div className="flex w-full flex-col items-center justify-center py-20 text-center text-red-900">
      <ExclamationCircleIcon className="my-5 h-16 w-16 text-red-700" />
      <h1 className="flex items-center py-4 text-2xl">
        Sorry, something went wrong
      </h1>

      {error ? (
        <>
          <h1 className="flex items-center justify-center py-2 text-center text-xl font-light">
            {error.message}
          </h1>
          <p>{error.description}</p>
        </>
      ) : null}

      <CardSecButton className="my-8" onClick={onRetryAction.onClick}>
        <Text text={onRetryAction.label ?? 'Retry'} />
      </CardSecButton>
    </div>
  );
}
