import { useTranslations } from 'next-intl';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

import { useResendInvitationMutation } from '@keymono/apis';

import { ActionContainer } from './ActionContainer';

interface IInvitationOptions {
  className?: string;
  id: string;
  orgId: string;
}

interface IResendInvitationCTAProps extends IInvitationOptions {}

/**
 * -----------------------------------------------------------------------------
 * This actions allows the user to resend out an inviation.
 * Calls its own logic to post the invite, track the errors and loading states.
 */
export function ResendInvitationCTA({
  className = '',
  id,
  orgId,
}: IResendInvitationCTAProps) {
  const t = useTranslations('Invitations.resend');

  const { data, isLoading, error, resendOrganizationInvitation } =
    useResendInvitationMutation({ id });

  const handleClick = () => {
    resendOrganizationInvitation({ orgId, invitationId: id });
  };

  return (
    <ActionContainer
      className={className}
      // className="mt-8 px-6 ring-4"
      actionType="primary-action"
      errorMessage={error?.message || null}
      Icon={EnvelopeIcon}
      isLoading={isLoading}
      isSuccess={!!data}
      loadingMessage={t('loading')}
      successMessage={t('success')}
      onActionPress={handleClick}
      title={t('title')}
      titleRetry={t('retry')}
    />
  );
}
