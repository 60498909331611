import { IllustrationProductsInternationally } from '@keymono/design-system';

/**
 * -----------------------------------------------------------------------------
 * This is displayed when there are no pending Orders for the current org.
 * TODO: Add Translations
 */
export function OrdersListEmpty() {
  return (
    <div className="flex grow flex-col items-center justify-center">
      <IllustrationProductsInternationally className="max-w-full" />
      <h2 className="max-w-xl py-4 text-2xl text-gray-600">No Orders added.</h2>
      <p className="max-w-sm text-center text-sm text-teal-700 ">
        You have not added any orders to track.
      </p>
    </div>
  );
}
