import { asyncResultIsError, executeAsyncFn } from './utils/asyncExecutor';

type ApiError = unknown;

type ApiClientErrorType = {
  server: ApiError;
  client: Error;
};

export class ApiClientError<
  TErrorType extends 'server' | 'client'
> extends Error {
  constructor(
    public errorType: TErrorType,
    public detail: ApiClientErrorType[TErrorType],
    message?: string
  ) {
    super(message);
  }
}

export type ApiResponse<TData> =
  | {
      success: true;
      status: number;
      json: TData;
    }
  | {
      success: false;
      status: number;
      error: ApiClientError<'server'>;
    }
  | {
      success: false;
      status: number;
      error: ApiClientError<'client'>;
    };

export async function request<TData>(
  url: string | URL,
  init?: RequestInit | undefined
): Promise<ApiResponse<TData>> {
  const fetchFn = fetch(url, init);
  const fetchResult = await executeAsyncFn<Response, Error>(fetchFn);
  if (asyncResultIsError(fetchResult)) {
    const [_, error] = fetchResult;
    return {
      success: false,
      status: 0,
      error: new ApiClientError('client', error, 'failed to fetch'),
    };
  }
  const [response] = fetchResult;

  const parseBodyFn = response.json();
  const parseBodyResult = await executeAsyncFn<any, Error>(parseBodyFn);
  if (asyncResultIsError(parseBodyResult)) {
    if (!response.ok) {
      return {
        success: false,
        status: response.status,
        error: new ApiClientError(
          'server',
          { message: response.statusText },
          response.statusText
        ),
      };
    }

    const [_, error] = parseBodyResult;
    return {
      success: false,
      status: 0,
      error: new ApiClientError(
        'client',
        error,
        'unable to parse request body'
      ),
    };
  }

  const [jsonBody] = parseBodyResult;
  if (!response.ok) {
    return {
      success: false,
      status: response.status,
      error: new ApiClientError('server', jsonBody, 'response not ok'),
    };
  }
  return {
    success: true,
    status: response.status,
    json: jsonBody as TData,
  };
}
