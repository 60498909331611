import { Fragment } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import {
  BuildingOffice2Icon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/outline';
import { IMembership } from '@keymono/shared-types';

import { SwitchOrganizationItemWrapper } from './styles';
import { useMembershipRedirection } from '../hooks';

interface IItemProfileImageProps {
  name: string;
  logoUrl: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the logo of the organization or a fallback building icon if the image
 * url is not present.
 */
function OrganizationLogo({ name, logoUrl }: IItemProfileImageProps) {
  return (
    <div className="shrink-0">
      {logoUrl ? (
        <Image
          alt=""
          title={`${name}'s logo. The other organization you are part of.`}
          className="org-logo"
          height={24}
          src={logoUrl}
          // src="https://dummyimage.com/200x200/fce6e6/ff0000.png&text=T"
          width={24}
        />
      ) : (
        <div className="no-org-logo-icon">
          <BuildingOfficeIcon aria-hidden="true" />
        </div>
      )}
    </div>
  );
}

interface IOrganizationDropdownTriggerProps {
  activeMembership: IMembership;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the logo of the organization or a fallback building icon if the image
 * url is not present.
 */
function OrganizationDropdownTrigger({
  activeMembership,
}: IOrganizationDropdownTriggerProps) {
  const { orgName, orgLogoUrl } = activeMembership;

  return (
    <div className="inline-flex shrink-0 items-center p-2 py-1.5 text-xs ltr:pr-1 rtl:pl-1">
      <div className="rounded-full">
        {orgLogoUrl ? (
          <Image
            alt="Company Logo"
            title={`${orgName}'s logo. The other organization you are part of.`}
            className="rounded-full"
            height={22}
            src={orgLogoUrl}
            width={22}
          />
        ) : (
          <BuildingOfficeIcon aria-hidden="true" className="h-5 w-5" />
        )}
      </div>

      <span className="mx-2 max-w-[12rem] truncate text-ellipsis">
        {orgName}
      </span>
    </div>
  );
}

interface IOrganizationDropdownMenuProps {
  accountId: string | number;
  activeOrgId: string;
  membership: IMembership[];
}

export function OrganizationDropdownMenu({
  accountId,
  activeOrgId,
  membership,
}: IOrganizationDropdownMenuProps) {
  const activeOrg = membership.find((item) => item.org === activeOrgId);
  const { requestNavigationToOrg } = useMembershipRedirection();

  /**
   * This is used to either hide or show the create ORG button.
   * As per request from @Ivan, the app is not ready to give public access to create org feature.
   * Hidden to  prevent the user from navigating to it.
   * Once ready to be used, just delete this constant render the button directly.
   */
  const IS_CREATE_ORG_CTA_ENABLED = false;

  return (
    <Popover className="relative">
      <Popover.Button
        className="
          ui-open:bg-red-100 ui-open:text-red-600 flex items-center justify-center
          rounded-full border border-gray-200 text-gray-600 shadow-gray-100
          hover:bg-red-50 hover:text-red-800 focus:outline-none focus:ring-2
          focus:ring-red-100 focus:ring-offset-1
        "
      >
        {activeOrg ? (
          <OrganizationDropdownTrigger activeMembership={activeOrg} />
        ) : (
          <div className="shrink-0 p-2">
            <BuildingOfficeIcon aria-hidden="true" className="h-5 w-5" />
          </div>
        )}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className="
            absolute z-10 mt-3 w-screen max-w-xs px-2 ltr:right-0 rtl:left-0
            sm:px-0
          "
        >
          <div
            className="
              mt-0.5 overflow-hidden rounded-xl bg-white shadow-xl
              shadow-gray-400/10 ring-1 ring-gray-500 ring-opacity-5
            "
          >
            <div className="relative grid gap-1 px-2 py-4  sm:gap-2 sm:p-2">
              {membership.map(({ orgName, type, org, orgLogoUrl }) => {
                const activeClass = activeOrgId === org ? 'bg-red-100' : '';

                // const routePath = `/${accountId}/org/${org}/details`;

                const handleClick = () => {
                  requestNavigationToOrg({ orgId: org });
                };

                return (
                  <SwitchOrganizationItemWrapper
                    key={orgName}
                    type="button"
                    onClick={handleClick}
                    className={`group ${activeClass}`}
                  >
                    <OrganizationLogo name={orgName} logoUrl={orgLogoUrl} />
                    <div className="mx-4 text-start">
                      <p className="text-sm font-medium text-gray-900">
                        {orgName}
                      </p>
                      <p className="mt-1 text-xs capitalize text-gray-500">
                        {type}
                      </p>
                    </div>
                  </SwitchOrganizationItemWrapper>
                );
              })}
            </div>

            {IS_CREATE_ORG_CTA_ENABLED ? (
              <div
                className="
                space-y-1 bg-red-50/50 px-6 py-4 sm:flex
                sm:space-x-10 sm:space-y-0 sm:px-5
              "
              >
                <div className="flow-root">
                  <Link
                    href={`/${accountId}/org/create`}
                    className="
                    group inline-flex items-center rounded-full border
                    border-red-200 bg-red-50 px-2.5 py-1.5 text-sm
                    leading-5 text-red-500 shadow-sm transition-all duration-300
                    ease-linear hover:bg-red-600 hover:text-white
                    focus:outline-none focus:ring-2 focus:ring-red-100
                    focus:ring-offset-1
                  "
                  >
                    <BuildingOffice2Icon
                      className="h-5 w-5 group-hover:text-white"
                      aria-hidden="true"
                    />
                    <span className="mx-5 text-xs">
                      Create another Organization
                    </span>
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
