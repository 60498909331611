import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UserMeDTI, UserMeDTO, getUserMe } from './users.api';
import { ApiResponse } from '../../api-client-v2';

export function USE_USER_ME_QUERY_KEY() {
  return ['users.me'];
}

type UseUserMeOptions = Omit<
  UseQueryOptions<
    ApiResponse<UserMeDTI>,
    unknown,
    ApiResponse<UserMeDTI>,
    ReturnType<typeof USE_USER_ME_QUERY_KEY>
  >,
  'queryKey' | 'queryFn'
>;

export function useUserMe(request: UserMeDTO, options?: UseUserMeOptions) {
  return useQuery({
    queryKey: USE_USER_ME_QUERY_KEY(),
    queryFn: () => getUserMe(request),
    ...options,
  });
}
