import type { IUserRaw } from '@keymono/shared-types';

import { ApiClient } from '../../../api-client';
import { IApiError, IApiResponse } from '../../../types';
import {
  fetchUserDetailsUrl,
  forgotPasswordUrl,
  loginUrl,
  logoutUrl,
  passwordResetUrl,
  registerUrl,
  signUpUrl,
  updateUserDetailsUrl,
  verifyEmailUrl,
} from '../../../urls';

import {
  IForgotPasswordOptions,
  IForgotPasswordPayload,
  IForgotPasswordResponseData,
  ILoginOptions,
  ILoginPayload,
  ILoginResponseDataRaw,
  ILogoutOptions,
  ILogoutPayload,
  ILogoutResponseData,
  IPasswordResetPayload,
  IPasswordResetResponseData,
  IRegistrationOptions,
  IRegistrationPayload,
  IRegistrationResponseData,
  ISignUpOptions,
  ISignUpPayload,
  ISignUpResponseData,
  IUpdatableUserDetailsOptions,
  IUpdatableUserDetailsPayloadRaw,
  IVerifyEmailPayload,
  IVerifyEmailData,
} from './types';

import {
  formatLoginData,
  formatUserDetailsData,
} from './formatUserAccountsResponse';

/**
 * This is provides the API client methods for all user authentication,
 * authorization and identity services like login, forgot password etc.
 *
 * NOTE: The `refreshToken` service has been moved out as a global to avoid
 * cyclic dependencies since its to be consumed within the base `ApiClient`.
 *
 */
export class UserAccountsAPIClient extends ApiClient {
  private static classInstance?: UserAccountsAPIClient;

  private constructor() {
    super({
      baseURL: process?.env?.NEXT_PUBLIC_BASE_API_URL || '',
      apiVersion: process?.env?.NEXT_PUBLIC_BASE_API_VERSION || '',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new UserAccountsAPIClient();
    }

    return this.classInstance;
  };

  /**
   * This starts the sign up email verification authentication services.
   * Mainly expects an email payload which will receive a verification email on
   * to be used in the registration process.
   */
  public signUpUser = async (payload: ISignUpOptions) => {
    const response = await this.post<ISignUpResponseData, ISignUpPayload>(
      signUpUrl(),
      {
        email: payload.email,
        recaptcha: 'RANDOM-NOT-CHECKED-IN-NON-PROD',
      },
      { requiresAuth: false }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * This validates the token received from the email registration link which was
   * triggered by the user, allowing the API to update the previous email.
   */
  public verifyEmail = async (payload: IVerifyEmailPayload) => {
    const response = await this.post<IVerifyEmailData, IVerifyEmailPayload>(
      verifyEmailUrl(),
      payload,
      { requiresAuth: false }
    );

    if (!response.success) throw response;

    return { verified: true };
  };

  /**
   * This starts the user registration service.
   */
  public registerUser = async (payload: IRegistrationOptions) => {
    const { email, firstName, password, token } = payload;

    const response = await this.post<
      IRegistrationResponseData,
      IRegistrationPayload
    >(
      registerUrl(),
      {
        email,
        first_name: firstName,
        password,
        token,
      },
      { requiresAuth: false }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * This provides login authentication services. Mainly expects a username/email
   * & password payload and retrieves the tokens and user info from the backend.
   */
  public loginUser = async (payload: ILoginOptions) => {
    const { email, password, locale } = payload;
    const response = await this.post<ILoginResponseDataRaw, ILoginPayload>(
      loginUrl(),
      { email, password },
      {
        requiresAuth: false,
        headers: {
          'Accept-Language': locale,
        },
      }
    );

    if (!response.success) throw response;

    return formatLoginData(response.data);
  };

  /**
   * This provides logout services.
   */
  public logoutUser = async (payloads: ILogoutOptions[]) => {
    const logoutPromises: Promise<
      IApiError | IApiResponse<ILogoutResponseData>
    >[] = [];

    payloads.forEach((payload: ILogoutOptions) => {
      const { token } = payload;

      const responsePromise = this.post<ILogoutResponseData, ILogoutPayload>(
        logoutUrl(),
        {},
        { accessToken: token }
      );
      logoutPromises.push(responsePromise);
    });

    const resolvedResponses = await Promise.all(logoutPromises).then(
      (logoutResponses) => {
        const updatedLogoutResponses: (
          | IApiError
          | IApiResponse<ILogoutResponseData>
        )[] = [];

        logoutResponses.forEach((response, index) => {
          const logoutResponse = { ...response, index };
          updatedLogoutResponses.push(logoutResponse);
        });

        return updatedLogoutResponses;
      }
    );

    if (!resolvedResponses[0].success) throw resolvedResponses[0];

    return resolvedResponses;
  };

  /**
   * This starts the forgot password email password reset link services.
   * Mainly expects an email payload which will receive a verification link on
   * to be used in resetting the password.
   */
  public forgotPassword = async (payload: IForgotPasswordOptions) => {
    const response = await this.post<
      IForgotPasswordResponseData,
      IForgotPasswordPayload
    >(
      forgotPasswordUrl(),
      {
        email: payload.email,
        recaptcha: 'RANDOM-NOT-CHECKED-IN-NON-PROD',
      },
      { requiresAuth: false }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * This starts the user password reset service.
   */
  public resetPassword = async (payload: IPasswordResetPayload) => {
    const { password, token } = payload;

    const response = await this.post<
      IPasswordResetResponseData,
      IPasswordResetPayload
    >(passwordResetUrl(), { password, token }, { requiresAuth: false });

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch the details of the current logged in user by their id.
   */
  public fetchUserDetails = async () => {
    const response = await this.get<IUserRaw>(fetchUserDetailsUrl());

    if (!response.success) throw response;

    return formatUserDetailsData(response.data);
  };

  /**
   * This calls a mutation that patches and updates the existing user details.
   */
  public updateUserDetails = async (options: IUpdatableUserDetailsOptions) => {
    const { userId, ...payload } = options;

    const response = await this.patch<
      IUserRaw,
      IUpdatableUserDetailsPayloadRaw
    >(updateUserDetailsUrl(userId), payload);

    if (!response.success) throw response;

    return formatUserDetailsData(response.data);
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for All User Accounts related requests.
 */
export const USER_ACCOUNTS_API = UserAccountsAPIClient.getClientInstance();
