import Link from 'next/link';
import { useRouter } from 'next/router';

import { LogoKeymonoLogomark } from '@keymono/design-system';

interface IAppLogoActionProps {
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the logo of  the app which on click routes the user to the active
 * `organization` page.
 */
export function AppLogoAction({ className = '' }: IAppLogoActionProps) {
  const { query } = useRouter();
  const accountId = (query.accountId || '0') as string;

  return (
    <Link
      href={`/${accountId}/org`}
      className={`
        flex items-center justify-center rounded-md border-2 border-red-400
        bg-white text-red-600
        ${className}
      `}
    >
      <LogoKeymonoLogomark className="h-8 w-8" />
    </Link>
  );
}
