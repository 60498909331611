import tw, { styled } from 'twin.macro';

/**
 * Wrapper div for the Product List Card.
 * Renders a flex layout. Flex-col for mobile and flex-row for desktop.
 */
export const CardWrapper = styled.div`
  ${tw`
    relative
    flex
    flex-col
    justify-start
    items-center
    overflow-hidden
    rounded-xl
    border
    border-gray-100
    bg-white
    shadow-lg
    shadow-gray-100/10

    md:flex-row

    transition-all
    duration-1000
    ease-in

    hover:(border-red-100 shadow-red-50/60)
  `};
`;

/**
 * -----------------------------------------------------------------------------
 * Injects additional tailwind styles to the form, with twin.macro
 */
export const StyledForm = styled.form`
  ${tw`
    space-y-4
    transition-all
    ease-in-out
    delay-100
    duration-500

    md:space-y-6
 `};

  .form-field-row {
    ${tw`
      flex
      flex-col
      items-center
      justify-between
      gap-4

      sm:flex-row
    `};
  }
`;
