import { useMutation } from '@tanstack/react-query';

import { IApiError } from '../../../../types';
import { IRegistrationData } from '../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the registration up mutation keys in react query cache.
 */
const MUT_REGISTRATION_USER = 'registration-mutation-key';

interface IUseRegistrationPayload {
  onSuccessCallback: (registrationData: IRegistrationData) => void;
  onErrorCallback: (err: IApiError) => void;
}

/**
 *  Mutation to gracefully handle user registration requests
 */
export const useRegistrationMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseRegistrationPayload) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_REGISTRATION_USER],
    networkMode: 'always',
    retry: false,
    mutationFn: USER_ACCOUNTS_API.registerUser,
    onSuccess: (response) => {
      onSuccessCallback(response?.data.data);
    },
    onError: (err: IApiError) => {
      onErrorCallback(err);
      return err;
    },
  });

  return {
    error,
    isLoading,
    submitRegistrationDetails: mutate,
  };
};
