import Link from 'next/link';
import { UserPlusIcon } from '@heroicons/react/24/outline';

/**
 * -----------------------------------------------------------------------------
 * This renders an CTA that allows the user to log into another account for
 * multiple session navigation.
 */
export function AddAnotherLoginBtn() {
  return (
    <Link
      href="/auth/login"
      className="
        group inline-flex w-full items-center px-8 py-4 text-sm
        leading-5 text-gray-500 hover:bg-red-50
        hover:text-red-800 focus:outline-none focus:ring-2
        focus:ring-red-100 focus:ring-offset-1
      "
    >
      <UserPlusIcon
        className="
          h-5 w-5 text-gray-700 group-hover:text-red-800 ltr:-ml-2 ltr:mr-4 rtl:-mr-2 rtl:ml-4
        "
        aria-hidden="true"
      />
      <span>Add another account</span>
    </Link>
  );
}
