import { IProduct } from '@keymono/apis';

import { CardWrapper } from '../styles';

interface IProductListCardHeaderProps {
  name: string;
}

function ProductListCardHeader({ name }: IProductListCardHeaderProps) {
  return (
    <div className="flex flex-row">
      <div className="flex flex-1 flex-col justify-center px-4">
        <h3 className="text-base font-semibold text-gray-800">{name}</h3>
      </div>
    </div>
  );
}

interface IProductListCardProps {
  product: IProduct;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a new product card.
 */
export function ProductListCard({ product }: IProductListCardProps) {
  const { description, name } = product;

  return (
    <CardWrapper className="group px-4 py-4">
      <ProductListCardHeader name={name} />
      <p
        className="
          flex flex-1 flex-col px-4 text-xs
          text-gray-500 transition-all duration-500 ease-linear
        "
      >
        {description}
      </p>
    </CardWrapper>
  );
}
