import { IProduct } from '@keymono/apis';

import { ProductListCard } from './ProductListCard';

interface IAllProductsListProps {
  products: IProduct[];
}

/**
 * ----------------------------------------------------------------------------
 * Renders all Products in a List View.
 */
export function AllProductsList({ products }: IAllProductsListProps) {
  return (
    <div className="flex flex-col gap-4">
      {products.map((product) => (
        <ProductListCard product={product} key={product.id} />
      ))}
    </div>
  );
}
