import { useEffect, useMemo, useState } from 'react';

import { useTranslations } from '@keymono/i18n';
import { IChoice, IUseChoicesQueryProps, useChoicesQuery } from '@keymono/apis';
import { ChoicesCountriesSelectDropdown } from '@keymono/design-system';

import {
  CountrySelectorContainer,
  CountrySelectorDropDownWrapper,
} from './styles';
import { NoCountriesLoadedYetFallback } from './NoCountriesLoadedYetFallback';

interface IOrganizationCountriesProps {
  onChange: (selectedCountry: IChoice) => void;
  selectedCountry: IChoice | null;
  hasError: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a select item to provide a list of countries to choose from.
 */
export function CountrySelectorInput({
  onChange,
  selectedCountry,
  hasError,
}: IOrganizationCountriesProps) {
  const t = useTranslations('Country');

  /**
   * Cache the active search key in the parent so that the user's current
   * search key within `ChoicesCountriesSelectDropdown` is not lost when the
   * server returns.
   */
  const [activeSearchKey, setActiveSearchKey] = useState('');

  /**
   * Maintain the current page in the pagination of the countries list query.
   * This is used to refetch the data when the user searches for a country.
   * Or scrolls to the bottom of the list.
   */
  const [countriesListPage, setCountriesListPage] = useState(1);

  /**
   * Disabled eslint for the below code block because of conflict with prettier
   */
  /* eslint-disable indent */
  const queryOptions: IUseChoicesQueryProps =
    activeSearchKey !== ''
      ? {
          type: 'countries',
          query: activeSearchKey,
        }
      : {
          type: 'countries',
          page: countriesListPage,
        };
  /* eslint-enable indent */

  const {
    data,
    error: networkError,
    isLoading,
    refetch,
  } = useChoicesQuery(queryOptions);

  const error = hasError || networkError?.message;
  const classNameError = error ? 'has-error' : '';

  const handleRefetchCountries = () => {
    refetch();
  };

  /**
   * Maintain the total page count of the countries list query.
   */
  const pagination = data?.meta?.pagination;
  const totalPageCount = pagination
    ? Math.ceil(pagination.no_of_records / pagination.page_size)
    : 1;

  // Declare the results array to avoid undefined errors
  const results = useMemo(() => data?.results || [], [data]);

  /**
   * Maintain the current list of countries
   * The result of the `useChoicesQuery` will be appended to this list.
   * based on the current page.
   */
  const [countriesListData, setCountriesListData] =
    useState<IChoice[]>(results);

  /**
   * Update the countries list data when the new data is fetched
   * Also, remove any duplicates from the list.
   */
  useEffect(() => {
    if (results && !isLoading) {
      setCountriesListData((countries) => {
        const newCountries = [...countries, ...results].filter(
          (value, index, array) =>
            array.findIndex((item) => item.key === value.key) === index
        );
        return newCountries;
      });
    }
  }, [results, isLoading]);

  /**
   * Update the page number of the countries list query to fetch the next page of countries.
   */
  const handleUpdateCountriesListPage = () => {
    if (countriesListPage >= totalPageCount) {
      return;
    }
    setCountriesListPage((page) => page + 1);
  };

  const handleUpdateCountrySearchKey = (newSearchKey: string) => {
    setActiveSearchKey(newSearchKey);
  };

  return (
    <CountrySelectorContainer>
      <label htmlFor="country" className="sr-only">
        {t('country')}
      </label>

      <CountrySelectorDropDownWrapper className={`${classNameError}`}>
        <label htmlFor="country" className="form-label">
          {t('country')}
        </label>

        {/**
         * Only show the error screen if there is no data and an error has occurred.
         * Otherwise the loading states and no data state will be handled with
         * the child `ChoicesCountriesSelectDropdown`.
         */}
        {!countriesListData && error ? (
          <NoCountriesLoadedYetFallback
            isLoading={isLoading}
            error={error}
            onRefetchCountries={handleRefetchCountries}
          />
        ) : (
          <div>
            <ChoicesCountriesSelectDropdown
              activeSearchKey={activeSearchKey}
              countries={countriesListData || []}
              isLoading={isLoading}
              onChange={onChange}
              onScrollToBottom={handleUpdateCountriesListPage}
              onUpdateSearchKey={handleUpdateCountrySearchKey}
              selectedChoice={selectedCountry}
              className={`${
                error
                  ? 'border-red-300 text-xs text-red-500/80 text-red-900'
                  : 'border-gray-200 text-sm'
              }`}
            />
            {error ? <div className="error-message">{error}</div> : null}
          </div>
        )}
      </CountrySelectorDropDownWrapper>
    </CountrySelectorContainer>
  );
}
