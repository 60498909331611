import { useAuthStore } from '@keymono/services';
import { useUserDetailsQuery } from '@keymono/apis';

import { CreateOrganizationCTA } from './CreateOrganizationCTA';
import { OrganizationDropdownMenu } from './OrganizationDropdownMenu';

export function OrganizationSwitcher() {
  const { activeSession } = useAuthStore();
  const { data, isLoading, error } = useUserDetailsQuery({});

  if (!activeSession) return null;

  const { index, activeOrgId, user } = activeSession;

  const accountId = index || 0;

  /**
   * We prefer to get the latest membership data from the useUserDetailsQuery.
   * This is in case the user has just created an org (or deleted, in future).
   * If the data is not available, we fallback to the membership data stored in activeSession.
   */
  const shouldUseMembershipCache = isLoading || error || !data;
  const membership = shouldUseMembershipCache
    ? user?.membership || {}
    : data?.membership || {};

  if (!membership || membership?.length <= 0) {
    return (
      <CreateOrganizationCTA
        accountId={accountId}
        labelClassName="hidden sm:inline"
      />
    );
  }

  return (
    <OrganizationDropdownMenu
      accountId={accountId}
      activeOrgId={activeOrgId || ''}
      membership={membership}
    />
  );
}

export default OrganizationSwitcher;
