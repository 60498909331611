import { ReactNode } from 'react';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { IMembership } from '@keymono/shared-types';

// import { Text, useTranslations } from '@keymono/i18n';
import { Text } from '@keymono/i18n';
import { useAuthStore } from '@keymono/services';
import { CardSecButton, IBaseUIErrorOptions } from '@keymono/design-system';

import { OrgMembershipListItem } from './OrgMembershipListItem';
import { OrgMembershipListItemLoader } from './OrgMembershipListItemLoader';
import { OrgMembershipListBaseWrapper } from './styles';

interface IOrgMembershipListBaseProps {
  children: ReactNode;
  className?: string;
  title?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the list of organizations from which a user can select from.
 */
function OrgMembershipListBase({
  children,
  className,
  title,
}: IOrgMembershipListBaseProps) {
  // const t = useTranslations('Login');

  return (
    <OrgMembershipListBaseWrapper className={className}>
      <div className="py-4 text-center">
        <BuildingOffice2Icon className="feature-icon mx-auto h-16 w-16 text-teal-700" />

        <h2 className="mt-2 text-2xl font-normal text-gray-900">
          Your Organizations
        </h2>
        <p className="my-4 text-sm text-teal-800">
          {title || 'Please select an organization to proceed.'}
        </p>
      </div>

      <ul className="mt-4 grid grid-cols-1 items-center justify-center gap-2">
        {children}
      </ul>
    </OrgMembershipListBaseWrapper>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state of `OrgMembershipListView`.
 */
export function OrgMembershipListError({
  error,
  onRetryAction,
}: IBaseUIErrorOptions) {
  // const t = useTranslations('Login');

  if (!error || !onRetryAction) return null;

  const { message, description } = error;

  return (
    <OrgMembershipListBase
      className="has-error"
      title="Sorry! We could not load your memberships."
    >
      <div className="flex flex-col gap-2 text-sm text-red-700">
        <h2>{message}</h2>
        {description ? <h3>{description}</h3> : null}

        <CardSecButton
          className="mt-4 flex justify-center px-12"
          onClick={onRetryAction.onClick}
        >
          <Text text={onRetryAction.label || 'Retry'} />
        </CardSecButton>
      </div>
    </OrgMembershipListBase>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state of `OrgMembershipListView`.
 */
export function OrgMembershipListLoader() {
  // const t = useTranslations('Login');

  const { activeSession } = useAuthStore();

  /**
   * Show the loading state cards based on the number of previously cached memberships.
   */
  const prevMembershipCount = activeSession?.user?.membership?.length || 2;
  const items = [...Array(prevMembershipCount).keys()];

  return (
    <OrgMembershipListBase
      className="opacity-70"
      title="Loading Memberships, please wait..."
    >
      {items.map((i) => (
        <OrgMembershipListItemLoader key={i} />
      ))}
    </OrgMembershipListBase>
  );
}

interface IOrgMembershipListProps {
  memberships: IMembership[];
}

/**
 * -----------------------------------------------------------------------------
 * This renders the list of organizations from which a user can select from.
 */
export function OrgMembershipListView({
  memberships,
}: IOrgMembershipListProps) {
  return (
    <OrgMembershipListBase>
      {memberships.map((orgMembership) => (
        <OrgMembershipListItem
          key={orgMembership.orgName}
          membership={orgMembership}
        />
      ))}
    </OrgMembershipListBase>
  );
}
