import Link from 'next/link';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';

interface ICreateOrganizationCTAProps {
  accountId: number | string;
  className?: string;
  labelClassName?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the logo of the organization or a fallback building icon if the image
 * url is not present.
 */
export function CreateOrganizationCTA({
  accountId,
  className = '',
  labelClassName = '',
}: ICreateOrganizationCTAProps) {
  return (
    <Link
      href={`/${accountId}/org/create`}
      className={`form-btn-variant-primary group ${className}`}
    >
      <BuildingOffice2Icon
        className="group-hover:text-white"
        aria-hidden="true"
      />
      <span className={labelClassName}>Create an Organization</span>
    </Link>
  );
}
