import type { IUser } from '@keymono/shared-types';
import { useAuthStore } from '@keymono/services';

/**
 * This simple hook updates the user details profile data.
 */
export function useUpdateUserDataCallback() {
  const { activeSession, updateActiveSession } = useAuthStore();

  const handleUpdateUserData = (userData: IUser) => {
    if (activeSession) {
      updateActiveSession({
        ...activeSession,
        user: userData,
      });
    }
  };

  return { updateUserDataCallback: handleUpdateUserData };
}
