import React from 'react';
import { PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { SVGContentLoader, Title } from '@keymono/design-system';
import { cx } from 'class-variance-authority';
import Link, { LinkProps } from 'next/link';
import { Url } from 'url';
import { PeriodBarChart, PeriodBarChartData } from './PeriodBarChart';

type ScoreCardReportLinkProps = LinkProps & {
  className?: string;
};
function ScoreCardReportLink({ className, ...rest }: ScoreCardReportLinkProps) {
  return (
    <Link
      {...rest}
      className={cx(
        'rounded bg-blue-50 px-1 py-1 text-center text-xs text-blue-500 hover:bg-blue-100 md:px-3',
        className
      )}
    >
      <span className="hidden text-center md:inline">detailed report</span>
      <span className="text-center md:hidden">
        <PresentationChartLineIcon width={16} />
      </span>
    </Link>
  );
}

type ScoreCardScoreProps = {
  children: React.ReactNode;
  intent?: 'default' | 'positive' | 'neutral' | 'negative';
  className?: string;
};
function ScoreCardScore({
  children,
  intent = 'default',
  className,
}: ScoreCardScoreProps) {
  const classes = cx(
    'text-xl font-medium',
    {
      'text-green-600': intent === 'positive',
      'text-gray-600': intent === 'neutral',
      'text-red-600': intent === 'negative',
    },
    className
  );

  return <data className={classes}>{children}</data>;
}

type ScoreCardSubTitleProps = {
  children: React.ReactNode;
};
function ScoreCardSubTitle({ children }: ScoreCardSubTitleProps) {
  return <h4 className="text-sm text-gray-600">{children}</h4>;
}

type ScoreCardSubScoreProps = {
  children: React.ReactNode;
};
function ScoreCardSubScore({ children }: ScoreCardSubScoreProps) {
  return <data className="text-sm">{children}</data>;
}

type ScoreCardTitleProps = {
  children: React.ReactNode;
};
function ScoreCardTitle({ children }: ScoreCardTitleProps) {
  return (
    <Title tag="h3" className="whitespace-nowrap text-base text-gray-600">
      {children}
    </Title>
  );
}
type ScoreCardRowProps = {
  children: React.ReactNode;
};
function ScoreCardRow({ children }: ScoreCardRowProps) {
  return (
    <div className="flex flex-row items-center justify-between gap-4 py-1">
      {children}
    </div>
  );
}

function ScoreCardDivider() {
  return <hr />;
}

type ScoreCardProps = {
  children: React.ReactNode;
};
export function ScoreCard({ children }: ScoreCardProps) {
  return (
    <article className="flex flex-col justify-between gap-2 rounded bg-white p-3 shadow dark:bg-slate-900">
      {children}
    </article>
  );
}

ScoreCard.Row = ScoreCardRow;
ScoreCard.Title = ScoreCardTitle;
ScoreCard.SubTitle = ScoreCardSubTitle;
ScoreCard.Score = ScoreCardScore;
ScoreCard.SubScore = ScoreCardSubScore;
ScoreCard.Divider = ScoreCardDivider;
ScoreCard.ReportLink = ScoreCardReportLink;
ScoreCard.BarChart = PeriodBarChart;

type DefaultScoreCardProps = {
  title: string;
  reportLink?: Url | string | undefined;
  titleScore: string | number;
  titleScoreChange: number;

  subScores: {
    title: string;
    score: string | number;
  }[];

  barData: PeriodBarChartData;
};
export function DefaultScoreCard({
  title,
  reportLink,
  titleScore,
  titleScoreChange,
  subScores,
  barData,
}: DefaultScoreCardProps) {
  let titleScoreChangeIntent: ScoreCardScoreProps['intent'] = 'neutral';
  let titleScoreChangeSymbol: '-' | '+' | '' = '';
  if (titleScoreChange > 0) {
    titleScoreChangeIntent = 'positive';
    titleScoreChangeSymbol = '+';
  } else if (titleScoreChange < 0) {
    titleScoreChangeIntent = 'negative';
    titleScoreChangeSymbol = '-';
  }

  return (
    <ScoreCard>
      <div>
        <ScoreCard.Row>
          <ScoreCard.Title>{title}</ScoreCard.Title>
          {reportLink && <ScoreCard.ReportLink href={reportLink} />}
        </ScoreCard.Row>
        <ScoreCard.Row>
          <ScoreCard.Score>{titleScore}</ScoreCard.Score>
          <ScoreCard.Score intent={titleScoreChangeIntent}>
            {titleScoreChangeSymbol}
            {`${Math.abs(titleScoreChange).toFixed(2)}%`}
          </ScoreCard.Score>
        </ScoreCard.Row>
      </div>
      <ScoreCard.Divider />
      {subScores.map((score) => (
        <ScoreCard.Row key={score.title}>
          <ScoreCard.SubTitle>{score.title}</ScoreCard.SubTitle>
          <ScoreCard.SubScore>{score.score}</ScoreCard.SubScore>
        </ScoreCard.Row>
      ))}
      <ScoreCard.Divider />
      <ScoreCard.BarChart data={barData} />
    </ScoreCard>
  );
}

export function DefaultScoreCardLoader() {
  return (
    <ScoreCard>
      <ScoreCard.Row>
        <SVGContentLoader height="20">
          <rect x="0" y="0" width="128" height="20" />
        </SVGContentLoader>
        <SVGContentLoader height="24">
          <rect x="0" y="0" width="128" height="24" />
        </SVGContentLoader>
      </ScoreCard.Row>
      <ScoreCard.Row>
        <SVGContentLoader height="28">
          <rect x="0" y="0" width="128" height="28" />
        </SVGContentLoader>
        <SVGContentLoader height="28">
          <rect x="0" y="0" width="128" height="28" />
        </SVGContentLoader>
      </ScoreCard.Row>
      <ScoreCard.Divider />
      <ScoreCard.Row>
        <SVGContentLoader height="20">
          <rect x="0" y="0" width="128" height="20" />
        </SVGContentLoader>
        <SVGContentLoader height="20">
          <rect x="0" y="0" width="128" height="20" />
        </SVGContentLoader>
      </ScoreCard.Row>
      <ScoreCard.Row>
        <SVGContentLoader height="20">
          <rect x="0" y="0" width="128" height="20" />
        </SVGContentLoader>
        <SVGContentLoader height="20">
          <rect x="0" y="0" width="128" height="20" />
        </SVGContentLoader>
      </ScoreCard.Row>
      <ScoreCard.Divider />
      <PeriodBarChart
        data={{
          color: '',
          label: '',
          unit: '',
          data: [0, 0],
        }}
      />
    </ScoreCard>
  );
}
