/**
 * -----------------------------------------------------------------------------
 * These endpoints provide support for the Order management APIs
 * -----------------------------------------------------------------------------
 */

import {
  IUseOrderDetailsQueryOptions,
  IUseOrgOrdersQueryOptions,
} from '../keymono-api/clients/orders/types';

/**
 * Endpoint for retrieving the all orders for the org.
 */
export const fetchOrgOrdersUrl = ({
  orgId,
  page = 1,
  channelCreatedTsGte,
  channelCreatedTsLte,
  ordering,
}: IUseOrgOrdersQueryOptions) => {
  const queryParams = new URLSearchParams();
  queryParams.append('page', String(page));
  queryParams.append('ordering', ordering);
  if (channelCreatedTsGte) {
    queryParams.append(
      'channel_created_ts__gte',
      new Date(channelCreatedTsGte).toISOString()
    );
  }
  if (channelCreatedTsLte) {
    queryParams.append(
      'channel_created_ts__lte',
      new Date(channelCreatedTsLte).toISOString()
    );
  }

  return `/orgs/${orgId}/orders?${queryParams.toString()}`;
};

/**
 * Endpoint for retrieving the details of a single order for the org.
 */
export const fetchOrderDetailsUrl = ({
  orgId,
  orderId,
}: IUseOrderDetailsQueryOptions) => `/orgs/${orgId}/orders/${orderId}`;
