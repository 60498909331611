import Image from 'next/image';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { IMembership } from '@keymono/shared-types';

import { useMembershipRedirection } from '../../../hooks';

interface IOrgMembershipListItemProps {
  membership: IMembership;
}

/**
 * -----------------------------------------------------------------------------
 * This renders an individual organization membership item
 */
export function OrgMembershipListItem({
  membership,
}: IOrgMembershipListItemProps) {
  const { org: orgId, orgLogoUrl, orgName, type } = membership;

  const { requestNavigationToOrg } = useMembershipRedirection();

  const handleClick = () => {
    requestNavigationToOrg({ orgId });
  };

  return (
    <li className="flex w-full  max-w-sm flex-grow">
      <button
        type="button"
        onClick={handleClick}
        className="
          group relative flex w-full flex-col justify-between space-x-2
          overflow-hidden rounded-md border border-gray-100 bg-white text-left
          text-gray-800 shadow-sm shadow-gray-50 outline-none transition-all
          duration-200 ease-linear hover:border-red-100 hover:bg-red-50/50
          hover:text-red-800 focus:outline-none focus:ring-1 focus:ring-red-100
          focus:ring-offset-1
        "
      >
        <span className="flex min-w-0 flex-1 items-center">
          <span className="block flex-shrink-0">
            <Image
              className="
                h-16 w-16  transition-all duration-200 ease-linear
                group-hover:scale-[1.05]
              "
              src={orgLogoUrl}
              alt="Company logo"
              height={100}
              width={100}
            />
          </span>
          <span
            className="
              block min-w-0 flex-1 px-4 transition-all duration-200
              ease-linear group-hover:scale-[1.02]
            "
          >
            <span className="block truncate text-sm font-medium">
              {orgName}
            </span>
            <span
              className="
                block truncate text-xs font-medium capitalize text-gray-500
                transition-all duration-200 ease-linear group-hover:text-red-500
              "
            >
              {type}
            </span>
          </span>
        </span>
        <span
          className="
            absolute bottom-2 inline-flex h-6 w-6 flex-shrink-0 items-center
            justify-center transition-all duration-200 ease-linear
            group-hover:translate-x-1 ltr:right-2 rtl:left-2
          "
        >
          <ArrowRightIcon
            className="
              h-4 w-4 text-gray-300 transition-all duration-200
              ease-linear group-hover:text-red-500
            "
            aria-hidden="true"
          />
        </span>
      </button>
    </li>
  );
}
