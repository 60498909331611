import { IUser } from '@keymono/shared-types';
import { useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';
import { useUpdateUserDataCallback } from '../useUpdateUserDataCallback';

/**
 * This is to track the user details update mutation keys in react query cache.
 */
const MUT_UPDATE_USER_DETAILS = 'update-user-details-mutation-key';

interface IUseUpdateUserDetailsOptions {
  onSuccessCallback: (updatedUserData: IUser) => void;
}

/**
 *  Mutation to handle updating of the user details/profile information.
 */
export const useUpdateUserDetailsMutation = ({
  onSuccessCallback,
}: IUseUpdateUserDetailsOptions) => {
  const { updateUserDataCallback } = useUpdateUserDataCallback();

  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_UPDATE_USER_DETAILS],
    networkMode: 'always',
    mutationFn: USER_ACCOUNTS_API.updateUserDetails,
    onSuccess: (userData) => {
      onSuccessCallback(userData);
      updateUserDataCallback(userData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Updating the user details info',
        message: err.message,
      });
      return err;
    },
  });

  return {
    error,
    isLoading,
    updateUserDetails: mutate,
  };
};
