import Image from 'next/image';

import { getFormattedDate } from '@keymono/utilities';
import { useTranslations } from '@keymono/i18n';

interface IOrgOverviewHeaderContentProps {
  createdAt: string;
  logoUrl: string;
  name: string;
  uniqueName: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the main content of the organization header component.
 * inludes the name, creationdate and it's unique name.
 */
export function OrgOverviewHeaderContent({
  createdAt,
  logoUrl,
  name,
  uniqueName,
}: IOrgOverviewHeaderContentProps) {
  const [name1, name2, ...nameRest] = name.split(' ');
  const t = useTranslations('OrganizationsDetails');
  const createdAtDate = getFormattedDate(new Date(createdAt), 'do LLLL YYY');

  return (
    <div className="sm:flex sm:items-center sm:justify-between">
      <div className="sm:flex sm:gap-x-5">
        <div className="flex-shrink-0">
          <Image
            className="mx-auto h-20 w-20 rounded-full"
            src={logoUrl}
            alt=""
            width={120}
            height={120}
          />
        </div>
        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-start">
          <p className="text-xl sm:text-2xl">
            <span className="font-bold text-gray-900">{name1}</span>
            {name2 ? <span className="ml-2 text-gray-900">{name2}</span> : null}
            {nameRest ? (
              <span className="mx-2 font-light text-gray-900">
                {nameRest.join(' ')}
              </span>
            ) : null}
          </p>
          <p
            dir="ltr"
            className="my-0.5 text-sm font-medium text-gray-600  rtl:sm:text-end"
          >
            {`@${uniqueName}`}
          </p>
          <p className="text-xs text-gray-600">
            <span className="me-1">{t('createdOn')}</span>
            <span className="text-red-800">{createdAtDate}</span>
          </p>
        </div>
      </div>
      <div className="mt-5 flex justify-center sm:mt-0">
        <a
          href="/"
          className="
            hidden items-center justify-center rounded-md
            border border-gray-300 bg-white px-4 py-2
            text-sm font-medium text-gray-700 shadow-sm
            hover:bg-gray-50
          "
        >
          {t('editProfile')}
        </a>
      </div>
    </div>
  );
}
