import tw, { styled } from 'twin.macro';

export const CardsGridContainer = styled.div`
  ${tw`
    grid
    grid-cols-1
    items-start
    transition-all
    duration-500

    lg:grid-cols-10
  `};
`;

export const CentralColumnPanelContainer = styled.div`
  ${tw`
    grid
    grid-cols-1
    gap-2
    transition-all
    duration-500

    sm:col-span-6
    lg:(col-span-7 me-4)
    xl:col-span-6
  `};
`;

export const RightColumnPanelContainer = styled.div`
  ${tw`
    grid
    grid-cols-1
    gap-2
    col-span-1
    transition-all
    duration-500

    sm:(col-span-4 h-full)
    lg:col-span-3
    xl:col-span-4
  `};
`;
