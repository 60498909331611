import { useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';
import { ILoginUserData } from '../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the login mutation keys in react query cache.
 */
const MUT_LOGIN_USER = 'login-mutation-key';

interface IUseLoginOptions {
  /**
   * Callback to be by the calling app if the login is successful.
   * Note: Usually this would be best handled within the onSuccess of the hook
   * itself but since the login would require additional platform specific
   * features like `next/router` & `localStorage` on the web and
   * `react-navigation` & `asyncStorage` on mobile, this will be done in the
   * callback of the respective platform without having to embed them in the util.
   *
   * @param loginData - The login success data
   * @returns
   */
  onSuccessCallback: (loginData: ILoginUserData) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This performs a login request and retrieves the authed user info and token.
 * Provides data fetching states and a callback to be used in the redirection later.
 *
 * @param IUseLoginOptions
 */
export const useLoginMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseLoginOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_LOGIN_USER],
    networkMode: 'always', // Even make calls when offline
    retry: false, // For login Request, do not retry failed requests.
    mutationFn: USER_ACCOUNTS_API.loginUser,
    onSuccess: (responseData) => {
      onSuccessCallback(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Login Attempt',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    submitLogin: mutate,
  };
};
