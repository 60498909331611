import { useTranslations } from '@keymono/i18n';
import { useAllIntegrationsQuery } from '@keymono/apis';

import { AllIntegrationsListItemsFallback } from './AllIntegrationsListItemsFallback';
import { NewIntegrationCardItem } from './NewIntegrationCardItem';

interface IAllIntegrationsListItemsProps {
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders all the integrations available for setup by organizations.
 * See also `OrgIntegrationsListItems`.
 */
export function AllIntegrationsListItems({
  orgId,
}: IAllIntegrationsListItemsProps) {
  const t = useTranslations('Integrations');

  const { data, isLoading, error, refetch } = useAllIntegrationsQuery({});

  const integrations = data?.integrations;

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !integrations) {
    return (
      <AllIntegrationsListItemsFallback
        isLoading={isLoading}
        error={error}
        onRetryAction={{ onClick: handleRetryOnFail }}
      />
    );
  }

  return (
    <>
      <div className="py-8">
        <h1 className="text-2xl font-bold tracking-tight text-gray-700">
          {t('allIntegrationsTitle')}
        </h1>
        <p className="mt-2 text-sm text-gray-500">
          {t('allIntegrationsDescription')}
        </p>
      </div>

      <div
        className="
          grid grid-cols-1 gap-4 border-b border-gray-100 pb-8
          sm:grid-cols-2 xl:grid-cols-3
        "
      >
        {integrations.map((integration) => (
          <NewIntegrationCardItem
            integration={integration}
            key={integration.id}
            orgId={orgId}
          />
        ))}
      </div>
    </>
  );
}
