import { SVGContentLoader } from '@keymono/design-system';

import { OrdersListTableRow } from '../../../styles';
import { OrdersListLoaderTableCell } from '../styles';

function LoaderColItem() {
  return (
    <SVGContentLoader
      speed={2}
      height={20}
      viewBox="0 0 60 20"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="5" rx="6" ry="36" width="60" height="10" />
    </SVGContentLoader>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders the skeleton loader of an individual row of `OrdersListTable`.
 */
export function OrdersListLoaderTableRow({
  className = '',
}: {
  className?: string;
}) {
  return (
    <OrdersListTableRow className={`!py-5 ${className}`}>
      <div className="max-lg:hidden lg:col-span-1">
        <SVGContentLoader
          speed={2}
          height={20}
          viewBox="0 0 20 20"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="5" rx="3" ry="3" width="10" height="10" />
        </SVGContentLoader>
      </div>
      <OrdersListLoaderTableCell className="col-span-2 lg:col-span-6">
        <SVGContentLoader
          speed={2}
          height={20}
          viewBox="0 0 250 20"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="5" rx="3" ry="3" width="150" height="10" />
        </SVGContentLoader>
      </OrdersListLoaderTableCell>
      <OrdersListLoaderTableCell className="text-sm font-medium max-lg:justify-self-start lg:col-span-5">
        <SVGContentLoader
          speed={2}
          height={20}
          viewBox="0 0 100 20"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="5" rx="3" ry="3" width="100" height="10" />
        </SVGContentLoader>
      </OrdersListLoaderTableCell>
      <OrdersListLoaderTableCell className="text-sm font-medium max-lg:justify-self-start lg:col-span-3">
        <LoaderColItem />
      </OrdersListLoaderTableCell>
      <OrdersListLoaderTableCell className="text-center text-sm font-medium max-lg:justify-self-start lg:col-span-2">
        <LoaderColItem />
      </OrdersListLoaderTableCell>
      <OrdersListLoaderTableCell className="lg:col-span-4">
        <LoaderColItem />
      </OrdersListLoaderTableCell>
      <OrdersListLoaderTableCell className="text-sm font-medium max-lg:justify-self-start lg:col-span-5">
        <LoaderColItem />
      </OrdersListLoaderTableCell>
      <OrdersListLoaderTableCell className="w-max lg:col-span-5">
        <LoaderColItem />
      </OrdersListLoaderTableCell>
      <OrdersListLoaderTableCell className="w-max lg:col-span-5">
        <LoaderColItem />
      </OrdersListLoaderTableCell>
    </OrdersListTableRow>
  );
}
