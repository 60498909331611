import Link from 'next/link';

import { TTextLocalized, Text } from '@keymono/i18n';

interface IFormLinkCTAProps {
  href: string;
  title: TTextLocalized;
  subTitle: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * Renders helpful text and an inline CTA link to a specific route.
 */
export function FormLinkCTA({ href, title, subTitle }: IFormLinkCTAProps) {
  return (
    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
      <Text text={title} />
      <Link href={href} className="form-link-action mx-2 inline-flex">
        <Text text={subTitle} />
      </Link>
    </p>
  );
}
