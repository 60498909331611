import tw, { styled } from 'twin.macro';

export const CardWrapper = styled.div`
  ${tw`
    relative
    !rounded-lg
    rounded-tl-lg
    rounded-tr-lg
    bg-white
    p-6
    sm:rounded-tr-none
  `};
`;
