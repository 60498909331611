import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import {
  AsyncComponentFallbackTemplate,
  CardSecButton,
  CircularLoader,
  IBaseUIFallbackCommonOptions,
  IBaseUIErrorOptions,
} from '@keymono/design-system';
import { Text } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * The loading state component used in OrderDetailsFallback.
 * TODO: Add Translations for the text.
 */
function OrderDetailsLoader() {
  return (
    <div className="flex w-full flex-col items-center justify-center py-20 text-center">
      <CircularLoader className="my-10 h-10 w-10" />
      <h1 className="flex items-center py-4 text-2xl">Loading</h1>
      <h1 className="flex items-center justify-center py-2 text-center text-xl font-light">
        Order Details
      </h1>
      <p className="py-10">Please wait</p>
    </div>
  );
}

/**
 * -----------------------------------------------------------------------------
 * Fallback shown when the app fails to retrieve the list of all orders.
 * TODO: Add a helpful illustration, with proper recovery message.
 * TODO: Translate the text from the parent component.
 */
function OrderDetailsError({ onRetryAction, error }: IBaseUIErrorOptions) {
  if (!error || !onRetryAction) return null;

  const { message, description } = error;

  return (
    <div className="flex w-full flex-col items-center justify-center py-20 text-center text-red-900">
      {/* // TODO: Replace this with an illustration */}
      <ExclamationCircleIcon className="my-5 h-16 w-16 text-red-700" />

      <h1 className="flex items-center py-4 text-2xl">Sorry</h1>
      {message ? (
        <>
          <h1 className="flex items-center justify-center py-2 text-center text-xl font-light">
            {message}
          </h1>
          {description ? <p /> : null}
        </>
      ) : null}

      <CardSecButton className="my-8" onClick={onRetryAction.onClick}>
        <Text text={onRetryAction.label ?? 'Retry'} />
      </CardSecButton>
    </div>
  );
}

/**
 * -----------------------------------------------------------------------------
 * Wrapper component for the error state component OrderDetailsError.
 * This is used in the `OrderDetailsFallback` component.
 * AsyncComponentFallbackTemplate expects an ErrorComponent prop that returns JSX element.
 * OrderDetailsError return null if the error or onRetryAction props are not provided.
 * This wrapper component ensures that the error component is always rendered.
 * TODO: Figure out a way to avoid this wrapper component.
 */
function OrderDetailsViewError({ error, onRetryAction }: IBaseUIErrorOptions) {
  return (
    <div className="mx-auto mb-40 flex flex-col  items-center justify-center px-4 pb-10">
      <OrderDetailsError error={error} onRetryAction={onRetryAction} />
    </div>
  );
}

interface IOrderDetailsFallbackProps extends IBaseUIFallbackCommonOptions {}

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback loading, and error states
 * Used in the `OrderDetails` component.
 */
export function OrderDetailsFallback({
  error,
  onRetryAction,
  isLoading,
}: IOrderDetailsFallbackProps) {
  return (
    <AsyncComponentFallbackTemplate
      isLoading={isLoading}
      error={error}
      onRetryAction={onRetryAction}
      ErrorComponent={OrderDetailsViewError}
      LoadingComponent={OrderDetailsLoader}
    />
  );
}
