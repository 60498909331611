import { IFetchOrderDetails } from '@keymono/apis';

interface IOrderTotalCardProps {
  totalAmount: IFetchOrderDetails['totalAmount'];
}

/**
 * Renders the product card for the order products summary section.
 */
export function OrderTotalCard({ totalAmount }: IOrderTotalCardProps) {
  return (
    <div className="my-4 flex items-start gap-x-8">
      <div className="flex w-full flex-col gap-y-4">
        <div className="flex w-full justify-between">
          <p className="text-sm text-gray-900">Subtotal</p>
          <p className="text-sm text-gray-900">{totalAmount}</p>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-sm text-gray-900">Shipping</p>
          {/**
           * TODO: Replace with shippingTotal once we have the data in the API.
           */}
          <p className="text-sm text-gray-900" />
        </div>
        <div className="flex w-full justify-between">
          <p className="text-sm font-medium text-gray-900">Total</p>
          <p className="text-sm font-medium text-gray-900">{totalAmount}</p>
        </div>
      </div>
    </div>
  );
}
