import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';
import { IUseOrderDetailsQueryOptions } from '../types';
import { ORDERS_API } from '../ordersAPIClient';

export const QUERY_FETCH_ORG_ORDERS_KEY = 'fetch-org-orders-key';

/**
 * Custom hook to fetch the list of Orders for an Org
 * Fetches from the Order API Client.
 * Uses React Query under the hood.
 */
export function useOrderDetailsQuery({
  orgId,
  orderId,
}: IUseOrderDetailsQueryOptions) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [QUERY_FETCH_ORG_ORDERS_KEY, orgId, orderId],
    queryFn: () => ORDERS_API.fetchOrderDetails({ orgId, orderId }),
    enabled: !!orgId, // Don't fetch if org id is not loaded.,
    onError: (err: IApiError) => err,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
}
