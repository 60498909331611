import { useTranslations } from '@keymono/i18n';
import { useOrganizationDetailsQuery } from '@keymono/apis';

import { OrgOverviewHeaderBase } from './OrgOverviewHeaderBase';
import { OrgOverviewHeaderContent } from './OrgOverviewHeaderContent';
import { OrgOverviewHeaderFallback } from './OrgOverviewHeaderFallback';

interface IOrgOverviewHeaderProps {
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the organization header component, with the name, creation
 * date and it's unique name.
 */
export function OrgOverviewHeader({ orgId }: IOrgOverviewHeaderProps) {
  const t = useTranslations('UserProfile.profileOverview');

  const { data, isLoading, error, refetch } = useOrganizationDetailsQuery({
    orgId,
  });

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !data) {
    return (
      <OrgOverviewHeaderBase title={t('title')}>
        <OrgOverviewHeaderFallback
          isLoading={isLoading}
          error={error}
          onRetryAction={{ onClick: handleRetryOnFail }}
        />
      </OrgOverviewHeaderBase>
    );
  }

  const { createdAt, logoUrl, name, uniqueName } = data;

  return (
    <OrgOverviewHeaderBase title={t('title')}>
      <OrgOverviewHeaderContent
        createdAt={createdAt}
        logoUrl={logoUrl}
        name={name}
        uniqueName={uniqueName}
      />
    </OrgOverviewHeaderBase>
  );
}
