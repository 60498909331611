import { UserInvitationsList } from '../../../../organizations/invitations';

interface IInvitationsOverviewProps {
  title: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the summary of all pending invitations for a user.
 */
export function InvitationsOverview({ title }: IInvitationsOverviewProps) {
  return (
    <section className="flex h-full flex-col items-center justify-center">
      <UserInvitationsList title={title} />
    </section>
  );
}
