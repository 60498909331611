import { useState, FormEvent } from 'react';

import { useTranslations } from '@keymono/i18n';
import { useUpdateOrgDetailsMutation, IChoice } from '@keymono/apis';

import { IAddressInfo, IOrganizationDetails } from '@keymono/shared-types';
import {
  AddressInfoInputField,
  CardSubmitButton,
  CardSecButton,
  FormFeedbackSection,
  TextInputField,
  useForm,
} from '@keymono/design-system';

import { CountrySelectorInput } from '../../../../../components';

type TUpdateOrgAddressInfoFormInputFields = Pick<
  IOrganizationDetails,
  | 'addressCity'
  | 'addressLine1'
  | 'addressLine2'
  | 'addressState'
  | 'addressCountry'
  | 'addressZip'
>;

interface IAddressCardEditProps {
  address: IAddressInfo;
  onExitEditMode: () => void;
  onUpdateSuccess: () => void;
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This card allows for the user to update the org's address information.
 */
export function AddressCardEdit({
  address,
  onExitEditMode,
  onUpdateSuccess,
  orgId,
}: IAddressCardEditProps) {
  const t = useTranslations('UserProfile.namesSection');
  const tCountry = useTranslations('Country');

  const {
    addressLine1: initLine1,
    addressLine2: initLine2,
    addressCity: initCity,
    addressState: initState,
    addressCountry: initCountry,
    addressCountryLabel: initCountryLabel,
    addressZip: initZip,
  } = address;

  const [country, setCountry] = useState<IChoice | null>({
    key: initCountry,
    label: initCountryLabel,
  });
  const [hasCountryError, setHasCountryError] = useState('');

  const handleOnCountryChange = (newCountry: IChoice) => {
    setCountry(newCountry);
  };

  const handleCancelEdit = () => {
    onExitEditMode();
  };

  const handleOnUpdateNamesSuccess = () => {
    onUpdateSuccess();
  };

  const {
    error,
    isLoading: isFormSubmitting,
    updateOrgDetails,
  } = useUpdateOrgDetailsMutation({
    orgId,
    onSuccessCallback: handleOnUpdateNamesSuccess,
  });

  const errorFields = error?.errorFields;

  const handlePostDetails = (values: TUpdateOrgAddressInfoFormInputFields) => {
    const {
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressCountry,
      addressZip,
    } = values;

    /**
     * Post form fields that have been changed and ignore  the none touched.
     */
    updateOrgDetails({
      orgId,
      ...(addressLine1 !== initLine1 ? { address_line1: addressLine1 } : {}),
      ...(addressLine2 !== initLine2 ? { address_line2: addressLine2 } : {}),
      ...(addressCity !== initCity ? { address_city: addressCity } : {}),
      ...(addressState !== initState ? { address_state: addressState } : {}),
      ...(addressZip !== initZip ? { address_zip: addressZip } : {}),
      ...(addressCountry !== initCountry
        ? { address_country: country?.label ?? '' }
        : {}),
    });
  };

  /**
   * The fields to be tracked within the form.
   */
  const addressInputFieldsValues: TUpdateOrgAddressInfoFormInputFields = {
    addressLine1: initLine1,
    addressLine2: initLine2,
    addressCity: initCity,
    addressState: initState,
    addressCountry: country?.label ?? '',
    addressZip: initZip,
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: addressInputFieldsValues,
    onSubmitCb: handlePostDetails,
  });

  const getErrorInCountrySelection = () => {
    if (!country) {
      setHasCountryError(tCountry('countryErrorMessage'));

      return true;
    }
    setHasCountryError('');

    return false;
  };

  const handleOnValidateAndSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const countryError = getErrorInCountrySelection();

    if (countryError) return;

    handleOnSubmitForm(event);
  };

  return (
    <form onSubmit={handleOnValidateAndSubmitForm}>
      {error?.message ? (
        <FormFeedbackSection
          isFormSubmitting={isFormSubmitting}
          type="error"
          message={error.message}
          description={error.description}
        />
      ) : (
        <FormFeedbackSection
          isFormSubmitting={isFormSubmitting}
          type="info"
          message={t('editNamesFormIntro.message')}
          description={t('editNamesFormIntro.description')}
        />
      )}

      <AddressInfoInputField
        error={errorFields?.address_line1 || errors.addressLine1}
        label="Address Line 1"
        name="addressLine1"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="Zone, Street, Lane, Plot"
        onError={handleOnError}
        value={values.addressLine1}
      />

      <AddressInfoInputField
        error={errorFields?.address_line2 || errors.addressLine2}
        label="Address Line 2"
        name="addressLine2"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="Zone, Street, Lane, Plot"
        onError={handleOnError}
        value={values.addressLine2}
      />

      <TextInputField
        type="text"
        error={errorFields?.address_city || errors.addressCity}
        label="City"
        name="addressCity"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="Please enter the City"
        onError={handleOnError}
        value={values.addressCity}
      />

      <TextInputField
        constraints={{ required: false }}
        type="text"
        error={errorFields?.address_state || errors.addressState}
        label="State"
        name="addressState"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="The state"
        onError={handleOnError}
        value={values.addressState}
      />

      <div className="pb-2.5">
        <CountrySelectorInput
          selectedCountry={country}
          onChange={handleOnCountryChange}
          hasError={errorFields?.address_country || hasCountryError}
        />
      </div>

      <TextInputField
        constraints={{ required: false }}
        type="text"
        error={errorFields?.address_zip || errors.addressZip}
        label="ZIP / Postal code"
        name="addressZip"
        onBlurCallback={handleOnBlur}
        onChangeCallback={handleOnInputChange}
        placeholder="Enter the ZIP code"
        onError={handleOnError}
        value={values.addressZip}
      />

      <div className="mt-2 flex w-full justify-end gap-2">
        <CardSecButton onClick={handleCancelEdit} isEnabled={!isFormSubmitting}>
          {t('formFields.cancelCTA.label')}
        </CardSecButton>
        <CardSubmitButton
          isEnabled={!isFormSubmitting}
          isLoading={isFormSubmitting}
          label={t('formFields.submitCTA.label')}
          loadingLabel={t('formFields.submitCTA.loadingLabel')}
        />
      </div>
    </form>
  );
}
