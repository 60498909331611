import { Suspense } from 'react';

import ForgotPasswordForm, {
  IForgotPasswordFormProps,
} from './ForgotPasswordForm';

/**
 * This dynamically imports `ForgotPasswordForm` content at the lazily when required.
 * This helps prevent nextjs hydration errors.
 */
export function DynamicForgotPasswordForm(props: IForgotPasswordFormProps) {
  return (
    <Suspense fallback={<h1 className="text-2xl">...</h1>}>
      <ForgotPasswordForm {...props} />
    </Suspense>
  );
}
