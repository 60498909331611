/**
 * -----------------------------------------------------------------------------
 * These endpoints provide support for the Product management APIs
 * -----------------------------------------------------------------------------
 */

/**
 * Endpoint for retrieving the all products for the org.
 */
export const fetchOrgProductsUrl = (orgId: string) => `/orgs/${orgId}/products`;
