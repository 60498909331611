import { useState } from 'react';
import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';
import { IApiError, usePasswordResetMutation } from '@keymono/apis';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';
import {
  Button,
  FormFeedbackSection,
  LogoKeymono,
  PasswordInputField,
  useForm,
} from '@keymono/design-system';

import { FormLinkCTA } from '../../components/FormLinkCTA';

import { FormContainer, StyledForm } from '../styles';

import { LoginInsteadCTA } from './LoginInsteadCTA';
import { LoginButtonCTA } from './LoginButtonCTA';

interface IPasswordResetFormInputFields {
  password: string;
  confirmPassword: string;
}

export interface IPasswordResetFormProps {
  token: string;
  isFormSubmitted: boolean;
  onSubmitSuccess: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the password reset form that captures the new user password.
 * TODO: Add validation for confirm password checks, and disable button if they
 *  do not match.
 */
export default function PasswordResetForm({
  token,
  onSubmitSuccess,
  isFormSubmitted,
}: IPasswordResetFormProps) {
  const t = useTranslations('PasswordReset');
  const tCommon = useTranslations('Common');

  const [formFeedback, setFormFeedback] = useState({
    message: '',
    // message: t('formIntro.message'),
    description: t('formIntro.description'),
  });

  const handleOnPasswordResetError = (error: IApiError) => {
    // TODO: Refactor this to the mutation
    errorLogToRemoteUtil({
      error,
      errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
      errorTitle: 'Error in Password Reset Attempt',
      message: error.message,
    });
  };

  const handleOnPasswordResetSuccess = () => {
    setFormFeedback({
      message: t('formSuccess.message'),
      description: t('formSuccess.description'),
    });
    onSubmitSuccess();
  };

  const {
    error,
    isLoading: isFormSubmitting,
    submitPasswordResetDetails,
  } = usePasswordResetMutation({
    onSuccessCallback: handleOnPasswordResetSuccess,
    onErrorCallback: handleOnPasswordResetError,
  });

  const errorFields = error?.errorFields;

  const handlePostPasswordResetDetails = ({
    password,
  }: IPasswordResetFormInputFields) => {
    submitPasswordResetDetails({
      password,
      token,
    });
  };

  /**
   * The fields to be tracked within the form.
   */
  const passwordResetInputFieldsValues: IPasswordResetFormInputFields = {
    password: '',
    confirmPassword: '',
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: passwordResetInputFieldsValues,
    onSubmitCb: handlePostPasswordResetDetails,
  });

  /**
   * TODO: Replace this temp work around with logic to get direct key from error object.
   */
  const isLinkExpired = error?.description?.includes(
    'is invalid or has expired.'
  );

  return (
    <FormContainer>
      <div className="form-responsive-wrapper">
        <div>
          <Link
            href="/"
            className="my-8 hidden w-auto sm:inline-flex"
            aria-label={tCommon('logoAriaLabel')}
            title={tCommon('logoAriaLabel')}
          >
            <LogoKeymono />
          </Link>
          <div className="form-header flex">
            <h1 className="grow">{t('title')}</h1>
          </div>

          {error?.message ? (
            <FormFeedbackSection
              isFormSubmitting={isFormSubmitting}
              type="error"
              message={error.message}
              description={error.description}
            />
          ) : (
            <FormFeedbackSection
              isFormSubmitting={isFormSubmitting}
              type="info"
              initialDescription
              message={formFeedback.message}
              messageLoadingText={t('formSubmitting.message')}
              description={formFeedback.description}
              descriptionLoadingText={t('formSubmitting.description')}
            />
          )}
        </div>

        {isFormSubmitted ? (
          <div>
            <LoginButtonCTA />
          </div>
        ) : (
          <StyledForm onSubmit={handleOnSubmitForm}>
            <PasswordInputField
              error={errorFields?.password || errors.password}
              label={t('formFields.password.label')}
              name="password"
              onBlurCallback={handleOnBlur}
              onChangeCallback={handleOnInputChange}
              onError={handleOnError}
              placeholder={t('formFields.password.placeholder')}
              value={values.password}
            />
            <PasswordInputField
              error={errors.confirmPassword}
              label={t('formFields.confirmPassword.label')}
              name="confirmPassword"
              onBlurCallback={handleOnBlur}
              onChangeCallback={handleOnInputChange}
              onError={handleOnError}
              placeholder={t('formFields.confirmPassword.placeholder')}
              value={values.confirmPassword}
            />

            <Button
              type="submit"
              isEnabled={!isFormSubmitting}
              isLoading={isFormSubmitting}
              label={t('formFields.submit.label')}
              loadingLabel={t('formFields.submit.loadingLabel')}
            />

            {isLinkExpired ? (
              <FormLinkCTA
                href="/auth/forgot-password"
                title={t('goForgotPasswordCTA.title')}
                subTitle={t('goForgotPasswordCTA.subTitle')}
              />
            ) : null}
            <LoginInsteadCTA />
          </StyledForm>
        )}
      </div>
    </FormContainer>
  );
}
