import { useState } from 'react';

import { useAuthStore } from '@keymono/services';

import { ContactNumbersCard } from './ContactNumbersCard';
import { ContactNumbersCardEdit } from './ContactNumbersCardEdit';

/**
 * -----------------------------------------------------------------------------
 * Displays the user's mobile/phone contact numbers information.
 */
export function ProfileContactNumbersCard() {
  const user = useAuthStore().activeSession?.user;

  const [isEditing, setIsEditing] = useState(false);

  if (!user) return null;

  const { phonePrimary } = user;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  return !isEditing ? (
    <ContactNumbersCard phonePrimary={phonePrimary} onEdit={handleEdit} />
  ) : (
    <ContactNumbersCardEdit onExitEditMode={handleCancelEdit} />
  );
}
