import { IApiError } from '../types';
import { checkIfTokenIsExpired } from '../utils';
import { sessionTimeoutError } from '../api-errors/formatErrorInAPICall';

type ITokenValidity =
  | {
      hasToken: true;
      token: string;
      expiry: string;
    }
  | {
      hasToken: false;
      error: IApiError;
    };

/**
 * This helps to check if the token or refresh token have expired to generated
 * new ones or throw an error to remind the user to login.
 */
export const validateOrRenewAppSession = async (
  token: string,
  expiry: string
): Promise<ITokenValidity> => {
  /**
   * If the token is expired, try to check if the refresh token is renewable
   * otherwise prompt a login.
   */
  if (checkIfTokenIsExpired(expiry)) {
    // /**
    //  * Throw a session timeout error to request the user to login again, if the
    //  * `refreshToken` also expired.
    //  */
    // TODO: Commented out refresh token since it's not yet supported.
    // if (checkIfTokenIsExpired(refreshToken)) {
    //   return { hasToken: false, error: sessionTimeoutError };
    // }

    /**
     * Fetch a new `token` if the `refreshToken` it's not expired as yet.
     */
    // const tokenResponse = await globalFetchRefreshTokenAPICall({
    //   refreshToken,
    // });

    // if (tokenResponse.success) {
    //   return {
    //     hasToken: true,
    //     token: tokenResponse.token,
    //     refreshToken: tokenResponse.refreshToken,
    //   };
    // }

    /**
     * Refresh API also failed. So returning the session timeout error.
     */
    return { hasToken: false, error: sessionTimeoutError };
  }

  /**
   * The token, is not expired, so return true with token details.
   */
  return { hasToken: true, token, expiry };
};
