import {
  ArrowDownOnSquareIcon,
  DocumentTextIcon,
  LinkIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

import { getFormattedDate } from '@keymono/utilities';
import { CardWithActions, SimpleButton } from '@keymono/design-system';
import { useOrganizationContractsQuery } from '@keymono/apis';

interface IOrgAgreementsContractsCardFallbackProps {
  isLoading: boolean;
  error?: {
    message: string;
    description?: string;
  } | null;
  onRetry: () => void;
}

function OrgAgreementsContractsCardFallback({
  isLoading,
  error,
  onRetry,
}: IOrgAgreementsContractsCardFallbackProps) {
  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Something went wrong while retrieving your the agreement contracts
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                <li>{error?.message}</li>
                <li>{error?.description}</li>
              </ul>
            </div>
            <SimpleButton onClick={onRetry} label="Retry" />
          </div>
        </div>
      </div>
    );
  }

  return isLoading ? (
    <div>
      <h2>TODO: Contracts details</h2>
      <p> Please wait.</p>
    </div>
  ) : (
    <div>No contracts found</div>
  );
}

interface IOrgAgreementsContractsCardProps {
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the org's agreements & contracts information and allows for download.
 */
export function OrgAgreementsContractsCard({
  orgId,
}: IOrgAgreementsContractsCardProps) {
  const { data, isLoading, error, refetch } = useOrganizationContractsQuery({
    orgId,
  });

  const handleGoTo = () => {
    // TODO: Go to Contract Agreements details.
  };

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !data) {
    return (
      <OrgAgreementsContractsCardFallback
        isLoading={isLoading}
        error={error}
        onRetry={handleRetryOnFail}
      />
    );
  }

  /**
   * Main icon feature that distinguishes the card info.
   */
  const cardIcon = {
    Icon: DocumentTextIcon,
    className: 'text-blue-700 bg-blue-50',
  };

  /**
   * Main icon feature that distinguishes the card info.
   * TODO: Un-hide this card and put some action to go to contracts details view
   */
  const cardActions = [
    {
      className: '!hidden',
      Icon: LinkIcon,
      onClick: handleGoTo,
      title: 'Go to',
    },
  ];

  return (
    <CardWithActions
      actions={cardActions}
      isSuccessVisible={false}
      cardIcon={cardIcon}
    >
      <h3 className="pb-2 text-lg font-medium">Agreements & Contracts</h3>

      <ul>
        {data.contracts.map(
          ({
            id,
            documentUrl,
            startDate,
            endDate,
            successFee,
            successFeeType,
          }) => {
            const dateStart = getFormattedDate(
              new Date(startDate),
              'do LLLL YYY'
            );
            const dateEnd = getFormattedDate(new Date(endDate), 'do LLLL YYY');

            return (
              <li key={id}>
                <a
                  href={documentUrl}
                  className="
                    group
                    relative
                    -mx-2
                    mt-2
                    block
                    cursor-pointer
                    overflow-hidden
                    rounded-lg
                    border
                    border-gray-100
                    bg-white
                    p-4
                    hover:border-red-100
                    hover:bg-red-50/50
                  "
                  rel="noreferrer"
                  title="Download this Agreement/Contract"
                  target="_blank"
                >
                  <div className="mb-2 flex flex-wrap items-center">
                    <p className="w-28 shrink-0 text-xs uppercase text-gray-400">
                      Started On
                    </p>
                    <h3 className="text-sm">{dateStart}</h3>
                  </div>
                  <div className="mb-2 flex flex-wrap items-center">
                    <p className="w-28 shrink-0 text-xs uppercase text-gray-400">
                      Ends On
                    </p>
                    <h3 className="text-sm text-gray-500">{dateEnd}</h3>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <p className="w-28 shrink-0 text-xs uppercase text-gray-400">
                      Success Rate
                    </p>
                    <h3 className="text-sm text-gray-500">
                      <span>{successFee}</span>
                      <span className="mx-1">{successFeeType}</span>
                    </h3>
                  </div>

                  <div
                    className="
                      absolute
                      right-2
                      top-2
                      flex
                      flex-1
                      cursor-pointer
                      items-center
                      rounded-lg
                      p-2
                      text-gray-500
                      opacity-80

                      hover:bg-red-100
                      hover:!text-red-500
                      group-hover:text-red-800
                    "
                  >
                    <ArrowDownOnSquareIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                  </div>
                </a>
              </li>
            );
          }
        )}
      </ul>
    </CardWithActions>
  );
}
