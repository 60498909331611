import { ApiClient } from '../../../api-client';

import { IPublicChoicesUrlOptions } from '../../../types';
import { publicChoicesUrl } from '../../../urls';

import { IChoicesData } from './types';

/**
 *
 */
export class CommonAPIClient extends ApiClient {
  private static classInstance?: CommonAPIClient;

  private constructor() {
    super({
      baseURL: process?.env?.NEXT_PUBLIC_BASE_API_URL || '',
      apiVersion: process?.env?.NEXT_PUBLIC_BASE_API_VERSION || '',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new CommonAPIClient();
    }

    return this.classInstance;
  };

  /**
   * Api call to fetch all the choices by a specific type.
   */
  public fetchChoices = async (options: IPublicChoicesUrlOptions) => {
    const response = await this.get<IChoicesData>(publicChoicesUrl(options));

    if (!response.success) throw response;

    return response.data;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for globally reusable APIs that are not tied to a specific service.
 */
export const COMMON_API = CommonAPIClient.getClientInstance();
