import { useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';
import { TMultiLogoutResponseData } from '../types';
import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the login mutation keys in react query cache.
 */
const MUT_LOGOUT_USER = 'logout-mutation-key';

/**
 * The useLogoutMutation Props
 */
export interface IUseLogoutOptions {
  /**
   * Callback on a successful logout.
   */
  onSuccessCallback: (logoutData: TMultiLogoutResponseData) => void;
  onErrorCallback: (err: IApiError) => void;
}

/**
 * This performs a logout request.
 *
 * @param IUseLogoutOptions
 */
export const useLogoutMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseLogoutOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_LOGOUT_USER],
    networkMode: 'always',
    mutationFn: USER_ACCOUNTS_API.logoutUser,
    onSuccess: (response) => onSuccessCallback(response),
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Logout Attempt',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    submitLogoutDetails: mutate,
  };
};
