import React, { useState, FormEvent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useUpdateUserDetailsMutation } from '@keymono/apis';
import {
  PasswordInputField,
  useForm,
  Button,
  FormFeedbackSection,
  IInputChanged,
} from '@keymono/design-system';
import { useTranslations } from '@keymono/i18n';
import { useAuthStore } from '@keymono/services';

import { useSessionInstance } from '../../hooks';

interface IUpdatePasswordInputField {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}
/**
 * This is the UpdatePassword form input field for the user. It renders the form
 * that captures the user's old password, new password and confirm new  password
 * TODO: to show a message for password successfully updated and with a CTA
 * to navigate to the login screen.
 */
export function PasswordUpdateForm() {
  const t = useTranslations('PasswordUpdate');

  const { replace } = useRouter();
  const user = useAuthStore().activeSession?.user;

  const [formFeedback, setFormFeedback] = useState({
    message: t('formIntro.message'),
    description: t('formIntro.description'),
  });

  const handleOnPasswordUpdateSuccess = () => {
    setFormFeedback({
      message: t('formSuccess.message'),
      description: t('formSuccess.description'),
    });
  };

  const {
    error,
    isLoading: isFormSubmitting,
    updateUserDetails,
  } = useUpdateUserDetailsMutation({
    onSuccessCallback: handleOnPasswordUpdateSuccess,
  });

  const errorFields = error?.errorFields;

  const handlePostPasswordUpdateDetails = ({
    password,
    oldPassword,
  }: IUpdatePasswordInputField) => {
    updateUserDetails({
      userId: user?.id || '',
      password,
      old_password: oldPassword,
    });
  };

  /** *
   * These are fields to be tracked with in the form
   */
  const updatePasswordInputFields: IUpdatePasswordInputField = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };

  const {
    values,
    errors,
    handleOnBlur,
    handleOnInputChange,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: updatePasswordInputFields,
    onSubmitCb: handlePostPasswordUpdateDetails,
  });

  const { removeSession } = useAuthStore();
  const { activeSession } = useSessionInstance();

  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const hasConfirmError = errors.confirmPassword || confirmPasswordError;

  const hasPasswordMismatch = values.password !== values.confirmPassword;

  /**
   *
   *method to check if the new password set matches with the confirm password
   *This sets an error message if password mismatches and sets the error to
   *empty if the passwords match
   *@returns setConfirmPasswordError
   */
  const handleOnConfirmPasswordError = () => {
    if (
      values.password !== values.confirmPassword &&
      values.confirmPassword !== ''
    ) {
      setConfirmPasswordError('Sorry passwords mismatch');
    } else {
      setConfirmPasswordError('');
    }
    return setConfirmPasswordError;
  };

  /**
   *
   *method to hijack the handleOnBlur method from the useForm hook such that we
   *can be able to display the error message to the user incase of password mismatch
   *and also display no errormessage incase the passwords match
   *empty if the passwords match
   */
  const handleOnBlurConfirmPasswordError = (
    options: IInputChanged<IUpdatePasswordInputField>
  ) => {
    handleOnBlur(options);
    handleOnConfirmPasswordError();
  };

  /**
   *
   *method to route to login page and clear active session of the user upon
   *successful password update
   */
  const handleOnSubmitNewPassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleOnSubmitForm(event);
    replace('/auth/login');
    removeSession(activeSession!);
  };

  return (
    <div>
      {error?.message ? (
        <FormFeedbackSection
          isFormSubmitting={isFormSubmitting}
          type="error"
          message={error.message}
          description={error.description}
        />
      ) : (
        <FormFeedbackSection
          isFormSubmitting={isFormSubmitting}
          type="info"
          message={formFeedback.message}
          description={formFeedback.description}
        />
      )}
      <hr />
      <form
        className="
            mt-10 flex  w-full flex-col space-y-4 pr-3 transition-all delay-100
            duration-500  rtl:pl-3  md:w-2/3 md:space-y-6
           "
        onSubmit={handleOnSubmitNewPassword}
      >
        <PasswordInputField
          error={errors?.oldPassword}
          label={t('formFields.oldPassword.label')}
          name="oldPassword"
          onBlurCallback={handleOnBlur}
          onChangeCallback={handleOnInputChange}
          onError={handleOnError}
          placeholder={t('formFields.oldPassword.placeholder')}
          value={values.oldPassword}
        />

        <PasswordInputField
          error={errorFields?.password || errors.password}
          label={t('formFields.password.label')}
          name="password"
          onBlurCallback={handleOnBlur}
          onChangeCallback={handleOnInputChange}
          onError={handleOnError}
          placeholder={t('formFields.password.placeholder')}
          value={values.password}
        />

        <PasswordInputField
          error={hasConfirmError}
          label={t('formFields.confirmPassword.label')}
          name="confirmPassword"
          onBlurCallback={handleOnBlurConfirmPasswordError as any}
          onChangeCallback={handleOnInputChange}
          onError={handleOnError}
          placeholder={t('formFields.confirmPassword.placeholder')}
          value={values.confirmPassword}
        />

        <div className="flex items-center gap-4">
          <Button
            className="flex w-fit shrink-0 justify-center"
            type="submit"
            isEnabled={!hasPasswordMismatch}
            isLoading={isFormSubmitting}
            label={t('formFields.submit.label')}
            loadingLabel={t('formFields.submit.loadingLabel')}
          />
          <Link href="/auth/forgot-password" className="form-link-action">
            <span className="text-center">
              {t('goForgotPasswordCTA.label')}
            </span>
          </Link>
        </div>
      </form>
    </div>
  );
}
