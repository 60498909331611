import { EnvelopeIcon, PencilIcon } from '@heroicons/react/24/outline';

import { CardWrapper } from '../styles';

interface INamesCardProps {
  email: string;
  onEdit: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the user's email summary
 */
export function EmailsCard({ email, onEdit }: INamesCardProps) {
  const handlePressEdit = () => {
    onEdit();
  };

  return (
    <CardWrapper className="group">
      <div>
        <span className="inline-flex rounded-lg bg-teal-50 p-3 text-teal-700 ring-4 ring-white">
          <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium">
          <p className="focus:outline-none">
            <span className="mx-1">{email}</span>
          </p>
        </h3>
        <p className="mt-2 text-sm text-gray-500">
          This is your primary email address. All notifications about account
          transactions, settings, updates, will be sent here. You can also add
          another email to customize the emails sent to you.
        </p>
      </div>

      <button
        type="button"
        title="Edit your profile emails"
        className="
          form-link-action
          absolute
          right-6 top-6 flex cursor-pointer rounded-lg
          p-2
          text-gray-300
          opacity-80
          hover:bg-red-100
          hover:text-red-700
          group-hover:text-red-700 group-hover:opacity-100
        "
        aria-hidden="true"
        onClick={handlePressEdit}
      >
        <PencilIcon className="h-6 w-6" />
      </button>
    </CardWrapper>
  );
}
