import Image from 'next/image';

import { useTranslations } from '@keymono/i18n';
import { useAuthStore } from '@keymono/services';

export function ProfileHeader() {
  const t = useTranslations('UserProfile.profileOverview');
  const user = useAuthStore().activeSession?.user;

  if (!user) return null;

  const { firstName, lastName, otherName, profileImageUrl, email } = user;

  return (
    <section aria-labelledby="profile-overview-title" title={t('title')}>
      <div className="overflow-hidden rounded bg-white shadow-sm shadow-gray-50">
        <h2 className="sr-only" id="profile-overview-title">
          {t('title')}
        </h2>
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                <Image
                  className="mx-auto h-20 w-20 rounded-full"
                  src={profileImageUrl}
                  alt=""
                  width={120}
                  height={120}
                />
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-xl sm:text-2xl">
                  <span className="font-bold text-gray-900">{firstName}</span>
                  {lastName ? (
                    <span className="mx-2 font-light text-gray-900">
                      {lastName}
                    </span>
                  ) : null}
                  {otherName ? (
                    <span className="mx-2 font-light text-gray-700">
                      {otherName}
                    </span>
                  ) : null}
                </p>
                <p className="text-sm font-medium text-gray-600">{email}</p>
                <p className="text-sm font-light text-gray-400">
                  {t('welcomeInfo')}
                </p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <a
                href="/"
                className="
                  hidden items-center justify-center rounded-md
                  border border-gray-300 bg-white px-4 py-2
                  text-sm font-medium text-gray-700 shadow-sm
                  hover:bg-gray-50
                "
              >
                Edit Profile
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
