import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { TTextLocalized, Text } from '@keymono/i18n';
import {
  ButtonLoadingIndicator,
  CardSecButton,
  THeroIcon,
} from '@keymono/design-system';

import { ErrorInlineWrapper, MessageInlineWrapper } from '../../styles';

interface IInvitationOptions {
  actionType: 'primary-action' | 'secondary-action';
  className?: string;
  errorMessage: string | null;
  Icon: THeroIcon;
  isLoading: boolean;
  isSuccess: boolean;
  successMessage: string;
  onActionPress: () => void;
  title: TTextLocalized;
  titleRetry: TTextLocalized;
}

interface IResendInvitationCTAProps extends IInvitationOptions {
  loadingMessage?: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * This actions allows the user to resend out an invitation.
 * Calls its own logic to post the invite, track the errors and loading states.
 */
export function ActionContainer({
  actionType,
  className,
  errorMessage: errorMessageInit,
  Icon,
  isLoading,
  isSuccess,
  loadingMessage = '',
  successMessage,
  onActionPress,
  title,
  titleRetry,
}: IResendInvitationCTAProps) {
  const t = useTranslations('Invitations');

  const [errorMessage, setError] = useState(errorMessageInit);

  useEffect(() => {
    setError(() => errorMessageInit);
  }, [errorMessageInit]);

  const handleClearError = () => {
    setError(() => null);
  };

  const handleClick = () => {
    onActionPress();
  };

  let btnClass = actionType === 'primary-action' ? 'primary' : 'secondary';
  let btnText = title;

  if (errorMessage) {
    btnClass = 'btn-has-error';
    btnText = titleRetry;
  } else if (isLoading) {
    btnClass = 'btn-is-loading';
    btnText = loadingMessage ?? t('pleaseWait');
  }

  if (isSuccess) {
    return (
      <MessageInlineWrapper className={className}>
        <Icon className="h-5 w-5  ltr:mr-2 rtl:ml-2" />
        <span>{successMessage}</span>
      </MessageInlineWrapper>
    );
  }

  return (
    <div className="relative inline-flex flex-col text-center">
      <CardSecButton className={btnClass} onClick={handleClick}>
        {isLoading ? (
          <span>
            <ButtonLoadingIndicator className="ltr:mr-3 rtl:ml-3" isEnabled />
            <Text text={btnText} />
          </span>
        ) : (
          <Text text={btnText} />
        )}
      </CardSecButton>

      {errorMessage ? (
        <ErrorInlineWrapper onClick={handleClearError}>
          <XMarkIcon className="h-5 w-5  ltr:mr-2 rtl:ml-2" />
          {errorMessage}
        </ErrorInlineWrapper>
      ) : null}
    </div>
  );
}
