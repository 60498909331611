import { useTranslations } from '@keymono/i18n';

interface IRetryForgotPasswordCTAProps {
  onRetry: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Allows the user to retry the forgot password process if the email was not
 * received.
 */
export function RetryForgotPasswordCTA({
  onRetry,
}: IRetryForgotPasswordCTAProps) {
  const t = useTranslations('ForgotPassword');

  return (
    <div className="my-4 text-sm font-light text-gray-500 dark:text-gray-400">
      {t('retryForgotPasswordCTA.title')}
      <button type="button" onClick={onRetry} className="form-link-action">
        {t('retryForgotPasswordCTA.subTitle')}
      </button>
    </div>
  );
}
