import Image from 'next/image';

import { EnvelopeIcon } from '@heroicons/react/24/outline';

import { IInvitationsListProps } from '../types';
import {
  CancelInvitationCTA,
  ResendInvitationCTA,
  AcceptInvitationCTA,
  DeclineInvitationCTA,
} from '../../actions';

/**
 * -----------------------------------------------------------------------------
 * This renders the list of pending Invitations in a card layout.
 * Currently optimized for mobile view.
 */
export function InvitationsListCards({
  invitations,
  orgId = '',
  className = '',
}: IInvitationsListProps) {
  return (
    <ul className={`divide-y divide-gray-200 pt-4 ${className}`}>
      {invitations.map((invitation, index) => {
        const { id, status, type, inviteType } = invitation;
        const inviteName =
          inviteType === 'org' ? invitation.email : invitation.orgName || '';
        const orgLogoUrl = inviteType === 'user' ? invitation.orgLogoUrl : '';

        return (
          <li
            key={id}
            className={index % 2 === 0 ? 'py-4' : 'bg-red-50/20 py-4'}
          >
            <div className="flex items-center gap-x-4">
              <div className="flex-shrink-0">
                {/**
                 * If the invitations are for an organization,
                 * render the Envelope Icon as Image.
                 * If the invitations are for a user,
                 * render the Image of the Org.
                 */}
                {inviteType === 'org' ? (
                  <EnvelopeIcon
                    className="h-10 w-10 rounded-full bg-gray-100 p-2 text-gray-500"
                    aria-hidden="true"
                  />
                ) : (
                  <Image
                    className="h-8 w-8 rounded-full"
                    src={orgLogoUrl}
                    width={32}
                    height={32}
                    alt=""
                  />
                )}
              </div>
              <div className="flex min-w-0 flex-1 flex-col items-start overflow-hidden">
                <p className="w-full truncate text-sm font-medium text-gray-900">
                  {inviteName}
                </p>
                <p className="truncate text-sm capitalize text-gray-500">
                  {type}
                </p>
                <p className="text-xs font-semibold leading-5 text-green-800">
                  {status}
                </p>
              </div>
              <div className="flex gap-x-2">
                {/**
                 * If the invitations are for an organization,
                 * render the Cancel and Resend Invitation CTAs.
                 * If the invitations are for a user,
                 * render the Accept and Decline Invitation CTAs.
                 */}
                {inviteType === 'org' ? (
                  <>
                    <CancelInvitationCTA id={id} orgId={orgId} />
                    <ResendInvitationCTA id={id} orgId={orgId} />
                  </>
                ) : (
                  <>
                    <AcceptInvitationCTA id={id} />
                    <DeclineInvitationCTA id={id} />
                  </>
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
