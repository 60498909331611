import {
  AsyncComponentFallbackTemplate,
  IBaseUIFallbackCommonOptions,
  IBaseUIErrorOptions,
} from '@keymono/design-system';

import {
  OrgMembershipListError,
  OrgMembershipListLoader,
} from '../OrgMembershipListView';

function OrgMembersAndInvitationsViewError({
  error,
  onRetryAction,
}: IBaseUIErrorOptions) {
  return (
    <div className="mx-auto mb-40 flex flex-col items-center justify-center px-4 pb-10">
      <OrgMembershipListError error={error} onRetryAction={onRetryAction} />
      {/* TODO: Add error section for org invitations */}
    </div>
  );
}

function LoadingOrgMembersAndInvitationsView() {
  return (
    <div className="mx-auto mb-40 flex flex-col  items-center justify-center px-4 pb-10">
      <OrgMembershipListLoader />
      {/* TODO: Add loading section for org invitations */}
    </div>
  );
}

interface IOrgMembersAndInvitationsViewLoaderProps
  extends IBaseUIFallbackCommonOptions {}

/**
 * -----------------------------------------------------------------------------
 * This component is rendered while the app fetches the user profile data from
 * which we can determine the user's role and permissions, membership orgs
 * status, and invitations to join other orgs.
 */
export function OrgMembersAndInvitationsFallback({
  isLoading,
  error,
  onRetryAction,
}: IOrgMembersAndInvitationsViewLoaderProps) {
  return (
    <AsyncComponentFallbackTemplate
      isLoading={isLoading}
      error={error}
      onRetryAction={onRetryAction}
      ErrorComponent={OrgMembersAndInvitationsViewError}
      LoadingComponent={LoadingOrgMembersAndInvitationsView}
    />
  );
}
