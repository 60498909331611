import { useState } from 'react';
import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';
import { useEmailVerificationQuery } from '@keymono/apis';
import {
  Button,
  FormFeedbackSection,
  LogoKeymono,
} from '@keymono/design-system';

import { FormLinkCTA } from '../../components/FormLinkCTA';

import { FormContainer } from '../styles';

export interface IEmailVerificationWithTokenProps {
  token: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the email verification with token UI that calls the API and
 * allows for the user to update their email.
 */
export function EmailVerificationWithToken({
  token,
}: IEmailVerificationWithTokenProps) {
  const t = useTranslations('EmailVerification');
  const tCommon = useTranslations('Common');

  const [formFeedback, setFormFeedback] = useState({
    message: t('intro.formFeedback.message'),
    description: t('intro.formFeedback.description'),
  });

  const handleOnVerifyEmailSuccess = () => {
    setFormFeedback({
      message: t('successAlert.formFeedback.message'),
      description: t('successAlert.formFeedback.description'),
    });
  };

  const {
    data,
    error,
    isLoading: isFormSubmitting,
    postVerifyEmail,
  } = useEmailVerificationQuery({
    token,
    onSuccessCallback: handleOnVerifyEmailSuccess,
  });

  const handleRetryVerifyEmail = () => {
    postVerifyEmail();
  };

  return (
    <FormContainer>
      <div className="form-responsive-wrapper">
        <div>
          <Link
            href="/"
            className="my-8 hidden w-auto sm:inline-flex"
            aria-label={tCommon('logoAriaLabel')}
            title={tCommon('logoAriaLabel')}
          >
            <LogoKeymono />
          </Link>
          <div className="form-header flex">
            <h1 className="grow">{t('title')}</h1>
          </div>

          {error?.message ? (
            <FormFeedbackSection
              isFormSubmitting={false}
              type="error"
              message={t('noTokenAlert.formFeedback.message')}
              description={
                error.description || t('noTokenAlert.formFeedback.description')
              }
            />
          ) : (
            <FormFeedbackSection
              isFormSubmitting={isFormSubmitting}
              type="info"
              message={formFeedback.message}
              messageLoadingText={t('intro.formFeedback.message')}
              description={formFeedback.description}
              descriptionLoadingText={t('intro.formFeedback.description')}
            />
          )}
        </div>

        {data?.verified ? (
          <FormLinkCTA
            href="/"
            title={t('successAlert.continueCTA.title')}
            subTitle={t('successAlert.continueCTA.subTitle')}
          />
        ) : (
          <>
            <Button
              className="w-auto"
              type="button"
              onClick={handleRetryVerifyEmail}
              isEnabled
              isLoading={isFormSubmitting}
              label={t('failedAlert.retryLabel')}
              loadingLabel={t('failedAlert.retryLoadingLabel')}
            />
            <FormLinkCTA
              href="/"
              title={t('failedAlert.continueCTA.title')}
              subTitle={t('failedAlert.continueCTA.subTitle')}
            />
          </>
        )}
      </div>
    </FormContainer>
  );
}
