import dayjs from 'dayjs';
import { SalesByDateResult, TopSkuByDateResult } from '../reports';
import { Series, Transpose, aggreate, sum, transpose } from './aggregations';

export type SeriesMetricTag = typeof SeriesMetricTags[number];
export const SeriesMetricTags = [
  'series:sales',
  'series:sales:price',
  'series:orders',
  'series:orders:value',
  'series:units',
  'series:units-per-order',
  'series:units:price:discounted',
  'series:units:price:full',
] as const;

export type FiniteMetricTag = typeof FiniteMetricTags[number];
export const FiniteMetricTags = ['finite:sales:velocity'] as const;

export const SeriesMetricNamesMap = {
  'series:sales': 'Sales',
  'series:sales:price': 'Avg. sales price',
  'series:orders': 'Orders',
  'series:orders:value': 'Avg. order value',
  'series:units-per-order': 'Avg. units / order',
  'series:units': 'Units sold',
  'series:units:price:discounted': 'Units sold @ discounted price',
  'series:units:price:full': 'Units sold @ full price',
} as const;

export const MetricNamesSeriesTagMap = new Map<string, string>(
  Object.entries(SeriesMetricNamesMap).map(([key, val]) => [val, key])
);

export const SeriesMetricColorMap: { [key in SeriesMetricTag]: string } = {
  'series:sales': 'hsl(320deg, 60%, 60%)',
  'series:sales:price': 'hsl(40deg, 60%, 60%)',
  'series:orders': 'hsl(80deg, 60%, 60%)',
  'series:orders:value': 'hsl(120deg, 60%, 60%)',
  'series:units-per-order': 'hsl(160deg, 60%, 60%)',
  'series:units': 'hsl(200deg, 60%, 60%)',
  'series:units:price:discounted': 'hsl(2400deg, 60%, 60%)',
  'series:units:price:full': 'hsl(280deg, 60%, 60%)',
};

export const SeriesMetricFormatMap: {
  [key in SeriesMetricTag]: UnitOfMeasure;
} = {
  'series:sales': 'currency',
  'series:sales:price': 'currency',
  'series:orders': 'number',
  'series:orders:value': 'currency',
  'series:units-per-order': 'number',
  'series:units': 'number',
  'series:units:price:discounted': 'number',
  'series:units:price:full': 'number',
};

export const FiniteMetricNamesMap: { [key in FiniteMetricTag]: string } = {
  'finite:sales:velocity': 'Sales velocity',
};

export type UnitOfMeasure = typeof UnitOfMeasures[number];
export const UnitOfMeasures = [
  'currency',
  'percentage',
  'number',
  'units/day',
] as const;

export type SeriesMetric = {
  tag: SeriesMetricTag;
  name: string;
  series: number[];
  uom: UnitOfMeasure;
  val: number;
};

export type FiniteMetric = {
  tag: FiniteMetricTag;
  name: string;
  val: number;
  uom: UnitOfMeasure;
};

type ObjectValues<TObj extends object, TValue> = {
  [key in keyof TObj]: TValue;
};

export class MetricFactory {
  private seriesCache: Map<SeriesMetricTag, SeriesMetric> = new Map();

  private finiteCache: Map<FiniteMetricTag, FiniteMetric> = new Map();

  private soa: Transpose<
    Omit<ObjectValues<SalesByDateResult, number>, 'date'> & { date: string }
  >;

  public sku: {
    bestSeller: string;
    totalSalesPrice: number;
  };

  constructor(data: SalesByDateResult[], extra: TopSkuByDateResult) {
    const transposed = transpose(data.reverse());
    this.soa = {
      date: transposed.date,
      no_of_orders: transposed.no_of_orders.map(Number),
      no_of_units: transposed.no_of_units.map(Number),
      no_of_discounted_units: transposed.no_of_discounted_units.map(Number),
      total_full_price: transposed.total_full_price.map(Number),
      total_sale_price: transposed.total_sale_price.map(Number),
    };
    this.sku = {
      bestSeller: extra?.sku || 'N/A',
      totalSalesPrice: extra?.total_sale_price || 0,
    };
  }

  public static formatMetric(
    metricValue: number,
    uom: UnitOfMeasure,
    locale: string
  ): string {
    switch (uom) {
      case 'currency': {
        if (Number.isNaN(metricValue)) {
          return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'USD',
          }).format(0);
        }
        return Intl.NumberFormat(locale, {
          style: 'currency',
          currency: 'USD',
        }).format(metricValue);
      }
      case 'number': {
        if (Number.isNaN(metricValue)) {
          return Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(
            0
          );
        }
        return Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(
          metricValue
        );
      }
      case 'percentage': {
        if (Number.isNaN(metricValue)) {
          return `${Intl.NumberFormat(locale, {
            minimumFractionDigits: 2,
          }).format(0)}%`;
        }
        return `${Intl.NumberFormat(locale, {
          minimumFractionDigits: 2,
        }).format(metricValue)}%`;
      }
      case 'units/day': {
        if (Number.isNaN(metricValue)) {
          return `${Intl.NumberFormat(locale, {
            maximumFractionDigits: 2,
          }).format(0)} units / day`;
        }
        return `${Intl.NumberFormat(locale, {
          maximumFractionDigits: 2,
        }).format(metricValue)} units / day`;
      }
      default:
        throw new Error('not implemented');
    }
  }

  public static percChange(prev: number, curr: number): number {
    const increase = curr - prev;
    const result = increase / prev;
    if (Number.isNaN(result)) return 0;
    if (result === Number.POSITIVE_INFINITY) return 100;

    return result * 100;
  }

  public getSeriesLabels(): string[] {
    const data = this.soa.date.map((v) => dayjs(v).format('YYYY-MM-DD'));
    return data;
  }

  public getFiniteMetric(tag: FiniteMetricTag): FiniteMetric {
    if (this.finiteCache.has(tag)) return this.finiteCache.get(tag)!;

    const metric: FiniteMetric = {
      tag,
      name: FiniteMetricNamesMap[tag],
      val: 0,
      uom: 'number',
    };

    switch (tag) {
      case 'finite:sales:velocity':
        {
          const unitsSold = this.getSeriesMetric('series:units');
          metric.val = unitsSold.val / unitsSold.series.length;
          metric.uom = 'units/day';
        }
        break;
      default:
        throw new Error('not implemented');
    }

    this.finiteCache.set(tag, metric);
    return metric;
  }

  public getSeriesMetric(tag: SeriesMetricTag): SeriesMetric {
    if (this.seriesCache.has(tag)) return this.seriesCache.get(tag)!;

    const metric: SeriesMetric = {
      tag,
      name: SeriesMetricNamesMap[tag],
      series: [],
      uom: 'number',
      val: 0,
    };

    switch (tag) {
      case 'series:sales':
        // eslint-disable-next-line no-lone-blocks
        {
          metric.name = 'Sales';
          metric.series = this.soa.total_full_price;
          metric.val = aggreate(metric.series, sum);
          metric.uom = SeriesMetricFormatMap['series:sales'];
        }
        break;
      case 'series:sales:price':
        // eslint-disable-next-line no-lone-blocks
        {
          const salesSeries = this.getSeriesMetric('series:sales');
          const unitSeries = this.getSeriesMetric('series:units');
          metric.name = 'Avg. sales price';
          metric.series = Series.div(salesSeries.series, unitSeries.series);
          metric.val = salesSeries.val / unitSeries.val;
          metric.uom = SeriesMetricFormatMap['series:sales:price'];
        }
        break;
      case 'series:orders:value':
        // eslint-disable-next-line no-lone-blocks
        {
          const salesSeries = this.getSeriesMetric('series:sales');
          const ordersSeries = this.getSeriesMetric('series:orders');
          metric.name = 'Order Value';
          metric.series = Series.div(salesSeries.series, ordersSeries.series);
          metric.val = salesSeries.val / ordersSeries.val;
          metric.uom = SeriesMetricFormatMap['series:orders:value'];
        }
        break;
      case 'series:orders':
        // eslint-disable-next-line no-lone-blocks
        {
          metric.name = 'Orders';
          metric.series = this.soa.no_of_orders;
          metric.val = aggreate(metric.series, sum);
          metric.uom = SeriesMetricFormatMap['series:orders'];
        }
        break;
      case 'series:units-per-order':
        // eslint-disable-next-line no-lone-blocks
        {
          const ordersSeries = this.getSeriesMetric('series:orders');
          const unitsSeries = this.getSeriesMetric('series:units');
          metric.name = 'Avg. units / order';
          metric.series = Series.div(unitsSeries.series, ordersSeries.series);
          metric.val = unitsSeries.val / ordersSeries.val;
          metric.uom = SeriesMetricFormatMap['series:units-per-order'];
        }
        break;
      case 'series:units':
        // eslint-disable-next-line no-lone-blocks
        {
          metric.name = 'Units sold';
          metric.series = this.soa.no_of_units;
          metric.val = aggreate(metric.series, sum);
          metric.uom = SeriesMetricFormatMap['series:units'];
        }
        break;
      case 'series:units:price:discounted':
        // eslint-disable-next-line no-lone-blocks
        {
          metric.name = 'Units sold @ Discounted price';
          metric.series = this.soa.no_of_discounted_units;
          metric.val = aggreate(metric.series, sum);
          metric.uom = SeriesMetricFormatMap['series:units:price:discounted'];
        }
        break;
      case 'series:units:price:full':
        // eslint-disable-next-line no-lone-blocks
        {
          metric.name = 'Units sold @ Full price';
          metric.series = Series.sub(
            this.soa.no_of_units,
            this.soa.no_of_discounted_units
          );
          metric.val = aggreate(metric.series, sum);
          metric.uom = SeriesMetricFormatMap['series:units:price:full'];
        }
        break;
      default:
        throw new Error('not implmented');
    }

    this.seriesCache.set(tag, metric);
    return metric;
  }
}
