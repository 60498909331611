import { ArrowPathIcon } from '@heroicons/react/24/outline';

import { useTranslations } from '@keymono/i18n';
import { ButtonLoadingIndicator } from '@keymono/design-system';

import {
  CountrySelectorDropDownFallBack,
  RefetchChoicesButton,
} from './styles';

interface ICountriesListLoaderProps {
  isLoading: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state of the `CountrySelectorInput` component.
 */
export function CountriesListLoader({ isLoading }: ICountriesListLoaderProps) {
  const t = useTranslations('Country');

  return isLoading ? (
    <CountrySelectorDropDownFallBack className="form-input-field">
      <ButtonLoadingIndicator isEnabled className="h-5 w-5" />
      <p>{t('loadingMessage')}</p>
    </CountrySelectorDropDownFallBack>
  ) : null;
}

interface INoCountriesLoadedYetFallbackProps {
  error?: string;
  isLoading: boolean;
  onRefetchCountries: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This isolates the negative case of unloaded countries to show either a loading
 * fallback or empty state. This makes sure that `CountrySelectorInput` is
 * rendered with actual countries items.
 */
export function NoCountriesLoadedYetFallback({
  error,
  isLoading,
  onRefetchCountries,
}: INoCountriesLoadedYetFallbackProps) {
  const t = useTranslations('Country');

  return isLoading ? (
    <div>
      <CountriesListLoader isLoading={isLoading} />
      {error ? ( // render error message and retry button incase of network failure
        <div>
          <RefetchChoicesButton
            type="button"
            onClick={onRefetchCountries}
            className="form-input-field country-input mt-1"
          >
            <ArrowPathIcon className="h-4 w-4" />
            {t('countryRetry')}
          </RefetchChoicesButton>
          <div className="error-message">{error}</div>
        </div>
      ) : null}
    </div>
  ) : null;
}
