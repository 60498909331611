import { TErrorInFields, IFieldErrorRaw } from '../types';

/**
 * Simple utility function that captures errors from the backend and formats
 * them into a single descriptive string.
 * TODO: Move this error util to the response interceptor
 */
export function formatBackendErrorsFromResponse(errorData: IFieldErrorRaw) {
  /**
   * Make the fields from the error objects objects.
   */
  let errors: Partial<TErrorInFields> = {};

  const entries = Object.entries(errorData);

  entries.forEach((entry) => {
    const key = entry[0] as keyof IFieldErrorRaw;
    const value = entry[1] as string | string[];

    if (typeof value === 'string') {
      errors = { [key]: value };
    } else {
      errors = { ...errors, [key]: value?.join(' ') };
    }
  });

  return errors;
}
