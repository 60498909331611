import { useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';
import { ISignUpData } from '../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the sign up mutation keys in react query cache.
 */
const MUT_SIGNUP_USER = 'signup-mutation-key';

interface IUseSignUpOptions {
  onSuccessCallback: (signUpData: ISignUpData) => void;
}

/**
 *  Mutation to gracefully handle sign up requests
 */
export const useSignUpMutation = ({ onSuccessCallback }: IUseSignUpOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_SIGNUP_USER],
    networkMode: 'always',
    retry: false,
    mutationFn: USER_ACCOUNTS_API.signUpUser,
    onSuccess: (response) => {
      onSuccessCallback(response?.data.data);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Sign Up Attempt',
        message: err.message,
      });
      return err;
    },
  });

  return {
    error,
    isLoading,
    submitSignUpEmail: mutate,
  };
};
