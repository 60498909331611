import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';
import { FormFeedbackSection, LogoKeymono } from '@keymono/design-system';

import { FormLinkCTA } from '../../components/FormLinkCTA';

import { FormContainer } from '../styles';

/**
 * -----------------------------------------------------------------------------
 * This renders a fallback UI on the email verification reset screen when no
 * token is found.
 */
export function NoTokenForEmailVerification() {
  const t = useTranslations('EmailVerification');
  const tCommon = useTranslations('Common');

  return (
    <FormContainer>
      <div className="form-responsive-wrapper">
        <div>
          <Link
            href="/"
            className="my-8 hidden w-auto sm:inline-flex"
            aria-label={tCommon('logoAriaLabel')}
            title={tCommon('logoAriaLabel')}
          >
            <LogoKeymono />
          </Link>
          <div className="form-header flex">
            <h1 className="grow">{t('title')}</h1>
          </div>
          <FormFeedbackSection
            isFormSubmitting={false}
            type="error"
            message={t('noTokenAlert.formFeedback.message')}
            description={t('noTokenAlert.formFeedback.description')}
          />
        </div>
        <div>
          <FormLinkCTA
            href="/"
            title={t('noTokenAlert.continueCTA.title')}
            subTitle={t('noTokenAlert.continueCTA.subTitle')}
          />
        </div>
      </div>
    </FormContainer>
  );
}
