import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';

import { IFetchAllIntegrationsData } from '../types';
import { ORGANIZATION_API } from '../organizationAPIClient';

export const QUERY_FETCH_ALL_INTEGRATIONS_KEY = 'fetch-all-integrations-key';

interface IUseAllIntegrationsOptions {
  onSuccessCallback?: (orgData: IFetchAllIntegrationsData) => void;
}

/**
 * This fetches a list of integrations available to for setup by any org.
 */
export const useAllIntegrationsQuery = ({
  onSuccessCallback,
}: IUseAllIntegrationsOptions) => {
  const { data, isLoading, isError, error, isPaused, refetch } = useQuery({
    queryKey: [QUERY_FETCH_ALL_INTEGRATIONS_KEY],
    networkMode: 'always',
    queryFn: () => ORGANIZATION_API.fetchAllIntegrations(),
    onSuccess: (response) => {
      onSuccessCallback?.(response);
    },
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isLoading,
    isError,
    isPaused,
    refetch,
  };
};
