import { useState } from 'react';
import Image from 'next/image';

import { IIntegration } from '@keymono/apis';
import { useTranslations } from '@keymono/i18n';
import { CardSecButton } from '@keymono/design-system';

import { CardWrapper, CardBodyWrapper, CardThumbnailWrapper } from '../styles';

import { NewIntegrationCardHeader } from './NewIntegrationCardHeader';
import { AddIntegrationSidePanel } from './AddIntegrationSidePanel';

interface INewIntegrationCardItemProps {
  integration: IIntegration;
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a new item integration that can be added to the the organization.
 * - This is split from `AllIntegrationsListItems`.
 */
export function NewIntegrationCardItem({
  orgId,
  integration,
}: INewIntegrationCardItemProps) {
  const t = useTranslations('Integrations');

  const { briefDescription, name, logoUrl } = integration;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <CardWrapper
      aria-label={t('addIntegration')}
      className="group"
      role="button"
      tabIndex={0}
    >
      <CardBodyWrapper>
        <NewIntegrationCardHeader integration={integration} />
        <CardThumbnailWrapper>
          <Image
            alt={name}
            className="thumbnail"
            src={logoUrl}
            height={600}
            width={600}
          />
        </CardThumbnailWrapper>
        <p
          className="
          flex flex-1 flex-col border-t border-gray-50 px-4 pb-2 pt-4 text-center text-xs
          text-gray-500 transition-all duration-500 ease-linear
        "
        >
          {briefDescription}
        </p>

        <div
          className="
          flex w-full items-end justify-center gap-2 px-4 pt-4 text-sm transition-all
          duration-200 ease-linear
        "
        >
          <CardSecButton onClick={handleOpenModal}>{t('add')}</CardSecButton>

          <AddIntegrationSidePanel
            orgId={orgId}
            integration={integration}
            isModalVisible={isModalVisible}
            onClosePanel={handleCloseModal}
          />
        </div>
      </CardBodyWrapper>
    </CardWrapper>
  );
}
