import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuthStore } from '@keymono/services';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';

import { IDeclineOrgInvitationsData } from '../types';

import { ORGANIZATION_API } from '../organizationAPIClient';
import { QUERY_USER_DETAILS } from '../../user-accounts/query-hooks/useUserDetailsQuery';

const MUT_DECLINE_INVITATION = 'decline-invitation-mutation-key';

interface IUseDeclineInvitationOptions {
  id: string;
  onSuccessCallback?: (sendInviteData: IDeclineOrgInvitationsData) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This mutation declines an existing invitation to join an org.
 */
export const useDeclineInvitationMutation = ({
  id,
  onSuccessCallback,
  onErrorCallback,
}: IUseDeclineInvitationOptions) => {
  const { activeSession } = useAuthStore();
  const reactQueryClient = useQueryClient();
  const userId = activeSession?.userId || '';

  const { mutate, isLoading, data, error } = useMutation({
    mutationKey: [MUT_DECLINE_INVITATION, id],
    networkMode: 'always',
    retry: false,
    mutationFn: ORGANIZATION_API.declineOrganizationInvitation,
    onSuccess: (responseData) => {
      reactQueryClient.invalidateQueries([QUERY_USER_DETAILS, userId]);
      onSuccessCallback?.(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Declining the Organization Invitation',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    data,
    declineOrganizationInvitation: mutate,
  };
};
