import Link from 'next/link';
import Image from 'next/image';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
// import { generateProfileImageURL } from '@keymono/apis';

/**
 * `IProfileInfoProps` is type definition for ProfileInfo component with
 * extended optional support for direction props from `TIsRTL`.
 */
interface IProfileInfoProps {
  /**
   * The accountId from the path params
   */
  accountId: number;
  /**
   * Email to be shown in the card. This is the email of the user and it accepts
   * one more.
   */
  email: string;
  /**
   * Title of the profile info card. This is the title or name/company of the user.
   */
  profileName: string;
  /**
   * Icon of the profile info card. This is user profile image endpoint
   */
  imageUrl: string;
  /**
   * Function to perform the logout operation.
   */
  onLogOut: () => void;
}

/**
 * -----------------------------------------------------------------------------
 *  Profile Info component is used to render the user information.
 *
 * @param IProfileInfoProps
 *
 * @returns return a complete UI for showing the profile card.
 */
export function ProfileInfo({
  accountId,
  email,
  onLogOut,
  imageUrl,
  profileName,
}: IProfileInfoProps): JSX.Element {
  const navPathWithSession = `/${accountId}/profile`;

  return (
    <div className="flex shrink-0 flex-col border-t border-t-gray-100 border-opacity-5 px-16 py-4 lg:px-0">
      <Link href={navPathWithSession} className="group block w-full shrink-0">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <Image
              alt={`Profile picture for ${profileName}`}
              className="inline-block h-9 w-9 shrink-0 rounded-full"
              height={32}
              // placeholder="blur"
              src={imageUrl || ''}
              width={32}
            />
          </div>
          <div className="mx-2">
            <p className="text-sm font-medium text-gray-100 group-hover:text-red-200">
              {profileName}
            </p>
            <p className="text-xs font-medium text-gray-300 group-hover:text-red-200">
              {email}
            </p>
          </div>
        </div>
      </Link>
      <button
        type="button"
        className="
         form-link-action group my-4 inline-flex self-start px-5 font-normal text-white transition-all
          duration-500 ease-in-out
        "
        onClick={onLogOut}
      >
        <ArrowRightOnRectangleIcon
          className="
            -ml-4 mr-4 h-5 w-5 text-gray-100
            group-hover:text-red-800 rtl:-mr-4 rtl:ml-4
          "
          aria-hidden="true"
        />
        <span>Logout</span>
      </button>
    </div>
  );
}
