const KEYMONO_API_ENVS = [
  'http://localhost:8080/api',
  'https://services.dev.keymono.com/api',
  'https://services.keymono.com/api',
] as const;
export type KeymonoApiEnv = typeof KEYMONO_API_ENVS[number];

const KEYMONO_API_HOSTS: { [key in KeymonoApiEnv]: string } = {
  'http://localhost:8080/api': 'localhost:8080',
  'https://services.dev.keymono.com/api': 'services.dev.keymono.com',
  'https://services.keymono.com/api': 'services.keymono.com',
};

export function keymonoApiHost(env: KeymonoApiEnv) {
  return KEYMONO_API_HOSTS[env];
}
