interface IColItemTextProps {
  text: string;
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Col Item component that renders text.
 */
export function ColItemText({ text, className = '' }: IColItemTextProps) {
  return <div className={`text-gray-700 ${className}`}>{text}</div>;
}
