const TODAY_TIME = new Date().getTime();

/**
 * This compares the retrieves the expiry time and compares it with the current date.
 */
export const checkIfTokenIsExpired = (expiryDate: string) => {
  const expiryTimestamp = Date.parse(expiryDate);

  return TODAY_TIME >= expiryTimestamp;
};
