import tw, { styled } from 'twin.macro';

export const InvitationsListLoaderTableCell = styled.td`
  ${tw`
    whitespace-nowrap
    py-4
    pe-3
    ps-4
    text-sm
    sm:ps-0
  `};
`;
