import { IllustrationCreateOrg } from '@keymono/design-system';

import { CreateOrganizationCTA } from '../organization-switcher';

interface IInvitationBodyProps {
  accountId: string | number;
}

/**
 * TODO: Add doc comments and translate this content.
 */
export function CreateOrgInvitation({ accountId }: IInvitationBodyProps) {
  return (
    <div
      className="
        absolute left-0 top-0 flex min-h-full w-full flex-1 flex-col
        items-center justify-center overflow-hidden px-4 py-8 text-center
      "
    >
      <IllustrationCreateOrg className="w-[90%] sm:w-full" />
      <h1 className="mb-4 mt-8 text-xl font-light text-gray-900">
        Welcome to Keymono Console
      </h1>
      <p className="my-2 max-w-xl text-sm font-medium text-teal-800">
        To begin, let&apos;s establish your organization - the fundamental basis
        for your account.
      </p>
      <p className="max-w-xl text-sm text-teal-700">
        Here, you will manage Products, Orders, Inventory, and invite other
        members to collaborate with you.
      </p>
      <div className="my-8">
        <CreateOrganizationCTA accountId={accountId} />
      </div>
    </div>
  );
}
