import { ReactNode } from 'react';

interface OrgOverviewHeaderBaseProps {
  title: string;
  children: ReactNode;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the organization header base component
 * Renders the section container, title, background color and padding
 */
export function OrgOverviewHeaderBase({
  title,
  children,
}: OrgOverviewHeaderBaseProps) {
  return (
    <section aria-labelledby="profile-overview-title" title={title}>
      <div className="overflow-hidden rounded bg-white shadow-sm shadow-gray-50">
        <h2 className="sr-only" id="profile-overview-title">
          {title}
        </h2>
        <div className="bg-white p-6">{children}</div>
      </div>
    </section>
  );
}
