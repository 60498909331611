import { IllustrationTeamworkPullingUp } from '@keymono/design-system';

interface INoPendingInvitationProps {
  title: string;
  message: string;
}

/**
 * -----------------------------------------------------------------------------
 * This is displayed when there are no pending invitations for the current org.
 */
export function NoPendingInvitation({
  title,
  message,
}: INoPendingInvitationProps) {
  return (
    <div className="flow-root h-full">
      <div className="-mx-4 flex h-full flex-col justify-center overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="flex min-w-full flex-col items-center py-2 text-center sm:px-6 lg:px-8">
          {/* // TODO: replace this with a better illustration  */}
          <IllustrationTeamworkPullingUp className="h-60 w-[50%] sm:w-[30%]" />
          <h2 className="max-w-xl py-4 text-2xl text-gray-600">{title}</h2>
          <p className="w-[60%] max-w-xl text-sm text-gray-600">{message}</p>
        </div>
      </div>
    </div>
  );
}
