/**
 * -----------------------------------------------------------------------------
 * These endpoints provide support for app-wider user authentication & management
 * -----------------------------------------------------------------------------
 */

/**
 * Endpoint for handling user `login` requests.
 */
export const signUpUrl = () => '/users/verify-email/signup';

/**
 * Endpoint for handling user `registration` requests.
 */
export const registerUrl = () => '/users';

/**
 * Endpoint for handling user `login` requests.
 */
export const loginUrl = () => '/users/login';

/**
 * This `identity service` generates the `refreshToken` if expired.
 */
export const refreshTokenUrl = () => '/users/token';

/**
 * Endpoint for handling user `logout` requests for a single user session.
 */
export const logoutUrl = () => '/users/logout';

/**
 * Endpoint for handling user `logout` requests for multiple sessions.
 */
export const logoutAllUrl = () => '/users/logout-all';

/**
 * Endpoint for handling user `forgot-password` email rest link requests.
 */
export const forgotPasswordUrl = () => '/users/verify-email/reset-password';

/**
 * Endpoint for handling user `password-reset` requests.
 */
export const passwordResetUrl = () => '/users/set-password';

/**
 * Endpoint for handling user `login` requests.
 */
export const verifyEmailUrl = () => '/users/update-email';

/**
 * Endpoint for retrieving details of the logged in user/me.
 */
export const fetchUserDetailsUrl = () => '/users/me';

/**
 * Endpoint for patching and updating the user details.
 */
export const updateUserDetailsUrl = (userId: string) => `/users/${userId}`;
