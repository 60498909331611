import { useQuery } from '@tanstack/react-query';
import { IOrganizationDetails } from '@keymono/shared-types';

import { IApiError } from '../../../../types';

import { ORGANIZATION_API } from '../organizationAPIClient';

/**
 * This is to track the fetched org details in app memory.
 */
export const QUERY_FETCH_ORG_DETAILS = 'fetch-org-details-query-key';

interface IUseOrganizationDetailsOptions {
  orgId: string;
  onSuccessCallback?: (orgData: IOrganizationDetails) => void;
}

/**
 * This performs a get request that retrieves the details of a specific organization.
 */
export const useOrganizationDetailsQuery = ({
  orgId,
  onSuccessCallback,
}: IUseOrganizationDetailsOptions) => {
  const { data, isLoading, isError, error, isPaused, refetch } = useQuery({
    queryKey: [QUERY_FETCH_ORG_DETAILS, orgId],
    networkMode: 'always',
    enabled: !!orgId, // Don't fetch if org id is not loaded.
    queryFn: () => ORGANIZATION_API.fetchOrgDetails(orgId),
    onSuccess: (response) => {
      onSuccessCallback?.(response);
    },
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isLoading,
    isError,
    isPaused,
    refetch,
  };
};
