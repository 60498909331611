import { useRouter } from 'next/router';
import { useOrderDetailsQuery } from '@keymono/apis';

import { OrderDetailsFallback } from './OrderDetailsFallback';
import { OrderDetailsHeader } from './OrderDetailsHeader';
import {
  OrderCustomerSummary,
  OrderProductsSummary,
} from './OrderDetailsCards';

/**
 * -----------------------------------------------------------------------------
 * This component renders all the details of an order.
 */
export function OrderDetails() {
  const { query } = useRouter();

  const orgId = (query.organizationId || '') as string;
  const orderId = (query.orderId || '') as string;

  const {
    data: orderData,
    isLoading,
    error,
    refetch,
  } = useOrderDetailsQuery({
    orgId,
    orderId,
  });

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !orderData) {
    return (
      <OrderDetailsFallback
        isLoading={isLoading}
        error={error}
        onRetryAction={{ onClick: handleRetryOnFail }}
      />
    );
  }

  const {
    extId,
    channelTimestamp,
    fulfillmentStatus,
    fulfillmentStatusLabel,
    items,
    orderStatus,
    orderStatusLabel,
    shippingDetails,
    totalAmount,
  } = orderData;

  const { shippingName } = shippingDetails;

  return (
    <div className="flex flex-col gap-y-8">
      <OrderDetailsHeader
        extId={extId}
        orderStatus={orderStatus}
        orderStatusLabel={orderStatusLabel}
        channelTimestamp={channelTimestamp}
      />
      <div className="grid grid-cols-1 items-start gap-5 transition-all duration-500 xl:grid-cols-10">
        <OrderProductsSummary
          className={shippingName ? 'xl:col-span-6' : 'xl:col-span-10'}
          fulfillmentStatus={fulfillmentStatus}
          fulfillmentStatusLabel={fulfillmentStatusLabel}
          items={items}
          totalAmount={totalAmount}
        />
        {shippingName ? (
          <OrderCustomerSummary
            className="xl:col-span-4"
            shippingDetails={shippingDetails}
          />
        ) : null}
      </div>
    </div>
  );
}
