import { useState } from 'react';
import Image from 'next/image';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import {
  IAddOrgIntegrationData,
  IApiError,
  IChoice,
  IIntegration,
  TIntegrationAuthenticationFields,
  useAddIntegrationMutation,
} from '@keymono/apis';
import { useTranslations } from '@keymono/i18n';
import {
  CardSubmitButton,
  CardSecButton,
  ChoicesSelectDropdown,
  TextInputField,
  useForm,
  IUIError,
} from '@keymono/design-system';

import { SidePanelFormFeedback } from './SidePanelFormFeedback';

/**
 * This utility generate the from field keys from the required server auth params.
 */
const generateFormFields = (
  initFields: IIntegration['authenticationFields']
) => {
  let fields = {};

  initFields.forEach((field) => {
    fields = { ...fields, [field.key]: '' };
  });

  return fields;
};

interface IIntegrationsSidePanelContentProps {
  orgId: string;
  integration: IIntegration;
  onClosePanel: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * The renders the content of the side panel that opens when the user clicks,
 * including the header, form fields and the actions buttons(cancel+submit).
 */
export function IntegrationsSidePanelContent({
  integration,
  onClosePanel,
  orgId,
}: IIntegrationsSidePanelContentProps) {
  const t = useTranslations('Integrations');

  const { authenticationFields } = integration || [];
  const [formError, setFromError] = useState<IUIError | null>(null);

  const { id } = integration;
  const [hasError, setHasError] = useState<IApiError | null>(null);

  const handleClearFormError = () => {
    setFromError(null);
  };

  const handleOnClosePanel = () => {
    handleClearFormError();
    onClosePanel();
  };

  // Get these from the integration object
  const [formFields, setFormFields] =
    useState<TIntegrationAuthenticationFields | null>(null);

  const handleOnAddIntegrationSuccess = (
    integrationData: IAddOrgIntegrationData
  ) => {
    if (window) {
      window?.open(integrationData.redirectUrl, '_blank');
    }

    onClosePanel();
  };

  const handleOnAddIntegrationError = (error: IApiError) => {
    setHasError(error);
  };

  const { isLoading, addOrganizationIntegration, error } =
    useAddIntegrationMutation({
      orgId,
      integrationId: id,
      onSuccessCallback: handleOnAddIntegrationSuccess,
      onErrorCallback: handleOnAddIntegrationError,
    });

  const errorFields = error?.errorFields;

  const handleAddIntegration = (
    formFieldsData: TIntegrationAuthenticationFields
  ) => {
    setFormFields(formFieldsData);

    addOrganizationIntegration({
      orgId,
      integrationId: id,
      authenticationFields: formFieldsData,
    });
  };

  const handleRetryOnError = () => {
    setHasError(null);

    if (formFields) {
      handleAddIntegration(formFields);
    }
  };

  const handleCancel = () => {
    setHasError(null);
    setFormFields(null);
  };

  const handlePostDetails = (values: TIntegrationAuthenticationFields) => {
    const isValid = Object.values(values).every((value) => value);

    if (isValid) {
      handleAddIntegration(values);
      handleClearFormError();
    } else {
      setFromError({
        message: t('feedback.missingFormFieldError'),
        description: t('feedback.missingFormFieldDescriptionError'),
      });
    }
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: generateFormFields(authenticationFields) || {},
    onSubmitCb: handlePostDetails,
  });

  return (
    <form
      onSubmit={handleOnSubmitForm}
      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
    >
      <div className="flex-1">
        {/* Header */}
        <div className="flex flex-row gap-4 bg-gray-50 px-4 py-6 sm:px-6">
          <div
            className="
              mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center
              rounded-full bg-gray-100 p-2 sm:mx-0 sm:h-24  sm:w-24
            "
          >
            <Image
              src={integration.logoUrl}
              alt={integration.name}
              className="h-full w-full object-contain object-center sm:h-full sm:w-full"
              width={120}
              height={120}
            />
          </div>
          <div className="flex items-center justify-between space-x-3">
            <div className="space-y-1">
              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                {integration.name}
              </Dialog.Title>
              <p className="text-sm text-gray-600">
                <span className="hidden sm:flex">
                  {integration.description}
                </span>
                <span className="flex sm:hidden">
                  {integration.briefDescription}
                </span>
              </p>
            </div>
            <div className="flex h-7 items-center">
              <button
                type="button"
                className="text-gray-400 hover:text-gray-500"
                onClick={handleOnClosePanel}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <SidePanelFormFeedback
          formError={formError}
          hasError={hasError}
          isLoading={isLoading}
          integration={integration}
          onClearError={handleCancel}
          onRetryRequest={handleRetryOnError}
        />

        {/* Divider container */}
        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <div className="mt-2">
              <section className="pt-4 text-sm text-gray-500">
                <div className="sm:col-span-3">
                  {authenticationFields?.map((field) => {
                    const newValue = values[field.key]
                      ? field?.choices?.[0]
                      : null;

                    const onHandleChange = (newChoice: IChoice) => {
                      handleOnInputChange({
                        name: field.key,
                        value: newChoice.key,
                      });
                    };

                    return (
                      <div key={field.key}>
                        {field.field_type === 'text' ? (
                          <TextInputField
                            className="
                              mt-2 space-y-2 px-6 sm:grid sm:grid-cols-3 sm:gap-4
                              sm:space-y-0 sm:py-5
                            "
                            classNameInput="block w-full"
                            classNameInputContainer="flex flex-col flex-1 sm:col-span-2"
                            classNameLabel="block text-start self-center"
                            type="text"
                            error={
                              errorFields?.[field.key] ??
                              errors?.[field.key] ??
                              ''
                            }
                            label={field.label}
                            name={field.key}
                            onBlurCallback={handleOnBlur}
                            onChangeCallback={handleOnInputChange}
                            placeholder={field.label}
                            onError={handleOnError}
                            value={values[field.key]}
                          />
                        ) : (
                          <div>
                            <ChoicesSelectDropdown
                              classNameContainer="
                                space-y-2 px-6 sm:grid sm:grid-cols-3 sm:gap-4
                                sm:space-y-0 sm:py-5 mt-2 sm:mt-0
                              "
                              className="
                                form-input-field sm:text-md relative block
                                h-10 w-full truncate border-gray-200 bg-gray-50/80
                                !py-2 text-gray-500 rtl:pr-2 sm:h-auto
                              "
                              classNameInput="block text-sm ps-1"
                              classNameInputContainer="flex-1 sm:col-span-2"
                              classNameLabel="block text-start self-center"
                              label={field.label}
                              choices={field.choices ?? []}
                              onChange={onHandleChange}
                              placeholder={t('selectOption')}
                              selectedChoice={newValue || null}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-end gap-4">
          <CardSecButton onClick={handleOnClosePanel}>
            {t('cancel')}
          </CardSecButton>
          <CardSubmitButton
            label={t('addIntegration')}
            loadingLabel={t('addIntegrationLoading')}
          />
        </div>
      </div>
    </form>
  );
}
