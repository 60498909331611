import { IOrganizationDetails } from '@keymono/shared-types';

import {
  IAddOrgIntegrationDataRaw,
  IAddOrgIntegrationData,
  IFetchAllIntegrationsDataRaw,
  IFetchAllIntegrationsData,
  IFetchALLOrganizationsDataRaw,
  IFetchALLOrganizationsData,
  IFetchOrgContractsData,
  IFetchOrgContractsDataRaw,
  IFetchOrgIntegrationsDataRaw,
  IFetchOrgIntegrationsData,
  IFetchOrgInvitationsListDataRaw,
  IFetchOrgInvitationsListData,
  IFetchOrgMembersListData,
  IFetchOrgMembersListDataRaw,
  IFetchOrgDetailsDataRaw,
  IReauthorizeOrgIntegrationDataRaw,
  IReauthorizeOrgIntegrationData,
} from './types';

/**
 * This transforms the members list data to add more useful keys and
 */
export function formatMembersResponseData(
  data: IFetchOrgMembersListDataRaw
): IFetchOrgMembersListData {
  // TODO: Also transform the `meta` data later since this is indeed IMetaRaw.
  const { results, meta } = data;

  const members = results.map(
    ({
      user,
      user_first_name: firstName,
      user_last_name: lastName,
      user_email: email,
      user_profile_image_url: profileImageUrl,
      created_at: createdAt,
      type_label: typeLabel,
      ...rest
    }) => ({
      ...rest,
      userId: user,
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`.trim(),
      email,
      profileImageUrl,
      typeLabel,
      createdAt,
    })
  );

  return { meta, members };
}

/**
 * This transforms the org invitations list data to add an identifier key
 */
export function formatOrgInvitationsResponseData(
  data: IFetchOrgInvitationsListDataRaw
): IFetchOrgInvitationsListData {
  const { results, meta } = data;

  const invitations = results.map(({ ...inviteData }) => ({
    ...inviteData,
    inviteType: 'org' as const, // Explicitly state that inviteType is 'org',
  }));

  return { meta, invitations };
}

/**
 * This transforms the organization details data to a more UI friendly format.
 */
export function formatOrganizationDetailsResponseData(
  data: IFetchOrgDetailsDataRaw
): IOrganizationDetails {
  const {
    legal_name: legalName,
    unique_name: uniqueName,
    logo_url: logoUrl,
    address_line1: addressLine1,
    address_line2: addressLine2,
    address_city: addressCity,
    address_state: addressState,
    address_country: addressCountry,
    address_country_label: addressCountryLabel,
    address_zip: addressZip,
    created_at: createdAt,
    ...rest
  } = data;

  return {
    ...rest,
    legalName,
    uniqueName,
    logoUrl,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressCountryLabel,
    addressZip,
    createdAt,
  };
}

/**
 * This transforms the All Organizations data to a more UI friendly format.
 * Loops over each organization object in the results array
 * Converts all keys except 'id' and 'name' to camelCase.
 * We are omitting the 'created_at' key, since not using it in the UI for now.
 */
export function formatOrganizationsResponseData(
  data: IFetchALLOrganizationsDataRaw
): IFetchALLOrganizationsData {
  const { results, meta } = data;
  const organizations = results.map(
    ({
      legal_name: legalName,
      unique_name: uniqueName,
      logo_url: logoUrl,
      address_line1: addressLine1,
      address_line2: addressLine2,
      address_city: addressCity,
      address_state: addressState,
      address_country: addressCountry,
      address_country_label: addressCountryLabel,
      address_zip: addressZip,
      ...rest
    }) => ({
      ...rest,
      legalName,
      uniqueName,
      logoUrl,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressCountry,
      addressCountryLabel,
      addressZip,
    })
  );

  return { organizations, meta };
}

/**
 * This transforms the organization contract details data to a more UI friendly format.
 */
export function formatOrganizationContractsResponseData(
  data: IFetchOrgContractsDataRaw
): IFetchOrgContractsData {
  // TODO: Also transform the `meta` data later since this is indeed IMetaRaw.
  const { results, meta } = data;

  const contracts = results.map(
    ({
      id,
      plan_name: name,
      document: documentUrl,
      start_date: startDate,
      end_date: endDate,
      plan_success_fee: successFee,
      plan_success_fee_type: successFeeType,
    }) => ({
      id,
      name,
      documentUrl,
      startDate,
      endDate,
      successFee,
      successFeeType,
    })
  );

  return { meta, contracts };
}

/**
 * This transforms the `all-integrations` list data.
 */
export function formatAllIntegrationsResponseData(
  data: IFetchAllIntegrationsDataRaw
): IFetchAllIntegrationsData {
  // TODO: Also transform the `meta` data later since this is indeed IMetaRaw.
  const { results, meta } = data;

  const integrations = results.map(
    ({
      brief_description: briefDescription,
      authentication_fields: authenticationFields,
      category_label: categoryLabel,
      description_html: descriptionHtml,
      logo_url: logoUrl,
      ...rest
    }) => ({
      ...rest,
      authenticationFields,
      briefDescription,
      categoryLabel,
      descriptionHtml,
      logoUrl,
    })
  );

  return { meta, integrations };
}

/**
 * This transforms the `org-integrations` list data.
 */
export function formatOrgIntegrationsResponseData(
  data: IFetchOrgIntegrationsDataRaw
): IFetchOrgIntegrationsData {
  // TODO: Also transform the `meta` data later since this is indeed IMetaRaw.
  const { results, meta } = data;

  const integrations = results.map(
    ({
      ext_id: extId,
      ext_label: extLabel,
      id,
      integration: integrationId,
      integration_name: integrationName,
      integration_category: integrationCategory,
      integration_category_label: integrationCategoryLabel,
      integration_description: integrationDescription,
      integration_brief_description: integrationDescriptionBrief,
      integration_logo_url: integrationLogoUrl,
      status,
      status_label: statusLabel,
      verification_error: verificationError,
      verification_error_label: verificationErrorLabel,
    }) => ({
      extId,
      extLabel,
      id,
      integrationId,
      integrationCategory,
      integrationCategoryLabel,
      integrationDescription,
      integrationDescriptionBrief,
      integrationLogoUrl,
      integrationName,
      status,
      statusLabel,
      verificationError,
      verificationErrorLabel,
    })
  );

  return { meta, integrations };
}

/**
 * This transforms the add-integration response to extract out the redirect url.
 */
export function formatAddIntegrationResponseData(
  data: IAddOrgIntegrationDataRaw
): IAddOrgIntegrationData {
  const { redirect_url: redirectUrl } = data.authentication;

  return { redirectUrl };
}

/**
 * This transforms the reauthorize-integration response to extract out the redirect url.
 */
export function formatReauthorizeIntegrationResponseData(
  data: IReauthorizeOrgIntegrationDataRaw
): IReauthorizeOrgIntegrationData {
  const { redirect_url: redirectUrl } = data;

  return { redirectUrl };
}
