export interface IFooterAction {
  /**
   * The callback to be triggered when the action button is pressed.
   */
  onClick: () => void;
  /**
   * The label to be shown on the action button.
   */
  label: string;
}

interface IFooterSessionsListItemsProps {
  /**
   * An array of label/onClick objects to be rendered in the footer.
   */
  actions: IFooterAction[];
}

/**
 * -----------------------------------------------------------------------------
 * This renders additional helpful links in the bottom of the sessions profiles
 * list for options like privacy policy, help, support or any other meaningful
 * contextual elements.
 *
 * TODO: Improve the `focus` state of the footer actions.
 *
 * @returns JSX.Element
 */
export function FooterSessionsListItems({
  actions,
}: IFooterSessionsListItemsProps) {
  return (
    <div className="-mt-px flex divide-x divide-gray-100 py-4">
      {actions.map((action) => (
        <div key={action.label} className="flex w-0 flex-1">
          <button
            type="button"
            onClick={action.onClick}
            className="
            relative inline-flex w-0 flex-1 items-center justify-center
            text-xs text-gray-500 hover:text-red-800
          "
          >
            <span>{action.label}</span>
          </button>
        </div>
      ))}
    </div>
  );
}
