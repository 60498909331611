/* eslint-disable indent */

import { useState } from 'react';
import { MapPinIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { CardWithActions } from '@keymono/design-system';
import { IAddressInfo } from '@keymono/shared-types';

import { AddressCard } from './AddressCard';
import { AddressCardEdit } from './AddressCardEdit';

interface IOrgAddressInformationCardProps {
  address: IAddressInfo;
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the org's address information and allows for editing.
 */
export function OrgAddressInformationCard({
  address,
  orgId,
}: IOrgAddressInformationCardProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleUpdateSuccess = () => {
    setIsEditing(false);
    setIsSuccessVisible(true);
  };

  const handleOnHideSuccessPopup = () => {
    setIsSuccessVisible(false);
  };

  /**
   * Main icon feature that distinguishes the card info.
   */
  const cardIcon = {
    Icon: MapPinIcon,
    className: 'text-red-700 bg-red-50',
  };

  /**
   * Actions to perform operations on the card like edit or cancel.
   */
  const mainCardAction = isEditing
    ? {
        Icon: XMarkIcon,
        title: 'Exit edit mode',
        onClick: handleCancelEdit,
      }
    : {
        Icon: PencilIcon,
        title: 'Edit the address information',
        onClick: handleEdit,
      };

  return (
    <CardWithActions
      actions={isSuccessVisible ? [] : [mainCardAction]}
      isSuccessVisible={isSuccessVisible}
      onHideSuccessPopup={handleOnHideSuccessPopup}
      cardIcon={cardIcon}
    >
      {!isEditing ? (
        <AddressCard address={address} />
      ) : (
        <AddressCardEdit
          orgId={orgId}
          onUpdateSuccess={handleUpdateSuccess}
          address={address}
          onExitEditMode={handleCancelEdit}
        />
      )}
    </CardWithActions>
  );
}
