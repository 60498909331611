import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';

import { IReauthorizeOrgIntegrationData } from '../types';
import { ORGANIZATION_API } from '../organizationAPIClient';

import { QUERY_FETCH_ORG_INTEGRATIONS_KEY } from './useOrgIntegrationsQuery';

const MUT_REAUTHORIZE_ORG_INTEGRATION =
  'reauthorize-org-integration-mutation-key';

interface IUseReauthorizeOrgIntegrationOptions {
  id: string;
  orgId: string;
  onSuccessCallback?: (
    reauthorizedData: IReauthorizeOrgIntegrationData
  ) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This mutation reauthorizes the selected integration to the org.
 */
export const useReauthorizeOrgIntegrationMutation = ({
  id,
  orgId,
  onSuccessCallback,
  onErrorCallback,
}: IUseReauthorizeOrgIntegrationOptions) => {
  const reactQueryClient = useQueryClient();
  const { mutate, isLoading, data, error } = useMutation({
    mutationKey: [MUT_REAUTHORIZE_ORG_INTEGRATION, id, orgId],
    retry: false,
    mutationFn: ORGANIZATION_API.reauthorizeOrganizationIntegration,
    onSuccess: (responseData) => {
      reactQueryClient.invalidateQueries([
        QUERY_FETCH_ORG_INTEGRATIONS_KEY,
        orgId,
      ]);
      onSuccessCallback?.(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Reauthorizing the Organization Integration',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    data,
    reauthorizeOrganizationIntegration: mutate,
  };
};
