import { useRouter } from 'next/router';

import { useAuthStore } from '@keymono/services';
import { IUser } from '@keymono/shared-types';

interface IRequestRedirectOnLoginOptions {
  /**
   * The `accountId` from the route query, or the session index of the active
   * account.
   */
  sessionIndex?: number | string;
  /**
   * The authed user. Note we only need the `membership` object for now but we
   * might require additional info like `invitation` for future redirection.
   */
  user?: IUser;
}

interface IRequestNavigationToOrgOptions {
  sessionIndex?: number | string;
  /**
   * The `org` id from the membership object or the `id` from the org creation.
   */
  orgId: string;
  /**
   * Whether user should navigate to the org details
   */
  goToDetails?: boolean;
}

interface IUseHasOrganizationCheckReturn {
  /**
   * This callback determines where to route the user based on the membership
   * info on the active session.
   */
  requestNavigationToOrg: (options: IRequestNavigationToOrgOptions) => void;
  requestRedirectToLoginScreen: () => void;
  requestRedirectOnLogin: (options?: IRequestRedirectOnLoginOptions) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This hook taps into the active sessions data, and determines whether the
 * session user info has some existing membership over which it redirects them
 * to the respective screen.
 */
export function useMembershipRedirection(): IUseHasOrganizationCheckReturn {
  const { replace } = useRouter();
  const { activeSession, updateActiveOrganization } = useAuthStore();

  const handleRedirectToLoginScreen = () => {
    if (!activeSession) {
      replace('/auth/login');
    }
  };

  const handleRequestNavigationToOrg = (
    options: IRequestNavigationToOrgOptions
  ) => {
    const {
      sessionIndex = activeSession?.index || 0,
      orgId,
      goToDetails = false,
    } = options;

    const userId = activeSession?.userId || '';

    let path = `/${sessionIndex}/org/${orgId}`;

    if (goToDetails) {
      path = `${path}/details`;
    }

    replace(path);

    updateActiveOrganization({
      orgId,
      userId,
    });
  };

  const handleRequestRedirectOnLogin = (
    options?: IRequestRedirectOnLoginOptions
  ) => {
    const { sessionIndex = activeSession?.index || 0, user } = options || {};
    const membership =
      user?.membership || activeSession?.user?.membership || [];
    const invitation =
      user?.invitation || activeSession?.user?.invitation || [];

    if (!membership[0] && !invitation[0]) {
      /**
       * Prompt the user to create an organization
       */
      replace(`/${sessionIndex}/onboarding`);

      return;
    }

    /**
     * If the user has just one organization, navigate to it directly.
     */
    if (membership.length === 1 && invitation.length === 0) {
      handleRequestNavigationToOrg({ orgId: membership[0].org, sessionIndex });
    } else {
      /**
       * Show the organizations list for the user select
       */
      replace(`/${sessionIndex}/org`);
    }
  };

  return {
    requestRedirectOnLogin: handleRequestRedirectOnLogin,
    requestRedirectToLoginScreen: handleRedirectToLoginScreen,
    requestNavigationToOrg: handleRequestNavigationToOrg,
  };
}
