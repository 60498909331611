import tw, { styled } from 'twin.macro';

/**
 * The Contains the base styles of the org list items view components.
 */
export const OrgMembershipListBaseWrapper = styled.div`
  &.has-error {
    .feature-icon {
      ${tw`
        text-red-700
    `};
    }

    h2,
    .feature-title {
      ${tw`
        text-red-900
      `};
    }
  }
`;
