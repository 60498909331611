import { Suspense } from 'react';

import PasswordResetForm, {
  IPasswordResetFormProps,
} from './PasswordResetForm';

export function DynamicPasswordResetForm(props: IPasswordResetFormProps) {
  return (
    <Suspense fallback={<h1 className="text-2xl">...</h1>}>
      <PasswordResetForm {...props} />
    </Suspense>
  );
}
