import { IApiError, IIntegration } from '@keymono/apis';
import { FormFeedbackSection, IUIError } from '@keymono/design-system';

import { NewIntegrationCardItemFallback } from './NewIntegrationCardItemFallback';

interface ISidePanelFormFeedbackProps {
  formError: IUIError | null;
  hasError: IApiError<any> | null;
  isLoading: boolean;
  integration: IIntegration;
  onClearError: () => void;
  onRetryRequest: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback state when the application is loading or fails to
 * add an integration
 */
export function SidePanelFormFeedback({
  formError,
  hasError,
  isLoading,
  integration,
  onClearError,
  onRetryRequest,
}: ISidePanelFormFeedbackProps) {
  const shouldShowFallback = !!hasError || isLoading;

  return shouldShowFallback ? (
    <div
      className={`px-4 py-5 sm:px-6 ${
        shouldShowFallback ? 'border-b border-gray-100' : ''
      }`}
    >
      {shouldShowFallback ? (
        <NewIntegrationCardItemFallback
          isLoading={isLoading}
          error={hasError}
          integration={integration}
          onRetry={onRetryRequest}
          onCancel={onClearError}
        />
      ) : null}

      {formError ? (
        <FormFeedbackSection
          className={`px-4 py-5 sm:px-6 ${
            shouldShowFallback ? 'border-b border-gray-50 ' : ''
          }`}
          type={isLoading ? 'info' : 'error'}
          message={formError?.message || ''}
          messageLoadingText="Integration is being added..."
          description={formError?.description || ''}
          descriptionLoadingText="Please wait..."
          isFormSubmitting={false}
        />
      ) : null}
    </div>
  ) : null;
}
