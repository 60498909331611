import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

import { IChoice, IPagination } from '@keymono/apis';
import {
  IconButton,
  ChoicesSelectDropdownWithSearch,
} from '@keymono/design-system';

interface IOrdersListFooterProps {
  pagination: IPagination;
  handlePageChange: (page: number) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the footer of the orders list table.
 * It contains the pagination information and navigation.
 */
export function OrdersListFooter({
  pagination,
  handlePageChange,
}: IOrdersListFooterProps) {
  const { page, noOfRecords, pageSize } = pagination;

  const totalPages = Math.ceil(noOfRecords / pageSize);
  const currentPageRecordStart = (page - 1) * pageSize + 1;
  const currentPageRecordEnd = Math.min(page * pageSize, noOfRecords);

  const pageChoices: IChoice[] = Array.from({ length: totalPages }, (_, i) => ({
    key: `${i + 1}`,
    label: `${i + 1}`,
  }));

  const selectedPageChoice =
    pageChoices.find((choice) => choice.key === `${page}`) ?? null;

  const handlePrevPage = () => {
    handlePageChange(page - 1);
  };

  const handleNextPage = () => {
    handlePageChange(page + 1);
  };

  return (
    <div className="flex justify-between gap-6 max-sm:flex-col-reverse md:items-center">
      <div className="max-sm:flex max-sm:items-center max-sm:justify-between max-sm:gap-4">
        <ChoicesSelectDropdownWithSearch
          classNameButton="end-2"
          classNameContainer=""
          classNameInputContainer=""
          classNameInput="w-20 !pe-8"
          choices={pageChoices}
          label="Go to page:"
          onChange={(item) => handlePageChange(Number(item.key))}
          pattern="^[0-9]{0,2}$"
          selectedChoice={selectedPageChoice}
        />
        <div className="text-sm text-gray-700 sm:hidden">
          {`${currentPageRecordStart} - ${currentPageRecordEnd} of ${noOfRecords} records`}
        </div>
      </div>
      <div className="flex items-center gap-x-2 max-sm:justify-center">
        <IconButton
          className="w-max rounded-e-none rounded-s bg-white text-gray-500 shadow-sm hover:border-red-100 hover:bg-red-50"
          type="button"
          disabled={page < 2}
          onClick={handlePrevPage}
          rightIcon={<ChevronLeftIcon width={24} />}
        />
        <div className="px-4 text-sm text-gray-700">
          {`${page} of ${totalPages}`}
        </div>
        <IconButton
          className="w-max rounded-e rounded-s-none bg-white text-gray-500 shadow-sm hover:border-red-100 hover:bg-red-50"
          type="button"
          disabled={page <= totalPages}
          onClick={handleNextPage}
          rightIcon={<ChevronRightIcon width={24} />}
        />
      </div>
      <div className="text-sm text-gray-700 max-sm:hidden">
        {`${currentPageRecordStart} - ${currentPageRecordEnd} of ${noOfRecords} records`}
      </div>
    </div>
  );
}
