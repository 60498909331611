const channelsInfo = [
  {
    name: 'Lifetime GVM',
    value: "It's gonna be BIG!",
  },
  {
    name: 'Channels',
    value: 'Amazon, Shopify, Facebook Marketplace, Alibaba, TBD',
  },
];

export function ChannelsSummary() {
  return (
    <div>
      <dl
        className="
          grid grid-cols-1 divide-y divide-gray-100
          overflow-hidden rounded-lg bg-white shadow-sm shadow-gray-100
          md:grid-cols-2 md:divide-x md:divide-y-0
        "
      >
        {channelsInfo.map(({ name, value }) => (
          <div key={name} className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-normal text-gray-700">{name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-lg font-semibold">
                <span className="font-medium text-gray-800">{value}</span>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
