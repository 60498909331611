import Link from 'next/link';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';

// import { useTranslations } from '@keymono/i18n';

interface IOrgMembersAndInvitationsEmptyProps {
  accountId: number;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback view when the user has no organizations or
 * invitations.
 */
export function OrgMembersAndInvitationsEmpty({
  accountId,
}: IOrgMembersAndInvitationsEmptyProps) {
  // const t = useTranslations('Login');

  return (
    <div
      className="
        mx-auto flex flex-col items-center justify-center rounded-md
        border border-red-100 bg-red-50/50 p-4 text-center
      "
    >
      <h1 className="text-red-700">Sorry!</h1>
      <p className="py-2 text-xs text-red-900">
        You have no orgs yet. Try to add one first.
      </p>

      <div className="flow-root">
        <Link
          href={`/${accountId}/org/create`}
          className="
            group inline-flex items-center rounded-full border
            border-red-200 bg-red-50 px-2.5 py-1.5 text-sm
            leading-5 text-red-500 shadow-sm transition-all duration-500
            ease-linear hover:bg-red-600 hover:text-white
            focus:outline-none focus:ring-2 focus:ring-red-100
            focus:ring-offset-1
          "
        >
          <BuildingOffice2Icon
            className="h-5 w-5 group-hover:text-white"
            aria-hidden="true"
          />
          <span className="mx-5 text-xs">Create an Organization</span>
        </Link>
      </div>
    </div>
  );
}
