import Image from 'next/image';
import Link from 'next/link';
import {
  // ArrowRightOnRectangleIcon,
  IdentificationIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { IActiveSession } from '@keymono/shared-types';

// import { FlagIcon } from '@keymono/i18n';
// import { generateProfileImageURL } from '@keymono/apis';

interface IActiveSessionPreviewProps {
  /**
   * The current active session got from the `authStore`.
   */
  activeSession: IActiveSession;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the main profile info in the flyover menu. Displaying the a large
 * profile picture, roles and account type and an option to navigate the user to
 * their full profile.
 *
 * It also renders a flag to indicate the actively logged in region.
 *
 * @returns JSX.Element
 */
export function ActiveSessionPreview({
  activeSession,
}: IActiveSessionPreviewProps) {
  if (!activeSession) {
    return null;
  }

  const { index } = activeSession;
  const { email, firstName, lastName } = activeSession.user;
  const displayPicture = '';

  const fullName = `${firstName} ${lastName}`;

  // const imageUrl = generateProfileImageURL(displayPicture || '');

  const showRoles = false;

  return (
    <div>
      {/* <FlagIcon
        id={regionIconId}
        className="mx-6 shrink-0 rounded-md"
        size={20}
      /> */}
      <div className="flex flex-1 flex-col px-8 py-2">
        {displayPicture ? (
          <Image
            alt={`${fullName} face`}
            className="
              mx-auto h-24 w-24 shrink-0 rounded-full border-4
              border-gray-50
            "
            height={200}
            // placeholder="blur"
            src={displayPicture}
            width={200}
          />
        ) : (
          <div
            className="
              mx-auto flex h-24 w-24 shrink-0 items-center
              justify-center rounded-full bg-gray-100
            "
          >
            <UserIcon
              className="flex  h-16 w-16 text-gray-200"
              aria-hidden="true"
            />
          </div>
        )}

        <dl className="mt-1 flex grow flex-col items-center justify-center gap-1">
          <dt className="sr-only">Name</dt>
          <dd className="text-xl font-medium text-gray-900 sm:text-xl">
            {fullName}
          </dd>
          <dt className="sr-only">Email Address</dt>
          <dd className="text-sm text-gray-700">{email}</dd>

          {showRoles ? (
            <>
              <dt className="sr-only">Title</dt>
              <dd className="text-sm text-gray-500">title</dd>
              <dt className="sr-only">Role</dt>
              <dd className="mt-3">
                <span
                  className="
                    rounded-full bg-red-50 px-2 py-1 text-xs font-medium
                    text-red-500
                  "
                >
                  role
                </span>
              </dd>
            </>
          ) : null}
        </dl>
      </div>

      <div className="relative">
        <div className="relative flex justify-center">
          <Link
            href={`/${index}/profile`}
            className="form-btn-variant-primary group"
          >
            <IdentificationIcon
              className="group-hover:text-white"
              aria-hidden="true"
            />
            <span>Manage your account</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
