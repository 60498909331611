import tw, { styled } from 'twin.macro';

/**
 * Interface for the OrderList styled components
 */
interface IOrderListSCProps {
  isMobileViewDisabled?: boolean;
}

export const OrdersListTableContainer = styled.div<IOrderListSCProps>(
  ({ isMobileViewDisabled }) => [
    tw`
      flex
      flex-col
      gap-y-6
    `,

    isMobileViewDisabled ? tw`overflow-x-auto` : tw`lg:overflow-x-auto`,
  ]
);

export const OrdersListTableHeaderWrapper = styled.div<IOrderListSCProps>(
  ({ isMobileViewDisabled }) => [
    tw`
      gap-x-4
    `,

    isMobileViewDisabled
      ? tw`
        inline-flex
      `
      : tw`
        hidden

        lg:inline-flex
      `,
  ]
);

export const OrdersListTableHeader = styled.div`
  ${tw`
    grid
    w-full
    auto-cols-[1rem]
    grid-flow-col
    items-center
    gap-6
    px-4

    [&>*:not(:first:child)]:overflow-hidden
  `}
`;

export const OrdersListTableHeaderColumnLabelStyledSpan = styled.span`
  ${tw`
    text-xs
    uppercase
    text-gray-500
  `};
`;

export const OrdersListTableBodyWrapper = styled.div`
  ${tw`
    inline-flex
    w-min
    min-w-full
    flex-col
    gap-y-4
  `};
`;

export const OrdersListTableRow = styled.div<IOrderListSCProps>(
  ({ isMobileViewDisabled }) => [
    tw`
      grid
      w-full
      grid-flow-row
      items-center
      gap-x-6
      gap-y-4
      rounded-lg
      p-4
      cursor-pointer

      [&>*:not(:first:child)]:overflow-hidden
    `,

    isMobileViewDisabled
      ? tw`
        auto-cols-[1rem]
        grid-flow-col
        items-center
      `
      : tw`
        max-lg:grid-cols-2
        lg:auto-cols-[1rem]
        lg:grid-flow-col
        lg:items-center
      `,
  ]
);
