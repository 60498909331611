import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ChartData,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

function makeBarData(data: PeriodBarChartData): ChartData<'bar'> {
  return {
    labels: [data.label],
    datasets: [
      {
        label: 'Previous',
        data: [data.data[0]],
        backgroundColor: '#E5E7EB',
      },
      {
        label: 'Current',
        data: [data.data[1]],
        backgroundColor: data.color,
      },
    ],
  };
}

type PreviousDataPoint = number;
type CurrentDataPoint = number;

export type PeriodBarChartData = {
  color: string;
  label: string;
  unit: string;
  data: [PreviousDataPoint, CurrentDataPoint];
};

export function PeriodBarChart({ data }: { data: PeriodBarChartData }) {
  return <Bar options={options} data={makeBarData(data)} />;
}

export function PeriodBarChartLoader() {
  return <div />;
}
