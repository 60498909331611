import tw, { styled } from 'twin.macro';

export const OrdersListLoaderTableCell = styled.div`
  ${tw`
    whitespace-nowrap
    text-sm
    flex
    flex-col
    items-start
    justify-center
  `};
`;
