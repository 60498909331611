import { IBaseUIFallbackCommonOptions } from '@keymono/design-system';

import { OrgOverviewHeaderLoader } from './OrgOverviewHeaderLoader';
import { OrgOverviewHeaderError } from './OrgOverviewHeaderError';

/**
 * -----------------------------------------------------------------------------
 * Fallback component for `OrgOverviewHeader`.
 * Renders the loading and error state for `OrgOverviewHeader`.
 */
export function OrgOverviewHeaderFallback({
  isLoading,
  error,
  onRetryAction,
}: IBaseUIFallbackCommonOptions) {
  if (error) {
    return (
      <OrgOverviewHeaderError error={error} onRetryAction={onRetryAction} />
    );
  }
  return isLoading ? <OrgOverviewHeaderLoader /> : null;
}
