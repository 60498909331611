type PathParams<TPath extends string> =
  TPath extends `${infer _}{${infer Param}}/${infer Rest}`
    ? { [K in Param | keyof PathParams<Rest>]: string }
    : TPath extends `${infer _}{${infer Param}}`
    ? { [K in Param]: string }
    : null;

type PathType<TPath extends string> = {
  path: TPath;
  params: PathParams<TPath>;
};

type Scheme = 'https' | 'http';
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type ContentType = 'application/json' | 'application/x-www-form-urlencoded';

type RequestBuilderConfig = {
  scheme: Scheme;
  host: string;
  basePath: string;
  path: string;
  queryParams: string;
  method: HttpMethod;
  body: URLSearchParams | string | undefined;
  contentType: ContentType;
  headers: Headers;
};

export class RequestBuilder {
  private config: RequestBuilderConfig = {
    scheme: 'https',
    host: '',
    basePath: '',
    path: '',
    queryParams: '',
    method: 'GET',
    contentType: 'application/json',
    body: undefined,
    headers: new Headers(),
  };

  constructor(config?: Partial<RequestBuilderConfig>) {
    this.config = { ...this.config, ...config };
  }

  public getConfig() {
    return this.config;
  }

  public scheme(scheme: Scheme): RequestBuilder {
    this.config.scheme = scheme;
    return this;
  }

  public host(host: string) {
    this.config.host = host;
    return this;
  }

  public basePath(basePath: string) {
    this.config.basePath = basePath;
    return this;
  }

  public method(method: HttpMethod) {
    this.config.method = method;
    return this;
  }

  public requestBody(contentType: ContentType, body: any) {
    this.config.headers.set('Content-Type', contentType);
    if (contentType === 'application/json') {
      this.config.body = JSON.stringify(body);
    } else if (contentType === 'application/x-www-form-urlencoded') {
      this.config.body = new URLSearchParams(body);
    } else {
      throw new TypeError(
        `RequestBuilder.setRequestBody recieved contentType ${contentType} this is currently unsupported`
      );
    }
    return this;
  }

  public path<TPath extends string>(
    path: PathType<TPath>['path'],
    params: PathType<TPath>['params']
  ) {
    if (params === null) {
      this.config.path = path;
      return this;
    }
    const paramMap = new Map(Object.entries(params));
    this.config.path = path
      .split('/')
      .map((part) => {
        if (part.charAt(0) !== '{') return part;
        if (part.charAt(part.length - 1) !== '}') return part;

        const param = part.substring(1, part.length - 1);
        if (!paramMap.has(param)) {
          throw new TypeError(
            `RequestBuilder.setPath(): unable to find param ${param} in given params for path ${path}`
          );
        }
        return paramMap.get(param)!;
      })
      .join('/');
    return this;
  }

  public queryParams(queryParams: Record<string, string>) {
    const params = new URLSearchParams(queryParams);
    this.config.queryParams = `?${params.toString()}`;
    return this;
  }

  public setHeader(name: string, value: string) {
    this.config.headers.set(name, value);
    return this;
  }

  public appendHeader(name: string, value: string) {
    this.config.headers.set(name, value);
    return this;
  }

  public build(): [URL, RequestInit | undefined] {
    const url = new URL(
      `${this.config.scheme}://${this.config.host}${this.config.basePath}${this.config.path}${this.config.queryParams}`
    );

    const requestInit: RequestInit = {
      method: this.config.method,
      headers: this.config.headers,
      body: this.config.body,
    };
    return [url, requestInit];
  }
}

export const KeymonoRequestBuilder = new RequestBuilder();
