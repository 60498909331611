import Image from 'next/image';
import { useRouter } from 'next/router';

import { useAuthStore } from '@keymono/services';
import { CardSecButton } from '@keymono/design-system';
import { IMember } from '@keymono/shared-types';

interface IMemberListsProps {
  members: IMember[];
}

export function MembersTable({ members }: IMemberListsProps) {
  const { push } = useRouter();
  const { activeSession } = useAuthStore();

  if (!activeSession) return null;

  const { index: accountId, userId, activeOrgId: orgId } = activeSession;

  /**
   * TODO: Get this count from the backend on the members object.
   * @Aman
   */
  const membersAdminCount = members.length;
  const membersCount = members.length;

  const handleOnClickInviteNewMember = () => {
    push(`/${accountId}/org/${orgId}/invitations`);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {`${membersCount} Members`}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the members with access to this organization. You can
            invite more members to collavorate with you via email.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CardSecButton onClick={handleOnClickInviteNewMember}>
            Invite a new member
          </CardSecButton>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-100">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Revoke Access</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100/80">
                {members.map(
                  (
                    {
                      userId: id,
                      email,
                      firstName,
                      lastName,
                      type,
                      profileImageUrl,
                    },
                    index
                  ) => {
                    const isCurrentUser = id === userId;

                    const handleOnClickLeaveOrg = () => {
                      // TODO: Add logic to remove this active session user from org.
                      // TODO: Also remember to remove the org details cache.
                    };

                    const handleOnClickRevokeAccess = () => {
                      // TODO: Remove this user from the org if it's permissible
                    };

                    /**
                     * TODO: Fine tune this with permission based restrictions.
                     */
                    const userCanLeave = isCurrentUser && membersAdminCount > 1;

                    /**
                     * TODO: Extract out the table row and all its respective
                     *  fns and logical content into a separate component
                     * TODO: Also always display the current user at the top.
                     */
                    return (
                      <tr
                        key={email}
                        className={index % 2 === 0 ? undefined : 'bg-red-50/20'}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <Image
                                className="h-8 w-8 rounded-full"
                                src={profileImageUrl}
                                alt=""
                                width={32}
                                height={32}
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                <span>{firstName}</span>
                                <span className="mx-1 font-normal">
                                  {lastName}
                                </span>
                              </div>
                              <div className="text-gray-400">{email}</div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm capitalize text-gray-500">
                          {type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          {
                            // TODO: Refactor this into a simpler component with high reuse.
                            userCanLeave ? (
                              <button
                                type="button"
                                title="Leave this organization" // TODO: Translate this component.
                                className="
                                  inline-flex items-center rounded-full border border-gray-300
                                  bg-white px-2.5 py-0.5 text-sm font-medium leading-5
                                  text-gray-700 shadow-sm  transition-all duration-500
                                  ease-in-out hover:border-red-100 hover:bg-red-50
                                  hover:text-red-700 active:bg-red-300 active:text-red-600
                                "
                                onClick={handleOnClickLeaveOrg}
                              >
                                Leave Organization
                              </button>
                            ) : (
                              <button
                                type="button"
                                title="Remove user from this org" // TODO: Translate this component.
                                className="
                                  inline-flex items-center rounded-full border border-gray-300
                                  bg-white px-2.5 py-0.5 text-sm font-medium leading-5
                                  text-gray-700 shadow-sm  transition-all
                                  duration-500  ease-in-out hover:border-red-100 hover:bg-red-50
                                  hover:text-red-700 active:bg-red-300 active:text-red-600
                                "
                                onClick={handleOnClickRevokeAccess}
                              >
                                Revoke Access
                              </button>
                            )
                          }
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
