import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * This styles the main div that surrounds the whole form.
 */
export const FormContainer = styled.div`
  ${tw`
    p-20
    w-full
    transition-all
    ease-in-out
    delay-100
    duration-500

    ltr:rounded-tl-lg
    rtl:rounded-tr-lg

  `};

  .form-responsive-wrapper {
    ${tw`
      space-y-4
      md:space-y-6
   `};
  }

  .form-header {
    ${tw`
      text-xl
      font-semibold
      leading-tight
      tracking-tight
      text-gray-800
      transition-all
      duration-500

      md:text-2xl
  `};
  }
`;

/**
 * -----------------------------------------------------------------------------
 * Injects additional tailwind styles to the form, with twin.macro
 */
export const StyledForm = styled.form`
  ${tw`
    space-y-4
    transition-all
    ease-in-out
    delay-100
    duration-500

    md:space-y-6
 `};

  .form-field-row {
    ${tw`
      flex
      flex-col
      items-center
      justify-between
      gap-4

      sm:flex-row
    `};
  }
`;

export const ButtonInlineWrapper = styled.button`
  ${tw`
    inline-flex
    w-fit
    items-center
    rounded-full
    border
    border-red-300
    bg-red-50
    px-2.5
    py-0.5
    text-sm
    font-medium
    leading-5
    text-gray-700
    shadow-sm
    hover:(bg-red-100 text-red-700)
  `};

  &.primary {
    ${tw`
      border-teal-700
      bg-teal-50
      text-teal-900
      hover:(bg-teal-100 text-teal-800)
    `};
  }

  &.secondary {
    ${tw`
      border-gray-700
      bg-white
      text-gray-900
      hover:(bg-red-50 text-red-800)
    `};
  }

  &.btn-is-loading {
    ${tw`
      border-teal-700
      bg-teal-50
      text-teal-900
      hover:(bg-teal-100 text-teal-800)
  `};
  }
`;

export const MessageInlineWrapper = styled.div`
  ${tw`
    relative
    inline-flex
    items-center
    text-center
    text-green-700
    text-sm
    font-medium
    w-fit
    px-2.5
    py-0.5
  `};
`;

/**
 * TODO: Make this more responsive at smaller screens.
 */
export const ErrorInlineWrapper = styled.span`
  ${tw`
      relative
      absolute
      flex
      items-end
      justify-end
      top-1
      right-0
      border
      rounded-md
      max-w-sm
      flex-wrap
      h-auto
      whitespace-normal
      cursor-pointer
      text-start
      border-red-200
      bg-white/95
      p-1
      text-xs
      text-red-600
  `};
`;
