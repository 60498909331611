import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * A link that invites the user to sign login instead.
 */
export function LoginInsteadCTA() {
  const t = useTranslations('PasswordReset');

  return (
    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
      {t('loginInsteadCTA.title')}
      <Link href="/auth/login" className="form-link-action ml-2">
        {t('loginInsteadCTA.subTitle')}
      </Link>
    </p>
  );
}
