import {
  CheckBadgeIcon,
  ClockIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';

import { TIntegrationStatus } from '@keymono/apis';

import { StatusBadgeWrapper } from './styles';

interface IIntegrationStatusBadgeProps {
  status: TIntegrationStatus;
  statusLabel: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the badge to be used in displaying the current status of the
 * org's integration setup.
 */
export function IntegrationStatusBadge({
  status,
  statusLabel,
}: IIntegrationStatusBadgeProps) {
  let icon = null;

  switch (status) {
    case 'draft': {
      icon = <ClockIcon className="status-badge-icon" />;
      break;
    }
    case 'verified': {
      icon = <CheckBadgeIcon className="status-badge-icon" />;
      break;
    }
    case 'rejected': {
      icon = <XCircleIcon className="status-badge-icon" />;
      break;
    }
    case 'failed-verification': {
      icon = <ExclamationCircleIcon className="status-badge-icon" />;
      break;
    }
    default: {
      icon = null;
    }
  }

  return (
    <StatusBadgeWrapper className={status}>
      <div className={`status-badge-container ${status}`}>
        {icon}
        <span className="status-badge-label">{statusLabel}</span>
      </div>
    </StatusBadgeWrapper>
  );
}
