import { IInvitationsListProps } from '../types';

import { InvitationsListCards } from './InvitationsListCards';
import { InvitationsListTable } from './InvitationsListTable';

/**
 * -----------------------------------------------------------------------------
 * Displays the list of all Pending Invitations in table view for desktop.
 * and card list view for mobile.
 * TODO: Combine the Table and Card view into a single component.
 */
export function InvitationsList({
  invitations,
  orgId = '',
}: IInvitationsListProps) {
  return (
    <>
      <InvitationsListTable
        className="max-sm:hidden"
        invitations={invitations}
        orgId={orgId}
      />
      <InvitationsListCards
        className="sm:hidden"
        invitations={invitations}
        orgId={orgId}
      />
    </>
  );
}
