import { useState } from 'react';

import { useAuthStore } from '@keymono/services';

import { NamesCard } from './NamesCard';
import { NamesCardEdit } from './NamesCardEdit';

/**
 * -----------------------------------------------------------------------------
 * Displays the user's names information and allows for editing.
 * TODO: Refactor both cards to use shared content here.
 */
export function ProfileNamesCard() {
  const user = useAuthStore().activeSession?.user;

  const [isEditing, setIsEditing] = useState(false);

  if (!user) return null;

  const { firstName, lastName, otherName } = user;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  return !isEditing ? (
    <NamesCard
      firstName={firstName}
      lastName={lastName}
      otherName={otherName || ''}
      onEdit={handleEdit}
    />
  ) : (
    <NamesCardEdit onExitEditMode={handleCancelEdit} />
  );
}
