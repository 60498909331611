import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuthStore } from '@keymono/services';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';
import { IAcceptOrgInvitationsData } from '../types';

import { ORGANIZATION_API } from '../organizationAPIClient';
import { QUERY_USER_DETAILS } from '../../user-accounts/query-hooks/useUserDetailsQuery';

const MUT_ACCEPT_INVITATION = 'accept-invitation-mutation-key';

interface IUseAcceptInvitationOptions {
  id: string;
  onSuccessCallback?: (sendInviteData: IAcceptOrgInvitationsData) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This mutation accepts an exisiting invitation via email to join an org.
 */
export const useAcceptInvitationMutation = ({
  id,
  onSuccessCallback,
  onErrorCallback,
}: IUseAcceptInvitationOptions) => {
  const { activeSession } = useAuthStore();
  const userId = activeSession?.userId || '';

  const reactQueryClient = useQueryClient();

  const { mutate, isLoading, data, error } = useMutation({
    mutationKey: [MUT_ACCEPT_INVITATION, id],
    networkMode: 'always',
    retry: false,
    mutationFn: ORGANIZATION_API.acceptOrganizationInvitation,
    onSuccess: (responseData) => {
      reactQueryClient.invalidateQueries([QUERY_USER_DETAILS, userId]);
      onSuccessCallback?.(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Accepting the Organization Invitation Attempt',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    data,
    acceptOrganizationInvitation: mutate,
  };
};
