import { IAddressInfo } from '@keymono/shared-types';

interface IAddressCardProps {
  address: IAddressInfo;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the orgs address info names summary
 */
export function AddressCard({ address }: IAddressCardProps) {
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountryLabel,
    addressZip,
  } = address;

  return (
    <>
      <h3 className="mb-2 text-lg font-medium">{addressLine1}</h3>
      <h4 className="text-sm">{addressLine2}</h4>
      <h5 className="my-2 text-sm">
        {addressCity ? <span>{addressCity}</span> : null}
        {addressState ? <span>{`, ${addressState}`}</span> : null}
        {addressCountryLabel ? <span>{`, ${addressCountryLabel}`}</span> : null}
      </h5>

      {addressZip ? (
        <p className="mt-1 text-sm font-light">{`Zip Code: ${addressZip}`}</p>
      ) : null}

      <p className="mt-2 border-t border-gray-50 pt-4 text-sm text-gray-500">
        This address will appear on all shipping transactions documents and is
        viewable by all members of the org.
      </p>
    </>
  );
}
