import { StarIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';

/**
 * -----------------------------------------------------------------------------
 * This combines a solid star icon and an outline star icon to create a half star.
 */
function HalfStarIcon() {
  return (
    <div className="relative">
      <StarOutlineIcon className="h-4 w-4 text-gray-300" />
      <div className="absolute bottom-0 left-0 right-1/2 top-0 overflow-hidden bg-white">
        <StarIcon className="h-4 w-4 text-yellow-500" />
      </div>
    </div>
  );
}

interface IColItemStarRatingProps {
  rating: number;
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Col Item component that renders a star rating (out of 5).
 * Accepts a decimal rating value and converts it to a star rating.
 * Shows empty stars for the remaining rating.
 */
export function ColItemStarRating({
  rating,
  className = '',
}: IColItemStarRatingProps) {
  const roundedRating = Math.round(rating * 2) / 2; // This will round to nearest 0.5
  const fullStarCount = Math.floor(roundedRating);
  const hasHalfStar = roundedRating % 1 !== 0; // This will be true if there is a half star
  const emptyStarCount = 5 - fullStarCount - (hasHalfStar ? 1 : 0);

  return (
    <div className={`flex gap-x-1 ${className}`}>
      {Array.from({ length: fullStarCount }, (_, i) => (
        <StarIcon key={i} className="h-4 w-4 text-yellow-500" />
      ))}
      {hasHalfStar ? <HalfStarIcon /> : null}
      {Array.from({ length: emptyStarCount }, (_, i) => (
        <StarOutlineIcon
          key={i + fullStarCount + (hasHalfStar ? 1 : 0)}
          className="h-4 w-4 text-gray-300"
        />
      ))}
    </div>
  );
}
