import { useState } from 'react';
import {
  DateType,
  DateValueType,
} from 'react-tailwindcss-datepicker/dist/types';

import { Disclosure } from '@headlessui/react';
import {
  ArrowsUpDownIcon,
  FunnelIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import {
  CalendarDropdown,
  ChoicesSelectDropdownWithSearch,
  HamburgerMenu,
  HamburgerMenuItem,
  IChoice,
  RadioDropdown,
  Title,
  ToggleButton,
} from '@keymono/design-system';
import { IUseOrgOrdersQueryOptions } from '@keymono/apis';

const sortOptions = [
  { key: 'created_at', label: 'Oldest first' },
  { key: '-created_at', label: 'Newest first' },
];

interface IOrderListHeaderProps {
  isMobileViewDisabled: boolean;
  onUpdateMobileView: (value: boolean) => void;
  navigateToOrderDetails?: (orderId: string) => void;
  onUpdateDateRange: (dateRange: DateValueType) => void;
  onUpdateOrdering: (ordering: IChoice) => void;
  ordering: IUseOrgOrdersQueryOptions['ordering'];
  onUpdateSearchKey?: (searchKey: string) => void;
  searchOptions?: IChoice[];
  startDate: DateType;
  endDate: DateType;
}

/**
 * -----------------------------------------------------------------------------
 * Renders the header component of Orders List.
 * Will include the search bar, sorting, filtering, bulk actions, etc.
 * TODO: Add translations
 */
export function OrdersListHeader({
  isMobileViewDisabled,
  onUpdateMobileView,
  navigateToOrderDetails,
  onUpdateDateRange,
  onUpdateOrdering,
  ordering,
  onUpdateSearchKey,
  searchOptions = [],
  startDate,
  endDate,
}: IOrderListHeaderProps) {
  // @ts-ignore
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const handleOnSearch = (item: IChoice) => {
    navigateToOrderDetails?.(item.key);
  };

  return (
    <Disclosure as="section" aria-labelledby="filter-heading" className="grid">
      <div className="flex items-center justify-between gap-5 max-lg:flex-col max-lg:items-start">
        <div className="flex w-full items-center justify-between gap-x-6 lg:justify-start">
          <Title tag="h4">Orders</Title>
          <ToggleButton
            containerClassName="h-7 w-12 md:hidden"
            buttonClassName="h-6 w-6"
            falseIcon={
              <ListBulletIcon className="h-4 w-4 stroke-gray-700 text-gray-700" />
            }
            trueIcon={
              <ListBulletIcon className="text-colorPri-600 h-4 w-4 stroke-2" />
            }
            value={isMobileViewDisabled}
            onChange={onUpdateMobileView}
          />
        </div>
        <div className="flex flex-row justify-between max-lg:w-full lg:items-center lg:justify-end lg:gap-x-4">
          <ChoicesSelectDropdownWithSearch
            classNameContainer=""
            classNameInputContainer=""
            classNameInput="w-48 lg:w-max"
            choices={searchOptions}
            icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />}
            label=""
            onChange={handleOnSearch}
            onUpdateSearchKey={onUpdateSearchKey}
            pattern=""
            selectedChoice={null}
          />
          <RadioDropdown
            label="Sort by"
            containerClassName="w-max md:w-40"
            buttonClassName="max-lg:[&>span]:hidden"
            icon={<ArrowsUpDownIcon width={20} />}
            onChange={onUpdateOrdering}
            options={sortOptions}
            selectedOption={{ key: ordering, label: 'Sort by' }}
          />
          <CalendarDropdown
            containerClassName="hidden lg:block"
            label="Date Range"
            onChange={onUpdateDateRange}
            startDate={startDate}
            endDate={endDate}
          />
          <Disclosure.Button
            className="form-btn group flex w-max items-center justify-between gap-x-3 whitespace-nowrap rounded-lg border border-gray-100 bg-white text-left text-sm font-medium text-gray-500 shadow-sm hover:bg-white hover:text-gray-900 focus-visible:outline-0 lg:hidden"
            onClick={() => setMobileFiltersOpen(true)}
          >
            <FunnelIcon
              className="h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </Disclosure.Button>
        </div>
      </div>
      <HamburgerMenu
        title="Filters"
        showFilters={mobileFiltersOpen}
        setShowFilters={setMobileFiltersOpen}
      >
        <HamburgerMenuItem>
          <CalendarDropdown
            inputWidth="w-full"
            label="Date Range"
            singleMonth
            onChange={onUpdateDateRange}
            startDate={startDate}
            endDate={endDate}
          />
        </HamburgerMenuItem>
      </HamburgerMenu>
    </Disclosure>
  );
}
