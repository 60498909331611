import { useAllOrganizationsQuery } from '@keymono/apis';

import { Title } from '@keymono/design-system';
import { AllOrganizationsListFallback } from './AllOrganizationsListFallback';
import { AllOrganizationsList } from './AllOrganizationsList';
import { NoOrganizations } from './NoOrganizations';

interface IOrganizationsOverviewProps {
  title: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the summary of all organizations for a user.
 */
export function OrganizationsOverview({ title }: IOrganizationsOverviewProps) {
  const { data, isLoading, error, refetch } = useAllOrganizationsQuery();

  const organizations = data?.organizations;

  const handleRetryOnFail = () => {
    refetch();
  };

  if (isLoading || error || !organizations) {
    return (
      <AllOrganizationsListFallback
        isLoading={isLoading}
        error={error}
        onRetryAction={{ onClick: handleRetryOnFail }}
      />
    );
  }

  return (
    <div className="flex grow flex-col items-start gap-6">
      <Title tag="h2">{title}</Title>
      {organizations.length > 0 ? (
        <AllOrganizationsList organizations={organizations} />
      ) : (
        <NoOrganizations />
      )}
    </div>
  );
}
