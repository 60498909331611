import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';

import { IFetchOrgIntegrationsData } from '../types';
import { ORGANIZATION_API } from '../organizationAPIClient';

export const QUERY_FETCH_ORG_INTEGRATIONS_KEY = 'fetch-org-integrations-key';

interface IUseOrgIntegrationsQueryOptions {
  orgId: string;
  onSuccessCallback?: (orgData: IFetchOrgIntegrationsData) => void;
}

/**
 * This fetches the list of installed integrations for the target organization.
 */
export const useOrgIntegrationsQuery = ({
  orgId,
  onSuccessCallback,
}: IUseOrgIntegrationsQueryOptions) => {
  const { data, isLoading, isError, error, isPaused, refetch } = useQuery({
    queryKey: [QUERY_FETCH_ORG_INTEGRATIONS_KEY, orgId],
    networkMode: 'always',
    queryFn: () => ORGANIZATION_API.fetchOrgIntegrations(orgId),
    onSuccess: (response) => {
      onSuccessCallback?.(response);
    },
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isLoading,
    isError,
    isPaused,
    refetch,
  };
};
