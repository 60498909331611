import { useQuery } from '@tanstack/react-query';
import { IUser } from '@keymono/shared-types';
import { useAuthStore } from '@keymono/services';

import { IApiError } from '../../../../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';
import { useUpdateUserDataCallback } from '../useUpdateUserDataCallback';

/**
 * This is to track the fetched me/user details in app memory.
 */
export const QUERY_USER_DETAILS = 'fetch-user-details-query-key';

interface IUseUserDetailsQueryOptions {
  onSuccessCallback?: (userData: IUser) => void;
}

/**
 * This performs a get request that retrieves the details the current authed user.
 */
export const useUserDetailsQuery = ({
  onSuccessCallback,
}: IUseUserDetailsQueryOptions) => {
  const { activeSession } = useAuthStore();
  const { updateUserDataCallback } = useUpdateUserDataCallback();

  const userId = activeSession?.userId || '';

  const { data, isLoading, isError, error, isPaused, refetch } = useQuery({
    queryKey: [QUERY_USER_DETAILS, userId],
    networkMode: 'always',
    enabled: !!userId,
    queryFn: () => USER_ACCOUNTS_API.fetchUserDetails(),
    onSuccess: (userData) => {
      onSuccessCallback?.(userData);
      updateUserDataCallback(userData);
    },
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isLoading,
    isError,
    isPaused,
    refetch,
  };
};
