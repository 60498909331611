import {
  IFetchOrderDetails,
  IFetchOrderDetailsRaw,
  IFetchOrgOrdersDataRaw,
  IFetchOrgOrdersData,
} from './types';

/**
 * This transforms the data received from `fetchOrgOrdersUrl` endpoint.
 * Currently converting the `snake_case` to `camelCase`.
 * And renames results array to orders.
 */
export function formatOrgOrdersResponseData(
  data: IFetchOrgOrdersDataRaw
): IFetchOrgOrdersData {
  const { results, meta: metaRaw } = data;

  const orders = results.map(
    ({
      org_integration: orgIntegration,
      ext_id: extId,
      channel_created_ts: channelTimestamp,
      total_quantity: noOfItems,
      total_amount: totalAmount,
      fulfillment_channel: fulfillmentChannel,
      fulfillment_channel_label: fulfillmentChannelLabel,
      order_status: orderStatus,
      order_status_label: orderStatusLabel,
      fulfillment_status: fulfillmentStatus,
      fulfillment_status_label: fulfillmentStatusLabel,
      shipping_name: shippingName,
      shipping_first_name: shippingFirstName,
      shipping_last_name: shippingLastName,
      shipping_company: shippingCompany,
      shipping_phone: shippingPhone,
      shipping_email: shippingEmail,
      shipping_line1: shippingLine1,
      shipping_line2: shippingLine2,
      shipping_city: shippingCity,
      shipping_state: shippingState,
      shipping_country: shippingCountry,
      shipping_zip: shippingZip,
      ...rest
    }) => ({
      ...rest,
      orgIntegration,
      extId,
      channelTimestamp,
      noOfItems,
      totalAmount,
      fulfillmentChannel,
      fulfillmentChannelLabel,
      orderStatus,
      orderStatusLabel,
      fulfillmentStatus,
      fulfillmentStatusLabel,
      shippingDetails: {
        shippingName,
        shippingFirstName,
        shippingLastName,
        shippingCompany,
        shippingPhone,
        shippingEmail,
        shippingLine1,
        shippingLine2,
        shippingCity,
        shippingState,
        shippingCountry,
        shippingZip,
      },
    })
  );

  const {
    pagination: { no_of_records: noOfRecords, page, page_size: pageSize },
  } = metaRaw;

  const meta = {
    pagination: {
      noOfRecords,
      page,
      pageSize,
    },
  };

  return { meta, orders };
}

/**
 * This transforms the data received from `fetchOrderDetailsUrl` endpoint.
 */
export function formatOrderDetailsResponseData(
  data: IFetchOrderDetailsRaw
): IFetchOrderDetails {
  const {
    org_integration: orgIntegration,
    ext_id: extId,
    channel_created_ts: channelTimestamp,
    total_quantity: noOfItems,
    total_amount: totalAmount,
    fulfillment_channel: fulfillmentChannel,
    fulfillment_channel_label: fulfillmentChannelLabel,
    order_status: orderStatus,
    order_status_label: orderStatusLabel,
    fulfillment_status: fulfillmentStatus,
    fulfillment_status_label: fulfillmentStatusLabel,
    shipping_name: shippingName,
    shipping_first_name: shippingFirstName,
    shipping_last_name: shippingLastName,
    shipping_company: shippingCompany,
    shipping_phone: shippingPhone,
    shipping_email: shippingEmail,
    shipping_line1: shippingLine1,
    shipping_line2: shippingLine2,
    shipping_city: shippingCity,
    shipping_state: shippingState,
    shipping_country: shippingCountry,
    shipping_zip: shippingZip,
    items: rawItems,
    ...rest
  } = data;

  const items = rawItems.map(
    ({
      ext_id: itemExtId,
      price_per_item: pricePerItem,
      tax_per_item: taxPerItem,
      ...itemRest
    }) => ({
      ...itemRest,
      extId: itemExtId,
      pricePerItem,
      taxPerItem,
    })
  );

  return {
    ...rest,
    orgIntegration,
    extId,
    channelTimestamp,
    noOfItems,
    totalAmount,
    fulfillmentChannel,
    fulfillmentChannelLabel,
    orderStatus,
    orderStatusLabel,
    fulfillmentStatus,
    fulfillmentStatusLabel,
    shippingDetails: {
      shippingName,
      shippingFirstName,
      shippingLastName,
      shippingCompany,
      shippingPhone,
      shippingEmail,
      shippingLine1,
      shippingLine2,
      shippingCity,
      shippingState,
      shippingCountry,
      shippingZip,
    },
    items,
  };
}
