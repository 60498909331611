import { useState } from 'react';
import Image from 'next/image';
import { XCircleIcon } from '@heroicons/react/20/solid';

import {
  IApiError,
  IOrgIntegration,
  IReauthorizeOrgIntegrationData,
  useRemoveOrgIntegrationMutation,
  useReauthorizeOrgIntegrationMutation,
} from '@keymono/apis';

import { CardSecButton } from '@keymono/design-system';

import { IntegrationStatusBadge } from '../IntegrationStatusBadge';

import { CardBodyWrapper, CardWrapper } from '../styles';

interface IActionRemoveExistingOrgIntegrationProps {
  id: string;
  orgId: string;
}

/**
 * TODO: Add translations
 */
function ActionRemoveExistingOrgIntegration({
  id,
  orgId,
}: IActionRemoveExistingOrgIntegrationProps) {
  // TODO: Handle errors in failed integration removal.
  const { removeOrganizationIntegration, isLoading } =
    useRemoveOrgIntegrationMutation({
      id,
      orgId,
    });

  const handleOnClick = () => {
    removeOrganizationIntegration({ id, orgId });
  };

  return (
    <CardSecButton isLoading={isLoading} onClick={handleOnClick}>
      Remove
    </CardSecButton>
  );
}

interface IActionReauthorizeExistingOrgIntegrationProps {
  id: string;
  orgId: string;
}

function ActionReauthorizeExistingOrgIntegration({
  id,
  orgId,
}: IActionReauthorizeExistingOrgIntegrationProps) {
  const [hasError, setHasError] = useState<IApiError | null>(null);

  const handleOnReauthorizeIntegrationSuccess = (
    integrationData: IReauthorizeOrgIntegrationData
  ) => {
    if (window) {
      window?.open(integrationData.redirectUrl, '_blank');
    }
  };

  const handleOnReauthorizeIntegrationError = (error: IApiError) => {
    setHasError(error);
  };

  // TODO: Handle errors in failed reauthorization.
  // const { data, isLoading, error, reauthorizeOrganizationIntegration } =
  const { isLoading, reauthorizeOrganizationIntegration } =
    useReauthorizeOrgIntegrationMutation({
      id,
      orgId,
      onSuccessCallback: handleOnReauthorizeIntegrationSuccess,
      onErrorCallback: handleOnReauthorizeIntegrationError,
    });
  const handleOnClick = () => {
    reauthorizeOrganizationIntegration({ id, orgId });
  };

  // const isLoading = true;
  // const error = null;

  return (
    <div className="flex flex-col">
      {hasError ? (
        <div className="mb-2 flex w-full p-1">
          <div className="text-red-700">
            <XCircleIcon className="h-4 w-4" />
            <span className="mx-2 text-xs">
              Failed to reauthorize. Please try again
            </span>
          </div>
        </div>
      ) : null}

      <CardSecButton
        isEnabled={!isLoading}
        isLoading={isLoading}
        onClick={handleOnClick}
      >
        {isLoading ? 'Authorizing' : 'Reauthorize'}
      </CardSecButton>
    </div>
  );
}

interface IExistingIntegrationCardItemProps {
  integration: IOrgIntegration;
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders an already setup integration for the active organization.
 * - This is split from `OrgIntegrationsListItems`.
 */
export function ExistingIntegrationCardItem({
  orgId,
  integration,
}: IExistingIntegrationCardItemProps) {
  const {
    extId,
    extLabel,
    id,
    integrationName,
    integrationLogoUrl,
    status,
    statusLabel,
    verificationErrorLabel,
  } = integration;

  const handleOnClickItem = () => {
    // TODO: View Item Details
  };

  return (
    <CardWrapper
      aria-label="Active Integration Card"
      className="group"
      onClick={handleOnClickItem}
      role="button"
      tabIndex={0}
    >
      <CardBodyWrapper>
        <div className="flex w-full justify-end">
          <IntegrationStatusBadge status={status} statusLabel={statusLabel} />
        </div>
        <div className="flex w-full flex-row">
          <div className="mx-4 h-20 w-20 rounded-full border border-gray-100 bg-gray-50 p-2">
            <Image
              alt={integrationName}
              src={integrationLogoUrl}
              className="h-full w-full object-contain object-center sm:h-full sm:w-full"
              width={120}
              height={120}
            />
          </div>
          <div className="flex flex-1 flex-col justify-center px-4">
            <div className="gap-2">
              <h3 className="text-base font-semibold text-gray-800">
                {integrationName}
              </h3>
              <div className="mb-2 text-sm font-medium text-gray-600">
                {extLabel}
              </div>
            </div>
          </div>
        </div>

        {verificationErrorLabel ? (
          <p className="error-msg">{verificationErrorLabel}</p>
        ) : null}

        {status || extId ? (
          <div className="actions">
            {['draft', 'rejected', 'failed-verification'].includes(status) ? (
              <ActionRemoveExistingOrgIntegration id={id} orgId={orgId} />
            ) : null}
            {extId ? (
              <ActionReauthorizeExistingOrgIntegration id={id} orgId={orgId} />
            ) : null}
          </div>
        ) : null}
      </CardBodyWrapper>
    </CardWrapper>
  );
}
