import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';

import { PRODUCTS_API } from '../productsAPIClient';

export const QUERY_FETCH_ORG_PRODUCTS_KEY = 'fetch-org-products-key';

/**
 * Custom hook to fetch the list of Products for an Org
 * Fetches from the Product API Client.
 * Uses React Query under the hood.
 */
export function useOrgProductsQuery(orgId: string) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [QUERY_FETCH_ORG_PRODUCTS_KEY, orgId],
    queryFn: () => PRODUCTS_API.fetchOrgProducts(orgId),
    enabled: !!orgId, // Don't fetch if org id is not loaded.,
    onError: (err: IApiError) => err,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
}
