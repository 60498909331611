import { request as httpRequest } from '../../api-client-v2';
import { requestBuilder } from '../utils/rb';

export type MembershipDTI = {
  id: string;
  user: string;
  org: string;
  org_name: string;
  org_logo_url: string;
  type: string;
  type_label: string;
};

export type InvitationDTI = {
  id: string;
  org: string;
  org_logo_url: string;
  org_name: string;
  type: string;
  status: string;
};

export type UserMeDTI = {
  id: string;
  email: string;
  phone_country_code: string;
  phone: string;
  first_name: string;
  last_name: string;
  profile_image_url: string;
  membership: InvitationDTI[];
  invitation: MembershipDTI[];
};

export type UserMeDTO = {
  authToken: string;
};

export async function getUserMe(request: UserMeDTO) {
  const [url, init] = requestBuilder()
    .setHeader('Content-Type', 'application/json')
    .setHeader('Authorization', `Token ${request.authToken}`)
    .method('GET')
    .path('/users/me', null)
    .build();

  const response = await httpRequest<UserMeDTI>(url, init);
  return response;
}
