import Image from 'next/image';
import { IUserInvite } from '@keymono/shared-types';

import {
  AcceptInvitationCTA,
  DeclineInvitationCTA,
} from '../../../invitations/actions';

interface IOrgInvitationsListItemProps {
  invitation: IUserInvite;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a single organization invitation item
 */
export function OrgInvitationsListItem({
  invitation,
}: IOrgInvitationsListItemProps) {
  const { id, orgLogoUrl, orgName, type } = invitation;

  return (
    <li className="flex w-full  max-w-sm flex-grow">
      <div
        className="
          group relative flex w-full max-w-sm flex-col justify-between
          space-x-2 overflow-hidden rounded-md border border-gray-100 bg-white
          text-left text-gray-800 shadow-sm shadow-gray-50 outline-none transition-all
          duration-200 ease-linear hover:bg-red-50/50 hover:text-red-800
          focus:outline-none focus:ring-1 focus:ring-red-100 focus:ring-offset-1
        "
      >
        <span className="flex min-w-0 flex-1">
          <span className="block flex-shrink-0">
            <Image
              className="h-16 w-16"
              src={orgLogoUrl}
              alt="Company logo"
              height={100}
              width={100}
            />
          </span>
          <span
            className="
              block min-w-0 flex-1 px-4 py-2 transition-all duration-200
              ease-linear group-hover:scale-[1.02]
            "
          >
            <span className="block truncate text-sm font-medium">
              {orgName}
            </span>
            <span
              className="
                block truncate pb-2 text-xs font-medium capitalize text-gray-500
                transition-all  duration-200 ease-linear group-hover:text-red-500
              "
            >
              {type}
            </span>

            <div className="absolute bottom-2 right-2 flex gap-2">
              <DeclineInvitationCTA id={id} />
              <AcceptInvitationCTA id={id} />
            </div>
          </span>
        </span>
      </div>
    </li>
  );
}
