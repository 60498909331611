import { TFulfillmentStatus, TOrderStatus } from '@keymono/apis';

import { TStatusColor } from './types';

/**
 * Record of payment status and their corresponding colors
 */
export const statusColors: Record<
  TFulfillmentStatus | TOrderStatus,
  TStatusColor
> = {
  processed: 'green',
  cancelled: 'red',
  fulfilled: 'green',
  unfulfilled: 'yellow',
  open: 'blue',
};

/**
 * Function to format date in the format: MMM DD at HH:MMam/pm
 */
export const formatDate = (sourceDate: string, showYear = false): string => {
  // Create a new Date object from the source date
  const date = new Date(sourceDate);

  // Define an array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Get the month, day, and time
  const month = monthNames[date.getUTCMonth()];
  const day = date.getUTCDate();
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Convert the hours to a 12-hour format and set the AM/PM
  const amPm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours ?? 12; // the hour '0' should be '12'
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

  // Return the formatted date
  if (showYear) {
    const year = date.getUTCFullYear();
    return `${month} ${day}, ${year} at ${hours}:${formattedMinutes}${amPm}`;
  }

  return `${month} ${day} at ${hours}:${formattedMinutes}${amPm}`;
};
