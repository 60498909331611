import { SVGContentLoader } from '@keymono/design-system';

function LoaderItem() {
  return (
    <SVGContentLoader
      speed={2}
      height={20}
      viewBox="0 0 150 20"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="5" rx="3" ry="3" width="150" height="10" />
    </SVGContentLoader>
  );
}

/**
 * -----------------------------------------------------------------------------
 * The Footer component of OrdersListLoader.
 * Renders the skeleton screen for the the `OrdersListFooter` component.
 */
export function OrdersListLoaderFooter() {
  return (
    <div className="flex justify-between gap-6 p-3 max-sm:flex-col-reverse md:items-center">
      <div className="max-sm:flex max-sm:items-center max-sm:justify-between max-sm:gap-4">
        <LoaderItem />
        <div className="text-sm text-gray-700 sm:hidden">
          <LoaderItem />
        </div>
      </div>
      <div className="flex items-center gap-x-2 max-sm:justify-center">
        <LoaderItem />
      </div>
      <div className="text-sm text-gray-700 max-sm:hidden">
        <LoaderItem />
      </div>
    </div>
  );
}
