type AggregationFn = (data: (number | null)[]) => number;

function reduceSum(acc: number | null, next: number | null): number {
  return Number(acc) + Number(next);
}

export function sum(data: (number | null)[]): number {
  return Number(data.reduce(reduceSum, 0));
}

export function avg(data: (number | null)[]): number {
  return Number(data.reduce(reduceSum, 0)) / data.length;
}

export function aggreate(data: (number | null)[], fn: AggregationFn): number {
  return fn(data);
}

export function safeDivide(numerator: number, denominator: number) {
  return denominator === 0 ? numerator / 1 : numerator / denominator;
}

export type Transpose<TObj extends Record<string, unknown>> = {
  [key in keyof TObj]: Array<TObj[key]>;
};

type Prettfiy<TObj> = {
  [TKey in keyof TObj]: TObj[TKey];
} & {};

export function transpose<TObj extends Record<string, unknown>>(
  data: TObj[]
): Prettfiy<Transpose<TObj>> {
  if (!data || data.length === 0) {
    throw new RangeError("can't transpose AoS to SoA as data.length is 0");
  }

  const keys = Object.keys(data[0]) as Array<keyof TObj>;
  const result = Object.fromEntries(
    keys.map((key) => [key, new Array(data.length) as Array<TObj[typeof key]>])
  ) as Transpose<TObj>;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [dataIndex, d] of data.entries()) {
      result[key][dataIndex] = d[key];
    }
  }

  return result;
}

export namespace Series {
  export function zip(a: number[], b: number[]): [number, number][] {
    const size = Math.max(a.length, b.length);
    const result: [number, number][] = new Array(size).fill([0, 0]);
    for (let i = 0; i < size; i += 1) {
      result[i] = [a[i] || 0, b[i] || 0];
    }
    return result;
  }

  export function ident(a: number[]): number[] {
    return a;
  }

  export function sub(a: number[], b: number[]): number[] {
    return zip(a, b).map(([_a, _b]) => _a - _b);
  }

  export function div(a: number[], b: number[]): number[] {
    return zip(a, b).map(([_a, _b]) => _a / (_b || 1));
  }
}
