/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */

import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';
import { updateAuthStoreTokenInfo, useAuthStore } from '@keymono/services';

import {
  IApiClient,
  IApiConfiguration,
  IApiError,
  IApiResponse,
  TApiResponseError,
} from '../types';

import {
  EXPIRED_SESSION_REFRESH_TOKEN,
  formatErrorInAPICall,
} from '../api-errors/formatErrorInAPICall';

import { validateOrRenewAppSession } from './validateOrRenewAppSession';

const JSON_HEADER = 'application/json';

/**
 * -----------------------------------------------------------------------------
 * This is the base class to be extended by all the other API clients.
 * It's the direct gateway to axios methods and creates type-safe wrappers
 * around the axios methods to be implemented by the instance clients
 * - such as the `UserAccountsAPIClient`.
 *
 * - It adds error handling logic throw interceptors and overrides successful
 * request to add additional success keys.
 * - Also injects tokens on sessions that require auth, and check for token
 * validity or refreshes it behind the scenes if valid. Will log suspend calls
 * if token is expired.
 *
 * @param IApiConfiguration config - axios Request Config.
 * @link [AxiosRequestConfig](https://github.com/axios/axios#request-config)
 */
export abstract class ApiClient implements IApiClient {
  protected readonly client: AxiosInstance;

  baseURL = '';

  apiVersion = '';

  /**
   * Creates an instance of the `ApiClient`.
   * @param config IApiConfiguration
   */
  public constructor(config: IApiConfiguration) {
    this.client = ApiClient.createAxiosClient(config);
    this.baseURL = config.baseURL || '';
    this.apiVersion = config.apiVersion || '';

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  protected static createAxiosClient(
    apiConfiguration: IApiConfiguration
  ): AxiosInstance {
    const { accessToken, responseType, timeout, contentType, acceptHeader } =
      apiConfiguration;

    return axios.create({
      baseURL: `${apiConfiguration.baseURL}${
        apiConfiguration.apiVersion || ''
      }`,
      responseType: responseType || 'json',
      timeout: timeout ? timeout * 1000 : 10 * 1000,
      headers: {
        'Content-Type': contentType || JSON_HEADER,
        Accept: acceptHeader || JSON_HEADER,
        ...(accessToken ? { Authorization: `Token ${accessToken}` } : {}),
      },
    });
  }

  private interceptAxiosRequestConfig = async (config: IApiConfiguration) => {
    const { baseURL, requiresAuth = true, accessToken } = config;
    let authHeader = {};
    const regionBaseURL = baseURL;

    const { activeSession } = useAuthStore.getState();

    /**
     * If we have a given accessToken give it high priority over the users
     * active session token.
     */
    if (accessToken) {
      authHeader = {
        Authorization: `Token ${accessToken}`,
      };
    } else if (requiresAuth) {
      /**
       * If this endpoints needs a Bearer token from the identity service, check
       * for existing tokens validity, refresh them if possible otherwise throw
       * session timeout error on request.
       */
      const tokenValidity = await validateOrRenewAppSession(
        activeSession?.token || '',
        activeSession?.expiry || ''
      );

      /**
       * This is a temporary gate to fix issues with unstable session renewal
       * triggers from the front-end.
       */
      const skipSessionCheck = true;

      // TODO: This is a noop block. To bypass session checks.
      if (skipSessionCheck) {
        // Remove it later on updating session renewal logic.

        authHeader = {
          Authorization: `Token ${activeSession?.token || ''}`,
        };
      } else if (!tokenValidity.hasToken) {
        const { error } = tokenValidity;
        const errorObject = {
          ...error,
          name: EXPIRED_SESSION_REFRESH_TOKEN,
          config: {
            baseURL: regionBaseURL,
            url: config.url,
          },
        };

        updateAuthStoreTokenInfo({
          token: '',
          refreshToken: '',
          userId: activeSession?.userId || '',
          isExpired: true,
          expiry: '',
        });

        throw errorObject;
      } else {
        const { token, expiry } = tokenValidity;

        updateAuthStoreTokenInfo({
          token,
          refreshToken: '',
          userId: activeSession?.userId || '',
          isExpired: false,
          expiry,
        });

        authHeader = {
          Authorization: `Token ${token}`,
        };
      }
    }

    const requestConfig = {
      ...config,
      baseURL: regionBaseURL,
      headers: {
        ...authHeader,
        ...config.headers,
      },
    };

    return requestConfig;
  };

  private interceptAxiosRequestError = (error: Error) => Promise.reject(error);

  private initializeRequestInterceptor = () => {
    this.client.interceptors.request.use(
      this.interceptAxiosRequestConfig,
      this.interceptAxiosRequestError
    );
  };

  private interceptAxiosResponse = (response: AxiosResponse) => ({
    ...(response || {}),
    success: true,
  });

  protected interceptAxiosResponseError = (error: TApiResponseError) => {
    const { activeSession } = useAuthStore.getState();
    const errorObject = formatErrorInAPICall<IApiConfiguration['data']>(
      error,
      activeSession?.userId || '0'
    );

    errorLogToRemoteUtil({
      error,
      errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
      errorTitle: errorObject.type,
      message: errorObject.message,
    });

    throw errorObject;
  };

  private initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(
      this.interceptAxiosResponse,
      this.interceptAxiosResponseError
    );
  };

  /**
   * Wrapper around the axios post request to `POST` request calls to the axios
   * client.
   *
   * @param endpoint The URL of the request
   * @param payload
   * @param config
   * @returns
   */
  public post = async <TResponseData = any, TRequestPayload = any>(
    endpoint: string,
    payload: TRequestPayload,
    config?: IApiConfiguration<TRequestPayload>
  ): Promise<IApiResponse<TResponseData> | IApiError<TRequestPayload>> => {
    try {
      const response = await this.client.post<
        TResponseData,
        IApiResponse<TResponseData>
      >(endpoint, payload, config);

      return response;
    } catch (error) {
      return error as IApiError;
    }
  };

  public patch = async <TResponseData = any, TRequestPayload = any>(
    endpoint: string,
    payload: TRequestPayload,
    config?: IApiConfiguration<TRequestPayload>
  ): Promise<IApiResponse<TResponseData> | IApiError<TRequestPayload>> => {
    try {
      return this.client.patch<TResponseData, IApiResponse<TResponseData>>(
        endpoint,
        payload,
        config
      );
    } catch (error) {
      return error as IApiError;
    }
  };

  public get = async <
    TResponseData = any,
    TRequestConfig = any,
    TErrorFields = any
  >(
    endpoint: string,
    config?: IApiConfiguration<TRequestConfig>
  ): Promise<IApiResponse<TResponseData> | IApiError<TErrorFields>> => {
    try {
      return this.client.get<TResponseData, IApiResponse<TResponseData>>(
        endpoint,
        config
      );
    } catch (error) {
      return error as IApiError;
    }
  };

  public delete = async <
    TResponseData = any,
    TRequestConfig = any,
    TErrorFields = any
  >(
    endpoint: string,
    config?: IApiConfiguration<TRequestConfig>
  ): Promise<IApiResponse<TResponseData> | IApiError<TErrorFields>> => {
    try {
      return this.client.delete<TResponseData, IApiResponse<TResponseData>>(
        endpoint,
        config
      );
    } catch (error) {
      return error as IApiError;
    }
  };
}
