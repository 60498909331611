import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';
import { ISendOrgInvitationsData } from '../types';

import { ORGANIZATION_API } from '../organizationAPIClient';
import { QUERY_FETCH_INVITATIONS_LIST_KEY } from './useInvitationsListQuery';

const MUT_SEND_INVITATION = 'send-invitation-mutation-key';

interface IUseSendInvitationOptions {
  onSuccessCallback: (sendInviteData: ISendOrgInvitationsData) => void;
  onErrorCallback?: (error: IApiError) => void;
  orgId: string;
}

/**
 * This mutation creates a new invitation for a member to join an org via email.
 */
export const useSendInvitationMutation = ({
  onSuccessCallback,
  onErrorCallback,
  orgId,
}: IUseSendInvitationOptions) => {
  const reactQueryClient = useQueryClient();
  const { mutate, isLoading, data, error } = useMutation({
    mutationKey: [MUT_SEND_INVITATION],
    networkMode: 'always',
    retry: false,
    mutationFn: ORGANIZATION_API.sendOrganizationInvitation,
    onSuccess: (responseData) => {
      reactQueryClient.invalidateQueries([
        QUERY_FETCH_INVITATIONS_LIST_KEY,
        orgId,
      ]);
      onSuccessCallback(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Send Organization Invitation Attempt',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    data,
    submitOrganizationInvitation: mutate,
  };
};
