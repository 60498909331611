import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';

import { IFetchOrgContractsData } from '../types';
import { ORGANIZATION_API } from '../organizationAPIClient';

/**
 * This is to track the fetched org contract agreements in app memory.
 */
export const QUERY_FETCH_ORG_CONTRACTS = 'fetch-org-contracts-query-key';

interface IUseOrganizationDetailsOptions {
  orgId: string;
  onSuccessCallback?: (orgData: IFetchOrgContractsData) => void;
}

/**
 * This performs a get request that retrieves the contracts/agreements details
 * of a specific organization.
 */
export const useOrganizationContractsQuery = ({
  orgId,
  onSuccessCallback,
}: IUseOrganizationDetailsOptions) => {
  const { data, isLoading, isError, error, isPaused, refetch } = useQuery({
    queryKey: [QUERY_FETCH_ORG_CONTRACTS, orgId],
    enabled: !!orgId,
    queryFn: () => ORGANIZATION_API.fetchOrgContracts(orgId),
    onSuccess: (response) => {
      onSuccessCallback?.(response);
    },
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isLoading,
    isError,
    isPaused,
    refetch,
  };
};
