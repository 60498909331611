import { useRouter } from 'next/router';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@keymono/design-system';
import { IFetchOrderDetails } from '@keymono/apis';

import { formatDate, statusColors } from '../utilities';
import { ColItemStatusLabel } from '../OrdersOverview/OrdersList/OrdersListTable/ColItems';

interface IOrderDetailsHeaderProps {
  extId: IFetchOrderDetails['extId'];
  orderStatus: IFetchOrderDetails['orderStatus'];
  orderStatusLabel: IFetchOrderDetails['orderStatusLabel'];
  channelTimestamp: IFetchOrderDetails['channelTimestamp'];
}

/**
 * -----------------------------------------------------------------------------
 * This component renders the header part of the Order Details Page.
 */
export function OrderDetailsHeader({
  extId,
  orderStatus,
  orderStatusLabel,
  channelTimestamp,
}: IOrderDetailsHeaderProps) {
  const { back: nextRouterGoBack } = useRouter();

  const orderDateFormatted = formatDate(channelTimestamp, true);
  const orderSource = 'Amazon';
  const orderReceiptSummary = `${orderDateFormatted} from ${orderSource}`;

  const handleOnClickBackButton = () => {
    nextRouterGoBack();
  };

  return (
    <div className="flex justify-between gap-4 max-sm:flex-col sm:items-start">
      <div className="flex items-start gap-x-8">
        <IconButton
          type="button"
          loading={false}
          rightIcon={<ChevronLeftIcon width={24} />}
          className="w-max rounded-lg border-gray-500 bg-white
            text-gray-500 shadow-sm hover:border-red-100 hover:bg-red-50"
          onClick={handleOnClickBackButton}
        />
        <div className="flex flex-col gap-2 max-sm:gap-4">
          <div className="flex items-center gap-2 max-sm:flex-col max-sm:items-start">
            <h1 className="text-lg font-medium text-gray-900">{extId}</h1>
            <ColItemStatusLabel
              className="w-max lg:col-span-5"
              status={orderStatusLabel}
              color={statusColors[orderStatus]}
            />
          </div>
          <span className="text-sm text-gray-700">{orderReceiptSummary}</span>
        </div>
      </div>
    </div>
  );
}
