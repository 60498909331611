import { IBaseUIErrorOptions, CardSecButton } from '@keymono/design-system';
import { Text, useTranslations } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * This renders the error state of `InvitationsList`.
 */
export function InvitationsListError({
  error,
  onRetryAction,
}: IBaseUIErrorOptions) {
  const t = useTranslations('Invitations.invitationsListError');

  if (!error || !onRetryAction) return null;

  const { message, description } = error;

  return (
    <div className="flex flex-col items-center gap-2 pt-5 text-sm text-red-700">
      <h2>{message}</h2>
      {description ? <h3>{description}</h3> : null}

      <CardSecButton
        className="mt-4 flex justify-center px-12"
        onClick={onRetryAction.onClick}
      >
        <Text text={onRetryAction.label || t('retry')} />
      </CardSecButton>
    </div>
  );
}
