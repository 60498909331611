import dynamic from 'next/dynamic';

/**
 * This dynamically imports `ActiveAccountsLoginForm` content at the lazily when
 * required.
 * This helps prevent nextjs hydration errors.
 */
const DynamicActiveAccountsLoginForm = dynamic(
  () => import('./ActiveAccountsLoginForm'),
  { ssr: false }
);

export default DynamicActiveAccountsLoginForm;
