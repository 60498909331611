import { useAuthStore } from '@keymono/services';
import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';

import { ORGANIZATION_API } from '../organizationAPIClient';

export const QUERY_FETCH_ALL_ORGANIZATIONS_KEY = 'fetch-all-organizations-key';

/**
 * Custom hook to fetch the list of Organizations for the current user.
 */
export function useAllOrganizationsQuery() {
  const { activeSession } = useAuthStore();

  const userId = activeSession?.userId || '';

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [QUERY_FETCH_ALL_ORGANIZATIONS_KEY, userId],
    queryFn: () => ORGANIZATION_API.fetchAllOrganizations(),
    enabled: !!userId, // Check wether user is logged in or not.
    onError: (err: IApiError) => err,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
}
