import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';

/**
 * -----------------------------------------------------------------------------
 * Navigates the user to the login screen, from sign up
 */
export function LoginCTA() {
  const t = useTranslations('ForgotPassword');

  return (
    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
      <span className="">{t('loginCTA.subTitle')}</span>
      <Link href="/auth/login" className="form-link-action -mx-2 block">
        {t('loginCTA.title')}
      </Link>
    </p>
  );
}
