import { UserIcon } from '@heroicons/react/24/outline';

import { IFetchOrderDetails } from '@keymono/apis';
import { CardWithActions } from '@keymono/design-system';

import { OrderCustomerSummaryDisplay } from './OrderCustomerSummaryDisplay';

interface IOrderCustomerSummaryProps {
  className?: string;
  shippingDetails: IFetchOrderDetails['shippingDetails'];
}

/**
 * -----------------------------------------------------------------------------
 * Renders the details related to the customer in an order.
 */
export function OrderCustomerSummary({
  className = '',
  shippingDetails,
}: IOrderCustomerSummaryProps) {
  /**
   * Main icon feature that distinguishes the card info.
   */
  const cardIcon = {
    Icon: UserIcon,
    className: 'text-teal-700 bg-teal-50',
  };

  return (
    <CardWithActions
      className={`max-sm:w-full ${className}`}
      actions={[]}
      isSuccessVisible={false}
      cardIcon={cardIcon}
    >
      <OrderCustomerSummaryDisplay shippingDetails={shippingDetails} />
    </CardWithActions>
  );
}
