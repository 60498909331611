import { IllustrationHangingReports } from '@keymono/design-system';

/**
 * -----------------------------------------------------------------------------
 * This renders the summary info of all reportable content for an organization.
 */
export function ReportsOverview() {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <IllustrationHangingReports />
      <h2 className="max-w-xl py-4 text-2xl text-gray-600">
        Sorry, Nothing to Report. Yet!
      </h2>
      <p className="max-w-sm text-center text-sm text-teal-700 ">
        You have not made any transaction. You can start by adding a few
        products in your inventory, or create several categories. We aggregate
        all your sales and provide you with detailed reports.
      </p>
    </div>
  );
}
