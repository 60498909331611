import { useRouter } from 'next/router';

import { OrgOverviewHeader } from '../../org-details';

import { SendNewInvitation } from './SendNewInvitation';
import { OrgInvitationsList } from './OrgInvitationsList';

/**
 * -----------------------------------------------------------------------------
 * Primary feature component for the Org Invitations page.
 * Renders the Org overview header, Send New Invitation feature and the list of pending invitations.
 */
export function InvitationSummary() {
  const { query } = useRouter();
  const orgId = (query.organizationId || '') as string;

  return (
    <div className="flex flex-col gap-y-2">
      <OrgOverviewHeader orgId={orgId} />
      <section className="flex flex-col rounded-lg bg-white px-4 py-8 shadow-gray-100 sm:px-6 lg:px-8">
        <div className="justify-center border-b border-gray-50 pb-4 sm:flex sm:items-center">
          <SendNewInvitation
            className=" w-full lg:max-w-2xl"
            orgId={orgId}
            showUserGroupIcon={false}
          />
        </div>

        <OrgInvitationsList orgId={orgId} />
      </section>
    </div>
  );
}
