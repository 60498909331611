import { useTranslations } from 'next-intl';
import { CheckIcon } from '@heroicons/react/24/outline';

import { useCancelInvitationMutation } from '@keymono/apis';

import { ActionContainer } from './ActionContainer';

interface ICancelInvitationCTAProps {
  id: string;
  orgId: string;
}

/**
 * -----------------------------------------------------------------------------
 * Provides an action that allows a organization admin to cancel a pending
 * invitation for which the invitee has not accepted.
 */
export function CancelInvitationCTA({ id, orgId }: ICancelInvitationCTAProps) {
  const t = useTranslations('Invitations.cancel');

  const { data, isLoading, error, cancelOrganizationInvitation } =
    useCancelInvitationMutation({ id, orgId });

  const handleClick = () => {
    cancelOrganizationInvitation({ id, orgId });
  };

  return (
    <ActionContainer
      actionType="secondary-action"
      errorMessage={error?.message || null}
      Icon={CheckIcon}
      isLoading={isLoading}
      isSuccess={!!data}
      loadingMessage={t('loading')}
      successMessage={t('success')}
      onActionPress={handleClick}
      title={t('title')}
      titleRetry={t('retry')}
    />
  );
}
