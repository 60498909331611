import { useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';
import { IResendOrgInvitationsData } from '../types';

import { ORGANIZATION_API } from '../organizationAPIClient';

const MUT_RESEND_INVITATION = 'resend-invitation-mutation-key';

interface IUseResendInvitationOptions {
  id: string;
  onSuccessCallback?: (sendInviteData: IResendOrgInvitationsData) => void;
  onErrorCallback?: (error: IApiError) => void;
}

/**
 * This mutation resends an exisiting invitation via email to join an org.
 */
export const useResendInvitationMutation = ({
  id,
  onSuccessCallback,
  onErrorCallback,
}: IUseResendInvitationOptions) => {
  const { mutate, isLoading, data, error } = useMutation({
    mutationKey: [MUT_RESEND_INVITATION, id],
    networkMode: 'always',
    retry: false,
    mutationFn: ORGANIZATION_API.resendOrganizationInvitation,
    onSuccess: (responseData) => {
      onSuccessCallback?.(responseData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Resending the Organization Invitation Attempt',
        message: err.message,
      });
      onErrorCallback?.(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    data,
    resendOrganizationInvitation: mutate,
  };
};
