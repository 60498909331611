import { IOrganizationDetails } from '@keymono/shared-types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';

import { ORGANIZATION_API } from '../organizationAPIClient';

import { QUERY_FETCH_ORG_DETAILS } from './useOrganizationDetailsQuery';

/**
 * This is to track the organization details update mutation keys in react query cache.
 */
const MUT_UPDATE_ORG_DETAILS = 'update-org-details-mutation-key';

interface IUeUpdateOrgDetailsOptions {
  orgId: string;
  onSuccessCallback: (updatedOrgData: IOrganizationDetails) => void;
}

/**
 *  Mutation to handle updating of the organization details/profile information.
 */
export const useUpdateOrgDetailsMutation = ({
  orgId,
  onSuccessCallback,
}: IUeUpdateOrgDetailsOptions) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_UPDATE_ORG_DETAILS],
    mutationFn: ORGANIZATION_API.updateOrganizationDetails,
    onSuccess: (orgData) => {
      queryClient.setQueryData([QUERY_FETCH_ORG_DETAILS, orgId], orgData);
      onSuccessCallback(orgData);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Updating the Organization details.',
        message: err.message,
      });
      return err;
    },
  });

  return {
    error,
    isLoading,
    updateOrgDetails: mutate,
  };
};
