import { useQuery } from '@tanstack/react-query';

import { IApiError } from '../../../../types';

import { ORGANIZATION_API } from '../organizationAPIClient';
import { IFetchOrgInvitationsListData } from '../types';

export const QUERY_FETCH_INVITATIONS_LIST_KEY = 'fetch-invitations-list-key';

interface IUseOrganizationDetailsOptions {
  orgId: string;
  onSuccessCallback?: (orgData: IFetchOrgInvitationsListData) => void;
}

/**
 * This fetches a list of all invitations for the user to join an organization.
 */
export const useInvitationsListQuery = ({
  orgId,
  onSuccessCallback,
}: IUseOrganizationDetailsOptions) => {
  const { data, isLoading, isError, error, isPaused, refetch } = useQuery({
    queryKey: [QUERY_FETCH_INVITATIONS_LIST_KEY, orgId],
    networkMode: 'always',
    // retry: true,
    enabled: !!orgId, // Don't fetch if org id is not loaded.
    queryFn: () => ORGANIZATION_API.fetchInvitationsList(orgId),
    onSuccess: (response) => {
      onSuccessCallback?.(response);
    },
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isLoading,
    isError,
    isPaused,
    refetch,
  };
};
