import tw, { styled } from 'twin.macro';

export const CardWrapper = styled.div`
  ${tw`
    relative
    flex
    flex-col
    overflow-hidden
    rounded-xl
    border
    border-gray-100
    bg-white
    shadow-lg
    shadow-gray-100/10

    transition-all
    duration-1000
    ease-in

    hover:(border-red-100 shadow-red-50/60)
  `};
`;

export const CardThumbnailWrapper = styled.div`
  ${tw`
    aspect-auto
    h-56

    transition-all
    duration-1000
    ease-in
    group-hover:(bg-red-50/50 opacity-75)
  `};

  .thumbnail {
    ${tw`
      h-full
      w-full
      object-contain
      object-center
      p-20

      sm:(h-full w-full)
    `};
  }
`;

export const OrganizationBadgeWrapper = styled.div`
  ${tw`
    absolute
    flex
    top-1
    gap-2
    px-4
    py-2

    ltr:right-0
    rtl:left-0
  `};

  .organization-badge-container {
    ${tw`
      inline-flex
      items-center
      rounded-full
      px-2.5
      py-0.5
      text-xs
      font-medium
      border
      bg-green-50
      border-green-800/20
      text-green-700
    `}
  }

  .organization-badge-label {
    ${tw`
      text-xs
      font-medium
    `}
  }
`;

export const CategoryBadgeWrapper = styled.div`
  ${tw`
    -mx-1
    px-2.5
    py-0.5
    inline-flex
    items-center
    rounded-full
    bg-red-50
    text-xs
    font-medium
    text-red-900
    opacity-80
  `};
`;

export const CardBodyWrapper = styled.div`
  ${tw`
    flex
    flex-1
    flex-col
    space-y-2
    p-4

    transition-all
    duration-500
    ease-in

    group-hover:bg-red-50/20
  `};

  h3 {
    ${tw`
      text-base
      font-semibold
      text-gray-800
    `}
  }

  .description {
    ${tw`
      leading-5
      py-2
      text-xs
      text-gray-700
    `}
  }
`;
