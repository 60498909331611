import { useUserDetailsQuery } from '@keymono/apis';
import { useTranslations } from '@keymono/i18n';

import {
  InvitationsList,
  InvitationsListFallback,
  NoPendingInvitation,
} from './InvitationsList';

interface IUserInvitationsListProps {
  title: string;
}

/**
 * -----------------------------------------------------------------------------
 * This component fetches the data and renders the list of all pending invitations for a user.
 * uses the `useUserDetailsQuery` hook to fetch the data.
 * uses the `InvitationsList` component to render the list.
 * uses the `InvitationsListFallback` component to render the loading and error states.
 * uses the `NoPendingInvitation` component to render the empty state.
 */
export function UserInvitationsList({ title }: IUserInvitationsListProps) {
  const t = useTranslations('Invitations.noPendingInvitationsUser');
  const { data, isLoading, error, refetch } = useUserDetailsQuery({});

  const handleRetry = () => {
    refetch();
  };

  const invitations = data?.invitation;
  const prevInvitationsCount = invitations?.length || 4;

  if (isLoading || error || !invitations) {
    return (
      <>
        <h1 className="text-2xl font-bold">{title}</h1>
        <InvitationsListFallback
          isLoading={isLoading}
          error={error}
          invitationsCount={prevInvitationsCount}
          onRetryAction={{
            onClick: handleRetry,
          }}
        />
      </>
    );
  }

  const invitationsCount = invitations.length;

  if (invitationsCount <= 0) {
    return <NoPendingInvitation title={t('title')} message={t('message')} />;
  }

  return (
    <>
      <h1 className="text-2xl font-bold">{title}</h1>
      <InvitationsList invitations={invitations} />
    </>
  );
}
