import { Button, IllustrationCreateOrgChannel } from '@keymono/design-system';

/**
 * -----------------------------------------------------------------------------
 * This renders the UI to prompt the user to add a channel.
 */
export function AddChannels() {
  return (
    <div
      className="
        flex min-h-full w-full flex-1 flex-col
        items-center justify-center overflow-hidden rounded-lg
        bg-white px-4 py-8 text-center shadow-sm shadow-gray-100
      "
    >
      <IllustrationCreateOrgChannel className="w-[50%] sm:w-[45%]" />
      <p className="max-w-xl text-sm text-gray-600">Coming Soon</p>
      <div className="my-8">
        <Button
          className="px-20 sm:w-auto"
          type="submit"
          isEnabled={false}
          isLoading={false}
          label="Add Channels"
          loadingLabel="Please wait"
        />
      </div>
      <p className="max-w-xl text-sm text-teal-700">
        Here, you soon be able to add your commerce channels...
      </p>
    </div>
  );
}
