import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

import { IFetchOrderDetails } from '@keymono/apis';

interface IOrderCustomerSummaryDisplayProps {
  shippingDetails: IFetchOrderDetails['shippingDetails'];
}

/**
 * -----------------------------------------------------------------------------
 * Renders the details of the customer in an order.
 */
export function OrderCustomerSummaryDisplay({
  shippingDetails,
}: IOrderCustomerSummaryDisplayProps) {
  const {
    shippingName,
    shippingLine1,
    shippingLine2,
    shippingCity,
    shippingState,
    shippingZip,
    shippingCountry,
  } = shippingDetails;

  const addressLine3 = `${shippingCity}, ${shippingState} ${shippingZip}, ${shippingCountry}`;

  return (
    <div className="flex flex-col gap-y-10 rounded-lg bg-white sm:min-w-[22rem]">
      <div className="flex w-full flex-col items-start">
        <p className="text-base font-medium text-gray-900">Name</p>
        <p className="text-sm text-gray-700">{shippingName}</p>
      </div>
      <div className="flex w-full flex-col">
        <p className="text-base font-medium text-gray-900">Address</p>
        <div className="flex w-full justify-between">
          <div className="flex flex-col">
            <p className="text-sm text-gray-700">{shippingLine1}</p>
            <p className="text-sm text-gray-700">{shippingLine2}</p>
            <p className="text-sm text-gray-700">{addressLine3}</p>
          </div>
          <DocumentDuplicateIcon width={24} className="text-gray-700" />
        </div>
      </div>
    </div>
  );
}
