import { useI18n } from '@keymono/i18n';
import { SVGContentLoader } from '@keymono/design-system';

/**
 * -----------------------------------------------------------------------------
 * This renders the skeleton loader of an individual Invitation item.
 */
export function InvitationsListLoaderCard() {
  const { isRTL } = useI18n().language;

  return (
    <li className="py-4">
      <div className="flex items-center gap-x-4">
        <div className="flex-shrink-0">
          <SVGContentLoader
            speed={2}
            height={32}
            viewBox="0 0 32 32"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            rtl={isRTL}
          >
            <circle cx="16" cy="16" r="16" />
          </SVGContentLoader>
        </div>
        <div className="min-w-0 flex-1">
          <SVGContentLoader
            speed={2}
            height={60}
            viewBox="0 0 120 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            rtl={isRTL}
          >
            <rect x="0" y="5" rx="3" ry="3" width="120" height="10" />
            <rect x="0" y="25" rx="3" ry="3" width="100" height="10" />
            <rect x="0" y="45" rx="3" ry="3" width="60" height="10" />
          </SVGContentLoader>
        </div>
        <SVGContentLoader
          speed={2}
          height={40}
          viewBox="0 0 110 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          rtl={isRTL}
        >
          <rect x="0" y="12" rx="10" ry="10" width="50" height="15" />
          <rect x="60" y="12" rx="10" ry="10" width="50" height="15" />
        </SVGContentLoader>
      </div>
    </li>
  );
}
