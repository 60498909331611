import { useQuery } from '@tanstack/react-query';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

import { IApiError } from '../../../../types';

import { IVerifyEmailData } from '../types';
import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to email verification keys in cache
 */
const QUERY_VERIFY_EMAIL = 'verify-email-mutation-key';

interface IUseSignUpOptions {
  token: string;
  onSuccessCallback: (verifiedEmailData: IVerifyEmailData) => void;
}

/**
 *  Mutation to that handles email verification
 */
export const useEmailVerificationQuery = ({
  token,
  onSuccessCallback,
}: IUseSignUpOptions) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [QUERY_VERIFY_EMAIL, token],
    networkMode: 'always',
    enabled: !!token,
    queryFn: () => USER_ACCOUNTS_API.verifyEmail({ token }),
    onSuccess: (response) => {
      onSuccessCallback(response);
    },
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Email Verification Attempt',
        message: err.message,
      });
      return err;
    },
  });

  return {
    data,
    error,
    isLoading,
    postVerifyEmail: refetch,
  };
};
