import { IOrganization } from '@keymono/apis';

import { OrganizationListCard } from './OrganizationListCard';

interface IAllOrganizationsListProps {
  organizations: IOrganization[];
}

/**
 * ----------------------------------------------------------------------------
 * Renders all Organizations in a List View.
 */
export function AllOrganizationsList({
  organizations,
}: IAllOrganizationsListProps) {
  return (
    <div
      className="grid grid-cols-1 gap-4 border-b border-gray-100 pb-8
    sm:grid-cols-2 xl:grid-cols-3"
    >
      {organizations.map((organization) => (
        <OrganizationListCard
          organization={organization}
          key={organization.id}
        />
      ))}
    </div>
  );
}
