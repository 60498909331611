import { IllustrationEmptyBox } from '@keymono/design-system';

/**
 * -----------------------------------------------------------------------------
 * This renders the UI to prompt the user to add a channel.
 */
export function InventoryOverview() {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <IllustrationEmptyBox />
      <h2 className="max-w-xl py-4 text-2xl text-gray-600">
        No Inventory Found!
      </h2>
      <p className="max-w-sm text-center text-sm text-teal-700 ">
        Oops. Such emptiness. Please get started by creating some Products and
        Categories.
      </p>
    </div>
  );
}
