import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiClientError } from '../../api-client-v2';
import { login, logout } from './authentication.api';
import type {
  LoginDTI,
  LoginDTO,
  LogoutDTI,
  LogoutDTO,
} from './authentication.api';

function USE_LOGIN_MUTATION_KEY() {
  return ['authentication.login'] as const;
}

type UseLoginOptions = Omit<
  UseMutationOptions<LoginDTI, unknown, LoginDTO>,
  'mutationFn' | 'mutationKey'
>;
export function useLogin(options?: UseLoginOptions) {
  return useMutation({
    mutationKey: USE_LOGIN_MUTATION_KEY(),
    mutationFn: async (request) => {
      const data = await login(request);
      return data;
    },
    ...options,
  });
}

function USE_LOGOUT_MUTATION_KEY() {
  return ['authentication.logout'] as const;
}

type UseLogoutOptions = Omit<
  UseMutationOptions<
    LogoutDTI,
    ApiClientError<'server'> | ApiClientError<'client'>,
    LogoutDTO
  >,
  'mutationFn' | 'mutationKey'
>;
export function useLogout(options?: UseLogoutOptions) {
  return useMutation({
    mutationKey: USE_LOGOUT_MUTATION_KEY(),
    mutationFn: async (request) => {
      const data = await logout(request);
      return data;
    },
    ...options,
  });
}
